import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import H6 from '@material-tailwind/react/Heading6';
import SidebarItem from "./SidebarItem";
import items from "../data/sidebar.json";

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-left-64');
    const location = useLocation().pathname.replace(/\//g, '');
    // console.log('locationlocation', location)

    return (
        // <>
        // {location != "logmasuk" ?
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-20 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        // href="https://material-tailwind.com?ref=mtd"
                        // target="_blank"
                        // rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <H6 color="gray">Sistem <br />Pengesanan &<br />Maklumat Haiwan</H6>
                        <span className='text-xs text-gray-500'>Majlis Bandaraya Seberang Perai</span>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        { items.map((item, key) => <SidebarItem num={key} item={item} />) }
                        
                    </div>
                </div>
            </div>
        </>
        // : ''}
        // </>
    );
}
