import React, { useState, useEffect } from 'react';
import { Button } from '@material-tailwind/react';
import { DialogActions, DialogContent, Tooltip, MenuItem, TextField } from '@mui/material';
import CustomModal from './CustomModal';
import { useApplicationView } from 'query/actions/Crud';
import View from './ActionButton/View';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import Example from './Spinner/Example';
import { Spinner } from './Spinner';
import { useRegisterStaff } from 'query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateStaff } from 'query/admin';
import { useSearchStaffInfo } from 'query/admin';
import { useRoles } from 'query/admin';

export default function ButtonGroupActionPentadbir({isAdmin, id, data, status, channel, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);

    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }
    
    // view
    const getApplicationView = async (data) => {
        await mutateView(data, {
            onSuccess: (data) => {
                setResults(data.data.data)
            }
        })
    }

    const actionButton = (id, action) => {
        // let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
        // let data = {'id': id, 'user': accessToken}
        // if(action == 'Lihat'){
        //     setTitle('Butiran Permohonan')
        //     // getApplicationView(data)
        // }
        setSize('sm')
        setBackdropClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Kemaskini Pentadbir"; break;
            case 2: titleModal = "Padam Pentadbir"; break;
            // case 1: titleModal = "Butiran Permohonan"; setSize('lg'); setBackdropClose(true); break;
            // case 2: titleModal = "Status Permohonan"; break;
            // case 3: titleModal = "Status Permohonan"; break;
            // case 4: titleModal = "Status Permohonan"; break;
            // case 5: titleModal = "Pengesahan Bayaran"; break;
            // case 6: titleModal = "Cetak Bil"; setSize('md'); setBackdropClose(true); break;
            // case 7: titleModal = "Status Permohonan";
        }
        setTitle(titleModal)
        setPage(<UpdateStaff closeModal={closeModal} data={data} type={action} setRefreshTable={setRefreshTable} />)
        setShowModal(true)
    }

    return (
        <>
        <div className='flex flex-wrap my-4'>
            <div className={`p-1`}>
                <Tooltip title="Kemaskini" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="orange"
                        iconOnly
                        onClick={() => actionButton(data.admin_id, 1)}
                    >
                        <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            <div className={`p-1`}>
                <Tooltip title="Padam" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="red"
                        iconOnly
                        onClick={() => actionButton(data.admin_id, 2)}
                    >
                        <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
        </div>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={size}
                title={title}
                content={page}
                backdropClose={backdropClose}
            /> : 
        ''}
        </>
    )
}

function UpdateStaff({type, data, closeModal, setRefreshTable}){    
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useUpdateStaff();
    const { mutate: mutateSearch, isLoading: loadngSearch } = useSearchStaffInfo();
    const roles = useRoles();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        id: Yup.string().required(`Ruangan Wajib Diisi`),
        name: Yup.string().required(`Ruangan Wajib Diisi`),
        department: Yup.string().required(`Ruangan Wajib Diisi`),
        role: Yup.string().required(`Ruangan Wajib Diisi`),
        email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`)
    });
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, getValues } = useForm({
        defaultValues: {
            id: '',
            name: '',
            department: '',
            role: '',
            email: ''
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (dataSubmit) => {
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        dataSubmit['admin_id'] = data.admin_id
        await mutate(dataSubmit, {
            onSuccess: (data) => {
                Notify(data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }

    const passwordVisibility = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        reset({
          id: data.staff_id,
          name: data.name,
          department: data.department,
          role: data.role_id,
          email: data.email
        })
    }, [data])

    function onStatusUpdate(type){
        let d = {
            status: type
        }
        onSubmit(d)
    }

    const searchStaffInfo = async () => {
        console.log('getValues', getValues('id'))
        if(getValues('id')){
            await mutateSearch(getValues('id'), {
                onSuccess: (data) => {
                    console.log('searchStaffInfo', data.data)
                    if(data.data.response.response_code == '200'){
                        reset({
                            id: data.data.data.no_pekerja,
                            name: data.data.data.nama,
                            email: data.data.data.email,
                            department: data.data.data.lokasi,
                        })
                    }
                }
            })
        }
    }

    return (
        <>
        {type == 1 ? 
            isLoading ? <div className='flex justify-center items-center'><Example type='spin' color='black' height='5rem' width='5rem' /></div> :
            // page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-col w-full">
                    <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col my-1">

                            <div className="flex flex-row justify-between w-full /mb-6 font-light">
                                <div className="w-2/3 /mr-4">
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="No. Pekerja"
                                                variant="outlined"
                                                size="small"
                                                // color="secondary"
                                                helperText={errors.id ? errors.id.message : ' '}
                                                error={errors.id ? errors.id : false}
                                                fullWidth
                                            />
                                        )}
                                        control={control}
                                        name="id"
                                    />
                                </div>
                                <div className="/w-1/3">
                                    <Button
                                        // color="green"
                                        type="button"
                                        onClick={searchStaffInfo}
                                        ripple="light"
                                    >
                                        Cari Info Pekerja
                                    </Button>
                                </div>
                            </div>
                            
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nama Pekerja"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.name ? errors.name.message : ' '}
                                            error={errors.name ? errors.name : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="name"
                                />
                            </div>
                            
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Emel"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.email ? errors.email.message : ' '}
                                            error={errors.email ? errors.email : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="email"
                                />
                            </div>

                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Jabatan"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.department ? errors.department.message : ' '}
                                            error={errors.department ? errors.department : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="department"
                                />
                            </div>

                            <span className="block text-sm mt-2 mb-1">Pilih Peranan</span>
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            className='capitalize'
                                            label="Peranan"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors?.role ? errors?.role.message : ' '}
                                            error={errors?.role ? errors?.role : false}
                                            fullWidth
                                        >
                                            {roles.data?.map((role, index) => (
                                                // <option key={index} value={subcategory.id}>
                                                //     {subcategory.subcategory_name}
                                                // </option>
                                                <MenuItem className='capitalize' key={index} value={role.id} >
                                                  {role.title}
                                                </MenuItem>
                                            ))}
                                            {/* <MenuItem value='jantan'>Jantan</MenuItem>
                                            <MenuItem value='betina'>Betina</MenuItem> */}
                                        </TextField>
                                    )}
                                    control={control}
                                    name='role'
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="orange"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Kemaskini
                </Button>
            </DialogActions>
            </>
        : 
            <>
            <DialogContent>
                <div className="flex flex-col my-1">
                    <div className="w-full px-2 mb-6">
                        <h2 className="text-sm">Anda pasti untuk padam?</h2>
                        <div className="mt-4 mb-3">
                            <span className="pr-1 text-gray-500">Info Staf: </span>
                            <span className="font-bold text-sm">{data.email}</span>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {/* {isLoadingUpdate ? 
                    <>
                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                        <span className='ml-2'>Loading...</span>
                    </> :  */}
                    <>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>
                        
                        <Button
                            color="red"
                            onClick={() => onStatusUpdate(3)}
                            ripple="light"
                        >
                            Padam
                        </Button>
                    </>
                {/* } */}
            </DialogActions>
            </>
        }
        </>
        
    )
}