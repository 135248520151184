import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import { useState, useEffect } from 'react';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';

// handle the main routes
function AdminRoute({ component: Component, ...rest }) {
    const userInfo = useRecoilValue(userInfoAtom);
    const type = sessionStorage.getItem('type')
    const [pathname, setPathname] = useState('')
    // console.log('userInfouserInfo',userInfo)

    useEffect(() => {
      console.log('type admin',sessionStorage.getItem('type'))
      if(type == 1){
        setPathname('/v2/tadbir')
        sessionStorage.removeItem('type')
      } else {
        setPathname('/v2/logmasuk')
      }
    }, [type])
  
    return (
      <Route
        // basename={'/v2'}
        {...rest}
        render={(props) =>
          userInfo?.isAdmin ? (
            <>
            <Sidebar />
            <div className="md:ml-64">
                <Component {...props} />
            </div>
            <Footer />
            </>
          ) : (
            <Redirect
              to={{ pathname: pathname }}
            />
          )
        }
      />
    );
  }
  
  export default AdminRoute;