import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { toast } from 'react-toastify';

import * as api from "api/Admin";

// insert
const useNewApplication = () => {

    return useMutation(newApplicationRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const newApplicationRequest = async (data) => {
    return api.newApplicationRequest(data)
}

// list
const useLicenseList = () => {
    let history = useHistory();

    // return useQuery(['ApplicationList', data], () => applicationListRequest(data))

    return useMutation(licenseListRequest, {
        onSuccess: (data) => {
            // toast.success("Kemaskini Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });

            // history.reload()
        },
    });
};
const useAllLicenseList = () => {
    return useMutation(licenseListRequest, {
        onSuccess: (data) => {
        },
    });
};
const licenseListRequest = async (data) => {
    return api.licenseListRequest(data)
}

// list
const useLicenseListReport = () => {
    return useMutation(licenseListReport, {
        onSuccess: (data) => {
        },
    });
};
const licenseListReport = async (data) => {
    return api.licenseListReport(data)
}

// view
const useLicenseView = () => {

    return useMutation(licenseViewRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const licenseViewRequest = async (data) => {
    return api.licenseViewRequest(data)
}

// update
const useApplicationStatusUpdate = () => {
    let history = useHistory();

    return useMutation(applicationStatusUpdateRequest, {
        onSuccess: (data) => {
            toast.success("Kemaskini Berjaya!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            // history.push('/lesen/senarai-permohonan')
            // history.go(0)
            // window.location.reload();
        },
    });
};
const applicationStatusUpdateRequest = async (data) => {
    return api.applicationStatusUpdateRequest(data)
}

// courier list
const useLicenseCourierList = () => {
    return useMutation(licenseCourierListRequest, {
        onSuccess: (data) => {
        },
    });
};
const licenseCourierListRequest = async (data) => {
    return api.licenseCourierListRequest(data)
}

// list
const useSearchInfo = () => {
    return useMutation(searchInfo, {
        onSuccess: (data) => {
        },
    });
};
const searchInfo = async (data) => {
    return api.searchInfo(data)
}

const useEditLicense = () => {
    return useMutation(editLicense, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const editLicense = async (data) => {
    return api.editLicense(data)
}

export { 
    useNewApplication, 
    useLicenseList, 
    useAllLicenseList, 
    useLicenseView,
    useApplicationStatusUpdate,
    useLicenseCourierList,
    useSearchInfo,
    useLicenseListReport,
    useEditLicense
};