import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardStatus from '@material-tailwind/react/CardStatus';
import CardStatusFooter from '@material-tailwind/react/CardStatusFooter';
import Icon from '@material-tailwind/react/Icon';
import PetIcon from 'assets/icon/pet-bed.png';

export default function LicenseCard({
    icon,
    valid,
    title,
    account,
    status,
}) {
    return (
        <div className="px-4 mb-10">
            <Card className="w-72 h-40 bg-license text-white relative">
                <div className='flex justify-end'>
                    <div className='absolute opacity-80 mt-2'>
                        <img src={PetIcon} className="w-24 h-24" />
                    </div>
                </div>
                {/* <div className="h-24"> */}
                <div className='flex flex-col'>
                    <h3 className='text-xs'>{title}</h3>
                    <h1 className='text-xl font-bold'>{account}</h1>
                </div>
                {/* </div> */}
                <div className='absolute bottom-0 mb-3'>
                    <div className='pr-1 bg-white bg-opacity-20 rounded-lg inline-block text-white text-xs'>
                        <span className="font-bold text-white px-2 capitalize">{status}</span>
                        <span className="text-white"><Icon family={'font-awesome'} name={icon} size='xs' color="white" /></span>
                    </div>
                    <div className='text-white text-xs'>
                        <span className="text-white">{valid}</span>
                    </div>
                </div>
            </Card>
        </div>
    );
}
