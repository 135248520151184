import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'
import { ConstructionOutlined, Window } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomModal from '../../components/CustomModal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateCourierInfo } from 'query/actions/Crud'
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useProfile } from 'query';
import { useUpdateProfile } from 'query';
import { useOauthUpdateProfile } from 'query';
import Example from 'components/Spinner/Example';
import ResponseStatus from 'components/ResponseStatus';
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useChangePassword } from 'query';

export default function TukarKatalaluan() {
    const { data, isFetching } = useProfile();
    const {mutate, isLoading} = useChangePassword()
    const [title, setTitle] = useState('');
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(`Ruangan Wajib Diisi`),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Katalaluan Tidak Sama')
    });
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues: {
            password: '',
            confirm_password: ''
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (dataSubmit) => {
        dataSubmit['user_id'] = data.user_id
        console.log('dataSubmit',dataSubmit)
  
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(dataSubmit, {
            onSuccess: (res) => {
              Notify(res.response.response_code, loadingToast)
              closeModal()
            }
        })
      }
      
    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Tukar Katalaluan Berjaya", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            reset()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Kemaskini Gagal", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }
    return (
        <>
        <div className="bg-cyan-500 pt-14 pb-28 px-3 md:px-8 h-auto"></div>

        <div className="px-3 md:px-8 h-auto -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <Card>
                        <CardHeader size='sm' color="green" contentPosition="none">
                            <h2 className="text-white text-xl">Tukar Katalaluan</h2>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-col my-1">
                                    <span className="block text-sm mb-5">Masukkan katalaluan baru.</span>
                                    <div className="w-full lg:w-6/12 mb-6 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <InputIcon
                                                    {...field}
                                                    type="password"
                                                    color="lightBlue"
                                                    size="regular"
                                                    outline={true}
                                                    placeholder="Katalaluan Baru"
                                                    // iconFamily="font-awesome"
                                                    // iconName="fas fa-eye"
                                                    error={errors.password ? errors.password.message : ''}
                                                />
                                            )}
                                            control={control}
                                            name="password"
                                        />
                                    </div>
                                    
                                    <div className="w-full lg:w-6/12 mb-6 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    type="password"
                                                    color="lightBlue"
                                                    size="regular"
                                                    outline={true}
                                                    placeholder="Ulang Katalaluan Baru"
                                                    error={errors.confirm_password ? errors.confirm_password.message : ''}
                                                />
                                            )}
                                            control={control}
                                            name="confirm_password"
                                        />
                                    </div>

                                    <div className="w-full lg:w-6/12 my-4 font-light">
                                        <Button
                                            color="amber"
                                            onClick={handleSubmit(onSubmit)}
                                            ripple="light"
                                            disabled={isLoading}
                                        >
                                            Tukar
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={'sm'}
                title={title}
                content={pageModal}
                backdropClose={false}
            /> : 
        ''}
        </>
    )
}