import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import Image from '@material-tailwind/react/Image';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import ProfilePicture from 'assets/img/team-1-800x800.jpg';
import { useLogout } from 'query/actions/Auth';
import Avatar from '@mui/material/Avatar';

import items from 'data/userSidebar.json'

// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

export default function UserNavbar({ showSidebar, setShowSidebar }) {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, isLoading, isError, error } = useLogout(2);
    const location = useLocation().pathname;
    const [active, setActive] = useState(null);
    const [titleName, setTitleName] = useState(null);
    const [subtitleName, setSubtitleName] = useState(null);
    let history = useHistory();

    useEffect(() => {
        let result = location.split('/').slice(0,3).join('/');
        if(result=='' || result==null){
            // console.log('result2',result)
            result = location.split('/').slice(0,2).join('/');
        }
        setActive(result)
    }, [location])
    
    useEffect(() => {
        items.map((item, index) => {
            if(active == item.path){
                setTitleName(item.title)
            }
            item.childrens?.map((item) => {
                if(location == item.path){
                    setSubtitleName(item.title)
                }
            })
        })
    }, [active])

    const logOut = async () => {
        // history.replace('/logmasuk')
        await mutate();
    }

    return (
        // <div className='fixed w-full z-10'>
            <nav className="bg-cyan-500 md:ml-64 py-6 px-3">
                <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                    <div className="md:hidden">
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowSidebar('left-0')}
                        >
                            <Icon name="menu" size="2xl" color="white" />
                        </Button>
                        <div
                            className={`absolute top-2 md:hidden ${
                                showSidebar === 'left-0' ? 'left-64' : '-left-64'
                            } z-50 transition-all duration-300`}
                        >
                            <Button
                                // color="cyan"
                                // buttonType="link"
                                className="fixed"
                                size="lg"
                                iconOnly
                                rounded
                                ripple="light"
                                onClick={() => setShowSidebar('-left-64')}
                            >
                                <Icon name="close" size="2xl" color="white" />
                            </Button>
                        </div>
                    </div>

                    <div className="flex justify-between items-center w-full">
                        <h4 className="uppercase text-white text-sm tracking-wider mt-1">
                            <span className='lg:block hidden'>
                                {titleName}
                            </span>
                            <span className='lg:block hidden capitalize text-lg font-bold'>
                                {subtitleName}
                            </span>
                        </h4>

                        <div className="flex">

                            <div className="-mr-4 ml-6">
                                {/* <Menu /> */}
                            </div>

                            <div className="-mr-4 ml-6">
                                <Dropdown
                                    color="transparent"
                                    buttonText={
                                        <div className="flex flex-row">
                                            {/* <Image className="w-12" src={ProfilePicture} rounded /> */}
                                            <Avatar
                                                sx={{"&.MuiAvatar-root": {
                                                    backgroundColor: "rgba(3, 169, 244, 0.7)",
                                                    border: 3
                                                }}}
                                            >
                                                <i className="fa-solid fa-user text-white"></i>
                                            </Avatar>
                                            <span className='ml-2 text-white lg:flex md:flex hidden items-center'>{userInfo.name}</span>
                                        </div>
                                    }
                                    rounded
                                    style={{
                                        padding: 0,
                                        color: 'transparent',
                                    }}
                                >
                                    {/* <DropdownItem color="lightBlue">
                                        Profil Saya
                                    </DropdownItem> */}
                                    {/* <DropdownItem color="lightBlue">
                                        Tetapan
                                    </DropdownItem> */}
                                    <DropdownItem color="lightBlue" onClick={() => logOut()}>
                                        Log Keluar
                                    </DropdownItem>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        // </div>
    );
}
