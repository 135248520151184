import React, { useState, useEffect } from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import PropTypes from 'prop-types';

export default function CustomModal({closeModal, size, title, content, backdropClose, hideButtonClose}) {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setShowModal(true)
    }, [closeModal])

    const hideModal = () => {
        setShowModal(false)

        setTimeout(() => {
            closeModal()
        }, 500)
    }

    return (
        <>
            <StyleDialog
                open={showModal}
                onClose={backdropClose ? hideModal : ''}
                fullWidth={true}
                maxWidth={size}
                scroll={'body'}
                className="!rounded-xl"
            >
                <StyleDialogTitle className="uppercase" onClose={hideButtonClose ? '' : hideModal}>
                    {title}
                </StyleDialogTitle>
                {content}
            </StyleDialog>
            {/* <Modal size={size} active={showModal} toggler={backdropClose ? () => hideModal() : ''}>
                {hideButtonClose ? 
                <ModalHeader>
                    {title}
                </ModalHeader> : 
                <ModalHeader toggler={() => hideModal()}>
                    {title}
                </ModalHeader>
                }
                {content}
            </Modal> */}
        </>
    );
}

const StyleDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
      borderRadius: "15px",
    },
}));

const StyleDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose!='' ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <i className="fa-solid fa-xmark"></i>
            </IconButton>
        ) : null}
        </DialogTitle>
    );
};

StyleDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};