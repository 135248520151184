import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { useLicenseList, useAllLicenseList } from 'query/actions/license/Crud';
import moment from "moment";
import ButtonGroupActionLesen from './ButtonGroupActionLesen';
import differenceBy from 'lodash/differenceBy';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
import DunData from 'data/dun.json'
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import ExportToExcel from './ExportToExcel';
import Excel from './Excel';
import CarianLaporanLesen from './lesen/CarianLaporanLesen';
import CarianLaporanOperasiHaiwanMerayau from './lesen/CarianLaporanOperasiHaiwanMerayau';
import { useTangkapLupusList } from 'query/actions/tangkaplupus/Crud';
import ExcelOperasiHaiwanMerayau from './ExcelOperasiHaiwanMerayau';

export default function DataTableCardLaporanOperasiHaiwanMerayau() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, data: dataList, error, isError, isLoading, isFetching } = useTangkapLupusList();
    const fileName = 'laporan-operasi-haiwan-merayau'+moment().format('YYYYMMDDHmmss');
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [allLists, setAllLists] = useState([]);
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [search, setSearch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = search==null ? 
            {'page': page, 'perPage': countPerPage, 'user': accessToken, type: 'merayau'} :
            {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search, type: 'merayau'}
        // let data = {'page': page, 'perPage': countPerPage, 'user': accessToken, type: 'merayau'}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, search, refreshTable]);

    // search
    // useEffect(() => {
    //     let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
    //     let data = {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search, type: 'merayau'}
    //     getLicenseList(data);
    //     setIsAdmin((accessToken!='' ? false : true))
    //     console.log('search',search)
    // }, [page, search]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

    // admin
    const columns = [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '5rem'
        },
        {
            name: <span>KOD</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='uppercase'>{row.kod}</div>
                </div>,
            selector: row => row.kod,
            sortable: true,
            // width: '10rem'
        },
        {
            name: <span>NAMA OPERASI</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='uppercase'>{row.nama}</div>
                </div>,
            selector: row => row.kod,
            sortable: true,
            // width: '10rem'
        },
        {
            name: <span>JENIS OPERASI</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.jenis }</div>
                </div>,
            selector: row => row.jenis,
            sortable: true,
            // width: '19.5rem'
        },
        {
            name: <span>TARIKH</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.tarikh).format('D MMM YYYY') }</div>
                </div>,
            // selector: row => row.created_at,
            sortable: true,
            // width: '7rem'
        },
        {
            name: <span>MASA</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.masa }</div>
                </div>,
            // selector: row => row.created_at,
            sortable: true,
            // width: '7rem'
        },
        {
            name: <span>JENIS HAIWAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.haiwan }</div>
                </div>,
            selector: row => row.haiwan,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>BILANGAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.bilangan }</div>
                </div>,
            selector: row => row.bilangan,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>KAEDAH TANGKAPAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.kaedah }</div>
                </div>,
            selector: row => row.kaedah,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>TINDAKAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.tindakan_name }</div>
                </div>,
            selector: row => row.tindakan_name,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>NOTA</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.nota }</div>
                </div>,
            selector: row => row.nota,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>ADUAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.aduan }</div>
                </div>,
            selector: row => row.aduan,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>DUN</span>,
            cell: row => <div className='mb-1'>
                {DunData.map((item, index) => {
                    if(item.value == row.dun){
                        return item.label
                    }
                })}
                    {/* {row.dun} */}
                </div>,
            selector: row => row.dun,
            sortable: true
        },
        {
            name: <span>PARLIMEN</span>,
            cell: row => <div className='mb-1'>
                    {row.parlimen}
                </div>,
            selector: row => row.parlimen,
            sortable: true
        },
    ];

    return (
        <>
        <div className='mb-14'>
            <CarianLaporanOperasiHaiwanMerayau setSearch={setSearch} setPage={setPage} setShowTable={setShowTable} />
        </div>
        <Card>
            <CardHeader size='sm' color="purple" contentPosition="left">
                <h2 className="text-white text-xl">Hasil Carian</h2>
            </CardHeader>
            <CardBody className='-mt-3'>
                <div className='flex justify-end mx-4'>
                    <ExcelOperasiHaiwanMerayau data={search} />
                    {/* <ExportToExcel apiData={dataList?.data.data.result} fileName={fileName} /> */}
                </div>
                <div className="overflow-x-auto mt-4">
                    <DataTable 
                        // title=" "
                        // className='!w-20 !overflow-x-scroll'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        // selectableRowDisabled={rowDisabledCriteria}
                        // contextActions={contextActions}
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={''}
                title={`${selectedRows.length} Permohonan Dipilih`}
                content={pageModal}
                backdropClose={true}
            /> : 
        ''}
        </>
    );
}


// // admin
// const columns = [
//     {
//       name: '#',
//       cell: row => row.application_id,
//       selector: row => row.application_id,
//       sortable: true,
//       width: '5rem'
//     },
//     {
//       name: 'SALURAN',
//       cell: row => <div style={{fontSize: '10px'}} className={`px-2 py-1 rounded-lg text-white text-xs ${row.channel==1 ? 'bg-purple-500' : 'bg-teal-500'}`}>
//           { row.channel==1 ? 'Online' : 'Kaunter' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'INFO PEMOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ row.name }</div>
//             <div className='mb-1'>{ row.mykad }</div>
//             <div className='mb-1'>{ row.phone }</div>
//             <div>{ row.email }</div>
//       </div>,
//     //   selector: row => `${ row.status } ${ row.channel }`,
//       sortable: true,
//       width: '22rem'
//     },
//     {
//       name: 'STATUS',
//       cell: row => <div style={{fontSize: '10px'}} 
//                 className={`px-2 py-1 rounded-lg text-white text-xs 
//                 ${row.status==1 ? 'bg-gray-500' : 
//                 row.status==2 ? 'bg-teal-500' : 
//                 row.status==3 ? 'bg-red-500' : 'bg-amber-500'}`
//             }>
//             { row.status==1 ? 'Baru' : row.status==2 ? 'Lulus' : row.status==3 ? 'Batal' : 'Tidak Lengkap' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'TARIKH MOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
//             <div>{ moment(row.created_at).format('h:mm A') }</div>
//         </div>,
//       selector: row => row.created_at,
//       sortable: true,
//     //   width: '12%'
//     },
//     {
//       name: ' ',
//       cell: row => <ButtonGroupAction 
//                 isAdmin={isAdmin} 
//                 id={row.application_id} 
//                 status={row.status} 
//             />,
//       selector: row => row.application_id,
//       sortable: true,
//     //   width: '12%'
//     }
//   ];

// const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);
