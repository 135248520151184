import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import MBSPlogo from "assets/img/mbsp.png";

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';
import { useLogin } from 'query/actions/Auth';

// component
import CustomModal from 'components/CustomModal';
import Register from 'components/Register';
import Example from 'components/Spinner/Example';
import LoginComponent from 'components/Login';

// material-tailwind
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import InputIcon from "@material-tailwind/react/InputIcon";

import { toast } from 'react-toastify';

// import LoginImg from '../assets/img/login.jpg';
// import SignupImg from '../assets/img/signup.jpg';
import LoginImg from '../assets/img/Mobile login-cuate.svg';
import SignupImg from '../assets/img/Sign up-cuate.svg';
import OAuth2Login from 'react-simple-oauth2-login/dist/OAuth2Login';
import { useOauthLogin } from 'query/actions/Auth';
import PopupBanner from 'components/PopupBanner';

export default function Login() {
    const { mutate, error, isError, isLoading, isSuccess, data } = useLogin();
    const oauthLogin = useOauthLogin();
    const setUserInfoAtom = useSetRecoilState(userInfoAtom);
    
    const [showModal, setShowModal] = useState(false);
    const [showModalLogin, setShowModalLogin] = useState(false);
    const [showModalPopup, setShowModalPopup] = useState(true);
    const [title, setTitle] = useState('');

    async function closeModal() {
        await setShowModal(false);
    }
    async function closeModalLogin() {
        await setShowModalLogin(false);
    }
    async function closeModalPopup() {
        await setShowModalPopup(false);
    }

    let history = useHistory();
    const logIn = (data) => {
        setUserInfoAtom([{
            userId: data.id,
            userPassword: data.password
        }])
        history.replace('/')
    }

    const validationSchema = Yup.object().shape({
        id: Yup.string().required(`Ruangan Wajib Diisi`),
        password: Yup.string().required(`Ruangan Wajib Diisi`),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues: {
            id: '',
            password: ''
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code)
            }
        })
    }
    // console.log('datadata',data?.data.response.response_code)

    const Notify = (code) => {
        if(code == '500'){
            toast.error("ID Pengguna atau Katalaluan tidak sah!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
        // else {
        //     // toast.success("Log masuk berjaya, Selamat Datang!", {
        //     //     position: toast.POSITION.TOP_CENTER
        //     // });
        //     if(data.data.data.isAdmin){
        //         history.replace('/')
        //     } else {
        //         history.go(0)
        //         history.go('/utama')
        //         // window.location.href('/utama')
        //     }
        // }
    };

    const onClickOauthLogin = () => {
        // const params = new URLSearchParams({
        //     client_id: '7',
        //     redirect_uri: 'https://oro-staging.mpkk.gov.my/apps/auth/callback',
        //     response_type: 'code',
        //     scope: ''
        // });
        // const str = params.toString();
        // oauthLogin.mutate()
        // history.push('/apps/api/oauth')
        window.location.href = '/apps/api/oauth';
    }
    const onClickOauthRegister = () => {
        // const params = new URLSearchParams({
        //     client_id: '7',
        //     redirect_uri: 'https://oro-staging.mpkk.gov.my/apps/auth/callback',
        //     response_type: 'code',
        //     scope: ''
        // });
        // const str = params.toString();
        // oauthLogin.mutate()
        // history.push('/apps/api/oauth')
        window.location.href = 'https://oauth.mpkk.gov.my/register';
    }

    const onSuccess = response => console.log(response);
    const onFailure = response => console.error(response);

    return (
        <>
            <div className="absolute inset-0">
                <div className='flex min-h-full lg:h-screen md:h-screen bg-login-background justify-center items-center'>
                    <div className="my-8">
                        {/* <div className='md:max-w-md md:w-md lg:max-w-lg lg:w-lg mx-5'> */}
                        <div className='mx-5'>
                            <Card>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* <CardHeader size='lg' color='cyan' contentPosition="" className='/login'>
                                        <div className='flex flex-row'>
                                            <div className="w-28 mr-2 /px-4">
                                                <img src={MBSPlogo} />
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='flex flex-col'>
                                                    <span className="text-white text-lg uppercase /text-center">Sistem Permohonan Lesen Anjing</span>
                                                    <span className="text-white text-md /text-center">Majlis Bandaraya Seberang Perai</span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardHeader> */}

                                    <div className='flex flex-row justify-center p-4 rounded-xl bg-cyan-500 /h-40 -mt-12 shadow-xl mb-4'>
                                        <div className='flex w-20 lg:w-auto /justify-center /mb-2'>
                                            <img className='/h-20 w-20' src={MBSPlogo} />
                                        </div>
                                        <div className='flex items-center'>
                                            <div className='flex flex-col pl-2'>
                                                <span className="text-white text-sm lg:text-lg /text-center font-bold uppercase" style={{lineHeight: '1.25'}}>
                                                    Sistem Permohonan Lesen Anjing
                                                </span>
                                                <span className="text-white text-xs lg:text-sm /text-center" style={{lineHeight: '2'}}>
                                                    Majlis Bandaraya Seberang Perai
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='flex flex-col lg:flex lg:flex-row md:flex md:flex-row gap-8 p-4'>
                                        <div className='text-center rounded-lg shadow-xl border border-gray-100 /mt-5'>
                                            <CardBody>
                                                <div className="flex flex-wrap my-1 justify-center">
                                                    <img src={LoginImg} className="h-32 w-32 lg:h-48 lg:w-48 md:h-32 md:w-32" />
                                                </div>
                                                <span className='text-sm text-gray-500'>Log masuk ke akaun anda</span>
                                            </CardBody>
                                            <CardFooter className="pt-1">
                                                <div className='flex justify-center'>
                                                    {/* <OAuth2Login
                                                        authorizationUrl="https://oauth.mpkk.gov.my/oauth/authorize"
                                                        responseType="code"
                                                        clientId="7"
                                                        redirectUri="https://oro-staging.mpkk.gov.my/apps/auth/callback"
                                                        buttonText="Auth code login"
                                                        onSuccess={onSuccess}
                                                        onFailure={onFailure}
                                                    /> */}
                                                    {isLoading ? 
                                                    <>
                                                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                                        <span className='ml-2'>Loading...</span>
                                                    </> : 
                                                    <Button
                                                        color="cyan"
                                                        // buttonType="outline"
                                                        size="lg"
                                                        rounded={true}
                                                        block={false}
                                                        iconOnly={false}
                                                        ripple="dark"
                                                        // type="submit"
                                                        onClick={() => {setShowModalLogin(true); setTitle('Log Masuk Pengguna')}}
                                                        // onClick={onClickOauthLogin}
                                                    >
                                                        Log Masuk
                                                    </Button>
                                                    }
                                                </div>
                                            </CardFooter>
                                        </div>
                                        <div className='text-center rounded-lg shadow-xl border border-gray-100 /mt-5'>
                                            <CardBody>
                                                <div className="flex flex-wrap my-1 justify-center">
                                                    <img src={SignupImg} className="h-32 w-32 lg:h-48 lg:w-48 md:h-32 md:w-32" />
                                                </div>
                                                <span className='text-sm text-gray-500'>Daftar untuk pengguna baru</span>
                                            </CardBody>
                                            <CardFooter className="pt-1">
                                                <div className='flex justify-center'>
                                                    {isLoading ? 
                                                    <>
                                                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                                        <span className='ml-2'>Loading...</span>
                                                    </> : 
                                                    <Button
                                                        color="cyan"
                                                        // buttonType="outline"
                                                        size="lg"
                                                        rounded={true}
                                                        block={false}
                                                        iconOnly={false}
                                                        ripple="dark"
                                                        // type="submit"
                                                        onClick={() => setShowModal(true)}
                                                        // onClick={onClickOauthRegister}
                                                    >
                                                        Daftar Baru
                                                    </Button>
                                                    }
                                                </div>
                                            </CardFooter>
                                        </div>
                                    </div>
                                </form>
                            </Card>

                            {/* <CardBody>
                                <div className="flex flex-wrap my-1">
                                    <div className="w-full mt-1 mb-10 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <InputIcon
                                                    {...field}
                                                    type="text"
                                                    color="lightBlue"
                                                    size="regular"
                                                    outline={false}
                                                    placeholder="ID Pengguna"
                                                    iconFamily="font-awesome"
                                                    iconName="far fa-id-card"
                                                    error={errors.id ? errors.id.message : ''}
                                                    disabled={isLoading ? true : false}
                                                />
                                            )}
                                            control={control}
                                            name="id"
                                        />
                                    </div>
                                    
                                    <div className="w-full mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                            <InputIcon
                                                {...field}
                                                type="password"
                                                color="lightBlue"
                                                size="regular"
                                                outline={false}
                                                placeholder="Katalaluan"
                                                iconFamily="font-awesome"
                                                iconName="fas fa-lock"
                                                error={errors.password ? errors.password.message : ''}
                                                disabled={isLoading ? true : false}
                                            />
                                            )}
                                            control={control}
                                            name="password"
                                        />
                                    </div>

                                    <div className="flex justify-center w-full font-light">
                                        <Button 
                                            color=""
                                            buttonType="link"
                                            // onClick={(e) => closeModal()}
                                            ripple="dark"
                                            className="capitalize"
                                            type="button"
                                        >
                                            Lupa Katalaluan?
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                            <CardFooter className="pt-1">
                                <div className='flex justify-center'>
                                    {isLoading ? 
                                    <>
                                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                        <span className='ml-2'>Loading...</span>
                                    </> : 
                                    <Button
                                        color="teal"
                                        buttonType="outline"
                                        size="lg"
                                        // rounded={true}
                                        block={false}
                                        iconOnly={false}
                                        ripple="dark"
                                        type="submit"
                                    >
                                        Log Masuk
                                    </Button>
                                    }
                                </div>
                            </CardFooter> */}
                            
                            {/* <div className='flex justify-center p-5'>
                                <span className='text-white text-sm'>Daftar sebagai&nbsp;</span>
                                <span className='text-white text-sm font-bold underline cursor-pointer' onClick={() => setShowModal(true)}>Pengguna Baru</span>
                            </div> */}
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            {showModal ? 
                <CustomModal 
                    closeModal={closeModal} 
                    size='md'
                    title={'Daftar Pengguna Baru'}
                    content={<Register closeModal={closeModal} />}
                /> : 
            ''}
            {showModalLogin ? 
                <CustomModal 
                    closeModal={closeModalLogin} 
                    size='xs'
                    title={title}
                    content={<LoginComponent closeModal={closeModalLogin} setTitle={setTitle} />}
                /> : 
            ''}
            {showModalPopup ? 
                <CustomModal 
                    closeModal={closeModalPopup} 
                    size='lg'
                    title={''}
                    content={<PopupBanner closeModal={closeModalPopup} srcBanner="https://spmh.mbsp.gov.my/img/popup-banner.png" />}
                /> : 
            ''}
        </>
    )
}