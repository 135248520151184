import React, { forwardRef, useEffect, useState, useRef }from "react";
import { useLocation, useHistory, useParams } from 'react-router-dom';
import MBSPlogo from "assets/img/mbsp.png";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useReactToPrint } from 'react-to-print';
// mui
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from "@material-tailwind/react";
import Example from "components/Spinner/Example";
import { useGetTransaction } from "query/actions/transaction/Crud";

export default function Receipt() {
    const location = useLocation();
    const params = useParams();
    
    const { data, isFetching } = useGetTransaction(params.bid);
    console.log('data',data)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const formatNumber = (num) => {
        return (
            <NumberFormat
                thousandsGroupStyle="thousand"
                value={num}
                displayType="text"
                type="text"
                thousandSeparator={true}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
            />
        )
    }
    
    let history = useHistory()
    function gotoUtama(){
        history.replace('/v2/utama')
        // window.location.href = ""
    }

    return (
        <>
        {isFetching ? <div className="flex flex-row justify-center mt-5"><Example type='spin' color='black' height='5rem' width='5rem' /></div> :
        <>
        <div className="flex justify-center mt-5 mb-5">
            <Button 
                type='button' 
                color='green'
                className='mr-2'
                onClick={gotoUtama}
            >
                <i className="fa-solid fa-home pr-1" style={{fontSize: '1.25rem'}}></i>
                Utama
            </Button>
            <Button 
                type='button' 
                className='ml-2'
                onClick={handlePrint}
            >
                <i className="fa-solid fa-print pr-1" style={{fontSize: '1.25rem'}}></i>
                Cetak Bil
            </Button>
        </div>
        <div className="print-body" ref={componentRef}>
            <div className="flex flex-wrap justify-center px-2">
                <div className="w-7/12 bg-white p-10 rounded-xl">
                    <div className="flex flex-row w-full mb-2">
                        <div className="w-48 px-4">
                            <img src={MBSPlogo} />
                        </div>
                        <div className="flex flex-col w-full px-2">
                            <h1 className="text-2xl font-bold uppercase">Majlis Bandaraya Seberang Perai</h1>
                            <div className="flex justify-between /items-center text-sm">
                                <div className="flex flex-col">
                                    <span className="">Menara Bandaraya,</span>
                                    <span className="">Jalan Perda Utama, Bandar Perda,</span>
                                    <span className="">14000 Bukit Mertajam,</span>
                                    <span className="">Pulau Pinang, Malaysia</span>
                                </div>
                                <div className="flex flex-col text-right">
                                    <span className="">Telefon : (04) 549 7555</span>
                                    <span className="">Faks : (04) 538 9700</span>
                                    {/* <span className="">info@mpkk.gov.my</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full mb-5">
                        <div className="dashed-border"></div>
                    </div>
                    <div className="flex flex-row w-full justify-center mb-10">
                        <h3 className="text-lg font-bold uppercase">Resit Rasmi Bayaran Lesen Anjing</h3>
                    </div>

                    <div className="flex flex-row w-full mb-10 px-16">
                        <div className="flex flex-col w-full">
                            <TableContainer 
                                component={Paper} 
                                sx={{'&.MuiTableContainer-root': {
                                    borderRadius: 3, 
                                    border: 1, 
                                    borderColor: "rgb(245, 245, 245)",
                                    boxShadow: "none"
                                }}}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Status Bayaran</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold">{data?.transaction.response_data.status}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Transaksi ID</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold">{data?.transaction.response_data.trxId}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Batch ID</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold">{data?.transaction.response_data.batchId}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Tarikh & Masa Bayar</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold">{moment(data?.transaction.response_data.paidDatetime, 'YYYYMMDDHmmss').format('D MMM YYYY h:mm A')}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="text-base font-bold uppercase">Jumlah telah bayar (RM)</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="text-base font-bold">
                                                        {data?.transaction.response_data.status!="UNSUCCESSFUL" ? formatNumber(data?.transaction.response_data.amount) : '0.00'}
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                    {data?.license && data?.user ?
                    <>
                    <div className="flex flex-row w-full mb-10 px-16">
                        <div className="flex flex-col w-full">
                            <h1 className="text-lg font-bold mb-2">Maklumat Lesen</h1>
                            <TableContainer 
                                component={Paper} 
                                sx={{'&.MuiTableContainer-root': {
                                    borderRadius: 3, 
                                    border: 1, 
                                    borderColor: "rgb(245, 245, 245)",
                                    boxShadow: "none"
                                }}}
                            >
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">No. Akaun Lesen</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold">{data?.license}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Nama Pemilik</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold capitalize">{data?.user}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Bilangan Anjing</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold capitalize">{data?.pets}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {data?.transaction.submission ? 
                                        <TableRow sx={{ "& td": { border: 0 } }}>
                                            <TableCell align="left">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="">Tempat Pengambilan Patil</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-2 mb-2">
                                                    <span className="font-semibold capitalize">
                                                        {data?.transaction.submission}
                                                    </span>
                                                </div>
                                            </TableCell>
                                        </TableRow> : ''}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {data?.transaction.submission_type == "courier" ?
                            <div className="flex flex-row pt-5">
                                <div className="w-full text-center bg-red-100 p-2 rounded-xl">
                                    <span className="text-sm font-bold">Penghantaran patil akan dilakukan selepas 3 hari bekerja dari tarikh bayaran dijelaskan.</span>
                                </div>
                            </div> 
                            :
                            <div className="flex flex-row pt-5">
                                <div className="w-full text-center bg-red-100 p-2 rounded-xl">
                                    <span className="text-sm font-bold">Pengambilan patil hanya boleh dilakukan selepas 3 hari bekerja dari tarikh bayaran dijelaskan.</span>
                                </div>
                            </div> 
                            }
                        </div>
                    </div>
                    </> : ''}

                    <div className="flex flex-row items-center justify-center /mt-3">
                        <div className="text-sm">Resit ini dijana menggunakan komputer dan tiada tandatangan diperlukan.</div>
                    </div>

                    <div className="flex flex-row items-center justify-between mt-10">
                        <div className="flex flex-col">
                            <div className="font-bold text-sm">Penafian:</div>
                            <div className="text-xs">Resit Rasmi hanya sah apabila bayaran ditunaikan oleh bank.</div>
                        </div>
                        <div className="text-xs"><i>Resit ini dijana pada {moment().format('D MMM YYYY h:mm A')}</i></div>
                    </div>
                </div>
            </div>
        </div>
        </>
        }
        </>
    )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(245, 245, 245)",//theme.palette.common.black,
    //   color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
