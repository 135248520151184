import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import PropTypes from 'prop-types';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormControlLabel, MenuItem, Checkbox, TextField } from '@mui/material';

export default function Permohonan() {
    const [page, setPage] = useState(0);
    const [data, setData] = useState({
      formOne: {},
      formTwo: {},
      formThree: {},
      formFour: {}
    });

    //* Customize Icon
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, 1) 100%)',
                // 'linear-gradient( 95deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 0.6) 100%)',
            //   'linear-gradient( 95deg, #90EE90 0%, #90EE90 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, .8) 100%)',
                // 'linear-gradient( 95deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 1) 100%)',
            //   'linear-gradient( 95deg, #90EE90 0%, #90EE90 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 5,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
    }));
  
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
            // 'linear-gradient( 136deg, #90EE90 0%, #009000 100%)',
            'linear-gradient( 136deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 1) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
            // 'linear-gradient( 136deg, #009000 0%, #90EE90 100%)',
            'linear-gradient( 136deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, .8) 100%)',
            boxShadow: '0 4px 10px 0 rgba(123, 31, 162, .25)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = { 
            1: <span>1</span>,
            2: <span>2</span>,
            3: <span>3</span>,
            4: <span>4</span>,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
      /**
       * Whether this step is active.
       * @default false
       */
      active: PropTypes.bool,
      className: PropTypes.string,
      /**
       * Mark the step as completed. Is passed to child components.
       * @default false
       */
      completed: PropTypes.bool,
      /**
       * The label displayed in the step icon.
       */
      icon: PropTypes.node,
    };

    const stepsLabel = ['Butiran Pemohon', 'Maklumat Premis', 'Info Anjing', 'Pengesahan'];


    const NextPage = () => {
        if (isLastPage()) return;
        return setPage(p => p + 1);
    };

    const goBack = () => {
        if (isFirstpage()) {
            // setShowAlertCancel(true);
            return;
        }
        return setPage(p => p - 1);
    };

    const isLastPage = () => {
        return page === 3;
    };
    const isFirstpage = () => {
        return page === 0;
    };

    const updateData = (type, newData) => {
        setData((data) => {
          return { ...data, [type]: newData };
        });
        if (!isLastPage()) return NextPage();
    };
    console.log('datadata',data)

    const getPage = (page) => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
        if(page == 0){
            return (<FormStepOne update={updateData} data={data} back={goBack} />)
        } else if(page == 1){
            return (<FormStepTwo update={updateData} data={data} back={goBack} />)
        } else if(page == 2){
            return (<FormStepThree update={updateData} data={data} back={goBack} />)
        } else if(page == 3){
            return (<FormStepFour update={updateData} data={data} back={goBack} />)
        }
    }

    return (
        <>
            <div className="bg-light-blue-500 pt-5 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <Stepper alternativeLabel activeStep={page} connector={<ColorlibConnector />}>
                        {stepsLabel.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </div>
            </div>

            <div className="px-3 md:px-8 -mt-12">
                <div className="container px-4 mx-auto max-w-full">
                    <div className='flex'>
                        {/* {getPage(page)} */}
                        {/* {page == 0 ? (<FormStepOne update={updateData} data={data} back={goBack} />) :
                            page == 1 ? (<FormStepTwo update={updateData} data={data} back={goBack} />) :
                            page == 2 ? (<FormStepThree update={updateData} data={data} back={goBack} />) :
                            page == 3 ? (<FormStepFour update={updateData} data={data} back={goBack} />) :
                        ''} */}
                        <FormStepOne enable={page == 0 ? 'block' : 'hidden'} update={updateData} data={data} back={goBack} />
                        <FormStepTwo enable={page == 1 ? 'block' : 'hidden'} update={updateData} data={data} back={goBack} />
                        <FormStepThree enable={page == 2 ? 'block' : 'hidden'} update={updateData} data={data} back={goBack} />
                        <FormStepFour enable={page == 3 ? 'block' : 'hidden'} update={updateData} data={data} back={goBack} />
                    </div>
                </div>
            </div>
        </>
    )
}

const FormStepOne = ({ enable, update, data, back }) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const numRegExp = /^\d+$/;
    const validationSchema = Yup.object().shape({
        mykad: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. MyKad Tidak Sah')
                .min(12, "Minimum nombor adalah 12 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        name: Yup.string().required(`Ruangan Wajib Diisi`),
        phone: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                .min(10, "Minimum nombor adalah 10 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`),
        address: Yup.string().required(`Ruangan Wajib Diisi`),
        postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        city: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const { handleSubmit, control, formState:{ errors } } = useForm({
        defaultValues: {
            mykad: '',
            name: '',
            phone: '',
            email: '',
            address: '',
            postcode: '',
            city: ''
        },
        resolver: yupResolver(validationSchema),
    });

    function onSubmit(data) {
        update('formOne', data);
    }

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-2xl">Butiran Pemohon</h2>
                                <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 1
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Semakan Cukai Taksiran
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-6/12 pr-4 mb-10 font-light">
                                        <TextField
                                            id="cukai"
                                            label="No. Cukai Taksiran"
                                            variant="filled"
                                            size="small"
                                            color="secondary"
                                            fullWidth
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-10 font-light">
                                        <Button
                                            type="button"
                                            color="blue"
                                            size="lg"
                                        >
                                            Semak
                                        </Button>
                                    </div>
                                </div>

                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Maklumat Peribadi
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="mykad"
                                                    label="No. MyKad"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.mykad ? errors.mykad.message : ' '}
                                                    error={errors.mykad ? errors.mykad : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="mykad"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="name"
                                                    label="Nama Penuh (Seperti di MyKad)"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.name ? errors.name.message : ' '}
                                                    error={errors.name ? errors.name : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="name"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="phone"
                                                    label="No. Telefon"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.phone ? errors.phone.message : ' '}
                                                    error={errors.phone ? errors.phone : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="phone"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="email"
                                                    label="Emel"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.email ? errors.email.message : ' '}
                                                    error={errors.email ? errors.email : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="email"
                                        />
                                    </div>
                                </div>

                                <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                                    Alamat Surat Menyurat
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="address"
                                                    label="Alamat"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.address ? errors.address.message : ' '}
                                                    error={errors.address ? errors.address : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="address"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="postcode"
                                                    label="Poskod"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.postcode ? errors.postcode.message : ' '}
                                                    error={errors.postcode ? errors.postcode : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="postcode"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="city"
                                                    label="Bandar"
                                                    variant="filled"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.city ? errors.city.message : ' '}
                                                    error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="city"
                                        />
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <div></div>
                    <Button
                        type="button"
                        color="purple"
                        size="lg"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepTwo = ({ enable, update, data, back }) => {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const numRegExp = /^\d+$/;
    const validationSchema = Yup.object().shape({
        address: Yup.string().required(`Ruangan Wajib Diisi`),
        postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        city: Yup.string().required(`Ruangan Wajib Diisi`),
        district: Yup.string().required(`Ruangan Wajib Diisi`),
        dun: Yup.string().required(`Ruangan Wajib Diisi`),
        parlimen: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_status: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_type: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const { handleSubmit, control, formState:{ errors } } = useForm({
        defaultValues: {
            address: '',
            postcode: '',
            city: '',
            district: '',
            dun: '',
            parlimen: '',
            premise_status: '',
            premise_type: ''
        },
        resolver: yupResolver(validationSchema),
    });
    function onSubmit(data) {
        update('formTwo', data);
    }

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-2xl">Maklumat Premis</h2>
                                <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 2
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Alamat Premis
                                </h6>
                                <div className="flex flex-wrap -mt-4">
                                    <div className="w-full lg:w-12/12 mb-1 font-light">
                                        <FormControlLabel control={<Checkbox color="secondary" />} label="Sama Seperti Alamat Surat Menyurat" />
                                    </div>
                                </div>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Alamat"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.address ? errors.address.message : ' '}
                                                    error={errors.address ? errors.address : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="address"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Poskod"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.postcode ? errors.postcode.message : ' '}
                                                    error={errors.postcode ? errors.postcode : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="postcode"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Bandar"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.city ? errors.city.message : ' '}
                                                    error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="city"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Daerah"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.district ? errors.district.message : ' '}
                                                    error={errors.district ? errors.district : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value={10}>test 1</MenuItem>
                                                    <MenuItem value={20}>test 2</MenuItem>
                                                    <MenuItem value={30}>test 3</MenuItem>
                                                    <MenuItem value={40}>test 4</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="district"
                                        />
                                        {/* <FormControl fullWidth size="small">
                                            <InputLabel id="select-daerah-label">Daerah</InputLabel>
                                            <Select
                                                labelId="select-daerah-label"
                                                id="select-daerah" 
                                                label="Daerah"
                                            >
                                                <MenuItem value={10}>test 1</MenuItem>
                                                <MenuItem value={20}>test 2</MenuItem>
                                                <MenuItem value={30}>test 3</MenuItem>
                                                <MenuItem value={40}>test 4</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="DUN"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.dun ? errors.dun.message : ' '}
                                                    error={errors.dun ? errors.dun : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value={10}>test 1</MenuItem>
                                                    <MenuItem value={20}>test 2</MenuItem>
                                                    <MenuItem value={30}>test 3</MenuItem>
                                                    <MenuItem value={40}>test 4</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="dun"
                                        />
                                        {/* <FormControl fullWidth size="small">
                                            <InputLabel id="select-dun-label">DUN</InputLabel>
                                            <Select
                                                labelId="select-dun-label"
                                                id="select-dun" 
                                                label="DUN"
                                            >
                                                <MenuItem value={10}>test 1</MenuItem>
                                                <MenuItem value={20}>test 2</MenuItem>
                                                <MenuItem value={30}>test 3</MenuItem>
                                                <MenuItem value={40}>test 4</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </div>
                                    <div className="w-full lg:w-4/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Parlimen"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.parlimen ? errors.parlimen.message : ' '}
                                                    error={errors.parlimen ? errors.parlimen : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value={10}>test 1</MenuItem>
                                                    <MenuItem value={20}>test 2</MenuItem>
                                                    <MenuItem value={30}>test 3</MenuItem>
                                                    <MenuItem value={40}>test 4</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="parlimen"
                                        />
                                        {/* <FormControl fullWidth size="small">
                                            <InputLabel id="select-parlimen-label">Parlimen</InputLabel>
                                            <Select
                                                labelId="select-parlimen-label"
                                                id="select-parlimen" 
                                                label="Parlimen"
                                            >
                                                <MenuItem value={10}>test 1</MenuItem>
                                                <MenuItem value={20}>test 2</MenuItem>
                                                <MenuItem value={30}>test 3</MenuItem>
                                                <MenuItem value={40}>test 4</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </div>
                                </div>

                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Ciri-Ciri Premis
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Status Premis"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.premise_status ? errors.premise_status.message : ' '}
                                                    error={errors.premise_status ? errors.premise_status : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value='premise_owner'>Pemilik Premis</MenuItem>
                                                    <MenuItem value='premise_rent'>Penyewa Premis</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="premise_status"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 px-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Jenis Premis"
                                                    variant="filled"
                                                    size="small"
                                                    helperText={errors.premise_type ? errors.premise_type.message : ' '}
                                                    error={errors.premise_type ? errors.premise_type : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value='pangsapuri'>Pangsapuri Tingkat Bawah</MenuItem>
                                                    <MenuItem value='teres'>Teres</MenuItem>
                                                    <MenuItem value='berkembar'>Berkembar</MenuItem>
                                                    <MenuItem value='banglo'>Banglo</MenuItem>
                                                    <MenuItem value='kampung'>Kampung</MenuItem>
                                                    <MenuItem value='lkg'>Ladang/Kilang/Gudang</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="premise_type"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 pl-4 mb-5 font-light">
                                        {/* <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Parlimen"
                                        /> */}
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <Button
                        type="button"
                        color="gray"
                        size="lg"
                        onClick={back}
                    >
                        Kembali
                    </Button>
                    <Button
                        type="button"
                        color="purple"
                        size="lg"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepThree = ({ enable, update, data, back }) => {
    function onSubmit(data) {
        update('location', data);
    }

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-2xl">Info Anjing</h2>
                                <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 3
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Bilangan anjing yang dibenarkan:
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-auto pr-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Status Premis"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Jenis Premis"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Parlimen"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Poskod"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Bandar"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto pl-1 mb-10 font-light flex items-stretch">
                                        <div className='inline-block align-middle text-red-500' style={{fontSize: '20px'}}>
                                            <i class="fas fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <Button
                        color="gray"
                        size="lg"
                        onClick={back}
                    >
                        Kembali
                    </Button>
                    <Button
                        color="purple"
                        size="lg"
                        onClick={onSubmit}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepFour = ({ enable, update, data, back }) => {
    function onSubmit(data) {
        update('location', data);
    }

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-2xl">Pengesahan</h2>
                                <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 4
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <form>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Bilangan anjing yang dibenarkan:
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-auto pr-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Status Premis"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Jenis Premis"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Parlimen"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Poskod"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto px-4 mb-10 font-light">
                                        <Input
                                            type="text"
                                            color="purple"
                                            placeholder="Bandar"
                                        />
                                    </div>
                                    <div className="w-full lg:w-auto pl-1 mb-10 font-light flex items-stretch">
                                        <div className='inline-block align-middle text-red-500' style={{fontSize: '20px'}}>
                                            <i class="fas fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <Button
                        color="gray"
                        size="lg"
                        onClick={back}
                    >
                        Kembali
                    </Button>
                    <Button
                        color="green"
                        size="lg"
                        onClick={onSubmit}
                    >
                        Hantar
                    </Button>
                </div>
            </div>
        </>
    )
}