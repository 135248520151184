import React from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import { useLicenseList } from 'query/actions/license/Crud';
import { Button } from '@material-tailwind/react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import moment from "moment";
import { useLicenseListReport } from 'query/actions/license/Crud';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useTangkapLupusListReport } from 'query/actions/tangkaplupus/Crud';

export default function ExcelOperasiHaiwanMerayau({data}) {
    const userInfo = useRecoilValue(userInfoAtom);
    const [page, setPage] = useState(0);
    const countPerPage = 5;
    const [download, setDownload] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lists, setLists] = useState([])
    const btnRef = useRef(null)
    const { mutate, data: dataList, error, isError, isLoading, isSuccess, isFetching } = useTangkapLupusListReport();
    const headers = [
        { label: "#", key: "row" },
        { label: "KOD", key: "kod" },
        { label: "NAMA OPERASI", key: "nama" },
        { label: "JENIS OPERASI", key: "jenis" },
        { label: "TARIKH", key: "tarikh" },
        { label: "MASA", key: "masa" },
        { label: "JENIS HAIWAN", key: "haiwan" },
        { label: "BILANGAN", key: "bilangan" },
        { label: "KAEDAH TANGKAPAN", key: "kaedah" },
        { label: "TINDAKAN", key: "tindakan" },
        { label: "NOTA", key: "nota" },
        { label: "ADUAN", key: "aduan" },
        { label: "DUN", key: "dun" },
        { label: "PARLIMEN", key: "parlimen" }
    ];
      
    // const data = [
    //     { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
    //     { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
    //     { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
    // ];

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data.result.map(row => ({
                    row: row.row_num,
                    kod: row.kod,
                    nama: String(row.nama).toUpperCase(),
                    jenis: row.jenis,
                    tarikh: moment(row.tarikh).format('D MMM YYYY'),
                    masa: row.masa,
                    haiwan: row.haiwan,
                    bilangan: row.bilangan,
                    kaedah: row.kaedah,
                    tindakan: row.tindakan,
                    nota: row.nota,
                    aduan: row.aduan,
                    dun: row.dun,
                    parlimen: row.parlimen
                })))
                // setTimeout(() => {setDownload(true)}, 1000)
            }
        })
    }
    
    // const exportToExcel = () => {
    //     let data = {'all': true}
    //     getLicenseList(data);
    // }

    useEffect(() => {
        if(data != null){
            let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
            let dataAll = {'search': data, type: 'merayau'}
            getLicenseList(dataAll);
            // setLists(data.map(row => ({
            //     channel: row.channel_name,
            //     license_no: row.license_no,
            //     owner: row.name+"\r\n"+row.mykad,
            //     premise_address: row.pa_address+"\r\n"+row.pa_postcode+"\r\n"+row.pa_city,
            //     premise_status: row.premise_status,
            //     premise_type: row.premise_type,
            //     pets_total: row.total,
            //     payment: (row.payment==2 ? 'Telah Bayar' : 'Belum Bayar')+"\r\n"+row.trans_channel_name,
            //     submission_type: row.submission_type,
            //     dun: row.dun,
            //     parlimen: row.parlimen,
            //     created_at: moment(row.created_at).format('D MMM YYYY')+"\r\n"+moment(row.created_at).format('h:mm A')
            // })))
            // setTimeout(() => {setDownload(true)}, 1000)
        }
    }, [data])

    // useEffect(() => {
    //     if(download){
    //         console.log('btnRef', btnRef)
    //         // btnRef.click()
    //         return <CSVDownload data={lists} headers={headers} target="_blank" />
    //     }
    // }, [download])

    // const exportExcel = async (event, done) => {
    //     if(!loading){
    //         setLoading(true)
    //         done()
    //         let data = {'all': true}
    //         await mutate(data, {
    //             onSuccess: (data) => {
    //                 setLists(data.data.data.result.map(row => ({
    //                     channel: row.channel_name,
    //                     license_no: row.license_no,
    //                     owner: row.name+"\r\n"+row.mykad,
    //                     premise_address: row.pa_address+"\r\n"+row.pa_postcode+"\r\n"+row.pa_city,
    //                     premise_status: row.premise_status,
    //                     premise_type: row.premise_type,
    //                     pets_total: row.total,
    //                     payment: (row.payment==2 ? 'Telah Bayar' : 'Belum Bayar')+"\r\n"+row.trans_channel_name,
    //                     submission_type: row.submission_type,
    //                     dun: row.dun,
    //                     parlimen: row.parlimen,
    //                     created_at: moment(row.created_at).format('D MMM YYYY')+"\r\n"+moment(row.created_at).format('h:mm A')
    //                 })))
    //                 console.log('data', data.data.data.result)
    //                 // done(true)
    //                 // setTimeout(() => {done(true)}, 5000)
    //                 setLoading(false)
    //             }
    //         })
    //         setTimeout(() => {done(true)}, 5000)
    //     }
    // }

    // function test(done){
    //     if(lists.length > 0){
    //         setTimeout(() => {done()}, 1000)
    //     }
    // }

    return (
        <>
        <CSVLink
            data={lists} 
            headers={headers}
            asyncOnClick={true}
            filename={'Laporan-Operasi-Haiwan-Merayau-' + moment().format('DDMMYYYYhhmmss')}
            // onClick={exportExcel}
        >
            <div className='bg-green-500 p-3 px-4 text-white rounded-lg'>
                <i class="fas fa-file-excel mr-2"></i>Export ke Excel
            </div>
        </CSVLink>
        {/* <Button
            color="green"
            className="normal-case"
            // buttonType="outline"
            // size="lg"
            // rounded={true}
            block={false}
            iconOnly={false}
            ripple="dark"
            // type="submit"
            onClick={(e) => exportToExcel()}
        >
            <i class="fas fa-file-excel pr-1"></i>Export ke Excel
        </Button> */}
        </>
    )
}
