import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import moment from "moment";
import differenceBy from 'lodash/differenceBy';
import Button from "@material-tailwind/react/Button";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
import Example from "components/Spinner/Example";
import { toast } from 'react-toastify';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useOperationList } from 'query/actions/tangkaplupus/Crud';
import ButtonGroupActionTangkapLupus from './ButtonGroupActionTangkapLupus';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, MenuItem, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { useNewOperation } from 'query/actions/tangkaplupus/Crud';

export default function DataTableCardOperasi() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, error, isError, isLoading, isSuccess } = useOperationList();
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [title, setTitle] = useState('');
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = {'page': page, 'perPage': countPerPage, 'user': accessToken}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, refreshTable]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = useMemo(() => {
		const handleApprove = () => {
            setPageModal(<View closeModal={closeModal} id={''} action={7} />)
            setShowModal(true)
			// console.log('selectedRows',selectedRows)
			// if (window.confirm(`Luluskan permohonan:\r ${selectedRows.map(r => r.application_id)}?`)) {
			// 	setToggleCleared(!toggleCleared);
			// 	setLists(differenceBy(lists, selectedRows, 'status'));
			// }
		};

		return (
            <>
            <div className='px-1 hidden md:block lg:block'>
                <Button
                    onClick={() => handleApprove()}
                    type="button"
                    color="green"
                >
                    <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    Lulus
                </Button>
            </div>
            <div className='px-1 lg:hidden'>
                <Tooltip title="Lulus" placement="left">
                    <Button
                        onClick={() => handleApprove()}
                        type="button"
                        color="green"
                        iconOnly
                    >
                        <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            </>
		);
	}, [lists, selectedRows, toggleCleared]);

    // admin
    const columns = [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '5rem'
        },
        {
            name: <span>KOD & NAMA OPERASI</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='uppercase'>{row.kod} - {row.nama}</div>
                </div>,
            selector: row => row.kod,
            sortable: true,
            // width: '10rem'
        },
        {
            name: <span>KETERANGAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='capitalize'>{row.keterangan}</div>
                </div>,
            selector: row => row.kod,
            sortable: true,
            // width: '10rem'
        },
        // {
        //     name: <span>STATUS</span>,
        //     cell: row => <div style={{fontSize: '10px'}} 
        //                 className={`px-2 py-1 rounded-lg text-white font-medium text-xs text-center 
        //                 ${row.status=='Aktif' ? 'bg-green-500' : 'bg-red-500'}`
        //             }>
        //             { row.status }
        //         </div>,
        //     selector: row => row.status,
        //     sortable: true,
        //     width: '7rem'
        // },
        {
            name: <span>TARIKH</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='flex flex-row'>
                        <div className='mb-1 w-14'>MULA:</div>
                        <div className='mb-1'>{ moment(row.tarikh_mula).format('D MMM YYYY') }</div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='mb-1 w-14'>TAMAT:</div>
                        <div className='mb-1'>{ moment(row.tarikh_tamat).format('D MMM YYYY') }</div>
                    </div>
                </div>,
            // selector: row => row.created_at,
            sortable: true,
            // width: '7rem'
        },
        // {
        //     name: <span>TARIKH TAMAT</span>,
        //     cell: row => <div className='flex flex-col my-4'>
        //             <div className='mb-1'>{ moment(row.tarikh_tamat).format('D MMM YYYY') }</div>
        //         </div>,
        //     // selector: row => row.created_at,
        //     sortable: true,
        //     // width: '7rem'
        // },
        {
            name: ' ',
            // cell: row => <ButtonGroupActionTangkapLupus 
            //             isAdmin={isAdmin} 
            //             id={row.idno} 
            //             // status={row.status} 
            //             // plate_no={row.plate_no}
            //             setRefreshTable={setRefreshTable}
            //         />,
            // selector: row => row.row_num,
            sortable: true,
            //   width: '12%'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);

    function onAddHandler(){
        setPageModal(<AddMaklumatOperasi closeModal={closeModal} setRefreshTable={setRefreshTable} />)
        setTitle('Tambah Operasi')
        setShowModal(true)
    }

    return (
        <>
        <Card>
            <CardHeader size='sm' color="red" contentPosition="left">
                <h2 className="text-white text-xl">Senarai Operasi</h2>
            </CardHeader>
            <CardBody className='/-mt-3'>
                <div className='flex justify-end mx-4'>
                    <Button
                        color="blue"
                        className="normal-case mr-1"
                        // buttonType="outline"
                        // size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // type="submit"
                        onClick={onAddHandler}
                    >
                        <i class="fas fa-plus pr-1"></i>Tambah Operasi
                    </Button>
                </div>
                <div className="overflow-x-auto">
                    <DataTable 
                        // title=" "
                        className='w-full'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        selectableRowDisabled={rowDisabledCriteria}
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={'sm'}
                title={title}
                content={pageModal}
                backdropClose={false}
            /> : 
        ''}
        </>
    );
}

const AddMaklumatOperasi = ({closeModal, setRefreshTable}) => {
    const { mutate, error, isError, isLoading, isSuccess } = useNewOperation();

    const validationSchema = Yup.object().shape({
        nama: Yup.string().required(`Ruangan Wajib Diisi`),
        kategori: Yup.string().required(`Ruangan Wajib Diisi`),
        keterangan: Yup.string().required(`Ruangan Wajib Diisi`),
        tarikh_mula: Yup.string().required(`Ruangan Wajib Diisi`),
        tarikh_tamat: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const defaultValues = {
        nama: '',
        kategori: '',
        keterangan: '',
        tarikh_mula: '',
        tarikh_tamat: ''
    }
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        // console.log('errorserrors',errors)
        console.log('datadata',data)
        // // logIn(data)
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Tambah Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }
    
    return (
        <>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col my-1">
                        <div className="w-full px-2 /mb-6">
                            <div className='flex flex-col /mt-4 /mb-1'>
                                <div className="flex flex-wrap mt-1 /mb-5">
                                    <div className="w-full lg:w-12/12 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Jenis Operasi"
                                                    // placeholder="Pilih kaedah tangkapan"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.kategori ? errors.kategori.message : ''}
                                                    error={errors.kategori ? errors.kategori : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Penangkapan">Penangkapan</MenuItem>
                                                    <MenuItem value="Pelupusan">Pelupusan</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="kategori"
                                        />
                                    </div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Nama Operasi"
                                                    // placeholder="Masukkan alamat anda"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={addressValue}
                                                    // onChange={(event) => {
                                                    //     setAddressValue(event.target.value)
                                                    //     resetFieldValue('address', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.nama ? errors.nama.message : ''}
                                                    error={errors.nama ? errors.nama : false}
                                                    fullWidth
                                                    // multiline
                                                    // maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="nama"
                                        />
                                    </div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Keterangan"
                                                    // placeholder="Masukkan aduan"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={addressValue}
                                                    // onChange={(event) => {
                                                    //     setAddressValue(event.target.value)
                                                    //     resetFieldValue('address', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.keterangan ? errors.keterangan.message : ''}
                                                    error={errors.keterangan ? errors.keterangan : false}
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="keterangan"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 /pl-1 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="date"
                                                    label="Tarikh Mula"
                                                    // placeholder="Pilih tarikh"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.tarikh_mula ? errors.tarikh_mula.message : ''}
                                                    error={errors.tarikh_mula ? errors.tarikh_mula : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="tarikh_mula"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-1 /pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="date"
                                                    label="Tarikh Tamat"
                                                    // placeholder="Pilih tarikh"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.tarikh_tamat ? errors.tarikh_tamat.message : ''}
                                                    error={errors.tarikh_tamat ? errors.tarikh_tamat : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="tarikh_tamat"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                {/* <>
                    <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                    <span className='ml-2'>Loading...</span>
                </> :  */}
                <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>
                    <Button
                        color="blue"
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                    >
                        Tambah
                    </Button>
                </>
            </DialogActions>
        </>
    )
}
