import { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { useRoleMenu } from "query/admin";
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import {decode as base64_decode, encode as base64_encode} from 'base-64'

export default function SidebarItem({item}){
    const userInfo = useRecoilValue(userInfoAtom);
    // const { data, isFetching } = useRoleMenu(userInfo?.roleId)
    const [roleMenu, setRoleMenu] = useState([]);
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
    const location = useLocation().pathname;

    const role = JSON.parse(base64_decode(base64_decode(userInfo?.role)))
    console.log('rolerole',role)

    let history = useHistory();

    useEffect(() => {
        let result = location.split('/').slice(0,3).join('/');
        setActive(result)
    }, [location])
    
    useEffect(() => {
        if(active == item.path){
            setOpen(!open)
        }
    }, [active])
    // console.log('item.path',item.path)
    // console.log('active',active)
    // console.log('location',location)

    const handleClick = () => {
        setOpen(!open);
    };
    
    // useEffect(() => {
    //     if(!isFetching){
    //         setRoleMenu(JSON.parse(data.data.data.role))
    //         // setRoleMenu(role => item.role === role)
    //     }
    // }, [isFetching])
    // console.log('roleMenu',roleMenu)

    function loopMenu(title){
        let ret = false
        for(let i = 0; i < role[item.role].length; i++){
            if(title == role[item.role][i]){
                ret = true
            }
        }
        return ret
    }
    function loop(){
        let ret = false
        if(role[item.role] != undefined){
            ret = true
        }
        return ret
    }
    
    return (
        <>
        {role ?
        <>
        {/* <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        > */}
            {item.childrens ? 
                <>
                    {loop() ?
                    <>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                    <ListItemButton 
                        id={`${item.title}`}
                        className={`selected`}
                        selected={active == item.path}
                        onClick={handleClick}
                        sx={{ padding: 1.5, 
                            "&.Mui-selected": {
                                // backgroundColor: "rgb(3, 169, 244)",
                                backgroundColor: "rgb(0, 150, 136)",
                                borderRadius: 3,
                                color: "white"
                            },
                            // "&.Mui-selected:hover": {backgroundColor: "rgba(3, 169, 244, 0.7)"}, 
                            "&.Mui-selected:hover": {backgroundColor: "rgba(0, 150, 136, 0.7)"}, 
                            "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                        }}
                    >
                        {/* <ListItemIcon>
                            <SendIcon />
                        </ListItemIcon> */}
                        <div className="w-8 text-lg flex items-center">
                            <i className={item.icon}></i>
                        </div>
                        <ListItemText primary={item.title} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    { item.childrens.map((child, index) => 
                    <>
                    {loopMenu(child.title) ?
                    <Collapse key={index} in={open} timeout="auto" sx={{mt: 1}} unmountOnExit>
                        <List component="div" disablePadding>
                            <a href={child.path || "#"}>
                                <ListItemButton 
                                    className="submenu"
                                    selected={location == child.path}
                                    id={`${child.title}`} 
                                    sx={{ 
                                        ml: 2, 
                                        paddingY: 1.5, 
                                        fontSize: 14, 
                                        "&.Mui-selected": {
                                            // backgroundColor: "rgb(239, 246, 255)",
                                            backgroundColor: "rgb(237, 255, 255)",
                                            borderRadius: 3,
                                            color: "rgba(0, 98, 86)",
                                            fontWeight: "bold"
                                            // color: "rgba(59, 130, 246)"
                                        },
                                        "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                                    }}
                                >
                                    {child.title}
                                    {/* <ListItemIcon>
                                        <StarBorder />
                                    </ListItemIcon> */}
                                    {/* <ListItemText primary={child.title} /> */}
                                </ListItemButton>
                            </a>
                        </List>
                    </Collapse>
                    : ''}
                    </>
                    ) }
                    </List>
                    </>
                    : ''}
                </> 
                :
                <>
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                <a href={item.path || "#"}>
                    <ListItemButton 
                        id={`${item.title}`} 
                        selected={location=='/v2/' ? true : location == item.path}
                        sx={{ padding: 1.5, 
                            "&.Mui-selected": {
                                // backgroundColor: "rgb(3, 169, 244)",
                                backgroundColor: "rgb(0, 150, 136)",
                                borderRadius: 3,
                                color: "white"
                            }, 
                            // "&.Mui-selected:hover": {backgroundColor: "rgba(3, 169, 244, 0.7)"},  
                            "&.Mui-selected:hover": {backgroundColor: "rgba(0, 150, 136, 0.7)"}, 
                            "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                        }}
                    >
                        {/* <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon> */}
                        <div className="w-8 text-lg flex items-center">
                            <i className={item.icon}></i>
                        </div>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </a>
                </List>
                </>
            }
        {/* </List> */}
        </>
        : ''}
        </>
    )
}