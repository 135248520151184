import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useApplicationView, useApplicationStatusUpdate, useLicenseStatusUpdate, useCourierInfo, usePayment } from "query/actions/Crud";
import { useLicenseView } from "query/actions/license/Crud";
import { TextField, MenuItem, Tooltip, Checkbox, Typography, Alert, AlertTitle, Box, Tabs, Tab } from "@mui/material";
import Example from "components/Spinner/Example";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
// toastify
import { toast } from 'react-toastify';
import LicenseCard from "components/LicenseCard";
import LicenseBill from "components/LicenseBill";
import DirectTransaction from "components/DirectTransaction";
import md5 from "md5";
import { useDeliveryView } from "query/actions/plate/Crud";
import ResponseStatus from "components/ResponseStatus";
import { useDeliveryUpdate } from "query/actions/plate/Crud";
import EditLicenseInfo from "./EditLicenseInfo";
import CustomModal from "components/CustomModal";
import {decode as base64_decode, encode as base64_encode} from 'base-64'
import PropTypes from 'prop-types';

export default function View({closeModal, id, action, type, setRefreshTable, setTitle, setBackdropClose, setHideButtonClose}){  
    const [info, setInfo] = useState({});  
    const [pets, setPets] = useState(); 
    const [delivery, setDelivery] = useState(); 
    const [submission, setSubmission] = useState(false); 
    const [serahan, setSerahan] = useState(null); 
    const [serahanButton, setSerahanButton] = useState(false); 
    const [totalPay, setTotalPay] = useState(0);
    const [checked, setChecked] = useState(false);
    const [addressValue, setAddressValue] = useState('');
    const [postcodeValue, setPostcodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    const { mutate: mutateUpdate, isLoading: isLoadingUpdate, data: dataUpdate } = useApplicationStatusUpdate();
    const { mutate: mutateLicenseView, isLoading: isLoadingLicenseView, data: dataLicenseView } = useLicenseView();
    const { mutate: mutateLicenseUpdate, isLoading: isLoadingLicenseUpdate, data: dataLicenseUpdate } = useLicenseStatusUpdate();
    const { data: dataCourier, isFetching } = useCourierInfo()
    const { mutate: mutatePayment } = usePayment()
    const { mutate: mutateDeliveryView } = useDeliveryView();
    const role = userInfo?.role ? JSON.parse(base64_decode(base64_decode(userInfo?.role))) : [];
    const [showEditModal, setShowEditModal] = useState(false);
    const [refreshView, setRefreshView] = useState(false);
    const [editData, setEditData] = useState();

    let history = useHistory();
    
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const numRegExp = /^\d+$/;
    const validationSchema = Yup.object().shape({
        address: submission ? Yup.string().required(`Ruangan Wajib Diisi`) : Yup.string(),
        postcode: submission ? Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit") : Yup.string(),
        city: submission ? Yup.string().required(`Ruangan Wajib Diisi`) : Yup.string(),
        phone: submission ? Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                .min(10, "Minimum nombor adalah 10 digit")
                .max(12, "Maksimum nombor adalah 12 digit") : Yup.string(),
        pickup: submission ? Yup.string() : Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const defaultValues = {
        postcode: '',
        address: '',
        city: '',
        phone: '',
        pickup: ''
    }
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    function submissionField(){
        if(info){
            reset({
                address: info?.ma_address,
                postcode: info?.ma_postcode,
                city: info?.ma_city,
                phone: info?.phone,
                pickup: ''
            })
        }
    }

    // total payment
    useEffect(() => {
        if(!isFetching && pets?.length){
            // setTotalPay(eval((pets?.length * 10) + '+' + dataCourier?.data[0]?.amaun_caj))
            setTotalPay(eval(pets?.length * 10))
            submissionField()
        }
    }, [isFetching, pets])

    function onHandlerSubmission(submission){
        setSubmission(submission)
        setSerahan(submission)
        setSerahanButton(false)
        if(submission){
            if(info){
                setTimeout(() => {
                    reset({
                        address: info?.ma_address,
                        postcode: info?.ma_postcode,
                        city: info?.ma_city,
                        phone: info?.phone,
                        pickup: ''
                    })
                }, 100)
            }
            setTotalPay(eval((pets?.length * 10) + '+' + dataCourier?.data[0]?.amaun_caj))
        } else {
            reset({
                address: '',
                postcode: '',
                city: '',
                phone: '',
                pickup: ''
            })
            setTotalPay(eval(pets?.length * 10))
        }
    }

    // payment
    const onSubmitPayment = async (data) => {
        if(serahan==null){
            setSerahanButton(true)
        } else {
            let d = submission ? {
                application_id: id,
                address: data.address,
                postcode: data.postcode,
                city: data.city,
                phone: data.phone,
                amount: totalPay.toFixed(2),
                delivery: dataCourier?.data[0]?.amaun_caj,
                hash: md5('spmh@mbsp'+totalPay.toFixed(2)),
                submission: 'courier',
                accessToken: userInfo?.accessToken
            } : {
                application_id: id,
                pickup: data.pickup,
                amount: totalPay.toFixed(2),
                hash: md5('spmh@mbsp'+totalPay.toFixed(2)),
                submission: 'pickup',
                accessToken: userInfo?.accessToken
            }
            await mutatePayment(d, {
                onSuccess: (data) => {
                    if(data.data.response.response_code == '200'){
                        window.location.href = data.data.data.url
                    }
                    Notify(data.data.response.response_code, data.data.response.response_message)
                    // closeModal()
                    // setRefreshTable(true)
                }
            })
        }
    }

    // view
    const getView = async (data, type) => {
        if(type==1){
            await mutateView(data, {
                onSuccess: (data) => {
                    setInfo(data.data.data.info)
                    setPets(data.data.data.pets)
                }
            })
        } else if(type==2 && (action==1 || action==4)){
            await mutateLicenseView(data, {
                onSuccess: (data) => {
                    setInfo(data.data.data.info)
                    setPets(data.data.data.pets)
                    if(data.data.data?.delivery){
                        setDelivery(data.data.data.delivery)
                    }
                }
            })
        } else if(type==2 && action==8){
            await mutateDeliveryView(data, {
                onSuccess: (data) => {
                    setInfo(data.data.data.delivery)
                    setPets(data.data.data.pets)
                }
            })
        }
    }

    useEffect(() => {
        if(id){
            let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
            let data = {'id': id, 'user': accessToken}
            getView(data, type)
        }
    }, [id])
    
    // update
    const getStatusUpdate = async (data, type) => {
        if(type==1){
            await mutateUpdate(data, {
                onSuccess: (data) => {
                    Notify(data.data.response.response_code, data.data.response.response_message)
                    closeModal()
                    setRefreshTable(true)
                }
            })
        } else if(type==2){
            await mutateUpdate(data, {
                onSuccess: (data) => {
                    Notify(data.data.response.response_code, data.data.response.response_message)
                    closeModal()
                    setRefreshTable(true)
                }
            })
        }
    }

    // toastify
    const Notify = (code, title) => {
        if(code == '500'){
            toast.error(title, { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
    };

    function onStatusUpdate(status){
        let data = {'id': id, 'status': status, 'accessToken': userInfo?.accessToken}
        getStatusUpdate(data, type)
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    function printBill(){
        window.print();
    }

    const resetFieldValue = (field, value) => {
        resetField(field)
        setValue(field, value)
    }

    function onHandlerRedirect(){
        closeModal()
        if(userInfo?.isAdmin){
            history.push(`/lesen/permohonan-kemaskini/${id}`)
        } else {
            history.push(`/permohonan/kemaskini/${id}`)
        }
    }

    async function closeEditModal() {
        await setShowEditModal(false);
    }
    function openEditModal(id, type){
        setShowEditModal(true);
        let title;
        if(type == 'ma'){
            title = 'Alamat Surat Menyurat'
        } else if(type == 'pa'){
            title = 'Maklumat Premis'
        } else if(type == 'pl'){
            title = 'No. Patil'
        } else if(type == 'py'){
            title = 'No. Resit'
        }
        setEditData({id: id, type: type, title: title})
    }

    // edit license
    useEffect(() => {
        if(id && refreshView){
            let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
            let data = {'id': id, 'user': accessToken}
            getView(data, 2)
            setRefreshView(false)
        }
    }, [refreshView]);

    // batal lesen
    const [alasan, setAlasan] = useState('');
    const [alertAlasan, setAlertAlasan] = useState(false);
    const handleChange = (event) => {
        setAlasan(event.target.value);
        if(event.target.value != '' || event.target.value != null){
            setAlertAlasan(false)
        }
    };

    async function onDelete(){
        if(alasan == '' || alasan == undefined){
            setAlertAlasan(true)
        } else {
            let data = {'id': id, 'status': 4, 'reason': alasan, 'accessToken': userInfo?.accessToken}
            if(type==1){
                await mutateUpdate(data, {
                    onSuccess: (data) => {
                        if(data.data.response.response_code == '200'){
                            toast.success('Batal Berjaya!', { 
                                isLoading: false,
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                            setRefreshTable(true)
                            closeModal()
                        } else if(data.data.response.response_code == '201'){
                            toast.error('Ralat!', { 
                                isLoading: false,
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                        }
                    }
                })
            } else if(type==2){
                await mutateLicenseUpdate(data, {
                    onSuccess: (data) => {
                        if(data.data.response.response_code == '200'){
                            toast.success('Batal Berjaya!', { 
                                isLoading: false,
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                            setRefreshTable(true)
                            closeModal()
                        } else if(data.data.response.response_code == '201'){
                            toast.error('Ralat!', { 
                                isLoading: false,
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000
                            });
                        }
                    }
                })
            }
        }
    }

    const [valueTab, setValueTab] = useState(0);
  
    const handleChangeTab = (event, newValue) => {
      setValueTab(newValue);
    };

    // return
    // 1-application 2-license
    if(type==1){
        if(action==1){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex flex-wrap my-1">
                            <div className="w-full px-2 mb-6">
                                <div className="flex flex-col lg:flex-row justify-between">
                                    <div>
                                        <span className="pr-1 text-gray-500">No. Permohonan: </span>
                                        <span className="font-bold text-lg">{info.application_no}</span>
                                    </div>
                                    <div>
                                        <span className="text-md text-gray-500">Bayaran: </span>
                                        <span
                                            className={`px-2 py-1 rounded-lg font-medium border-2
                                                ${info.payment==2 ? 'border-green-500' : 'border-amber-500'}`}
                                        >
                                            <i className={`fa-solid pr-1 ${info.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
                                            { info.payment==2 ? 'Telah Bayar' : 'Belum Bayar' }
                                        </span>
                                    </div>
                                    <div>
                                        <span className="text-md text-gray-500">Saluran: </span>
                                        <span
                                            className={`px-2 py-1 rounded-lg font-medium border-2
                                                ${info.channel==1 ? 'bg-green-500 border-green-500' : 'bg-amber-500 border-amber-500'}`}
                                        >
                                            <i className={`fa-solid fa-circle pr-1 text-white`}></i>
                                            <span className={`text-white`}>
                                                { info.channel==1 ? 'Online' : 'Kaunter' }
                                            </span>
                                        </span>
                                    </div>
                                    {/* <div>
                                        <span className="text-md text-gray-500">Status: </span>
                                        <span 
                                            className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                                ${info.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                                    info.status=='2' ? 'bg-green-500 border-green-500' :
                                                    info.status=='3' ? 'bg-red-500 border-red-500' : 
                                                    'bg-amber-500 border-amber-500'}`}
                                        >
                                            {info.status_info}
                                            {info.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                                                info.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                                                info.status=='3' ? <i className="fa-solid fa-ban pl-1"></i> : 
                                                <i className="fa-solid fa-clipboard-question pl-1"></i>}
                                        </span>
                                    </div> */}
                                </div>
                            </div>

                            {info.status=='4' ?
                            <div className="w-full px-2 mb-6">
                                <Alert 
                                    icon={<i className="fas fa-exclamation-triangle text-5xl"></i>} 
                                    severity={info.status=='3' ? 'warning' : info.status=='4' ? 'error' : 'info'}
                                >
                                    <AlertTitle>Alasan/Sebab</AlertTitle>
                                    <span className="capitalize">{info.reason}</span>
                                </Alert>
                            </div>
                            : ''}

                            <div className="w-full lg:w-6/12 px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-indigo-500 w-full h-10 px-4 py-2 shadow-md-indigo">
                                        Maklumat Pemohon
                                    </div>
                                    <div className='flex flex-col mt-4 mb-1'>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. MyKad</div>
                                            <div className="ml-1">{ info.mykad }</div>
                                        </div>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Nama</div>
                                            <div className="ml-1">{ info.name }</div>
                                        </div>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Telefon</div>
                                            <div className="ml-1">{ info.phone }</div>
                                        </div>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Emel</div>
                                            <div className="ml-1">{ info.email }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="w-full lg:w-6/12 px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-teal-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                        Alamat Surat Menyurat
                                    </div>
                                    <div className='flex flex-col mt-4 mb-1'>
                                        <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                            <div className="ml-1">{ info.ma_address }</div>
                                        </div>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                            <div className="ml-1">{ info.ma_postcode }</div>
                                        </div>
                                        <div className='mb-2 border-2 rounded-md p-1'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                            <div className="ml-1">{ info.ma_city }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-purple-500 w-full h-10 px-4 py-2 shadow-md-purple">
                                        Maklumat Premis
                                    </div>
                                    <div className="flex flex-wrap w-full">
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1'>
                                            <div className='mb-2 border-2 rounded-md p-1 lg:pr-2'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Status Premis</div>
                                                <div className="ml-1">{ info.premise_status=='owner' ? 'Pemilik Premis' : 'Penyewa Premis' }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jenis Premis</div>
                                                <div className="ml-1 capitalize">{ info.premise_type }</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                                <div className="ml-1">{ info.pa_address }</div>
                                            </div>
                                            {/* <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                <div className="ml-1 capitalize">{ info.pa_postcode }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                <div className="ml-1 capitalize">{ info.pa_city }</div>
                                            </div> */}
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                <div className="ml-1 capitalize">{ info.pa_postcode }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                <div className="ml-1 capitalize">{ info.pa_city }</div>
                                            </div>
                                            {/* <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">DUN</div>
                                                <div className="ml-1">{ info.dun }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Daerah</div>
                                                <div className="ml-1 capitalize">{ info.district }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Parlimen</div>
                                                <div className="ml-1 capitalize">{ info.parlimen }</div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex flex-wrap w-full mt-3">
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">DUN</div>
                                                <div className="ml-1">{ info.dun }</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Daerah</div>
                                                <div className="ml-1 capitalize">{ info.district }</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Parlimen</div>
                                                <div className="ml-1 capitalize">{ info.parlimen }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <div className="w-full px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-purple-500 w-full h-10 px-4 py-2 shadow-md-purple">
                                        Maklumat Premis
                                    </div>
                                    <div className="flex flex-wrap w-full">
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1'>
                                            <div className='mb-2 border-2 rounded-md p-1 lg:pr-2'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Status Premis</div>
                                                <div className="ml-1">{ info.premise_status=='owner' ? 'Pemilik Premis' : 'Penyewa Premis' }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jenis Premis</div>
                                                <div className="ml-1 capitalize">{ info.premise_type }</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                                <div className="ml-1">{ info.pa_address }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                <div className="ml-1 capitalize">{ info.pa_postcode }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                <div className="ml-1 capitalize">{ info.pa_city }</div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">DUN</div>
                                                <div className="ml-1">{ info.dun }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Daerah</div>
                                                <div className="ml-1 capitalize">{ info.district }</div>
                                            </div>
                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Parlimen</div>
                                                <div className="ml-1 capitalize">{ info.parlimen }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                            <div className="w-full px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-cyan-500 w-full h-10 px-4 py-2 shadow-md-cyan">
                                        Info Anjing
                                    </div>
                                    <div className='flex flex-col mb-1'>
                                        {pets?.map((data, index) => (
                                        <div key={index} className="mb-3 px-2 pt-4 flex bg-gray-500 bg-opacity-10 rounded-lg">
                                            <div className="flex flex-row w-full">
                                                <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-1/5 mx-1'>
                                                    <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Jantina</div>
                                                    <div className="ml-1 capitalize">{ data.gender }</div>
                                                </div>
                                                <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-1/5 mx-1'>
                                                    <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Baka</div>
                                                    <div className="ml-1 capitalize">{ data.breed }</div>
                                                </div>
                                                <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-1/5 mx-1'>
                                                    <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Warna</div>
                                                    <div className="ml-1 capitalize">{ data.color }</div>
                                                </div>
                                                <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-1/5 mx-1'>
                                                    <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Mikrocip</div>
                                                    <div className="ml-1 capitalize">{ data.microchip=='1' ? 'Ya' : 'Tidak' }</div>
                                                </div>
                                                <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-1/5 mx-1'>
                                                    <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Mandul</div>
                                                    <div className="ml-1 capitalize">{ data.sterilization=='1' ? 'Ya' : 'Tidak' }</div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                            {/* {userInfo?.isAdmin && info?.status!='2' ? 
                            <div className="w-full px-2 mb-6">
                                <div className="flex justify-center">
                                    <div className="w-full lg:w-6/12">
                                        <form>
                                            <div className="mb-2">Pilih status dibawah.</div>
                                            <TextField id="outlined-basic" size='small' label="Status Permohonan" variant="outlined" fullWidth select>
                                                <MenuItem value={'2'}>{'Lulus'}</MenuItem>
                                                <MenuItem value={'3'}>{'Batal'}</MenuItem>
                                                <MenuItem value={'4'}>{'Tidak Lengkap'}</MenuItem>
                                            </TextField>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            : ''
                            } */}

                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>

                        {/* {userInfo?.isAdmin && info?.status!='2' ?
                        <Button
                            color="green"
                            // onClick={handleSubmit(onSubmit)}
                            ripple="light"
                        >
                            Simpan
                        </Button>
                        : ''
                        } */}
                    </DialogActions>

                {/* </div> */}
                </>
            )

        } else if(action==2 || action==3 || action==4){
            return (
                <>
                {/* <div className="lg:w-2xl md:w-lg"> */}
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 mb-6">
                                <h2 className="text-sm">Anda pasti untuk kemaskini permohonan ini?</h2>
                                <div className="mt-4 mb-3">
                                    <span className="pr-1 text-gray-500">No. Permohonan: </span>
                                    <span className="font-bold text-sm">{info.application_no}</span>
                                </div>
                                <div className="mt-1">
                                    <span className="pr-1 text-gray-500">Tindakan: </span>
                                    {/* <span 
                                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                            ${info.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                                info.status=='2' ? 'bg-green-500 border-green-500' :
                                                info.status=='3' ? 'bg-red-500 border-red-500' : 
                                                'bg-amber-500 border-amber-500'}`}
                                    >
                                        {info.status_info}
                                        {info.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                                            info.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                                            info.status=='3' ? <i className="fa-solid fa-ban pl-1"></i> : 
                                            <i className="fa-solid fa-clipboard-question pl-1"></i>}
                                    </span>
                                    <span className="px-2"><i className="fa-solid fa-arrow-right-long"></i></span> */}
                                    <span 
                                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                            ${action==2 ? 'bg-green-500 border-green-500' :
                                                action==3 ? 'bg-red-500 border-red-500' : 
                                                action==4 ? 'bg-amber-500 border-amber-500' : ''}`}
                                    >
                                        {action==2 ? <>Lulus<i className="fa-solid fa-circle-check pl-1"></i></> :
                                            action==3 ? <>Batal<i className="fa-solid fa-ban pl-1"></i></> : 
                                            action==4 ? <>Tidak Lengkap<i className="fa-solid fa-clipboard-question pl-1"></i></> : ''}
                                    </span>
                                </div>
                                <div className="mt-3 flex flex-col">
                                    <span className="pr-1 text-gray-500">Alasan/Sebab:</span>
                                    <TextField
                                        id="outlined-name"
                                        size="small"
                                        multiline
                                        rows={3}
                                        // label="Alasan/Sebab"
                                        value={alasan}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        error={alertAlasan}
                                        helperText={alertAlasan ? 'Ruangan Wajib Diisi' : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {isLoadingUpdate ? 
                            <>
                                <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                <span className='ml-2'>Loading...</span>
                            </> : 
                            <>
                                <Button 
                                    color="gray"
                                    buttonType="link"
                                    onClick={(e) => closeModal()}
                                    ripple="dark"
                                >
                                    Kembali
                                </Button>

                                {userInfo?.isAdmin ?
                                <Button
                                    color="blue"
                                    onClick={onDelete}
                                    // onClick={() => onStatusUpdate(action)}
                                    ripple="light"
                                >
                                    Kemaskini
                                </Button>
                                : ''
                                }
                            </>
                        }
                    </DialogActions>
                {/* </div> */}
                </>
            )
        } else if(action==5){
            return (
                <>
                    <DirectTransaction 
                        id={id} 
                        action={action}
                        closeModal={closeModal} 
                        setRefreshTable={setRefreshTable} 
                        setTitle={setTitle}
                        // setBackdropClose={setBackdropClose()}
                        // setHideButtonClose={setHideButtonClose()}
                    />
                </>
            )
        } else if(action==6){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex justify-center mb-10">
                            <Button type='button' onClick={handlePrint}>
                                <i className="fa-solid fa-print pr-1" style={{fontSize: '1.25rem'}}></i>
                                Cetak Bil
                            </Button>
                        </div>
                        <LicenseBill info={info} pets={pets} ref={componentRef} />
                    </DialogContent>
                {/* </div> */}
                </>
            )
        } else if(action==7){
            return (
                <>
                {/* <div className="lg:w-2xl md:w-lg"> */}
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 mb-6">
                                <h2 className="text-sm">Anda pasti untuk meluluskan permohonan yang dipilih?</h2>
                            </div>
                        </div>
                    </DialogContent>
                {/* </div> */}
                </>
            )
        } else if(action==8){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex flex-wrap my-1">
                            <div className="w-full px-2 mb-6">
                                <div className='w-full bg-white rounded-xl border border-gray-100 overflow-hdden shadow-lg pt-4 pb-1 px-4 /mt-4'>
                                    <div className='flex flex-col /mt-4 /mb-1'>
                                        <div className="text-gray-500 mb-1">Cara Bayaran</div>
                                        <div className='mb-5 border-2 rounded-md p-1'>
                                            <div className="ml-1">MBSPpay - Online Payment</div>
                                        </div>
                                        <div className="flex flex-row text-gray-500 mb-1">
                                            Cara Serahan Patil
                                            {serahanButton ?
                                            <Typography className="pl-2 font-medium text-xs text-red-500">
                                                *Ruangan Wajib Diisi
                                            </Typography>
                                            : ''}
                                        </div>
                                        <div className='flex flex-row justify-between'>
                                            <div 
                                                className={`${serahan==true ? 'bg-gradient-to-tr bg-indigo-500 shadow-md-indigo text-white' : 'text-black bg-gray-100'} cursor-pointer mb-4 mr-2 rounded-xl text-center w-full h-10 px-4 py-2`}
                                                onClick={() => onHandlerSubmission(true)}
                                            >
                                                Perkhidmatan Kurier
                                            </div>
                                            <div 
                                                className={`${serahan==false ? 'bg-gradient-to-tr bg-indigo-500 shadow-md-indigo text-white' : 'text-black bg-gray-100'} cursor-pointer mb-4 mr-2 rounded-xl text-center w-full h-10 px-4 py-2`}
                                                onClick={() => onHandlerSubmission(false)}
                                            >
                                                Ambil Sendiri di MBSP
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmitPayment)}>
                                        {serahan!=null ?
                                            <>
                                            {submission ?
                                            // <div className='w-full bg-white rounded-xl border border-gray-100 overflow-hdden shadow-lg pt-3 pb-1 px-4'>
                                                <div className='flex flex-col /mt-4 /mb-1'>
                                                    {/* <div className="flex flex-wrap /-mt-4">
                                                        <div className="w-full lg:w-12/12 mb-1 font-light">
                                                            <FormControlLabel 
                                                                control={<Checkbox color="secondary" checked={checked} onChange={() => setChecked(!checked)} />} 
                                                                label="Sama Seperti Alamat Surat Menyurat" 
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <div className="flex flex-wrap mt-1 /mb-5">
                                                        <div className="text-gray-500 mb-2">Maklumat Penghantaran</div>
                                                        <div className="w-full lg:w-12/12 mb-5 font-light">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type="text"
                                                                        label="Alamat"
                                                                        placeholder="Masukkan alamat anda"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        // value={addressValue}
                                                                        // onChange={(event) => {
                                                                        //     setAddressValue(event.target.value)
                                                                        //     resetFieldValue('address', event.target.value)
                                                                        // }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        helperText={errors.address ? errors.address.message : ''}
                                                                        error={errors.address ? errors.address : false}
                                                                        fullWidth
                                                                        multiline
                                                                        maxRows={3}
                                                                    />
                                                                )}
                                                                control={control}
                                                                name="address"
                                                            />
                                                        </div>
                                                        <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type="text"
                                                                        label="Poskod"
                                                                        placeholder="Masukkan poskod anda"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        // value={postcodeValue}
                                                                        // onChange={(event) => {
                                                                        //     setPostcodeValue(event.target.value);
                                                                        //     resetFieldValue('postcode', event.target.value)
                                                                        // }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        helperText={errors.postcode ? errors.postcode.message : ''}
                                                                        error={errors.postcode ? errors.postcode : false}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                control={control}
                                                                name="postcode"
                                                            />
                                                        </div>
                                                        <div className="w-full lg:w-6/12 pl-1 mb-5 font-light">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type="text"
                                                                        label="Bandar"
                                                                        placeholder="Masukkan bandar anda"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        // value={cityValue}
                                                                        // onChange={(event) => {
                                                                        //     setCityValue(event.target.value)
                                                                        //     resetFieldValue('city', event.target.value)
                                                                        // }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        helperText={errors.city ? errors.city.message : ''}
                                                                        error={errors.city ? errors.city : false}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                control={control}
                                                                name="city"
                                                            />
                                                        </div>
                                                        <div className="w-full lg:w-12/12 /mb-5 font-light">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id="phone"
                                                                        label="No. Telefon"
                                                                        placeholder="Masukkan no. telefon anda"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        // color="secondary"
                                                                        InputLabelProps={{ shrink: true }}
                                                                        helperText={errors.phone ? errors.phone.message : ' '}
                                                                        error={errors.phone ? errors.phone : false}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                                control={control}
                                                                name="phone"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            // </div> 
                                            :
                                            // <div className='w-full bg-white rounded-xl border border-gray-100 overflow-hdden shadow-lg pt-3 pb-1 px-4'>
                                                <div className='flex flex-col /mt-4 /mb-1'>
                                                    <div className="flex flex-wrap /mt-1 /mb-5">
                                                        <div className="text-gray-500 mb-2">Pilih Tempat</div>
                                                        <div className="w-full lg:w-12/12 mb-5 font-light">
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        select
                                                                        label="Tempat Pengambilan Patil"
                                                                        // placeholder="Pilih Tempat"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        helperText={errors.pickup ? errors.pickup.message : ''}
                                                                        error={errors.pickup ? errors.pickup : false}
                                                                        fullWidth
                                                                    >
                                                                        <MenuItem value='perda'>Menara Bandaraya, Bandar Perda</MenuItem>
                                                                        <MenuItem value='jawa'>Pejabat Cawangan MBSP Kg. Jawa, Butterworth</MenuItem>
                                                                        <MenuItem value='jawi'>Pejabat Cawangan MBSP Jawi</MenuItem>
                                                                    </TextField>
                                                                )}
                                                                control={control}
                                                                name="pickup"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            // </div>
                                            }
                                            </>
                                        : ''
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className="w-full px-2 mb-6">
                            <hr />
                                <div className='w-full bg-white rounded-xl border border-gray-100 overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className='flex flex-row items-center justify-between mx-4 mb-2'>
                                        <span>Harga Patil (RM)</span>
                                        <span className="font-bold">{!isFetching && pets?.length ? '10.00' : <Skeleton width={40} />}</span>
                                    </div>
                                    <div className='flex flex-row items-center justify-between mx-4 mb-2'>
                                        <span>Bilangan Anjing</span>
                                        <span className="font-bold">{!isFetching && pets?.length ? `x${pets?.length}` : <Skeleton width={40} />}</span>
                                    </div>
                                    {submission ?
                                    <div className='flex flex-row items-center justify-between mx-4 mb-2'>
                                        <span>Caj Penghantaran (RM)</span>
                                        <span className="font-bold">{!isFetching && pets?.length ? dataCourier?.data[0]?.amaun_caj : <Skeleton width={40} />}</span>
                                    </div> : ''}
                                    <div className="flex flex-row items-center justify-between mb-4 rounded-xl text-white bg-gradient-to-tr bg-teal-500 w-full h-12 px-4 py-2 shadow-md-teal">
                                        <span className="font-bold">Jumlah Perlu Bayar (RM)</span>
                                        <span className="font-bold text-lg">{!isFetching && pets?.length ? totalPay.toFixed(2) : <Skeleton width={40} />}</span>
                                    </div>
                                </div>

                                <hr className="mb-4" />
                                <div className="flex flex-col text-center /justify-between bg-orange-100 border-2 border-orange-500 /border-dashed p-4 /mb-4 rounded-xl">
                                    <div className='uppercase font-bold'>Peringatan</div>
                                    <div className="text-sm">Sila ambil perhatian pihak MBSP berhak menolak atau membatalkan permohonan lesen anjing jika terdapat maklumat yang tidak benar dan tidak tepat di dalam permohonan lesen anjing. Bayaran yang telah dibuat tidak akan dikembalikan. Sebarang surat menyurat atau rayuan tidak akan dilayan.</div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>
                        <Button
                            color="green"
                            onClick={handleSubmit(onSubmitPayment)}
                            ripple="light"
                        >
                            Bayar
                        </Button>
                    </DialogActions>

                {/* </div> */}
                </>
            )

        }

    } else if(type==2){
        if(action==1){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex flex-wrap my-1">
                            <div className="w-full lg:w-6/12">
                                <div className="flex justify-center">
                                    <LicenseCard
                                        icon={info.status=='1' ? "fa-solid fa-clock" : 
                                                info.status=='2' ? "fa-solid fa-circle-check" :
                                                info.status=='3' ? "fa-regular fa-calendar-xmark" :
                                                "fa-solid fa-ban"}
                                        title="No. Lesen"
                                        account={info.license_no}
                                        status={info.status_info}
                                        valid={info.start_date!=null ? `Sah sehingga ${ moment(info.end_date).format('D MMM YYYY') }` : '-'}
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-6/12 px-2 mb-6">
                                <div className="flex flex-col">
                                    <div className="mb-3">
                                        <span className="pr-1 text-md text-gray-500">No. Lesen: </span>
                                        <span className="font-bold text-lg">{info.license_no}</span>
                                    </div>
                                    <div className="mb-5">
                                        <span className="text-md text-gray-500">Status: </span>
                                        <span 
                                            className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                                ${info.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                                    info.status=='2' ? 'bg-green-500 border-green-500' :
                                                    info.status=='3' ? 'bg-amber-500 border-amber-500' :
                                                    'bg-red-500 border-red-500'}`}
                                        >
                                            {info.status_info}
                                            {info.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                                                info.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                                                info.status=='3' ? <i className="fa-regular fa-calendar-xmark pl-1"></i> :
                                                <i className="fa-solid fa-ban pl-1"></i>}
                                        </span>
                                    </div>
                                    <div className="mb-3">
                                        <span className="text-md text-gray-500">Bayaran: </span>
                                        <span
                                            className={`px-2 py-1 rounded-lg font-medium border-2
                                                ${info.payment==2 ? 'border-green-500' : 'border-amber-500'}`}
                                        >
                                            <i className={`fa-solid pr-1 ${info.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
                                            { info.payment==2 ? 'Telah Bayar' : 'Belum Bayar' }
                                        </span>
                                    </div>
                                    <div className="mb-5">
                                        <span className="pr-1 text-md text-gray-500">Tempoh: </span>
                                        <span className="font-bold text-lg">
                                            {info.start_date!=null ? 
                                            `${moment(info.start_date).format('D MMM YYYY')} - ${moment(info.end_date).format('D MMM YYYY')}` :
                                            '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {info.status=='4' ?
                            <div className="w-full px-2 mb-6">
                                <Alert 
                                    icon={<i className="fas fa-exclamation-triangle text-5xl"></i>} 
                                    severity={info.status=='3' ? 'warning' : info.status=='4' ? 'error' : 'info'}
                                >
                                    <AlertTitle>Alasan/Sebab</AlertTitle>
                                    <span className="capitalize">{info.reason}</span>
                                </Alert>
                            </div>
                            : ''}

                            {/* {info.payment=='2' ?
                            <div className="w-full px-2 mb-6">
                                <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                    <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-green-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                        <div className="flex flex-row justify-between">
                                            <span>Maklumat Bayaran</span>
                                            <div>
                                                {role.edit ?
                                                <Tooltip title="Kemaskini" placement="top">
                                                    <Button
                                                        type="button"
                                                        size="sm"
                                                        color="orange"
                                                        iconOnly
                                                        style={{height: '1.45rem'}}
                                                        onClick={() => openEditModal(id, 'py')}
                                                    >
                                                        <i className="fa-solid fa-edit" style={{fontSize: '.75rem'}}></i>
                                                    </Button>
                                                </Tooltip>
                                                : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-row mt-4 mb-1'>
                                        <div className='w-full mb-2 border-2 rounded-md p-1 mr-2'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Resit</div>
                                            <div className="ml-1">{ info.receipt_no }</div>
                                        </div>
                                        <div className='w-full mb-2 border-2 rounded-md p-1 ml-2'>
                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jumlah (RM)</div>
                                            <div className="ml-1">{ info.total_amount }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''} */}
                            
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example"
                                        sx={{'.MuiTabs-indicator' : {height: '4px'}}}
                                    >
                                        <Tab label="Info Lesen" {...a11yProps(0)} />

                                        {info.payment=='2' ?
                                        <Tab label="Maklumat Bayaran" {...a11yProps(1)} />
                                        : ''}

                                        {delivery != undefined ? 
                                        <Tab label="Perkhidmatan Kurier" {...a11yProps(2)} />
                                        : ''}
                                    </Tabs>
                                </Box>
                                <TabPanel value={valueTab} index={0}>
                                    <div className="flex flex-wrap mt-5">
                                        <div className="w-full px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-cyan-500 w-full h-10 px-4 py-2 shadow-md-cyan">
                                                    Info Anjing
                                                </div>
                                                <div className='flex flex-col mb-1'>
                                                    {pets?.map((data, index) => (
                                                    <div key={index} className="mb-3 px-2 pt-4 flex bg-gray-500 bg-opacity-10 rounded-lg">
                                                        <div className="flex /flex-wrap w-full">
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Patil</div>
                                                                <div className="ml-1 capitalize">{ data.plate_no ? data.plate_no : '-' }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Jantina</div>
                                                                <div className="ml-1 capitalize">{ data.gender }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Baka</div>
                                                                <div className="ml-1 capitalize">{ data.breed }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Warna</div>
                                                                <div className="ml-1 capitalize">{ data.color }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Mikrocip</div>
                                                                <div className="ml-1 capitalize">{ data.microchip=='1' ? 'Ya' : 'Tidak' }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 bg-white rounded-md p-1 w-full lg:w-2/12 mx-1'>
                                                                <div className="-mt-3 px-1 bg-white rounded-md text-gray-500 text-2xs absolute">Mandul</div>
                                                                <div className="ml-1 capitalize">{ data.sterilization=='1' ? 'Ya' : 'Tidak' }</div>
                                                            </div>
                                                            <div className="mt-1 mr-2 ml-2">
                                                                {role.edit ?
                                                                <Tooltip title="Kemaskini" placement="top">
                                                                    <Button
                                                                        type="button"
                                                                        size="sm"
                                                                        color="orange"
                                                                        iconOnly
                                                                        style={{height: '1.45rem'}}
                                                                        onClick={() => openEditModal(index, 'pl')}
                                                                    >
                                                                        <i className="fa-solid fa-edit" style={{fontSize: '.75rem'}}></i>
                                                                    </Button>
                                                                </Tooltip>
                                                                : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-6/12 px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-indigo-500 w-full h-10 px-4 py-2 shadow-md-indigo">
                                                    Maklumat Pemilik
                                                </div>
                                                <div className='flex flex-col mt-4 mb-1'>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. MyKad</div>
                                                        <div className="ml-1">{ info.mykad }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Nama</div>
                                                        <div className="ml-1">{ info.name }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Telefon</div>
                                                        <div className="ml-1">{ info.phone }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Emel</div>
                                                        <div className="ml-1">{ info.email }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="w-full lg:w-6/12 px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-teal-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                                    <div className="flex flex-row justify-between">
                                                        <span>Alamat Surat Menyurat</span>
                                                        <div>
                                                            {role.edit ?
                                                            <Tooltip title="Kemaskini" placement="top">
                                                                <Button
                                                                    type="button"
                                                                    size="sm"
                                                                    color="orange"
                                                                    iconOnly
                                                                    style={{height: '1.45rem'}}
                                                                    onClick={() => openEditModal(id, 'ma')}
                                                                >
                                                                    <i className="fa-solid fa-edit" style={{fontSize: '.75rem'}}></i>
                                                                </Button>
                                                            </Tooltip>
                                                            : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col mt-4 mb-1'>
                                                    <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                                        <div className="ml-1">{ info.ma_address }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                        <div className="ml-1">{ info.ma_postcode }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                        <div className="ml-1">{ info.ma_city }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="w-full px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-purple-500 w-full h-10 px-4 py-2 shadow-md-purple">
                                                    <div className="flex flex-row justify-between">
                                                        <span>Maklumat Premis</span>
                                                        <div>
                                                            {role.edit ?
                                                            <Tooltip title="Kemaskini" placement="top">
                                                                <Button
                                                                    type="button"
                                                                    size="sm"
                                                                    color="orange"
                                                                    iconOnly
                                                                    style={{height: '1.45rem'}}
                                                                    onClick={() => openEditModal(id, 'pa')}
                                                                >
                                                                    <i className="fa-solid fa-edit" style={{fontSize: '.75rem'}}></i>
                                                                </Button>
                                                            </Tooltip>
                                                            : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap w-full">
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1'>
                                                        <div className='mb-2 border-2 rounded-md p-1 lg:pr-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Status Premis</div>
                                                            <div className="ml-1">{ info.premise_status=='owner' ? 'Pemilik Premis' : 'Penyewa Premis' }</div>
                                                        </div>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jenis Premis</div>
                                                            <div className="ml-1 capitalize">{ info.premise_type }</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                                        <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                                            <div className="ml-1">{ info.pa_address }</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                            <div className="ml-1 capitalize">{ info.pa_postcode }</div>
                                                        </div>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                            <div className="ml-1 capitalize">{ info.pa_city }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="flex flex-wrap w-full mt-3">
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1'>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">DUN</div>
                                                            <div className="ml-1">{ info.dun }</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Daerah</div>
                                                            <div className="ml-1 capitalize">{ info.district }</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col w-full lg:w-4/12 mb-1 lg:pl-2'>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Parlimen</div>
                                                            <div className="ml-1 capitalize">{ info.parlimen }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    <div className="flex flex-wrap mt-5">
                                        {info.payment=='2' ?
                                        <div className="w-full px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-green-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                                    <div className="flex flex-row justify-between">
                                                        <span>Maklumat Bayaran</span>
                                                        <div>
                                                            {role.edit ?
                                                            <Tooltip title="Kemaskini" placement="top">
                                                                <Button
                                                                    type="button"
                                                                    size="sm"
                                                                    color="orange"
                                                                    iconOnly
                                                                    style={{height: '1.45rem'}}
                                                                    onClick={() => openEditModal(id, 'py')}
                                                                >
                                                                    <i className="fa-solid fa-edit" style={{fontSize: '.75rem'}}></i>
                                                                </Button>
                                                            </Tooltip>
                                                            : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex flex-row mt-4 mb-1'>
                                                    <div className="w-1/2 mr-2">
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /mr-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Resit</div>
                                                            <div className="ml-1">{ info.receipt_no }</div>
                                                        </div>
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">ID Batch</div>
                                                            <div className="ml-1">{ info.transaction_id }</div>
                                                        </div>
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Tarikh & Masa</div>
                                                            <div className="ml-1">{ moment(info.trans_created_at).format('D MMM YYYY, H:m A') }</div>
                                                        </div>
                                                        {/* <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Masa</div>
                                                            <div className="ml-1">{ moment(info.trans_created_at).format('H:m A') }</div>
                                                        </div> */}
                                                    </div>
                                                    <div className="w-1/2 ml-2">
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /mr-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Saluran Bayaran</div>
                                                            <div className="ml-1">{ info.trans_channel==1 ? 'Online' : 'Kaunter' }</div>
                                                        </div>
                                                        {/* <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Harga Patil (RM)</div>
                                                            <div className="ml-1">{ info.amount_license }</div>
                                                        </div> */}
                                                        {info.amount_courier != null ?
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Harga Kurier (RM)</div>
                                                            <div className="ml-1">{ info.amount_courier }</div>
                                                        </div>
                                                        : ''}
                                                        <div className='w-full mb-2 border-2 rounded-md p-1 /ml-2'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jumlah Bayaran (RM)</div>
                                                            <div className="ml-1">{ info.total_amount }</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='w-full mb-2 border-2 rounded-md p-1 mr-2'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Resit</div>
                                                        <div className="ml-1">{ info.receipt_no }</div>
                                                    </div>
                                                    <div className='w-full mb-2 border-2 rounded-md p-1 ml-2'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Jumlah (RM)</div>
                                                        <div className="ml-1">{ info.total_amount }</div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        : ''}
                                    </div>
                                </TabPanel>
                                <TabPanel value={valueTab} index={2}>
                                    {delivery != undefined ? 
                                    <div className="flex flex-wrap mt-5">                                        
                                        <div className="w-full lg:w-6/12 px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-teal-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                                    Alamat Penghantaran
                                                </div>
                                                <div className='flex flex-col mt-4 mb-1'>
                                                    <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                                        <div className="ml-1">{ delivery.post_address ? JSON.parse(delivery.post_address)?.address : '' }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                                        <div className="ml-1">{ delivery.post_address ? JSON.parse(delivery.post_address)?.postcode : '' }</div>
                                                    </div>
                                                    <div className='mb-2 border-2 rounded-md p-1'>
                                                        <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                                        <div className="ml-1">{ delivery.post_address ? JSON.parse(delivery.post_address)?.city : '' }</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-full lg:w-6/12 px-2 mb-6">
                                            <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                                                <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-indigo-500 w-full h-10 px-4 py-2 shadow-md-indigo">
                                                    Maklumat Penghantaran
                                                </div>
                                                <div className='flex flex-col mt-4 mb-1'>
                                                    {delivery.tracking_no != null ?
                                                    <>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Tracking</div>
                                                            <div className="ml-1">{ delivery.tracking_no }</div>
                                                        </div>
                                                        <div className='mb-2 border-2 rounded-md p-1'>
                                                            <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Tarikh Hantar</div>
                                                            <div className="ml-1">{ moment(delivery.updated_at).format('D MMM YYYY') }</div>
                                                        </div>
                                                        {/* {!userInfo?.isAdmin ?
                                                        <> */}
                                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Nama Kurier</div>
                                                                <div className="ml-1">{ delivery.courier_name }</div>
                                                            </div>
                                                            <div className='mb-2 border-2 rounded-md p-1'>
                                                                <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Website</div>
                                                                <div className="flex flex-row justify-between">
                                                                    <div className="ml-1">{ delivery.courier_website }</div>
                                                                    {/* <div className="">{ delivery.courier_website }</div> */}
                                                                </div>
                                                            </div>
                                                        {/* </>
                                                        : ''} */}
                                                    </>
                                                    : 'Dalam proses penghantaran'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                </TabPanel>
                            </Box>

                            
                        </div>

                        {showEditModal ? 
                            <CustomModal 
                                closeModal={closeEditModal} 
                                size={'sm'}
                                title={'Kemaskini ' + editData.title}
                                content={<EditLicenseInfo data={info} pets={pets} editData={editData} closeEditModal={closeEditModal} setRefreshView={setRefreshView} />}
                                backdropClose={true}
                            /> : 
                        ''}
                    </DialogContent>
                </>
            )

        } else if(action==4){
            return (
                <>
                {/* <div className="lg:w-2xl md:w-lg"> */}
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 mb-6">
                                <h2 className="text-sm">Anda pasti untuk batal lesen ini?</h2>
                                <div className="mt-4 mb-3">
                                    <span className="pr-1 text-gray-500">No. Lesen: </span>
                                    <span className="font-bold text-sm">{info.license_no}</span>
                                </div>
                                <div className="mt-1">
                                    <span className="pr-1 text-gray-500">Tindakan: </span>
                                    <span 
                                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                            ${info.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                                info.status=='2' ? 'bg-green-500 border-green-500' :
                                                info.status=='3' ? 'bg-amber-500 border-amber-500' : 
                                                'bg-red-500 border-red-500'}`}
                                    >
                                        {info.status_info}
                                        {info.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                                            info.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                                            info.status=='3' ? <i className="fa-regular fa-calendar-xmark pl-1"></i> : 
                                            <i className="fa-solid fa-ban pl-1"></i>}
                                    </span>
                                    <span className="px-2"><i className="fa-solid fa-arrow-right-long"></i></span>
                                    <span 
                                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                                            ${action==2 ? 'bg-green-500 border-green-500' :
                                                action==3 ? 'bg-amber-500 border-amber-500' : 
                                                action==4 ? 'bg-red-500 border-red-500' : ''}`}
                                    >
                                        {action==2 ? <>Aktif<i className="fa-solid fa-circle-check pl-1"></i></> :
                                            action==3 ? <>Tamat Tempoh<i className="fa-regular fa-calendar-xmark pl-1"></i></> : 
                                            action==4 ? <>Batal<i className="fa-solid fa-ban pl-1"></i></> : ''}
                                    </span>
                                </div>
                                <div className="mt-3 flex flex-col">
                                    <span className="pr-1 text-gray-500">Alasan/Sebab:</span>
                                    <TextField
                                        id="outlined-name"
                                        size="small"
                                        multiline
                                        rows={3}
                                        // label="Alasan/Sebab"
                                        value={alasan}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        error={alertAlasan}
                                        helperText={alertAlasan ? 'Ruangan Wajib Diisi' : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {isLoadingUpdate ? 
                            <>
                                <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                <span className='ml-2'>Loading...</span>
                            </> : 
                            <>
                                <Button 
                                    color="gray"
                                    buttonType="link"
                                    onClick={(e) => closeModal()}
                                    ripple="dark"
                                >
                                    Kembali
                                </Button>

                                {userInfo?.isAdmin ?
                                <Button
                                    color="blue"
                                    onClick={onDelete}
                                    // onClick={() => onStatusUpdate(action)}
                                    ripple="light"
                                >
                                    Kemaskini
                                </Button>
                                : ''
                                }
                            </>
                        }
                    </DialogActions>
                {/* </div> */}
                </>
            )
        } else if(action==5){
            return (
                <>
                    <DirectTransaction 
                        id={id} 
                        action={action}
                        closeModal={closeModal} 
                        setRefreshTable={setRefreshTable} 
                        setTitle={setTitle}
                        // setBackdropClose={setBackdropClose()}
                        // setHideButtonClose={setHideButtonClose()}
                    />
                </>
            )
        } else if(action==6){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex justify-center mb-10">
                            <Button type='button' onClick={handlePrint}>
                                <i className="fa-solid fa-print pr-1" style={{fontSize: '1.25rem'}}></i>
                                Cetak Bil
                            </Button>
                        </div>
                        <LicenseBill info={info} pets={pets} ref={componentRef} />
                    </DialogContent>
                {/* </div> */}
                </>
            )
        } else if(action==7){
            return (
                <>
                    <DirectTransaction 
                        id={id} 
                        action={action}
                        closeModal={closeModal} 
                        setRefreshTable={setRefreshTable} 
                        setTitle={setTitle}
                        // setBackdropClose={setBackdropClose()}
                        // setHideButtonClose={setHideButtonClose()}
                    />
                </>
            )
        } else if(action==8){
            return (
                <>
                    <PostPlate 
                        info={info} 
                        pets={pets} 
                        closeModal={closeModal} 
                        setRefreshTable={setRefreshTable} 
                    />
                </>
            )
        }
    }
}

const PostPlate = ({info, pets, closeModal, setRefreshTable}) => {
    const { mutate, isLoading, isSuccess, isFetching } = useDeliveryUpdate();
    const [responsePage, setResponsePage] = useState(false);

    const formSchema = Yup.object().shape({
        plate: Yup.string().required(`Ruangan Wajib Diisi`)
    });

    const validationSchema = Yup.object().shape({
        tracking: Yup.string().required(`Ruangan Wajib Diisi`),
        pet: Yup.array().of(formSchema).required("Ruangan Wajib Diisi")
    });

    const { handleSubmit, control, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { tracking: '', pet: [{ plate: '' }] },
    });

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "pet"
    });

    useEffect(() => {
        console.log('trigger 1')
        if(pets != undefined){
            console.log('trigger 2')

            // if(data==0){
            //     remove()
            // } else {
                for(let i=1; i < pets.length; i++){
                    console.log('trigger 3')
                    // if(id != ''){
                    //     console.log('trigger 4')
                        append({plate: ''})
                    // }
                }
            // }
        }
    }, [pets])

    const onSubmit = async (data) => {
        setResponsePage(false)
        let dat = {
            delivery_id: info.delivery_id, 
            license_id: info.license_id, 
            tracking: data.tracking, 
            plates: data.pet 
        }
        
        await mutate(dat, {
            onSuccess: (data) => {
                // setTitle('')
                setResponsePage(true)
            }
        })
    }

    return (
        <>
            <DialogContent>
                {responsePage ? 
                <div className="flex flex-row justify-center">
                    <ResponseStatus 
                        type={'success'} 
                        close={null} 
                        title={'Berjaya!'}
                        subtitle={'Penghantaran patil telah selesai.'}
                        isButton={null}
                    /> 
                </div>
                :
                <>
                <div className="flex flex-wrap my-1">
                    <div className="w-full lg:w-6/12 px-2 mb-6">
                        <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                            <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-indigo-500 w-full h-10 px-4 py-2 shadow-md-indigo">
                                Butiran Akaun
                            </div>
                            <div className='flex flex-col mt-4 mb-1'>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Lesen</div>
                                    <div className="ml-1">{ info.license_no }</div>
                                </div>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Nama</div>
                                    <div className="ml-1">{ info.name }</div>
                                </div>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">No. Telefon</div>
                                    <div className="ml-1">{ info.phone }</div>
                                </div>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Emel</div>
                                    <div className="ml-1">{ info.email }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-full lg:w-6/12 px-2 mb-6">
                        <div className='w-full bg-white rounded-xl overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                            <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-teal-500 w-full h-10 px-4 py-2 shadow-md-teal">
                                Alamat Penghantaran
                            </div>
                            <div className='flex flex-col mt-4 mb-1'>
                                <div className='mb-2 border-2 rounded-md p-1 h-20'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Alamat</div>
                                    <div className="ml-1">{ info.post_address ? JSON.parse(info.post_address)?.address : '' }</div>
                                </div>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Poskod</div>
                                    <div className="ml-1">{ info.post_address ? JSON.parse(info.post_address)?.postcode : '' }</div>
                                </div>
                                <div className='mb-2 border-2 rounded-md p-1'>
                                    <div className="-mt-3 px-1 bg-white text-gray-500 text-2xs absolute">Bandar</div>
                                    <div className="ml-1">{ info.post_address ? JSON.parse(info.post_address)?.city : '' }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-full px-2 mb-6">
                        <hr />
                        <div className='w-full bg-white rounded-xl border border-gray-100 overflow-hdden shadow-lg pt-4 pb-1 px-4 mt-4'>
                            <div className="-mt-8 mb-4 rounded-xl text-white grid items-center bg-gradient-to-tr bg-purple-500 w-full h-10 px-4 py-2 shadow-md-purple">
                                Maklumat Penghantaran
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h2 className="text-sm">Sila masukkan no. tracking.</h2>
                                <div className="mt-4 mb-3">
                                    <div className="mt-2 flex flex-wrap">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="tracking"
                                                    size='small' 
                                                    label={`No. Tracking`}
                                                    variant="outlined" 
                                                    InputLabelProps={{ shrink: true }} 
                                                    helperText={errors?.tracking ? errors?.tracking.message : ' '}
                                                    error={errors?.tracking ? errors?.tracking : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name={`tracking`}
                                        />
                                    </div>
                                </div>
                                <h2 className="text-sm">Sila masukkan no. patil untuk serahan.</h2>
                                <div className="mt-4 mb-3">
                                    {fields.map((item, index) => {
                                        return (
                                        <div key={item.id} className="mt-2 flex flex-wrap">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="plate"
                                                        size='small' 
                                                        label={`No. Patil ${index + 1}`}
                                                        variant="outlined" 
                                                        InputLabelProps={{ shrink: true }} 
                                                        helperText={errors?.pet?.[index]?.plate ? errors?.pet?.[index]?.plate.message : ' '}
                                                        error={errors?.pet?.[index]?.plate ? errors?.pet?.[index]?.plate : false}
                                                        fullWidth
                                                    />
                                                )}
                                                control={control}
                                                name={`pet[${index}].plate`}
                                            />
                                        </div>
                                        )
                                    })}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </>
                }
            </DialogContent>

            <DialogActions>
                {responsePage ?
                    <Button 
                        color="green"
                        // buttonType="link"
                        onClick={(e) => { closeModal(); setRefreshTable(true) }}
                        ripple="light"
                    >
                        Selesai
                    </Button>
                : 
                    <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>

                    {/* {userInfo?.isAdmin && info?.status!='2' ? */}
                    <Button
                        color="green"
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                    >
                        Simpan
                    </Button>
                    </>
                }
                {/* : ''
                } */}
            </DialogActions>
        </>
    )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <>{children}</>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }