import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import LicenseCard from 'components/LicenseCard';
import { useGetUtama } from "query/actions"
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import required modules
import { Pagination } from "swiper";

export default function SwiperLicenseCard({data, isSuccess}) {
    // const { data, error, isError, isLoading, isSuccess } = useGetUtama();

    return (
        <>
        {isSuccess ? <>
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            slidesOffsetAfter={20}
            className="mySwiper"
            // observer={true}
            // rebuildOnUpdate={true}
        >
            {data?.data.data.license.info != undefined ? 
                <>
                    {data?.data.data.license.info.length > 0 ?
                        <>
                            {data?.data.data.license.info.map((item, index) => 
                            <>
                            {item.payment==2 ?
                                <SwiperSlide key={index} className="w-72">
                                    <LicenseCard
                                        icon={item.status=='1' ? "fa-solid fa-clock" : 
                                                item.status=='2' ? "fa-solid fa-circle-check" :
                                                "fa-solid fa-ban"}
                                        title="No. Lesen"
                                        account={item.license_no}
                                        status={item.status_info}
                                        valid={item.start_date!=null ? `Sah sehingga ${ moment(item.end_date).format('D MMM YYYY') }` : '-'}
                                    />
                                </SwiperSlide>
                            : ''}
                            </>
                            )}
                        </>
                    : 'Tiada lesen'}
                </> : 'Tiada lesen'
                // <SwiperSlide className="w-72">
                //     <LicenseCard
                //         // icon={item.status=='1' ? "fa-solid fa-clock" : 
                //         //         item.status=='2' ? "fa-solid fa-circle-check" :
                //         //         "fa-solid fa-ban"}
                //         title="No. Lesen"
                //         account={'-'}
                //         status={'-'}
                //         valid={'-'}
                //     />
                // </SwiperSlide>
            }
        </Swiper>
        </>
        : <>
            Tiada lesen
        {/* <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            slidesOffsetAfter={20}
            className="mySwiper"
            // observer={true}
            // rebuildOnUpdate={true}
        >
            <SwiperSlide className="w-72">
                <LicenseCard
                    // icon={item.status=='1' ? "fa-solid fa-clock" : 
                    //         item.status=='2' ? "fa-solid fa-circle-check" :
                    //         "fa-solid fa-ban"}
                    title="No. Lesen"
                    account={'-'}
                    status={'-'}
                    valid={'-'}
                />
            </SwiperSlide>
        </Swiper> */}
        </>
        }
        </>
    )
}