import { atom } from "recoil";

export const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    if (process.browser) {
      const savedValue = sessionStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? sessionStorage.removeItem(key)
          : sessionStorage.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export const userInfoAtom = atom({
  key: "userInfoAtom",
  default: [],
  effects: [sessionStorageEffect("userInfo")]
});