import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useApplicationView, useApplicationStatusUpdate, useCourierInfo, usePayment } from "query/actions/Crud";
import { useLicenseView } from "query/actions/license/Crud";
import { TextField, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import Example from "components/Spinner/Example";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
// toastify
import { toast } from 'react-toastify';
import LicenseCard from "components/LicenseCard";
import LicenseBill from "components/LicenseBill";
import DirectTransaction from "components/DirectTransaction";
import md5 from "md5";
import { useDeliveryView } from "query/actions/plate/Crud";
import ResponseStatus from "components/ResponseStatus";
import { useDeliveryUpdate } from "query/actions/plate/Crud";
import { useEditLicense } from "query/actions/license/Crud";

export default function EditLicenseInfo({data, pets, editData, closeEditModal, setRefreshView}){
    const { mutate, isLoading } = useEditLicense();
    const numRegExp = /^\d+$/;
    const validationSchema = editData.type=='ma' ? Yup.object().shape({
        mailing_address: Yup.string().required(`Ruangan Wajib Diisi`),
        mailing_postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        mailing_city: Yup.string().required(`Ruangan Wajib Diisi`)
    }) : editData.type=='pa' ? Yup.object().shape({
        premise_address: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        premise_city: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_status: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_type: Yup.string().required(`Ruangan Wajib Diisi`)
    }) : editData.type=='py' ? Yup.object().shape({
        receipt_no: Yup.string().required(`Ruangan Wajib Diisi`)
    }) : editData.type=='pl' ? Yup.object().shape({
        plate_no: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'No. Patil Tidak Sah')
                .min(4, "Minimum nombor adalah 4 digit")
                .max(4, "Maksimum nombor adalah 4 digit"),
    }) : '';
    const defaultValues = {
        mailing_address: '',
        mailing_postcode: '',
        mailing_city: '',
        premise_address: '',
        premise_postcode: '',
        premise_city: '',
        premise_status: '',
        premise_type: '',
        receipt_no: '',
        plate_no: ''
    }

    useEffect(() => {
        if(data){
            if(editData.type=='ma'){
                reset({
                    mailing_address: data?.ma_address,
                    mailing_postcode: data?.ma_postcode,
                    mailing_city: data?.ma_city
                });
            } else if(editData.type=='pa'){
                reset({
                    premise_address: data?.pa_address,
                    premise_postcode: data?.pa_postcode,
                    premise_city: data?.pa_city,
                    premise_status: data?.premise_status,
                    premise_type: data?.premise_type
                });
            } else if(editData.type=='py'){
                reset({
                    receipt_no: data?.receipt_no
                });
            } else if(editData.type=='pl'){
                reset({
                    plate_no: pets[editData.id]?.plate_no
                });
            }
        }
    }, [data])

    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (dataSubmit) => {
        dataSubmit['type'] = editData.type
        if(editData.type=='ma' || editData.type=='pa'){
            dataSubmit['license_id'] = editData.id
        } else if(editData.type=='py'){
            dataSubmit['license_id'] = editData.id
            dataSubmit['trans_id'] = data.trans_id
            dataSubmit['trans_channel'] = data.trans_channel
        } else if(editData.type=='pl'){
            dataSubmit['pet_id'] = pets[editData.id].id
        }
        // console.log('dataSubmit', dataSubmit)
        await mutate(dataSubmit, {
            onSuccess: (data) => {
                toast.success("Kemaskini Berjaya!", { 
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
                closeEditModal()
                setRefreshView(true)
            }
        })
    }

    return (
        <>
            <DialogContent>
                <div className="flex flex-col w-full">
                    <span className="block text-sm mb-2 font-semibold uppercase text-gray-500">{editData.title}</span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col my-1">
                            {editData.type=='ma' ? 
                            <>
                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Alamat"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.mailing_address ? errors.mailing_address.message : ''}
                                            error={errors.mailing_address ? errors.mailing_address : false}
                                            fullWidth
                                            multiline
                                            maxRows={3}
                                        />
                                    )}
                                    control={control}
                                    name="mailing_address"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Poskod"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.mailing_postcode ? errors.mailing_postcode.message : ''}
                                            error={errors.mailing_postcode ? errors.mailing_postcode : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="mailing_postcode"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Bandar"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.mailing_city ? errors.mailing_city.message : ''}
                                            error={errors.mailing_city ? errors.mailing_city : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="mailing_city"
                                />
                            </div>
                            </> :
                            editData.type=='pa' ? 
                            <>
                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Alamat"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.premise_address ? errors.premise_address.message : ''}
                                            error={errors.premise_address ? errors.premise_address : false}
                                            fullWidth
                                            multiline
                                            maxRows={3}
                                        />
                                    )}
                                    control={control}
                                    name="premise_address"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Poskod"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.premise_postcode ? errors.premise_postcode.message : ''}
                                            error={errors.premise_postcode ? errors.premise_postcode : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="premise_postcode"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Bandar"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.premise_city ? errors.premise_city.message : ''}
                                            error={errors.premise_city ? errors.premise_city : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="premise_city"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            type="text"
                                            label="Status Premis"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.premise_status ? errors.premise_status.message : ''}
                                            error={errors.premise_status ? errors.premise_status : false}
                                            fullWidth
                                        >
                                            <MenuItem value='owner'>Pemilik Premis</MenuItem>
                                            <MenuItem value='renter'>Penyewa Premis</MenuItem>
                                        </TextField>
                            )}
                                    control={control}
                                    name="premise_status"
                                />
                            </div>

                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            type="text"
                                            label="Jenis Premis"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.premise_type ? errors.premise_type.message : ''}
                                            error={errors.premise_type ? errors.premise_type : false}
                                            fullWidth
                                        >
                                            <MenuItem value='pangsapuri'>Pangsapuri Tingkat Bawah</MenuItem>
                                            <MenuItem value='teres'>Teres</MenuItem>
                                            <MenuItem value='berkembar'>Berkembar</MenuItem>
                                            <MenuItem value='banglo'>Banglo</MenuItem>
                                            <MenuItem value='kampung'>Kampung</MenuItem>
                                            <MenuItem value='lkg'>Ladang/Kilang/Gudang</MenuItem>
                                        </TextField>
                                    )}
                                    control={control}
                                    name="premise_type"
                                />
                            </div>
                            </> :
                            editData.type=='pl' ? 
                            <>
                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="Patil"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.plate_no ? errors.plate_no.message : ''}
                                            error={errors.plate_no ? errors.plate_no : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="plate_no"
                                />
                            </div>
                            </> :
                            editData.type=='py' ? 
                            <>
                            <div className="w-full mb-4 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            label="No. Resit"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors.receipt_no ? errors.receipt_no.message : ''}
                                            error={errors.receipt_no ? errors.receipt_no : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="receipt_no"
                                />
                            </div>
                            </> : ''}

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeEditModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="orange"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Kemaskini
                </Button>
            </DialogActions>
        </>
    )
}

