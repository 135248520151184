import StatusCard from 'components/StatusCard';
import ChartLine from 'components/ChartLine';
import ChartBar from 'components/ChartBar';
import { Alert, AlertTitle } from "@mui/material";
import PageVisitsCard from 'components/PageVisitsCard';
import TrafficCard from 'components/TrafficCard';
import { useGetDashboard } from "query/admin"
import { Spinner } from 'components/Spinner';
import NumberFormat from "react-number-format";
import LicenseLatest from 'components/LicenseLatest';
import { Button } from '@material-tailwind/react';
import { useHistory } from 'react-router-dom';

export default function Dashboard() {
    const { data, error, isError, isLoading } = useGetDashboard();
    // console.log('useGetDashboard', data?.data.data)
    let history = useHistory()

    const formatNumber = (num) => {
        return (
            <NumberFormat
                thousandsGroupStyle="thousand"
                value={num}
                displayType="text"
                type="text"
                thousandSeparator={true}
            />
        )
    }

    if(isLoading){
        return (<Spinner /> )
    }

    return (
        <>
            <div className="bg-teal-500 px-3 md:px-8 h-40" />

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 mb-4">
                        <StatusCard
                            color="purple"
                            icon="fas fa-list-alt"
                            title="Permohonan"
                            amount={formatNumber(data.data.data.applications)}
                            viewMore="/v2/lesen/senarai-permohonan"
                        />
                        <StatusCard
                            color="pink"
                            icon="far fa-id-card"
                            title="Lesen"
                            amount={formatNumber(data.data.data.license)}
                            viewMore="/v2/lesen/senarai-lesen"
                        />
                        <StatusCard
                            color="orange"
                            icon="fas fa-dog"
                            title="Patil"
                            amount={formatNumber(data.data.data.plates)}
                            viewMore="/v2/lesen/carian-patil"
                        />
                        {/* <StatusCard
                            color="purple"
                            icon="fas fa-user-alt"
                            title="Pemilik"
                            amount="924"
                            percentage="1.10"
                            percentageIcon="arrow_downward"
                            percentageColor="orange"
                            date="Since yesterday"
                        /> */}
                        {/* <StatusCard
                            color="blue"
                            icon="poll"
                            title="Performance"
                            amount="49,65%"
                            percentage="12"
                            percentageIcon="arrow_upward"
                            percentageColor="green"
                            date="Since last month"
                        /> */}
                    </div>
                </div>
            </div>

            {data.data.data.courier > 0 ?
            <div className="px-3 pb-4 md:px-8 -mt-8">
                <div className="container mx-auto max-w-full">
                    <div className="w-full mb-10 px-4 font-light">
                        <Alert icon={<i className="fa-solid fa-circle-exclamation text-5xl"></i>} severity="warning">
                            <div className='flex flex-row justify-between w-full'>
                                <div className='flex flex-col w-full'>
                                    <AlertTitle>Penghantaran Patil</AlertTitle>
                                    Terdapat {data.data.data.courier} penghantaran patil belum diselesaikan
                                </div>
                                <div className='flex mx-4'>
                                    <Button
                                        type="button"
                                        // size="sm"
                                        color="orange"
                                        onClick={() => history.push('/v2/lesen/penghantaran-patil')}
                                        className="w-40"
                                    >
                                        Lihat Sekarang
                                    </Button>
                                </div>
                            </div>
                        </Alert>
                    </div>
                </div>
            </div>
            : ''}

            {/* <div className="px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-3 px-4 mb-14">
                            <TrafficCard group={data.data.data.application_group} total={data.data.data.applications} />
                        </div>
                        <div className="xl:col-start-3 xl:col-end-6 px-4 mb-14">
                            <LicenseLatest data={data.data.data.license_latest} />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <ChartLine />
                        </div>
                        <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            <ChartBar />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
