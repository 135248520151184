import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import UserSidebar from 'components/user/UserSidebar';
import Footer from 'components/Footer';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';

// handle the main routes
function SingleRoute({ component: Component, ...rest }) {
    const userInfo = useRecoilValue(userInfoAtom);
  
    return (
      <Route
        // basename={'/v2'}
        {...rest}
        render={(props) =>
          userInfo?.accessToken ? (
            <>
            {/* <UserSidebar />
            <div className="md:ml-64"> */}
                <Component {...props} />
            {/* </div>
            <Footer /> */}
            </>
          ) : (
            <Redirect
              to={{ pathname: "/v2/logmasuk" }}
            />
          )
        }
      />
    );
  }
  
  export default SingleRoute;