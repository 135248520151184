import React, { useState, useEffect } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// import Datepicker from 'react-tailwindcss-datepicker';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function CarianLaporanLesen({setSearch, setPage}) {
    const [toggle, setToggle] = useState(true);
    const [toggleName, setToggleName] = useState('Buka');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    // useEffect(() => {
    //     toggleCard()
    // }, [])

    const validationSchema = Yup.object().shape({
        lesen: Yup.string().when("lesen", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(11, "Minimum 11 digit").max(11, "Maximum 11 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        permohonan: Yup.string().when("permohonan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(10, "Minimum 10 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        mykad: Yup.string().when("mykad", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(12, "Minimum 12 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        nama: Yup.string().when("nama", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        status: Yup.string().when("status", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        saluran: Yup.string().when("saluran", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        serahan: Yup.string().when("serahan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        tarikh: Yup.string().when("tarikh", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        alamat: Yup.string().when("alamat", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(3, "Minimum 3 aksara").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        tahun: Yup.string().when("tahun", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
    }, [
        ["lesen", "lesen"], 
        ["permohonan", "permohonan"],
        ["mykad", "mykad"],
        ["nama", "nama"],
        ["status", "status"],
        ["saluran", "saluran"],
        ["serahan", "serahan"],
        ["tarikh", "tarikh"],
        ["alamat", "alamat"],
        ["tahun", "tahun"],
    ]);
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField } = useForm({
        defaultValues: {
            lesen: '',
            permohonan: '',
            mykad: '',
            nama: '',
            status: '',
            saluran: '',
            serahan: '',
            tarikh: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        console.log('datadata',data)
        if(startDate && endDate){
            data['startDate'] = moment(startDate).format('YYYY-MM-DD')
            data['endDate'] = moment(endDate).format('YYYY-MM-DD')
        }
        setSearch(data)
        setPage(0)
    }

    const onResetForm = () => {
        reset()
        setSearch(null)
        setPage(0)
        setDateRange([null,null])
    }

    const [value, setValue] = useState({});
    const [inputData, setInputData] = useState({});
  
    const handleValueChange = (newValue) => {
      // console.log("newValue:", newValue);
      setValue(newValue);
      setInputData((prev) => {
        let dataload = {...prev, ...newValue}
        return dataload
      })
    }
    // const [startDate, setStartDate] = useState(new Date());

    return (
        <>
            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-wrap justify-between mt-2'>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. Lesen" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.lesen ? errors.lesen.message : ''}
                                                error={errors.lesen ? errors.lesen : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="lesen"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. MyKad" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.mykad ? errors.mykad.message : ''}
                                                error={errors.mykad ? errors.mykad : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="mykad"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Nama Pemilik" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.nama ? errors.nama.message : ''}
                                                error={errors.nama ? errors.nama : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="nama"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Status" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'1'}>{'Tidak Aktif'}</MenuItem>
                                                <MenuItem value={'2'}>{'Aktif'}</MenuItem>
                                                <MenuItem value={'3'}>{'Tamat Tempoh'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Alamat" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.alamat ? errors.alamat.message : ''}
                                                error={errors.alamat ? errors.alamat : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="alamat"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="DUN" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="N1">N1 - Penaga</MenuItem>
                                                <MenuItem value="N2">N2 - Bertam</MenuItem>
                                                <MenuItem value="N3">N3 - Pinang Tunggal</MenuItem>
                                                <MenuItem value="N4">N4 - Permatang Berangan</MenuItem>
                                                <MenuItem value="N5">N5 - Sungai Dua</MenuItem>
                                                <MenuItem value="N6">N6 - Telok Ayer Tawar</MenuItem>
                                                <MenuItem value="N7">N7 - Sungai Puyu</MenuItem>
                                                <MenuItem value="N8">N8 - Bagan Jermal</MenuItem>
                                                <MenuItem value="N9">N9 - Bagan Dalam</MenuItem>
                                                <MenuItem value="N10">N10 - Seberang Jaya</MenuItem>
                                                <MenuItem value="N11">N11 - Permatang Pasir</MenuItem>
                                                <MenuItem value="N12">N12 - Penanti</MenuItem>
                                                <MenuItem value="N13">N13 - Berapit</MenuItem>
                                                <MenuItem value="N14">N14 - Machang Bubuk</MenuItem>
                                                <MenuItem value="N15">N15 - Padang Lalang</MenuItem>
                                                <MenuItem value="N16">N16 - Perai</MenuItem>
                                                <MenuItem value="N17">N17 - Bukit Tengah</MenuItem>
                                                <MenuItem value="N18">N18 - Bukit Tambun</MenuItem>
                                                <MenuItem value="N19">N19 - Jawi</MenuItem>
                                                <MenuItem value="N20">N20 - Sungai Bakap</MenuItem>
                                                <MenuItem value="N21">N21 - Sungai Acheh</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Parlimen" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="P41 - Kepala Batas">P41 - Kepala Batas</MenuItem>
                                                <MenuItem value="P42 - Tasek Gelugor">P42 - Tasek Gelugor</MenuItem>
                                                <MenuItem value="P43 - Bagan">P43 - Bagan</MenuItem>
                                                <MenuItem value="P44 - Permatang Pauh">P44 - Permatang Pauh</MenuItem>
                                                <MenuItem value="P45 - Bukit Mertajam">P45 - Bukit Mertajam</MenuItem>
                                                <MenuItem value="P46 - Batu Kawan">P46 - Batu Kawan</MenuItem>
                                                <MenuItem value="P47 - Nibong Tebal">P47 - Nibong Tebal</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Saluran" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.saluran ? errors.saluran.message : ''}
                                                error={errors.saluran ? errors.saluran : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'1'}>{'Online'}</MenuItem>
                                                <MenuItem value={'2'}>{'Kaunter'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="saluran"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Status Premis" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value='owner'>Pemilik Premis</MenuItem>
                                                <MenuItem value='renter'>Penyewa Premis</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Jenis Premis" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value='pangsapuri'>Pangsapuri Tingkat Bawah</MenuItem>
                                                <MenuItem value='teres'>Teres</MenuItem>
                                                <MenuItem value='berkembar'>Berkembar</MenuItem>
                                                <MenuItem value='banglo'>Banglo</MenuItem>
                                                <MenuItem value='kampung'>Kampung</MenuItem>
                                                <MenuItem value='lkg'>Ladang/Kilang/Gudang</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Serahan" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.serahan ? errors.serahan.message : ''}
                                                error={errors.serahan ? errors.serahan : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'null'}>{'Kaunter MBSP'}</MenuItem>
                                                <MenuItem value={'courier'}>{'Perkhidmatan Kurier'}</MenuItem>
                                                <MenuItem value={'pickup'}>{'Ambil Sendiri di MBSP'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="serahan"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <div className='text-xs text-gray-600 datepicker-label'>Julat Tarikh</div>
                                    <DatePicker
                                        // selected={startDate}
                                        // onChange={(date) => setStartDate(date)}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        dateFormat={'dd/MM/yyyy'}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        className='w-full border-[1px] border-black/25 hover:border-black rounded-[4px] px-5 h-10'
                                    />
                                    {(startDate && endDate==null) &&
                                    <span className='text-xs text-red-500'>Julat tarikh tidak lengkap</span>}
                                    {/* <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                        dateFormat={'dd/MM/yyyy'}
                                        className='!w-full border-[1px] border-black/25 hover:border-black rounded-[4px] px-5 h-10'
                                    /> */}
                                    {/* <Datepicker
                                        value={value}
                                        onChange={handleValueChange}
                                        inputClassName='w-full border-[1px] border-black/25 hover:border-black rounded-[4px] px-5 h-10'
                                        // containerClassName="bg-white"
                                        placeholder={"Date Range"} 
                                        showShortcuts={true} 
                                        // primaryColor='blue'
                                    /> */}
                                    {/* <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='date'
                                                size='small' 
                                                label="Tarikh" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.tarikh ? errors.tarikh.message : ''}
                                                error={errors.tarikh ? errors.tarikh : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="tarikh"
                                    /> */}
                                </div>
                                <div className='flex justify-center w-full p-2'>
                                    <Button 
                                        color="gray"
                                        buttonType="link"
                                        onClick={onResetForm}
                                        ripple="dark"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="blue"
                                        // onClick={() => onStatusUpdate(action)}
                                        ripple="light"
                                        type="submit"
                                    >
                                        Cari
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}