import React, { useEffect, useRef, useState } from "react";
import NotificationCard from "components/NotificationCard";

import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import { Alert, AlertTitle } from "@mui/material";

// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

import SwiperLicenseCard from "components/SwiperLicenseCard";
import StatusCard from "components/StatusCard";
import NumberFormat from "react-number-format";
import { useGetUtama } from "query/actions";

export default function Utama() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { data, error, isError, isLoading, isSuccess } = useGetUtama();

    const formatNumber = (num) => {
        return (
            <NumberFormat
                thousandsGroupStyle="thousand"
                value={num}
                displayType="text"
                type="text"
                thousandSeparator={true}
            />
        )
    }

    return (
        <>
            <div className="bg-cyan-500 px-3 md:px-8 h-48" />

            <div className="px-3 md:px-8 -mt-40">
                <div className="container mx-auto max-w-full">
                    <div className="w-full mb-10 px-4 font-light">
                        <Alert icon={<i className="fa-solid fa-face-laugh text-3xl"></i>} severity="info">
                            <AlertTitle>Hai, {userInfo.name}</AlertTitle>
                            Selamat Datang ke Sistem Permohonan Lesen Anjing MBSP
                        </Alert>
                    </div>
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto pt-2">
                <div className="container mx-auto max-w-full">
                    <div className='flex flex-wrap'>
                        <div className='w-full lg:w-4/12 pr-4 /px-4 mb-14'>
                            <div className="/pb-5">
                                <StatusCard
                                    color="purple"
                                    icon="fas fa-list-alt"
                                    title="Permohonan"
                                    amount={formatNumber(data?.data.data.total_application != undefined ? data?.data.data.total_application : 0)}
                                    viewMore="/v2/permohonan/senarai"
                                />
                            </div>
                            <div className="/pt-5">
                                <StatusCard
                                    color="pink"
                                    icon="far fa-id-card"
                                    title="Lesen"
                                    amount={formatNumber(data?.data.data.total_license != undefined ? data?.data.data.total_license : 0)}
                                    viewMore="/v2/maklumat-lesen"
                                />
                            </div>
                        </div>
                        <div className='w-full lg:w-8/12 px-4 mb-14'>
                            <Card>
                                <CardHeader size='sm' color="purple" contentPosition="left">
                                    <h2 className="text-white text-xl">Lesen</h2>
                                </CardHeader>
                                <div className="mt-12" style={{minHeight: '12.75rem'}}>
                                    <SwiperLicenseCard data={data} isSuccess={isSuccess} />
                                </div>
                            </Card>
                        </div>
                        {/* <div className='w-full lg:w-6/12 px-4 mb-14'>
                            <NotificationCard />
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-3 px-4 mb-14">
                            {/* <TrafficCard /> */}
                        </div>
                        <div className="xl:col-start-3 xl:col-end-6 px-4 mb-14">
                            {/* <PageVisitsCard /> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            {/* <ChartLine /> */}
                        </div>
                        <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                            {/* <ChartBar /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
