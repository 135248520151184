import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'
import { ConstructionOutlined } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { useCourierInfo } from 'query/actions/Crud'
import React, { useState, useEffect } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomModal from '../../components/CustomModal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateCourierInfo } from 'query/actions/Crud'
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

export default function CajPerkhidmatanKurier() {
  const { data, isFetching } = useCourierInfo()
  const [title, setTitle] = useState('');
  const [pageModal, setPageModal] = useState(null);
  const [showModal, setShowModal] = useState(false);
  async function closeModal() {
      await setShowModal(false);
  }

  function onUpdateHandler(){
    setPageModal(<UpdateCourierInfo data={data} closeModal={closeModal} />)
    setTitle('Kemaskini Maklumat Kurier')
    setShowModal(true)
  }
  return (
    <>
      <div className="bg-teal-500 pt-14 pb-28 px-3 md:px-8 h-auto"></div>

      <div className="px-3 md:px-8 h-auto -mt-24">
          <div className="container mx-auto max-w-full">
              <div className="grid grid-cols-1 px-4 mb-16">
                <Card>
                  <CardHeader size='sm' color="green" contentPosition="none">
                    <h2 className="text-white text-xl">Maklumat Kurier</h2>
                  </CardHeader>
                  <CardBody>
                    <div className='flex justify-end mx-4'>
                      <Button
                        color="amber"
                        className="normal-case mr-1"
                        // buttonType="outline"
                        // size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // type="submit"
                        onClick={() => onUpdateHandler()}
                      >
                        <i class="fas fa-edit pr-1"></i>Kemaskini
                      </Button>
                    </div>
                    <div className="flex flex-wrap justify-center mt-5 mb-5">
                      <div className="w-full /lg:w-8/12 font-light">
                        <div className="flex flex-row mb-10">
                          <div className="w-full lg:w-6/12 pr-4">
                            <TextField
                              label="Nama Syarikat"
                              variant="standard"
                              size="small"
                              color="primary"
                              value={data?.data[0]?.nama_syarikat_penghantaran}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ readOnly: true, }}
                            />
                          </div>
                          <div className="w-full lg:w-6/12 pl-4">
                            <TextField
                              label="Amuan Caj"
                              variant="standard"
                              size="small"
                              color="primary"
                              value={data?.data[0]?.amaun_caj}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ readOnly: true, }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="w-full lg:w-12/12">
                            <TextField
                              label="URL Jejak Pesanan (Track & Trace)"
                              variant="standard"
                              size="small"
                              color="primary"
                              value={data?.data[0]?.website}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ readOnly: true, }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
          </div>
      </div>

      {showModal ? 
        <CustomModal 
          closeModal={closeModal} 
          size={'sm'}
          title={title}
          content={pageModal}
          backdropClose={false}
        /> : 
      ''}
    </>
  )
}

const UpdateCourierInfo = ({data, closeModal}) => {
  const {mutate, isLoading} = useUpdateCourierInfo()
  const userInfo = useRecoilValue(userInfoAtom)

  const validationSchema = Yup.object().shape({
      name: Yup.string().required(`Ruangan Wajib Diisi`),
      amount: Yup.string().required(`Ruangan Wajib Diisi`),
      url: Yup.string().required(`Ruangan Wajib Diisi`)
  });
  const defaultValues = {
      name: '',
      amount: '',
      url: ''
  }
  const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
      defaultValues,
      resolver: yupResolver(validationSchema),
  });

  const resetFieldValue = (field, value) => {
      resetField(field)
      setValue(field, value)
  }

  useEffect(() => {
    reset({
      name: data.data[0].nama_syarikat_penghantaran,
      amount: data.data[0].amaun_caj,
      url: data.data[0].website
    })
  }, [data])
  
  const onSubmit = async (dataSubmit) => {
    const loadingToast = toast.loading("Please wait...", {
        position: toast.POSITION.TOP_CENTER
    });
    let d = {
      name: dataSubmit.name,
      amount: dataSubmit.amount,
      url: dataSubmit.url,
      id: data.data[0].idno,
      staffId: userInfo?.isAdmin ? userInfo.staffId : userInfo.userId
    }
    await mutate(d, {
        onSuccess: (data) => {
            Notify(data.data.response.response_code, loadingToast)
            closeModal()
        }
    })
  }
  const Notify = (code, loadingToast) => {
    if(code == '200'){
        toast.update(loadingToast, { 
            render: "Kemaskini Berjaya", 
            type: "success", 
            isLoading: false,
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
        });
    }
    
    if(code == '201'){
        toast.update(loadingToast, { 
            render: "Kemaskini Gagal", 
            type: "error", 
            isLoading: false,
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000
        });
    }
  }
  
  return (
      <>
        <DialogContent>
          <div className="flex flex-col my-1">
            <div className="w-full px-2 mb-6">
              <div className='flex flex-col mt-4 /mb-1'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Controller
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  type="text"
                                  label="Nama Syarikat"
                                  // placeholder="Masukkan alamat anda"
                                  variant="outlined"
                                  size="small"
                                  // value={addressValue}
                                  // onChange={(event) => {
                                  //     setAddressValue(event.target.value)
                                  //     resetFieldValue('address', event.target.value)
                                  // }}
                                  InputLabelProps={{ shrink: true }}
                                  helperText={errors.name ? errors.name.message : ''}
                                  error={errors.name ? errors.name : false}
                                  fullWidth
                                  // multiline
                                  // maxRows={3}
                              />
                          )}
                          control={control}
                          name="name"
                      />
                  </div>
                  <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Controller
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  type="number"
                                  label="Amaun Caj"
                                  // placeholder="Masukkan aduan"
                                  variant="outlined"
                                  size="small"
                                  // value={addressValue}
                                  // onChange={(event) => {
                                  //     setAddressValue(event.target.value)
                                  //     resetFieldValue('address', event.target.value)
                                  // }}
                                  InputLabelProps={{ shrink: true }}
                                  helperText={errors.amount ? errors.amount.message : ''}
                                  error={errors.amount ? errors.amount : false}
                                  fullWidth
                              />
                          )}
                          control={control}
                          name="amount"
                      />
                  </div>
                  <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Controller
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  type="text"
                                  label="URL Jejak Pesanan"
                                  // placeholder="Masukkan aduan"
                                  variant="outlined"
                                  size="small"
                                  // value={addressValue}
                                  // onChange={(event) => {
                                  //     setAddressValue(event.target.value)
                                  //     resetFieldValue('address', event.target.value)
                                  // }}
                                  InputLabelProps={{ shrink: true }}
                                  helperText={errors.url ? errors.url.message : ''}
                                  error={errors.url ? errors.url : false}
                                  fullWidth
                                  multiline
                                  maxRows={3}
                              />
                          )}
                          control={control}
                          name="url"
                      />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            color="gray"
            buttonType="link"
            onClick={(e) => closeModal()}
            ripple="dark"
          >
            Kembali
          </Button>
          <Button
            color="blue"
            onClick={handleSubmit(onSubmit)}
            ripple="light"
          >
            Simpan
          </Button>
        </DialogActions>
      </>
  )
}