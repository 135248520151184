import React from 'react'
import CarianLaporanLesen from 'components/lesen/CarianLaporanLesen';
import DataTableCardPenghantaranPatil from 'components/DataTableCardPenghantaranPatil'
import CarianPenghantaranPatil from 'components/lesen/CarianPenghantaranPatil'
import CarianPembaharuanLesen from 'components/lesen/CarianPembaharuanLesen';
import DataTableCardPembaharuanLesen from 'components/DataTableCardPembaharuanLesen';

export default function PembaharuanLesen() {
  return (
    <>
      {/* <div className="bg-light-blue-500 pt-24 pb-28 px-3 md:px-8 h-auto"> */}
      <div className={`bg-teal-500 pt-24 pb-28 px-3 md:px-8 h-auto`}>
        {/* <div className="container mx-auto max-w-full">
          <div className="px-4 mb-16">
            <CarianPembaharuanLesen />
          </div>
        </div> */}
      </div>

      <div className="px-3 md:px-8 h-auto -mt-40">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <DataTableCardPembaharuanLesen />
          </div>
        </div>
      </div>
    </>
  )
}
