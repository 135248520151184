import React, { useState, useEffect } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function CarianPembaharuanLesen({setSearch, setPage, setShowTable}) {
    const [toggle, setToggle] = useState(false);
    const [toggleName, setToggleName] = useState('Buka');

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    useEffect(() => {
        toggleCard()
    }, [])

    const validationSchema = Yup.object().shape({
        lesen: Yup.string().when("lesen", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(11, "Minimum 11 digit").max(11, "Maximum 11 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        mykad: Yup.string().when("mykad", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(12, "Minimum 12 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        nama: Yup.string().when("nama", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
    }, [
        ["lesen", "lesen"], 
        ["mykad", "mykad"],
        ["nama", "nama"],
    ]);
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, setValue } = useForm({
        defaultValues: {
            lesen: '',
            mykad: '',
            nama: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        console.log('datadata',data)
        setSearch(data)
        setPage(0)
        setShowTable(true)
    }

    const onResetForm = () => {
        reset()
        setSearch(null)
        setPage(0)
        setShowTable(false)
    }

    return (
        <>
            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-wrap justify-between mt-2'>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. Lesen" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.lesen ? errors.lesen.message : ''}
                                                error={errors.lesen ? errors.lesen : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="lesen"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. MyKad" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.mykad ? errors.mykad.message : ''}
                                                error={errors.mykad ? errors.mykad : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="mykad"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Nama Pemilik" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.nama ? errors.nama.message : ''}
                                                error={errors.nama ? errors.nama : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="nama"
                                    />
                                </div>
                                <div className='flex justify-center w-full p-2'>
                                    <Button 
                                        color="gray"
                                        buttonType="link"
                                        onClick={onResetForm}
                                        ripple="dark"
                                        type="button"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="blue"
                                        // onClick={() => onStatusUpdate(action)}
                                        ripple="light"
                                        type="submit"
                                    >
                                        Cari
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}