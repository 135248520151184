import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { useLicenseList } from 'query/actions/license/Crud';
import moment from "moment";
import ButtonGroupActionLesen from './ButtonGroupActionLesen';
import differenceBy from 'lodash/differenceBy';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useEmployeeList } from 'query/admin';
import AddNewStaff from './AddNewStaff';
import ButtonGroupActionPentadbir from './ButtonGroupActionPentadbir';

export default function DataTableCardPentadbir() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, error, isError, isLoading, isSuccess } = useEmployeeList();
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;

    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getEmployeeList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = {'page': page, 'perPage': countPerPage, 'user': accessToken}
        getEmployeeList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, refreshTable]);

    // admin
    const columns = [
        {
            name: '#',
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '5rem'
        },
        {
            name: 'INFO STAF',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'><b>{ row.email }</b></div>
                    <div className='mb-1'>{ row.name }</div>
                    <div>{ row.department }</div>
                </div>,
            selector: row => row.email,
            sortable: true,
            width: '29.5rem'
        },
        {
            name: 'PERANAN',
            cell: row => <span className='capitalize'>{row.title}</span>,
            selector: row => row.role_id,
            sortable: true,
            // width: '8rem'
        },
        {
            name: 'STATUS',
            cell: row => <div style={{fontSize: '10px'}} 
                        className={`px-2 py-1 rounded-lg text-white font-medium text-xs 
                        ${row.status==1 ? 'bg-green-500' : 
                        row.status==2 ? 'bg-gray-500' : 
                        row.status==3 ? 'bg-amber-500' : 'bg-red-500'}`
                    }>
                    { row.status_info }
                </div>,
            selector: row => row.status,
            sortable: true,
            // width: '8rem'
        },
        // {
        //     name: 'TARIKH',
        //     cell: row => <div className='flex flex-col my-4'>
        //             <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
        //             <div>{ moment(row.created_at).format('h:mm A') }</div>
        //         </div>,
        //     selector: row => row.created_at,
        //     sortable: true,
        //     width: '7rem'
        // },
        {
            name: ' ',
            cell: row => <ButtonGroupActionPentadbir 
                        isAdmin={isAdmin} 
                        data={row}
                        setRefreshTable={setRefreshTable}
                    />,
            // selector: row => row.license_id,
            sortable: true,
            //   width: '12%'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);

    return (
        <>
        <Card>
            <CardHeader size='sm' color="teal" contentPosition="left">
                <h2 className="text-white text-xl">Senarai Pentadbir</h2>
            </CardHeader>
            <CardBody className='-mt-3'>
                <div className='flex justify-end mx-4'>
                    <Button
                        color="blue"
                        className="normal-case"
                        // buttonType="outline"
                        // size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // type="submit"
                        onClick={() => setShowModal(true)}
                    >
                        <i class="fas fa-plus pr-1"></i>Tambah Pentadbir
                    </Button>
                </div>
                <div className="overflow-x-auto mt-4">
                    <DataTable 
                        // title=" "
                        className='w-full'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        // selectableRowDisabled={rowDisabledCriteria}
                        // contextActions={contextActions}
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={'sm'}
                title={`Tambah Pentadbir Baru`}
                content={<AddNewStaff closeModal={closeModal} setRefreshTable={setRefreshTable} />}
                backdropClose={true}
            /> : 
        ''}
        </>
    );
}
