import DataTableCardPengguna from 'components/DataTableCardPengguna';
import DataTableCardPentadbir from 'components/DataTableCardPentadbir';
import CarianLesen from 'components/lesen/CarianLesen';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

export default function SenaraiPengguna() {
    const userInfo = useRecoilValue(userInfoAtom);

    return (
        <>
            {/* <div className="bg-light-blue-500 px-3 md:px-8 h-40" /> */}
            {/* <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                {userInfo?.isAdmin ?
                <div className="container mx-auto max-w-full">
                    <div className="px-4 mb-16">
                        <CarianLesen />
                    </div>
                </div>
                : ''}
            </div> */}

            <div className="bg-teal-500 pt-24 pb-28 px-3 md:px-8 h-auto">
            </div>

            <div className="px-3 md:px-8 h-auto -mt-40">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <DataTableCardPengguna />
                    </div>
                </div>
            </div>
        </>
    );
}
