import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';
import * as api from "api/Api";

const useLogin = () => {
    let history = useHistory();
    const [auth, setAuth] = useRecoilState(userInfoAtom);

    return useMutation(loginRequest, {
        onSuccess: (data) => {
            if(data.data.response.response_code == '200'){
                setAuth(data.data.data);
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(data.data.data)
                );
                toast.success("Log masuk berjaya, Selamat Datang!", {
                    position: toast.POSITION.TOP_CENTER
                });

                // if(data.data.data.changePassword){
                //     sessionStorage.setItem(
                //         "changePassword",
                //         JSON.stringify(data.data.data.changePassword)
                //     );
                // }

                if(data.data.data.isAdmin){
                    history.replace('/v2')
                } else {
                    history.replace('/v2/utama')
                }
            }
        },
    });
};
const loginRequest = async (credential) => {
    return api.loginRequest(credential)
}
  
const useLogout = (type) => {
    let history = useHistory();
    console.log('trigger logout')
    return useMutation(logoutRequest, {
        onSuccess: (data) => {
            toast.success("Anda telah log keluar", {
                position: toast.POSITION.TOP_CENTER
            });
            sessionStorage.clear();
            window.location.reload()
            if(type == 1){
                sessionStorage.setItem("type", type);
            //     history.replace('/tadbir')
            // } else if(type == 2){
            //     history.replace('/logmasuk')
            }
            console.log('type',sessionStorage.getItem('type'))
        },
    });
};
const logoutRequest = async () => {
    return api.logoutRequest()
}

const useVerifyLogin = () => {
    let history = useHistory();
    const [auth, setAuth] = useRecoilState(userInfoAtom);

    return useMutation(verifyLogin, {
        onSuccess: (data) => {
            if(data.data.response.response_code == '200'){
                setAuth(data.data.data);
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(data.data.data)
                );
                toast.success("Log masuk berjaya, Selamat Datang!", {
                    position: toast.POSITION.TOP_CENTER
                });

                history.replace('/v2/utama')
            }
        },
    });
};
const verifyLogin = async (credential) => {
    return api.verifyLogin(credential)
}

const useOauthLogin = () => {
    let history = useHistory();
    const [auth, setAuth] = useRecoilState(userInfoAtom);

    return useMutation(oauthLogin, {
        onSuccess: (data) => {
            // if(data.data.response.response_code == '200'){
            //     setAuth(data.data.data);
            //     sessionStorage.setItem(
            //         "userInfo",
            //         JSON.stringify(data.data.data)
            //     );
            //     toast.success("Log masuk berjaya, Selamat Datang!", {
            //         position: toast.POSITION.TOP_CENTER
            //     });

            //     history.replace('/utama')
            // }
        },
    });
};
const oauthLogin = async () => {
    return api.oauthLogin()
}

const useLdapConnect = () => {
    let history = useHistory();
    const [auth, setAuth] = useRecoilState(userInfoAtom);

    return useMutation(ldapConnect, {
        onSuccess: (data) => {
            if(data.data.response.response_code == '200'){
                setAuth(data.data.data);
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(data.data.data)
                );
                toast.success("Log masuk berjaya, Selamat Datang!", {
                    position: toast.POSITION.TOP_CENTER
                });

                history.replace('/')
            }
        },
    });
};
const ldapConnect = async (credential) => {
    return api.ldapConnect(credential)
}

const useForgotPasssword = () => {
    return useMutation(forgotPassword, {
        onSuccess: (data) => {
        },
    });
};
const forgotPassword = async (credential) => {
    return api.forgotPassword(credential)
}

export { 
    useLogin, 
    useLogout,
    useVerifyLogin,
    useOauthLogin,
    useLdapConnect,
    useForgotPasssword
};