import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useRegister } from "query/actions/Registration";
import { Spinner } from 'components/Spinner';
import ResponseStatus from "./ResponseStatus";
import { toast } from 'react-toastify';
import { FormControlLabel, Alert, MenuItem, Checkbox, TextField, FormHelperText, Backdrop, CircularProgress } from '@mui/material';
import { useRoles } from "query/admin";
import { useRegisterStaff } from "query/admin";
import { useSearchStaffInfo } from "query/admin";

export default function AddNewStaff({closeModal, setRefreshTable}){    
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useRegisterStaff();
    const { mutate: mutateSearch, isLoading: loadngSearch } = useSearchStaffInfo();
    const roles = useRoles();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        id: Yup.string().required(`Ruangan Wajib Diisi`),
                // .matches(phoneRegExp, 'No. MyKad Tidak Sah')
                // .min(12, "Minimum nombor adalah 12 digit")
                // .max(12, "Maksimum nombor adalah 12 digit"),
        name: Yup.string().required(`Ruangan Wajib Diisi`),
        department: Yup.string().required(`Ruangan Wajib Diisi`),
                // .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                // .min(10, "Minimum nombor adalah 10 digit")
                // .max(12, "Maksimum nombor adalah 12 digit"),
        role: Yup.string().required(`Ruangan Wajib Diisi`),
        email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`)
    });
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, getValues } = useForm({
        defaultValues: {
            id: '',
            name: '',
            department: '',
            role: '',
            email: ''
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        // console.log('errorserrors',errors)
        console.log('datadata',data)
        // // logIn(data)
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Tambah Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Emel telah wujud!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        setPage(
            <ResponseStatus 
                type={'success'} 
                close={null} 
                title={'Berjaya!'}
                subtitle={'Log masuk ke akaun anda sekarang.'}
                isButton={null}
            />
        )
    }

    const passwordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const searchStaffInfo = async () => {
        console.log('getValues', getValues('id'))
        if(getValues('id')){
            await mutateSearch(getValues('id'), {
                onSuccess: (data) => {
                    console.log('searchStaffInfo', data.data)
                    if(data.data.response.response_code == '200'){
                        reset({
                            id: data.data.data.no_pekerja,
                            name: data.data.data.nama,
                            email: data.data.data.email,
                            department: data.data.data.lokasi,
                        })
                    }
                }
            })
        }
    }

    return (
        <>
        {/* <div className="lg:w-2xl md:w-lg"> */}
            {isLoading ? <div className='flex justify-center items-center'><Spinner /></div> :
            // page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-wrap w-full">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-wrap my-1">
                            <span className="block text-sm mb-1">Sila lengkapkan semua ruangan.</span>
                            <div className="flex flex-row justify-between w-full /mb-6 font-light">
                                <div className="w-2/3 /mr-4">
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                label="No. Pekerja"
                                                variant="outlined"
                                                size="small"
                                                // color="secondary"
                                                helperText={errors.id ? errors.id.message : ' '}
                                                error={errors.id ? errors.id : false}
                                                fullWidth
                                            />
                                        )}
                                        control={control}
                                        name="id"
                                    />
                                </div>
                                <div className="/w-1/3">
                                    <Button
                                        // color="green"
                                        type="button"
                                        onClick={searchStaffInfo}
                                        ripple="light"
                                    >
                                        Cari Info Pekerja
                                    </Button>
                                </div>
                            </div>
                            
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nama Pekerja"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.name ? errors.name.message : ' '}
                                            error={errors.name ? errors.name : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="name"
                                />
                            </div>
                            
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Emel"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.email ? errors.email.message : ' '}
                                            error={errors.email ? errors.email : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="email"
                                />
                            </div>

                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Jabatan"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.department ? errors.department.message : ' '}
                                            error={errors.department ? errors.department : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="department"
                                />
                            </div>

                            <span className="block text-sm mt-2 mb-1">Pilih Peranan</span>
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Peranan"
                                            variant="outlined"
                                            size="small"
                                            helperText={errors?.role ? errors?.role.message : ' '}
                                            error={errors?.role ? errors?.role : false}
                                            fullWidth
                                        >
                                            {roles.data?.map((role, index) => (
                                                // <option key={index} value={subcategory.id}>
                                                //     {subcategory.subcategory_name}
                                                // </option>
                                                <MenuItem key={index} value={role.id} >
                                                  {role.title}
                                                </MenuItem>
                                            ))}
                                            {/* <MenuItem value='jantan'>Jantan</MenuItem>
                                            <MenuItem value='betina'>Betina</MenuItem> */}
                                        </TextField>
                                    )}
                                    control={control}
                                    name='role'
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="green"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Tambah
                </Button>
            </DialogActions>
            </>
            }
        {/* </div> */}
        </>
        
    )
}