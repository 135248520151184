import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Button from "@material-tailwind/react/Button";
import Success from '../assets/icon/checked.png'
import Error from '../assets/icon/error.png'

export default function ResponseStatus({type, close, title, subtitle, isButton}){
    let history = useHistory();
    const gotoPage = (page) => {
        if(page != null){
            history.replace(page)
        }
        if(close!=undefined){ close() }
    }
    return (
        <>
            <div className='flex justify-center items-center'>
                {/* <div className="m-auto"> */}
                    <div className="flex flex-col">
                        <div className="flex justify-center p-5">
                            {type == 'success' ?
                                <img className="w-28 h-28" src={Success} /> :
                                <img className="w-24 h-24" src={Error} />
                            }
                        </div>
                        <div className="text-center pb-10 mr-6 ml-6">
                            <span className="text-xl font-bold block mb-2">{title}</span>
                            <span className="text-sm text-gray-500 block">{subtitle}</span>
                        </div>
                        {isButton != null ?
                            <div className="flex justify-center pb-5">
                                <Button
                                    color="green"
                                    onClick={() => gotoPage(isButton.page)}
                                    ripple="light"
                                >
                                    {isButton.name}
                                </Button>
                            </div>
                        : ''}
                    </div>
                {/* </div> */}
            </div>
        </>
    )
}