import { Button, Card, CardBody, CardHeader } from '@material-tailwind/react'
import { ConstructionOutlined, Window } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomModal from '../../components/CustomModal';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateCourierInfo } from 'query/actions/Crud'
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useProfile } from 'query';
import { useUpdateProfile } from 'query';
import { useOauthUpdateProfile } from 'query';
import Example from 'components/Spinner/Example';
import ResponseStatus from 'components/ResponseStatus';

export default function Profil() {
    const { data, isFetching } = useProfile();
    const [title, setTitle] = useState('');
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }
    // async function closeModal(type) {
    //     await setShowModal(false);
    //     if(type == 'success'){
    //         toast.success("Kemaskini Berjaya", { 
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 5000
    //         });
    //     } else if(type == 'error'){
    //         toast.error("Kemaskini Gagal", { 
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 5000
    //         });
    //     }
    // }

    function onUpdateHandler(){
        setPageModal(<UpdateProfile data={data} closeModal={closeModal} />)
        setTitle('Kemaskini Profil')
        setShowModal(true)
    }
    return (
        <>
        <div className="bg-cyan-500 pt-14 pb-28 px-3 md:px-8 h-auto"></div>

        <div className="px-3 md:px-8 h-auto -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-1 px-4 mb-16">
                    <Card>
                        <CardHeader size='sm' color="green" contentPosition="none">
                            <h2 className="text-white text-xl">Profil Akaun</h2>
                        </CardHeader>
                        <CardBody>
                            <div className='flex justify-end mx-4'>
                                <Button
                                    color="amber"
                                    className="normal-case mr-1"
                                    // buttonType="outline"
                                    // size="lg"
                                    // rounded={true}
                                    block={false}
                                    iconOnly={false}
                                    ripple="dark"
                                    // type="submit"
                                    onClick={() => onUpdateHandler()}
                                >
                                    <i class="fas fa-edit pr-1"></i>Kemaskini
                                </Button>
                            </div>
                            <div className="flex flex-wrap justify-center mt-5 mb-5">
                                <div className="w-full /lg:w-8/12 font-light">
                                    <div className="flex flex-row mb-10">
                                        <div className="w-full lg:w-6/12 pr-4">
                                            <TextField
                                                label="Nama"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                value={data?.name}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4">
                                            <TextField
                                                label="MyKad"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                value={data?.mykad}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row mb-10">
                                        <div className="w-full lg:w-6/12 pr-4">
                                            <TextField
                                                label="Emel"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                value={data?.email}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4">
                                            <TextField
                                                label="No. Telefon"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                value={data?.phone}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="flex flex-row mb-10">
                                        <div className="w-full lg:w-6/12 pr-4">
                                            <TextField
                                                label="Nama"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                // value={data?.data[0]?.nama_syarikat_penghantaran}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4">
                                            <TextField
                                                label="MyKad"
                                                variant="standard"
                                                size="small"
                                                color="primary"
                                                // value={data?.data[0]?.amaun_caj}
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true, }}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={'sm'}
                title={title}
                content={pageModal}
                backdropClose={false}
            /> : 
        ''}
        </>
    )
}


const UpdateProfile = ({data, closeModal}) => {
    const {mutate, isLoading} = useUpdateProfile()
    const [callbackPage, setCallbackPage] = useState();
    const [callbackButton, setCallbackButton] = useState();
    const userInfo = useRecoilValue(userInfoAtom)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        name: Yup.string().min(4, "Nama mesti lebih dari 3 aksara").required(`Ruangan Wajib Diisi`),
        mykad: Yup.string().required(`Ruangan Wajib Diisi`)
            .matches(phoneRegExp, 'No. MyKad Tidak Sah')
            .min(12, "Minimum nombor adalah 12 digit")
            .max(12, "Maksimum nombor adalah 12 digit"),
        email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`),
        phone: Yup.string().required(`Ruangan Wajib Diisi`)
            .matches(phoneRegExp, 'No. Telefon Tidak Sah')
            .min(10, "Minimum nombor adalah 10 digit")
            .max(12, "Maksimum nombor adalah 12 digit"),
        // password: Yup.string().required(`Ruangan Wajib Diisi`),
        // password: Yup.string().when("password", (val, schema) => {
        //     if(val?.length > 0) {  //if address exist then apply min max else not
        //        return Yup.string().min(8, "Katalaluan mesti sekurang-kurangnya 8 aksara").required("Required");
        //     } else { 
        //        return Yup.string().notRequired();
        //     }
        // }),
        // password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Katalaluan Tidak Sama')
    }, [
        ["password", "password"], 
    ]);
    const defaultValues = {
        name: '',
        mykad: '',
        email: '',
        phone: '',
        // password: '',
        // password_confirmation: ''
    }
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
  
    const resetFieldValue = (field, value) => {
        resetField(field)
        setValue(field, value)
    }
  
    useEffect(() => {
      reset({
        name: data.name,
        mykad: data.mykad,
        email: data.email,
        phone: data.phone
      })
    }, [data])
    
    const onSubmit = async (dataSubmit) => {
      dataSubmit['user_id'] = data.user_id

      const loadingToast = toast.loading("Please wait...", {
          position: toast.POSITION.TOP_CENTER
      });
      await mutate(dataSubmit, {
          onSuccess: (res) => {
            Notify(res.response.response_code, loadingToast)
            closeModal()
          }
      })
    }
    
    const Notify = (code, loadingToast) => {
      if(code == '200'){
          toast.update(loadingToast, { 
              render: "Kemaskini Berjaya", 
              type: "success", 
              isLoading: false,
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000
          });
      }
      
      if(code == '201'){
          toast.update(loadingToast, { 
              render: "Kemaskini Gagal", 
              type: "error", 
              isLoading: false,
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000
          });
      }
        
      setCallbackPage(
        <ResponseStatus 
            type={code == '200' ? 'success' : 'error'} 
            close={null} 
            title={code == '200' ? 'Berjaya!' : 'Gagal'}
            subtitle={code == '200' ? 'Info profil telah dikemaskini.' : 'Kemaskini gagal, sila cuba lagi.'}
            isButton={null}
        />
      )
      setCallbackButton(
        <Button 
            color={code=='200' ? "green" : 'blue'}
            // buttonType="link"
            onClick={code=='200' ? onSuccess : onError}
            ripple="light"
        >
            {code=='200' ? "Selesai" : 'Cuba Semula'}
        </Button>
      )
    }

    function onSuccess(){
        closeModal()
        window.location.reload()
    }
    function onError(){
        setCallbackPage('')
        setCallbackButton('')
    }
    
    return (
        <>
          <DialogContent>
            <div className="flex flex-col my-1">
              <div className="w-full px-2 mb-6">
                <div className='flex flex-col mt-4 /mb-1'>
                {callbackPage ? callbackPage :
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Nama"
                                    // placeholder="Masukkan alamat anda"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.name ? errors.name.message : ''}
                                    error={errors.name ? errors.name : false}
                                    fullWidth
                                    disabled={isLoading}
                                    // multiline
                                    // maxRows={3}
                                />
                            )}
                            control={control}
                            name="name"
                        />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="MyKad"
                                    // placeholder="Masukkan aduan"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.mykad ? errors.mykad.message : ''}
                                    error={errors.mykad ? errors.mykad : false}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                            control={control}
                            name="mykad"
                        />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="email"
                                    label="Emel"
                                    // placeholder="Masukkan alamat anda"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.email ? errors.email.message : ''}
                                    error={errors.email ? errors.email : false}
                                    fullWidth
                                    disabled={isLoading}
                                    // multiline
                                    // maxRows={3}
                                />
                            )}
                            control={control}
                            name="email"
                        />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="No. Telefon"
                                    // placeholder="Masukkan aduan"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.phone ? errors.phone.message : ''}
                                    error={errors.phone ? errors.phone : false}
                                    fullWidth
                                    disabled={isLoading}
                                />
                            )}
                            control={control}
                            name="phone"
                        />
                    </div>

                    {/* <hr />
                    <div className='mt-3 mb-3 text-gray-600 text-sm'>Masukkan katalaluan baru sekiranya <b>ingin tukar</b>.</div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    label="Katalaluan Baru"
                                    // placeholder="Masukkan alamat anda"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.password ? errors.password.message : ''}
                                    error={errors.password ? errors.password : false}
                                    fullWidth
                                    disabled={isLoading || isLoadingUpdate}
                                    // multiline
                                    // maxRows={3}
                                />
                            )}
                            control={control}
                            name="password"
                        />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    type="password"
                                    label="Sahkan Katalaluan Baru"
                                    // placeholder="Masukkan aduan"
                                    variant="outlined"
                                    size="small"
                                    // value={addressValue}
                                    // onChange={(event) => {
                                    //     setAddressValue(event.target.value)
                                    //     resetFieldValue('address', event.target.value)
                                    // }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.password_confirmation ? errors.password_confirmation.message : ''}
                                    error={errors.password_confirmation ? errors.password_confirmation : false}
                                    fullWidth
                                    disabled={isLoading || isLoadingUpdate}
                                />
                            )}
                            control={control}
                            name="password_confirmation"
                        />
                    </div> */}
                  </form>
                }
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            {isLoading ? 
            <>
                <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                <span className='ml-2'>Loading...</span>
            </> :
            <>
                {callbackButton ? callbackButton : 
                <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>
                    <Button
                        color="amber"
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                        disabled={isLoading}
                    >
                        Kemaskini
                    </Button>
                </>
                }
            </>}
          </DialogActions>
        </>
    )
  }