import React, { forwardRef, useEffect, useState }from "react";
import MBSPlogo from "assets/img/mbsp.png";
import moment from "moment";
import NumberFormat from "react-number-format";
import QRCode from "react-qr-code";

// mui
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(245, 245, 245)",//theme.palette.common.black,
    //   color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LicenseBill = ({info, pets}, ref) => { 
    const [quantity, setQuantity] = useState()

    const formatNumber = (num) => {
        return (
            <NumberFormat
                thousandsGroupStyle="thousand"
                value={num}
                displayType="text"
                type="text"
                thousandSeparator={true}
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
            />
        )
    }

    useEffect(() => {
        if(pets != undefined){
            setQuantity(pets.length)
        }
    }, [pets])

    return (
        <div className="print-body" ref={ref}>
            <div className="flex flex-wrap justify-center px-2">
                <div className="w-10/12">
                    <div className="flex flex-row w-full mb-2">
                        <div className="w-48 px-4">
                            <img src={MBSPlogo} />
                        </div>
                        <div className="flex flex-col w-full px-2">
                            <h1 className="text-2xl font-bold uppercase">Majlis Bandaraya Seberang Perai</h1>
                            <div className="flex justify-between /items-center text-sm">
                                <div className="flex flex-col">
                                    <span className="">Menara Bandaraya,</span>
                                    <span className="">Jalan Perda Utama, Bandar Perda,</span>
                                    <span className="">14000 Bukit Mertajam,</span>
                                    <span className="">Pulau Pinang, Malaysia</span>
                                </div>
                                <div className="flex flex-col text-right">
                                    <span className="">Telefon : (04) 549 7555</span>
                                    <span className="">Faks : (04) 538 9700</span>
                                    {/* <span className="">info@mpkk.gov.my</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full mb-5">
                        <div className="dashed-border"></div>
                    </div>
                    <div className="flex flex-row w-full justify-center mb-10">
                        <h3 className="text-lg font-bold uppercase">Bil Bayaran Lesen Anjing</h3>
                    </div>
                    <div className="flex flex-row w-full justify-between mb-10">
                        <div className="w-5/12">
                            <div className="text-sm font-bold">Bil Kepada:</div>
                            <div className="flex flex-col text-sm">
                                <span className="font-bold uppercase">{info.name}</span>
                                <span className="w-2/3">{info.ma_address},</span>
                                <span className="w-2/3">{info.ma_postcode}&nbsp;{info.ma_city},</span>
                                {/* <span className="">Kedah</span> */}
                            </div>
                        </div>
                        <div className="w-5/12 text-right mr-10">
                            <div className="text-sm font-bold">No. Permohonan:</div>
                            <div className="flex flex-col text-sm">{info.application_no}</div>
                            <div className="text-sm font-bold mt-4">Tarikh Bil:</div>
                            <div className="flex flex-col text-sm">{moment().format('D MMM YYYY')}</div>
                            {/* <div className="flex flex-col text-sm">
                                <div className="flex flex-row w-full">
                                    <div className="flex justify-between font-bold w-1/2">
                                        <span>No. Permohonan</span>
                                        <span>:</span>
                                    </div>
                                    <div className="w-1/2 pl-5">{info.application_no}</div>
                                </div> */}
                                {/* <div className="flex flex-row w-full">
                                    <div className="flex justify-between font-bold w-1/3">
                                        <span>Perkara</span>
                                        <span>:</span>
                                    </div>
                                    <div className="w-2/3 pl-5">Bayaran Lesen Anjing</div>
                                </div>
                                <div className="flex flex-row w-full">
                                    <div className="flex justify-between font-bold w-1/3">
                                        <span>Jabatan</span>
                                        <span>:</span>
                                    </div>
                                    <div className="w-2/3 pl-5">Perkhidmatan Kesihatan</div>
                                </div> */}
                                {/* <div className="flex flex-row w-full">
                                    <div className="flex justify-between font-bold w-1/2">
                                        <span>Tarikh Bil</span>
                                        <span>:</span>
                                    </div>
                                    <div className="w-1/2 pl-5">{moment().format('D MMM YYYY')}</div>
                                </div>
                            </div> */}
                        </div>
                        <div className="w-2/12">
                            <div className="flex flex-col text-sm">
                                {info.application_no ?
                                    <QRCode
                                        size={100}
                                        // style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={String(info.application_no).toUpperCase()}
                                        // viewBox={`0 0 256 256`}
                                    />
                                : ''}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row w-full mb-10">
                        <div className="flex flex-col w-full">
                            <h1 className="text-lg font-bold mb-2">Maklumat Bil</h1>
                            <TableContainer 
                                component={Paper} 
                                sx={{'&.MuiTableContainer-root': {
                                    borderRadius: 3, 
                                    border: 1, 
                                    borderColor: "rgb(245, 245, 245)",
                                    boxShadow: "none"
                                }}}
                            >
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Keterangan</StyledTableCell>
                                            <StyledTableCell align="right">Kuantiti</StyledTableCell>
                                            <StyledTableCell align="right">Harga Patil (Per Unit)</StyledTableCell>
                                            <StyledTableCell align="right">Jumlah (RM)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div className="flex flex-col h-20">
                                                    <span className="mb-2">Bilangan anjing yang didaftar</span>
                                                    <div className="flex flex-row w-full text-sm">
                                                        <div className="flex justify-between font-bold w-16">
                                                            <span>Perkara</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="pl-3">Bayaran Lesen Anjing</div>
                                                    </div>
                                                    <div className="flex flex-row w-full text-sm">
                                                        <div className="flex justify-between font-bold w-16">
                                                            <span>Jabatan</span>
                                                            <span>:</span>
                                                        </div>
                                                        <div className="pl-3">Perkhidmatan Kesihatan</div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-16 mb-2">
                                                    <span className="">{quantity}</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-16 mb-2">
                                                    <span className="">10.00</span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <div className="flex flex-col h-16 mb-2">
                                                    <span className="">{formatNumber(quantity * 10)}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="flex flex-row py-5">
                                <div className="w-1/3"></div>
                                <div className="w-2/3 bg-gray-100 p-4 rounded-xl">
                                    <div className="flex justify-between">
                                        <span className="text-sm font-bold">Jumlah Keseluruhan Perlu Dibayar (RM)</span>
                                        <span className="text-sm font-bold">{formatNumber(quantity * 10)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between /mt-3">
                        {/* <div></div> */}
                        <div className="text-xs"><i>Bil ini dicetak pada {moment().format('D MMM YYYY h:mm A')}</i></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(LicenseBill);