import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useRegister } from "query/actions/Registration";
import { Spinner } from 'components/Spinner';
import ResponseStatus from "./ResponseStatus";
import { toast } from 'react-toastify';
import { Select, FormControlLabel, Alert, MenuItem, Checkbox, TextField, FormHelperText, Backdrop, CircularProgress, OutlinedInput, InputLabel, Chip, FormControl } from '@mui/material';
import { useRoles } from "query";
import items from 'data/sidebar.json'
import { Box } from "@mui/system";
import { useRegisterRole } from "query/admin";
import { useRecoilValue } from "recoil";
import { userInfoAtom } from "recoil/UserInfo";
// import Select from 'react-select';
// import 'react-select/dist/react-select.css';

export default function AddNewRole({closeModal, setRefreshTable}){
    const userInfo = useRecoilValue(userInfoAtom);
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useRegisterRole();
    // const roles = useRoles();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(`Ruangan Wajib Diisi`),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, setValue } = useForm({
        defaultValues: {
            title: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        // console.log('errorserrors',errors)
        data['staffId'] = userInfo?.staffId
        console.log('datadata',data)
        // // logIn(data)
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Tambah Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        setPage(
            <ResponseStatus 
                type={'success'} 
                close={null} 
                title={'Berjaya!'}
                subtitle={'Log masuk ke akaun anda sekarang.'}
                isButton={null}
            />
        )
    }

    const passwordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
        }
    };
    const [lesen, setLesen] = useState([]);
    const [kacauganggu, setKacauganggu] = useState([]);
    const [penguatkuasaan, setPenguatkuasaan] = useState([]);
    const [laporan, setLaporan] = useState([]);
    const [tetapan, setTetapan] = useState([]);
    const [menu, setMenu] = useState([]);
  
    const handleChange = (event, params) => {
      const {
        target: { value }
      } = event;
      console.log('paramsparams',params)
    //   let duplicateRemoved = [];

    //     value.forEach((item) => {
    //         if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
    //             duplicateRemoved = duplicateRemoved.filter((x) => x === item);
    //         } else {
    //             duplicateRemoved.push(item);
    //         }
    //     });

    //     setPersonName(duplicateRemoved);
    //   setPersonName(
    //     // On autofill we get a stringified value.
    //     typeof value === "string" ? value.split(",") : value
    //   );
      setValue(params.state, typeof value === "string" ? value.split(",") : value)
    //   setMenu(typeof value === "string" ? value.split(",") : value)
      menu[params.state] = typeof value === "string" ? value.split(",") : value
    //   let duplicateRemoved = [];
    //   menu.forEach((item) => {
    //     if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
    //         duplicateRemoved = duplicateRemoved.filter((x) => x === item);
    //     } else {
    //         duplicateRemoved.push(item);
    //     }
    // });
    //   params.setstate(typeof value === "string" ? value.split(",") : value)
    };
    console.log('menu',menu)
    // console.log('lesen',lesen)

    return (
        <>
        {/* <div className="lg:w-2xl md:w-lg"> */}
            {isLoading ? <div className='flex justify-center items-center'><Spinner /></div> :
            // page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-col w-full">
                    {/* <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col w-full my-1">
                            
                            <div className="w-full /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Nama Peranan"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.title ? errors.title.message : ' '}
                                            error={errors.title ? errors.title : false}
                                            fullWidth
                                        />
                                    )}
                                    control={control}
                                    name="title"
                                />
                            </div>
                            
                            <span className="block text-sm mb-1">Senarai Menu</span>
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[1].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.lesen ? menu?.lesen : []}
                                                onChange={(e) => handleChange(e, {state:'lesen',setstate:setMenu})}
                                                input={<OutlinedInput label={items[1].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[1].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="lesen"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[2].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.kacauganggu ? menu?.kacauganggu : []}
                                                onChange={(e) => handleChange(e, {state:'kacauganggu',setstate:setMenu})}
                                                input={<OutlinedInput label={items[2].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[2].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="kacauganggu"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[3].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.penguatkuasaan ? menu?.penguatkuasaan : []}
                                                onChange={(e) => handleChange(e, {state:'penguatkuasaan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[3].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[3].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="penguatkuasaan"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[4].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.laporan ? menu?.laporan : []}
                                                onChange={(e) => handleChange(e, {state:'laporan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[4].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[4].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="laporan"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[5].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.tetapan ? menu?.tetapan : []}
                                                onChange={(e) => handleChange(e, {state:'tetapan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[5].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[5].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="tetapan"
                                />
                            </div>

                            <span className="block text-sm mt-6 mb-2">Senarai Tindakan</span>
                            <div className='flex /justify-between w-full -mt-4'>
                                <div className="w-1/2 px-4 mb-0 font-light">
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                {/* <div className='-mb-2 font-medium'>Kemaskini</div> */}
                                                <FormControlLabel
                                                    control={
                                                    <Checkbox {...field} name="edit" />
                                                    }
                                                    label="Kemaskini"
                                                />
                                            </>
                                        )}
                                        control={control}
                                        name={`edit`}
                                    />
                                </div>
                                <div className="w-1/2 px-4 mb-0 font-light">
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                                {/* <div className='-mb-2 font-medium'>Batal</div> */}
                                                <FormControlLabel
                                                    control={
                                                    <Checkbox {...field} name="delete" />
                                                    }
                                                    label="Batal"
                                                />
                                            </>
                                        )}
                                        control={control}
                                        name={`delete`}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="green"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Tambah
                </Button>
            </DialogActions>
            </>
            }
        {/* </div> */}
        </>
        
    )
}