import DataTableCardPenghantaranPatil from 'components/DataTableCardPenghantaranPatil'
import CarianPenghantaranPatil from 'components/lesen/CarianPenghantaranPatil'
import React from 'react'

export default function PenghantaranPatil() {
  return (
    <>
      {/* <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <div className="px-4 mb-16">
            <CarianPenghantaranPatil />
          </div>
        </div>
      </div> */}

      {/* <div className="px-3 md:px-8 h-auto -mt-24"> */}
      <div className="bg-teal-500 pt-24 pb-28 px-3 md:px-8 h-auto">
      </div>

      <div className="px-3 md:px-8 h-auto -mt-40">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <DataTableCardPenghantaranPatil />
          </div>
        </div>
      </div>
    </>
  )
}
