import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import MBSPlogo from "assets/img/mbsp.png";

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';
import { useLogin } from 'query/admin/Auth';

// component
import CustomModal from 'components/CustomModal';
import Register from 'components/Register';
import Example from 'components/Spinner/Example';
import LoginComponent from 'components/Login';

// material-tailwind
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import Button from '@material-tailwind/react/Button';
import InputIcon from "@material-tailwind/react/InputIcon";

import { toast } from 'react-toastify';

import LoginImg from '../../assets/img/login.jpg';
import SignupImg from '../../assets/img/signup.jpg';
import OAuth2Login from 'react-simple-oauth2-login/dist/OAuth2Login';
import { useOauthLogin } from 'query/actions/Auth';

export default function Login() {
    const { mutate, error, isError, isLoading, isSuccess, data } = useLogin();
    const oauthLogin = useOauthLogin();
    const setUserInfoAtom = useSetRecoilState(userInfoAtom);
    
    const [showModal, setShowModal] = useState(false);
    const [showModalLogin, setShowModalLogin] = useState(false);

    async function closeModal() {
        await setShowModal(false);
    }
    async function closeModalLogin() {
        await setShowModalLogin(false);
    }

    let history = useHistory();
    const logIn = (data) => {
        setUserInfoAtom([{
            userId: data.id,
            userPassword: data.password
        }])
        history.replace('/')
    }

    const validationSchema = Yup.object().shape({
        user_id: Yup.string().required(`Ruangan Wajib Diisi`),
        password: Yup.string().required(`Ruangan Wajib Diisi`),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues: {
            user_id: '',
            password: ''
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code)
            }
        })
    }
    // console.log('datadata',data?.data.response.response_code)

    const Notify = (code) => {
        if(code == '201'){
            toast.error("Akses tidak dibenarkan!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
        if(code == '202'){
            toast.error("Emel atau Katalaluan tidak sah!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
        if(code == '500'){
            toast.error("Tidak dapat berhubung dengan server LDAP!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
        // else {
        //     // toast.success("Log masuk berjaya, Selamat Datang!", {
        //     //     position: toast.POSITION.TOP_CENTER
        //     // });
        //     if(data.data.data.isAdmin){
        //         history.replace('/')
        //     } else {
        //         history.go(0)
        //         history.go('/utama')
        //         // window.location.href('/utama')
        //     }
        // }
    };

    return (
        <>
            <div className="absolute inset-0">
                <div className='flex min-h-full lg:h-screen md:h-screen bg-login-tadbir-background justify-center items-center'>
                    <div className="my-8">
                        <div className='mx-5'>
                            <Card>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {/* <CardHeader className="/!px-4" size='lg' color='teal' contentPosition="">
                                        <div className='flex flex-row'>
                                            <div className="w-20 mr-2 /px-4">
                                                <img src={MBSPlogo} />
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='flex flex-col'>
                                                    <span className="text-white /text-lg uppercase /text-center">Sistem Pengesanan & Maklumat Haiwan</span>
                                                    <span className="text-white text-sm /text-center">Majlis Bandaraya Seberang Perai</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <span className="text-white text-sm text-center">Sistem Pengesanan dan Maklumat Haiwan</span>
                                        <span className="text-white text-lg text-center">MBSP</span> */}
                                    {/* </CardHeader> */}

                                    <div className='flex flex-row /w-auto /lg:w-96 justify-center py-4 px-6 rounded-xl bg-teal-500 /h-40 -mt-12 shadow-xl mb-4'>
                                        <div className='flex w-20 lg:w-auto /justify-center /mb-2'>
                                            <img className='w-20' src={MBSPlogo} />
                                        </div>
                                        <div className='flex items-center lg:w-72'>
                                            <div className='flex flex-col pl-2'>
                                                <span className="text-white text-sm lg:text-lg /text-center font-bold uppercase" style={{lineHeight: '1.25'}}>
                                                    Sistem Pengesanan & Maklumat Haiwan
                                                </span>
                                                <span className="text-white text-xs lg:text-sm /text-center" style={{lineHeight: '2'}}>
                                                    Majlis Bandaraya Seberang Perai
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className='flex flex-col lg:flex lg:flex-row md:flex md:flex-row gap-8 p-4'>
                                        <div className='/text-center rounded-lg shadow-xl border border-gray-100 mt-5'> */}
                                            <CardBody>
                                                <div className="flex flex-col /w-96 my-1">
                                                    <span className="text-sm text-center text-gray-500 uppercase mb-6">Log Masuk Tadbir</span>

                                                    <div className="w-full mt-1 mb-5 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <InputIcon
                                                                    {...field}
                                                                    type="text"
                                                                    color="lightBlue"
                                                                    size="regular"
                                                                    outline={false}
                                                                    placeholder="ID Staf"
                                                                    iconFamily="font-awesome"
                                                                    iconName="far fa-id-badge"
                                                                    error={errors.user_id ? errors.user_id.message : ''}
                                                                    disabled={isLoading ? true : false}
                                                                />
                                                            )}
                                                            control={control}
                                                            name="user_id"
                                                        />
                                                    </div>
                                                    
                                                    <div className="w-full mb-5 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                            <InputIcon
                                                                {...field}
                                                                type="password"
                                                                color="lightBlue"
                                                                size="regular"
                                                                outline={false}
                                                                placeholder="Katalaluan"
                                                                iconFamily="font-awesome"
                                                                iconName="fas fa-lock"
                                                                error={errors.password ? errors.password.message : ''}
                                                                disabled={isLoading ? true : false}
                                                            />
                                                            )}
                                                            control={control}
                                                            name="password"
                                                        />
                                                    </div>

                                                </div>
                                            </CardBody>
                                            <CardFooter className="pt-1">
                                                <div className='flex justify-center'>
                                                    {isLoading ? 
                                                    <>
                                                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                                        <span className='ml-2'>Loading...</span>
                                                    </> : 
                                                    <Button
                                                        color="teal"
                                                        // buttonType="outline"
                                                        size="lg"
                                                        // rounded={true}
                                                        block={false}
                                                        iconOnly={false}
                                                        ripple="dark"
                                                        type="submit"
                                                    >
                                                        Log Masuk
                                                    </Button>
                                                    }
                                                </div>
                                            </CardFooter>
                                        {/* </div>
                                    </div> */}
                                </form>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            {showModal ? 
                <CustomModal 
                    closeModal={closeModal} 
                    size='md'
                    title={'Daftar Pengguna Baru'}
                    content={<Register closeModal={closeModal} />}
                /> : 
            ''}
            {showModalLogin ? 
                <CustomModal 
                    closeModal={closeModalLogin} 
                    size='xs'
                    title={'Log Masuk Pengguna'}
                    content={<LoginComponent closeModal={closeModalLogin} />}
                /> : 
            ''}
            {/* <OAuth2Login
                authorizationUrl="https://oauth.mpkk.gov.my/oauth/authorize"
                responseType="code"
                clientId="7"
                redirectUri="https://oro-staging.mpkk.gov.my/apps/auth/callback"
                buttonText="Auth code login"
                onSuccess={onSuccess}
                onFailure={onFailure}
            /> */}
        </>
    )
}