import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { useLicenseList, useAllLicenseList } from 'query/actions/license/Crud';
import moment from "moment";
import ButtonGroupActionLesen from './ButtonGroupActionLesen';
import differenceBy from 'lodash/differenceBy';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
import DunData from 'data/dun.json'
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import ExportToExcel from './ExportToExcel';
import Excel from './Excel';
import CarianLaporanLesen from './lesen/CarianLaporanLesen';

export default function DataTableCardLaporanLesen() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, data: dataList, error, isError, isLoading, isFetching } = useLicenseList();
    const { mutate: mutateAll, isFetching: isFetchingAll } = useAllLicenseList();
    const fileName = 'laporan-lesen-'+moment().format('YYYYMMDDHmmss');
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [allLists, setAllLists] = useState([]);
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [search, setSearch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = search==null ? 
            {'page': page, 'perPage': countPerPage, 'user': accessToken} :
            {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, search, refreshTable]);

    // search
    // useEffect(() => {
    //     let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
    //     let data = {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
    //     getLicenseList(data);
    //     setIsAdmin((accessToken!='' ? false : true))
    //     console.log('search',search)
    // }, [page, search]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = useMemo(() => {
		const handleApprove = () => {
            setPageModal(<View closeModal={closeModal} id={''} action={7} />)
            setShowModal(true)
			// console.log('selectedRows',selectedRows)
			// if (window.confirm(`Luluskan permohonan:\r ${selectedRows.map(r => r.application_id)}?`)) {
			// 	setToggleCleared(!toggleCleared);
			// 	setLists(differenceBy(lists, selectedRows, 'status'));
			// }
		};

		return (
            <>
            <div className='px-1 hidden md:block lg:block'>
                <Button
                    onClick={() => handleApprove()}
                    type="button"
                    color="green"
                >
                    <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    Lulus
                </Button>
            </div>
            <div className='px-1 lg:hidden'>
                <Tooltip title="Lulus" placement="left">
                    <Button
                        onClick={() => handleApprove()}
                        type="button"
                        color="green"
                        iconOnly
                    >
                        <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            </>
		);
	}, [lists, selectedRows, toggleCleared]);

    function findDun(dun){
        DunData.map((item, index) => {
            if(item.value = dun){
                return item.label
            }
        })
    }

    // admin
    const columns = [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '4.5rem'
        },
        {
            name: 'SALURAN',
            cell: row => <div style={{fontSize: '10px'}}  className={`p-1 rounded-lg font-medium border-2
                ${row.channel==1 ? 'bg-green-500 border-green-500' : 'bg-amber-500 border-amber-500'}`}>
                <i className={`fa-solid fa-circle pr-1 text-white`}></i>
                <span className='text-white'>
                    { row.channel==1 ? 'Online' : 'Kaunter' }
                </span>
                </div>,
            selector: row => row.channel,
            sortable: true,
            width: '6.5rem'
        },
        {
            name: <span>NO. LESEN</span>,
            cell: row => <div className='mb-1'>
                    {row.license_no}
                </div>,
            selector: row => row.license_no,
            sortable: true,
            width: '7.5rem'
        },
        {
            name: <span>PEMILIK LESEN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{row.name}</div>
                    <div className='mb-1'>{row.mykad}</div>
                    <div className='mb-1'>{ row.phone }</div>
                    <div>{ row.email }</div>
                </div>,
            selector: row => row.name,
            sortable: true,
            width: '15rem'
        },
        {
            name: <span>ALAMAT PREMIS</span>,
            cell: row => <div className='flex flex-col my-4 capitalize'>
                    <div className='mb-1'>{ row.pa_address }</div>
                    <div className='mb-1'>{ row.pa_postcode }</div>
                    <div className='mb-1'>{ row.pa_city }</div>
                </div>,
            selector: row => row.pa_address,
            sortable: true,
            width: '15rem'
        },
        {
            name: <span>STATUS<br />PREMIS</span>,
            cell: row => <div className='mb-1'>
                    {row.premise_status}
                </div>,
            selector: row => row.premise_status,
            sortable: true
        },
        {
            name: <span>JENIS<br />PREMIS</span>,
            cell: row => <div className='mb-1 capitalize'>
                    {row.premise_type}
                </div>,
            selector: row => row.premise_type,
            sortable: true
        },
        {
            name: <span>BIL.<br />ANJING</span>,
            cell: row => <div className='mb-1'>
                    {row.total}
                </div>,
            selector: row => row.total,
            sortable: true,
            right: true,
            width: '5rem'
        },
        {
            name: 'BAYARAN',
            cell: row => <div className='flex flex-col my-4'>
                    <div style={{fontSize: '10px'}}  className={`mb-1 p-1 rounded-lg font-medium border-2
                        ${row.payment==2 ? 'border-green-500' : 'border-amber-500'}`}>
                        <i className={`fa-solid pr-1 ${row.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
                        {row.payment==2 ? 'Telah Bayar' : 'Belum Bayar'}
                    </div>
                    <div className='mb-1 text-xs'>{row.trans_channel_name}</div>
                </div>,
            selector: row => row.payment,
            sortable: true,
            width: '7rem'
        },
        {
            name: <span>JENIS<br />SERAHAN</span>,
            cell: row => <div className='mb-1'>
                    {row.submission_type}
                </div>,
            selector: row => row.submission_type,
            sortable: true
        },
        {
            name: <span>DUN</span>,
            cell: row => <div className='mb-1'>
                {DunData.map((item, index) => {
                    if(item.value == row.dun){
                        return item.label
                    }
                })}
                    {/* {row.dun} */}
                </div>,
            selector: row => row.dun,
            sortable: true
        },
        {
            name: <span>PARLIMEN</span>,
            cell: row => <div className='mb-1'>
                    {row.parlimen}
                </div>,
            selector: row => row.parlimen,
            sortable: true
        },
        {
            name: <span>TARIKH</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
                    <div>{ moment(row.created_at).format('h:mm A') }</div>
                </div>,
            selector: row => moment(row.created_at).format('h:mm A'),
            sortable: true,
            width: '7rem'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);

    const getAllLicenseList = async (data) => {
        await mutateAll(data, {
            onSuccess: (data) => {
                setAllLists(data.data.data.result)
            }
        })
    }

    useEffect(() => {
        if(!isFetching){
            let data = {'all': true}
            getAllLicenseList(data);
        }
    }, [isFetching]);

    return (
        <>
        <div className='mb-14'>
            <CarianLaporanLesen setSearch={setSearch} setPage={setPage} setShowTable={setShowTable} />
        </div>
        <Card>
            <CardHeader size='sm' color="purple" contentPosition="left">
                <h2 className="text-white text-xl">Hasil Carian</h2>
            </CardHeader>
            <CardBody className='-mt-3'>
                <div className='flex justify-end mx-4'>
                    <Excel data={search} />
                    {/* <ExportToExcel apiData={dataList?.data.data.result} fileName={fileName} /> */}
                </div>
                <div className="overflow-x-auto mt-4">
                    <DataTable 
                        // title=" "
                        // className='!w-20 !overflow-x-scroll'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        // selectableRowDisabled={rowDisabledCriteria}
                        // contextActions={contextActions}
                        // onSelectedRowsChange={handleRowSelected}
                        // clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={''}
                title={`${selectedRows.length} Permohonan Dipilih`}
                content={pageModal}
                backdropClose={true}
            /> : 
        ''}
        </>
    );
}


// // admin
// const columns = [
//     {
//       name: '#',
//       cell: row => row.application_id,
//       selector: row => row.application_id,
//       sortable: true,
//       width: '5rem'
//     },
//     {
//       name: 'SALURAN',
//       cell: row => <div style={{fontSize: '10px'}} className={`px-2 py-1 rounded-lg text-white text-xs ${row.channel==1 ? 'bg-purple-500' : 'bg-teal-500'}`}>
//           { row.channel==1 ? 'Online' : 'Kaunter' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'INFO PEMOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ row.name }</div>
//             <div className='mb-1'>{ row.mykad }</div>
//             <div className='mb-1'>{ row.phone }</div>
//             <div>{ row.email }</div>
//       </div>,
//     //   selector: row => `${ row.status } ${ row.channel }`,
//       sortable: true,
//       width: '22rem'
//     },
//     {
//       name: 'STATUS',
//       cell: row => <div style={{fontSize: '10px'}} 
//                 className={`px-2 py-1 rounded-lg text-white text-xs 
//                 ${row.status==1 ? 'bg-gray-500' : 
//                 row.status==2 ? 'bg-teal-500' : 
//                 row.status==3 ? 'bg-red-500' : 'bg-amber-500'}`
//             }>
//             { row.status==1 ? 'Baru' : row.status==2 ? 'Lulus' : row.status==3 ? 'Batal' : 'Tidak Lengkap' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'TARIKH MOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
//             <div>{ moment(row.created_at).format('h:mm A') }</div>
//         </div>,
//       selector: row => row.created_at,
//       sortable: true,
//     //   width: '12%'
//     },
//     {
//       name: ' ',
//       cell: row => <ButtonGroupAction 
//                 isAdmin={isAdmin} 
//                 id={row.application_id} 
//                 status={row.status} 
//             />,
//       selector: row => row.application_id,
//       sortable: true,
//     //   width: '12%'
//     }
//   ];

// const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);
