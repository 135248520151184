import DataTableCardLaporanOperasiHaiwanMerayau from 'components/DataTableCardLaporanOperasiHaiwanMerayau'
import React from 'react'

export default function LaporanHaiwanMerayau() {
  return (
    <>
      <div className="bg-teal-500 pt-24 pb-28 px-3 md:px-8 h-auto">
      </div>

      <div className="px-3 md:px-8 h-auto -mt-40">
          <div className="container mx-auto max-w-full">
              <div className="grid grid-cols-1 px-4 mb-16">
                  <DataTableCardLaporanOperasiHaiwanMerayau />
              </div>
          </div>
      </div>
    </>
  )
}
