import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { useLicenseList } from 'query/actions/license/Crud';
import moment from "moment";
import ButtonGroupActionLesen from './ButtonGroupActionLesen';
import differenceBy from 'lodash/differenceBy';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import CarianPembaharuanLesen from './lesen/CarianPembaharuanLesen';
import { useSearchInfo } from 'query/actions/license/Crud';
import CarianMaklumat from './lesen/CarianMaklumat';

export default function DataTableCardCarianMaklumat() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, error, isError, isLoading, isSuccess } = useSearchInfo();
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [search, setSearch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showTable, setShowTable] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = search==null ? 
            {'page': page, 'perPage': countPerPage, 'user': accessToken} :
            {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, search, refreshTable]);

    // search
    // useEffect(() => {
    //     // if(search != null){
    //         let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
    //         let data = {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
    //         getLicenseList(data);
    //         setIsAdmin((accessToken!='' ? false : true))
    //     // } else {
    //     //     let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
    //     //     let data = {'page': page, 'perPage': countPerPage, 'user': accessToken}
    //     //     getLicenseList(data);
    //     //     setIsAdmin((accessToken!='' ? false : true))
    //     // }
    //     console.log('search',search)
    // }, [page, search]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = useMemo(() => {
		const handleApprove = () => {
            setPageModal(<View closeModal={closeModal} id={''} action={7} />)
            setShowModal(true)
			// console.log('selectedRows',selectedRows)
			// if (window.confirm(`Luluskan permohonan:\r ${selectedRows.map(r => r.application_id)}?`)) {
			// 	setToggleCleared(!toggleCleared);
			// 	setLists(differenceBy(lists, selectedRows, 'status'));
			// }
		};

		return (
            <>
            <div className='px-1 hidden md:block lg:block'>
                <Button
                    onClick={() => handleApprove()}
                    type="button"
                    color="green"
                >
                    <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    Lulus
                </Button>
            </div>
            <div className='px-1 lg:hidden'>
                <Tooltip title="Lulus" placement="left">
                    <Button
                        onClick={() => handleApprove()}
                        type="button"
                        color="green"
                        iconOnly
                    >
                        <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            </>
		);
	}, [lists, selectedRows, toggleCleared]);

    // admin
    const columns = userInfo?.isAdmin ? [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '4.5rem'
        },
        {
            name: 'NO. LESEN',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.license_no }</div>
                    {/* {row.payment==2 && row.plate_no!=0 ?
                    <div style={{fontSize: '10px', width: 'fit-content'}}  className={`p-1 text-white bg-green-500 rounded-lg font-medium border-2 border-green-500`}>
                        Selesai
                        <i className={`fa-solid pl-1 fa-circle-check`}></i>
                    </div> : row.payment==2 && row.plate_no==0 ?
                    <div style={{fontSize: '10px', width: 'fit-content'}}  className={`p-1 text-white bg-orange-500 rounded-lg font-medium border-2 border-orange-500`}>
                        Serahan Patil
                        <i className={`fa-solid pl-1 fa-dog`}></i>
                    </div> : ''
                    } */}
                </div>,
            selector: row => row.row_num,
            sortable: true,
            width: '8rem'
        },
        {
            name: <span>NO. PATIL</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1 text-right'>{ row.plate_no }</div>
                </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '8rem'
        },
        {
            name: 'PEMILIK LESEN',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.name }</div>
                    <div className='mb-1'>{ row.mykad }</div>
                    <div className='mb-1'>{ row.phone }</div>
                    <div>{ row.email }</div>
                </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '19.5rem'
        },
        {
            name: 'ALAMAT PREMIS',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.pa_address }</div>
                    <div className='mb-1'>{ row.pa_postcode }</div>
                    <div className='mb-1'>{ row.pa_city }</div>
                    {/* <div>{ row.email }</div> */}
                </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '19.5rem'
        },
        // {
        //     name: <span>BIL. ANJING</span>,
        //     cell: row => <div className='flex flex-col my-4'>
        //             <div className='mb-1 text-right'>{ row.total }</div>
        //         </div>,
        //     //   selector: row => `${ row.status } ${ row.channel }`,
        //     sortable: true,
        //     // width: '19.5rem'
        // },
        {
            name: 'TEMPOH',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.start_date).format('D MMM YYYY')+' - '+moment(row.end_date).format('D MMM YYYY') }</div>
                    {/* <div>{ moment(row.created_at).format('h:mm A') }</div> */}
                </div>,
            selector: row => row.created_at,
            sortable: true,
            // width: '7rem'
        },
        // {
        //     name: ' ',
        //     cell: row => <ButtonGroupActionLesen 
        //                 isAdmin={isAdmin} 
        //                 id={row.license_id} 
        //                 status={row.status} 
        //                 plate_no={row.plate_no}
        //                 setRefreshTable={setRefreshTable}
        //             />,
        //     selector: row => row.license_id,
        //     sortable: true,
        //     //   width: '12%'
        // }
    ] : [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '4.5rem'
        },
        {
            name: 'INFO LESEN',
            cell: row => <div className="flex flex-wrap mt-8">
                <LicenseCard
                    icon={row.status=='1' ? "fa-solid fa-clock" : 
                            row.status=='2' ? "fa-solid fa-circle-check" :
                            "fa-solid fa-ban"}
                    title="No. Lesen"
                    account={row.license_no}
                    status={row.status_info}
                    valid={row.start_date!=null ? `Sah sehingga ${ moment(row.end_date).format('D MMM YYYY') }` : '-'}
                />
            </div>,
            selector: row => row.row_num,
            sortable: true,
            width: '24rem'
        },
        {
            name: ' ',
            cell: row => <div className="flex flex-col">
                <div className="mb-3">
                    <span className="pr-1 text-xs text-gray-500">No. Lesen: </span>
                    <span className="font-bold text-md">{row.license_no}</span>
                </div>
                <div className="mb-3">
                    <span className="text-xs text-gray-500">Status: </span>
                    <span 
                        style={{fontSize: '10px'}}
                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                            ${row.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                row.status=='2' ? 'bg-green-500 border-green-500' :
                                row.status=='3' ? 'bg-amber-500 border-amber-500' :
                                'bg-red-500 border-red-500'}`}
                    >
                        {row.status_info}
                        {row.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                            row.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                            row.status=='3' ? <i className="fa-regular fa-calendar-xmark pl-1"></i> :
                            <i className="fa-solid fa-ban pl-1"></i>}
                    </span>
                </div>
                <div className="mb-3">
                    <span className="text-xs text-gray-500">Bayaran: </span>
                    <span
                        style={{fontSize: '10px'}}
                        className={`px-2 py-1 rounded-lg font-medium border-2
                            ${row.payment==2 ? 'border-green-500' : 'border-amber-500'}`}
                    >
                        <i className={`fa-solid pr-1 ${row.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
                        { row.payment==2 ? 'Telah Bayar' : 'Belum Bayar' }
                    </span>
                </div>
                <div className="mb-5">
                    <span className="pr-1 text-xs text-gray-500">Tempoh: </span>
                    <span className="font-bold text-md">
                        {row.start_date!=null ? 
                        `${moment(row.start_date).format('D MMM YYYY')} - ${moment(row.end_date).format('D MMM YYYY')}` :
                        '-'}
                    </span>
                </div>
            </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '19rem'
        },
        {
            name: 'TARIKH',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
                    <div>{ moment(row.created_at).format('h:mm A') }</div>
                </div>,
            selector: row => row.created_at,
            sortable: true,
            width: '7rem'
        },
        {
            name: ' ',
            cell: row => <ButtonGroupActionLesen 
                        isAdmin={isAdmin} 
                        id={row.license_id} 
                        status={row.status} 
                        // channel={row.channel}
                        setRefreshTable={setRefreshTable}
                    />,
            selector: row => row.license_id,
            sortable: true,
            //   width: '12%'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);
    console.log('showTable',showTable)

    return (
        <>
        <div className='mb-14'>
            <CarianMaklumat setSearch={setSearch} setPage={setPage} setShowTable={setShowTable} />
        </div>

        {showTable ? 
        <Card>
            <CardHeader size='sm' color="purple" contentPosition="left">
                <h2 className="text-white text-xl">Hasil Carian</h2>
            </CardHeader>
            <CardBody className='/-mt-3'>
                <div className="overflow-x-auto">
                    <DataTable 
                        // title=" "
                        className='w-full'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        selectableRowDisabled={rowDisabledCriteria}
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>
        : ''}

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={''}
                title={`${selectedRows.length} Permohonan Dipilih`}
                content={pageModal}
                backdropClose={true}
            /> : 
        ''}
        </>
    );
}
