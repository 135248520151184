import axios from 'axios';
import {decode as atob, encode as btoa} from 'base-64'

const client_id = "vrsyM6Ob2P6IT1pO1IKPbCwuh3nePXBBzSqiPgXt";
const prod = true;

const userDetail = JSON.parse(sessionStorage.getItem('userInfo'))
// console.log('userDetailuserDetail',userDetail)

const refetch = () => {
    return JSON.parse(sessionStorage.getItem('userInfo'))
}

export const header = (type) => {
    if(type === 'multipart'){
        return {
            headers: {
                // Authorization: 'Bearer ' + getToken(),
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                // Token: getToken(),
                crossDomain: true,
                'Access-Control-Allow-Origin': '*',
            },
        }
    }

    return {
        headers: {
            // Authorization: 'Bearer ' + getToken(),
            // Token: getToken(),
            crossDomain: true,
            'Access-Control-Allow-Origin': '*',
        },
        timeout: 20000,
    }
}

export const apiUrl = (role) => {
    if (!prod) {
        return `http://localhost/mpkk/api/`;
    } else {
        return `https://spmh.mbsp.gov.my/apps/admin/api/`;
    }
};
export const baseUrl = () => {
    if (!prod) {
        return `http://localhost/mpkk/`;
    } else {
        return `https://spmh.mbsp.gov.my/apps/`;
    }
};

/* api */
export const ldapConnect = async (credential) => {
    return await axios.post(
        apiUrl() + `ldap_connect`,
        {
            email: credential?.email,
            password: credential?.password
        },
        header()
    );
}

export const oauthLogin = async (credential) => {
    return await axios.get(
        apiUrl() + `oauth`,
        header()
    );
}

export const verifyLogin = async (credential) => {
    return await axios.post(
        apiUrl() + `verify_login`,
        {
            email: credential
        },
        header()
    );
}

export const getDashboard = async () => {
    return await axios.get(
        apiUrl() + `dashboard`,
        header()
    );
}
export const getUtama = async () => {
    let accessToken = null;
    if(userDetail != null){
        accessToken = userDetail
    } else {
        accessToken = refetch()
    }

    if(accessToken != null || userDetail.length > 0){
        return await axios.get(
            apiUrl() + `getUtama?user=${accessToken.accessToken}`,
            header()
        );
    }
}

export const loginRequest = async (credential) => {
    return await axios.post(
        apiUrl() + `login`,
        {
            user_id: credential?.user_id,
            password: credential?.password
        },
        header()
    );
}

export const logoutRequest = async () => {
    return await axios.get(
        apiUrl() + `logout`,
        header()
    );
}

export const registerRequest = async (data) => {
    return await axios.post(
        apiUrl() + `register`,
        {
            mykad: data.mykad,
            name: data.name,
            email: data.email,
            phone: data.phone,
            access_token: data.access_token,
            refresh_token: data.refresh_token,
            // password: data.password,
        },
        header()
    );
}

export const newApplicationRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newApplication`,
        {
            accessToken: userDetail.accessToken,
            channel: userDetail?.isAdmin ? 2 : 1,
            formOne: data.formOne,
            formTwo: data.formTwo,
            formThree: data.formThree,
            formFour: data.formFour,
        },
        header()
    );
}

export const applicationListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `applicationList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const applicationViewRequest = async (data) => {
    return await axios.get(
        apiUrl() + `applicationView?id=${data.id}`,
        header()
    );
}

export const applicationStatusUpdateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `applicationStatusUpdate`,
        {
            accessToken: userDetail.accessToken,
            application_id: data.id,
            status: data.status,
        },
        header()
    );
}

export const licenseListRequest = async (data) => {
    let addUrl = '', searchUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    console.log('data.search',data.search)
    let link = `licenseList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}${searchUrl}`
    if(data.all){
        link = `licenseList`;
    }
    return await axios.get(
        apiUrl() + link,
        header()
    );
}

export const licenseListReport = async (data) => {
    let searchUrl = ''
    if(data.search != '' && data.search != null){
        searchUrl = `?search=${btoa(JSON.stringify(data.search))}`
    }
    let link = `licenseListReport${searchUrl}`
    
    return await axios.get(
        apiUrl() + link,
        header()
    );
}

export const licenseCourierListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    let link = `licenseCourierList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`
    if(data.all){
        link = `licenseCourierList`;
    }
    return await axios.get(
        apiUrl() + link,
        header()
    );
}

export const licenseViewRequest = async (data) => {
    return await axios.get(
        apiUrl() + `licenseView?id=${data.id}`,
        header()
    );
}

export const newTransactionRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newTransaction`,
        {
            transaction_id: data.receipt,
            application_id: data.id,
            // license_id: data.id,
            receipt_no: data.receipt,
            channel: userDetail?.isAdmin ? 2 : 1,
            status: 'paid',
            created_by: userDetail?.isAdmin ? userDetail.staffId : userDetail.userId,
        },
        header()
    );
}

export const getTransaction = async (data) => {
    const res =  await axios.post(
        apiUrl() + `getTransaction`,
        {
            transaction_id: data,
            accessToken: userDetail?.accessToken
        },
        header()
    );
    return res.data.data;
}

export const getPetList = async (data) => {
    const res = await axios.get(
        apiUrl() + `petList?id=${data}`,
        header()
    );
    return res.data.data;
}

export const newPetPlateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newPetPlate`,
        {
            accessToken: userDetail.accessToken,
            license_id: data.id,
            plates: data.plates,
        },
        header()
    );
}

export const deliveryViewRequest = async (data) => {
    return await axios.get(
        apiUrl() + `deliveryView?id=${data.id}`,
        header()
    );
}

export const deliveryUpdateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `deliveryUpdate`,
        {
            delivery_id: data.delivery_id,
            license_id: data.license_id,
            tracking_no: data.tracking,
            plates: data.plates,
            staffId: userDetail?.isAdmin ? userDetail.staffId : userDetail.userId
        },
        header()
    );
}

export const employeeListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `employeeList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const getRoles = async () => {
    const res = await axios.get(
        apiUrl() + `roles`,
        header()
    );
    return res.data.data;
}

export const registerStaffRequest = async (data) => {
    return await axios.post(
        apiUrl() + `registerStaff`,
        {
            staff_id: data.id,
            name: data.name,
            email: data.email,
            department: data.department,
            role_id: data.role
        },
        header()
    );
}

export const roleListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `roleList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const registerRoleRequest = async (data) => {
    return await axios.post(
        apiUrl() + `registerRole`,
        {
            data
        },
        header()
    );
}

export const searchStaffInfo = async (data) => {
    return await axios.post(
        apiUrl() + `searchStaffInfo`,
        {
            no_pekerja: data
        },
        header()
    );
}

export const roleMenu = async (data) => {
    return await axios.post(
        apiUrl() + `roleMenu`,
        {
            role_id: data,
            accessToken: userDetail?.accessToken
        },
        header()
    );
}

// module penguatkuasaan
export const searchInfo = async (data) => {
    let addUrl = '', searchUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    console.log('data.search',data.search)
    let link = `searchInfo?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}${searchUrl}`
    if(data.all){
        link = `searchInfo`;
    }
    return await axios.get(
        apiUrl() + link,
        header()
    );
}


// module kacauganggu
export const tangkapLupusListRequest = async (data) => {
    let addUrl = '', searchUrl = '', typeUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    if(data.type != '' && data.type != null){
        typeUrl = `&type=${data.type}`
    }
    return await axios.get(
        apiUrl() + `tangkaplupusList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}${searchUrl}${typeUrl}`,
        header()
    );
}
export const tangkapLupusListReport = async (data) => {
    let addUrl = '', searchUrl = '', typeUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    if(data.type != '' && data.type != null){
        typeUrl = `&type=${data.type}`
    }
    return await axios.get(
        apiUrl() + `tangkaplupusListReport?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}${searchUrl}${typeUrl}`,
        header()
    );
}

export const operationListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `operationList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const newOperation = async (data) => {
    return await axios.post(
        apiUrl() + `newOperation`,
        {
            data
        },
        header()
    );
}

export const operationByType= async (data) => {
    const res = await axios.post(
        apiUrl() + `operationByType`,
        {
            type: data
        },
        header()
    );
    return res.data
}

export const newOperationByType = async (data) => {
    return await axios.post(
        apiUrl() + `newOperationByType`,
        data,
        header()
    );
}

export const editOperationByType = async (data) => {
    return await axios.post(
        apiUrl() + `editOperationByType`,
        data,
        header()
    );
}

export const deleteOperationByType = async (data) => {
    return await axios.post(
        apiUrl() + `deleteOperationByType`,
        data,
        header()
    );
}

// module tetapan
export const courierInfo = async () => {
    const res = await axios.get(
        apiUrl() + `courierInfo`,
        header()
    );
    return res.data
}

export const updateCourierInfo = async (data) => {
    return await axios.post(
        apiUrl() + `updateCourierInfo`,
        {
            name: data.name,
            amount: data.amount,
            url: data.url,
            staffId: data.staffId,
            id: data.id
        },
        header()
    );
}

export const updateStaff = async (data) => {
    const res = await axios.post(
        apiUrl() + `updateStaff`,
        {
            data
        },
        header()
    );
    return res.data;
}

export const updateRole = async (data) => {
    const res = await axios.post(
        apiUrl() + `updateRole`,
        {
            data
        },
        header()
    );
    return res.data;
}

export const editLicense = async (data) => {
    const res = await axios.post(
        apiUrl() + `editLicense`,
        data,
        header()
    );
    return res.data;
}


export const searchCukai = async (data) => {
    return await axios.post(
        apiUrl() + `searchCukai`,
        {
            id: data
        },
        header()
    );
}

export const userListRequest = async (data) => {
    let searchUrl = '', page = 0, perPage = 5;
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    return await axios.get(
        apiUrl() + `userList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${searchUrl}`,
        header()
    );
}

export const updateUser = async (data) => {
    const res = await axios.post(
        apiUrl() + `updateUser`,
        {
            data
        },
        header()
    );
    return res.data;
}