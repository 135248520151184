import React, { useState, useEffect } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function CarianPengguna({setSearch, setPage}) {
    const [toggle, setToggle] = useState(false);
    const [toggleName, setToggleName] = useState('Buka');

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    useEffect(() => {
        toggleCard()
    }, [])

    const validationSchema = Yup.object().shape({
        nama: Yup.string().when("nama", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(4, "Minimum 4 aksara").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        mykad: Yup.string().when("mykad", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(12, "Minimum 12 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        email: Yup.string().when("email", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().email('Format emel salah').required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        phone: Yup.string().when("phone", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(10, "Minimum 10 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
    }, [
        ["nama", "nama"], 
        ["mykad", "mykad"],
        ["email", "email"],
        ["phone", "phone"],
    ]);
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, setValue } = useForm({
        defaultValues: {
            nama: '',
            mykad: '',
            email: '',
            phone: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        console.log('datadata',data)
        setSearch(data)
        setPage(0)
    }

    const onResetForm = () => {
        reset()
        setSearch(null)
        setPage(0)
    }

    return (
        <>
            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-wrap justify-between mt-2'>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Nama" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.nama ? errors.nama.message : ''}
                                                error={errors.nama ? errors.patnamail : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="nama"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. MyKad" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.mykad ? errors.mykad.message : ''}
                                                error={errors.mykad ? errors.mykad : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="mykad"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='email'
                                                size='small' 
                                                label="Emel" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.email ? errors.email.message : ''}
                                                error={errors.email ? errors.email : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="email"
                                    />
                                </div>
                                <div className='w-full lg:w-3/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. Telefon" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.phone ? errors.phone.message : ''}
                                                error={errors.phone ? errors.phone : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="phone"
                                    />
                                </div>
                                <div className='flex justify-center w-full p-2'>
                                    <Button 
                                        color="gray"
                                        buttonType="link"
                                        onClick={onResetForm}
                                        ripple="dark"
                                        type="button"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="blue"
                                        // onClick={() => onStatusUpdate(action)}
                                        ripple="light"
                                        type="submit"
                                    >
                                        Cari
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}