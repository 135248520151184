import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import moment from "moment";
import differenceBy from 'lodash/differenceBy';
import Button from "@material-tailwind/react/Button";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
import Example from "components/Spinner/Example";
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useTangkapLupusList } from 'query/actions/tangkaplupus/Crud';
import ButtonGroupActionTangkapLupus from './ButtonGroupActionTangkapLupus';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextField, MenuItem, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { useOperationByType } from 'query/actions/tangkaplupus/Crud';
import { useNewOperationByType } from 'query/actions/tangkaplupus/Crud';
import { toast } from 'react-toastify';

export default function DataTableCardTangkapLupus() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, error, isError, isLoading, isSuccess } = useTangkapLupusList();
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [title, setTitle] = useState('');
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = {'page': page, 'perPage': countPerPage, 'user': accessToken}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, refreshTable]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = useMemo(() => {
		const handleApprove = () => {
            setPageModal(<View closeModal={closeModal} id={''} action={7} />)
            setShowModal(true)
			// console.log('selectedRows',selectedRows)
			// if (window.confirm(`Luluskan permohonan:\r ${selectedRows.map(r => r.application_id)}?`)) {
			// 	setToggleCleared(!toggleCleared);
			// 	setLists(differenceBy(lists, selectedRows, 'status'));
			// }
		};

		return (
            <>
            <div className='px-1 hidden md:block lg:block'>
                <Button
                    onClick={() => handleApprove()}
                    type="button"
                    color="green"
                >
                    <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    Lulus
                </Button>
            </div>
            <div className='px-1 lg:hidden'>
                <Tooltip title="Lulus" placement="left">
                    <Button
                        onClick={() => handleApprove()}
                        type="button"
                        color="green"
                        iconOnly
                    >
                        <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            </>
		);
	}, [lists, selectedRows, toggleCleared]);

    // admin
    const columns = [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '5rem'
        },
        {
            name: <span>KOD & NAMA OPERASI</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='uppercase'>{row.kod} - {row.nama}</div>
                </div>,
            selector: row => row.kod,
            sortable: true,
            // width: '10rem'
        },
        {
            name: <span>JENIS OPERASI</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.jenis }</div>
                </div>,
            selector: row => row.jenis,
            sortable: true,
            // width: '19.5rem'
        },
        {
            name: <span>TARIKH & MASA</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.tarikh).format('D MMM YYYY') }</div>
                    <div>{ row.masa }</div>
                </div>,
            // selector: row => row.created_at,
            sortable: true,
            // width: '7rem'
        },
        {
            name: <span>JENIS HAIWAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.haiwan }</div>
                </div>,
            selector: row => row.haiwan,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>BILANGAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.bilangan }</div>
                </div>,
            selector: row => row.bilangan,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>KAEDAH TANGKAPAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.kaedah }</div>
                </div>,
            selector: row => row.kaedah,
            sortable: true,
            // width: '8rem'
        },
        {
            name: <span>TINDAKAN</span>,
            cell: row => <div className='flex flex-col my-4'>
                    <div>{ row.tindakan_name }</div>
                </div>,
            selector: row => row.tindakan_name,
            sortable: true,
            // width: '8rem'
        },
        {
            name: ' ',
            cell: row => <ButtonGroupActionTangkapLupus 
                        isAdmin={isAdmin} 
                        id={row.idno} 
                        data={row}
                        // status={row.status} 
                        // plate_no={row.plate_no}
                        setRefreshTable={setRefreshTable}
                    />,
            selector: row => row.row_num,
            sortable: true,
            //   width: '12%'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);

    function onAddHandler(type){
        setPageModal(<AddMaklumatOperasi type={type} closeModal={closeModal} setRefreshTable={setRefreshTable} />)
        setTitle(type=='Penangkapan' ? 'Maklumat Penangkapan (Anjing/Kucing)' : 'Maklumat Pelupusan (Kacau Ganggu/Veterinar)')
        setShowModal(true)
    }

    return (
        <>
        <Card>
            <CardHeader size='sm' color="red" contentPosition="left">
                <h2 className="text-white text-xl">Senarai Penangkapan & Pelupusan</h2>
            </CardHeader>
            <CardBody className='-mt-3'>
                <div className='flex justify-end mx-4'>
                    <Button
                        color="blue"
                        className="normal-case mr-1"
                        // buttonType="outline"
                        // size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // type="submit"
                        onClick={() => onAddHandler('Penangkapan')}
                    >
                        <i class="fas fa-plus pr-1"></i>Tambah Penangkapan
                    </Button>
                    <Button
                        color="blue"
                        className="normal-case ml-1"
                        // buttonType="outline"
                        // size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        // type="submit"
                        onClick={() => onAddHandler('Pelupusan')}
                    >
                        <i class="fas fa-plus pr-1"></i>Tambah Pelupusan
                    </Button>
                </div>
                <div className="overflow-x-auto mt-4">
                    <DataTable 
                        // title=" "
                        className='w-full'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        selectableRowDisabled={rowDisabledCriteria}
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={'sm'}
                title={title}
                content={pageModal}
                backdropClose={false}
            /> : 
        ''}
        </>
    );
}

const AddMaklumatOperasi = ({type, closeModal, setRefreshTable}) => {
    const [parlimenValue, setParlimenValue] = useState('');
    const {data, isLoading} = useOperationByType(type);
    const {mutate} = useNewOperationByType();

    const validationSchema = Yup.object().shape({
        kod: Yup.string().required(`Ruangan Wajib Diisi`),
        kaedah: Yup.string().required(`Ruangan Wajib Diisi`),
        haiwan: Yup.string().required(`Ruangan Wajib Diisi`),
        bilangan: Yup.string().required(`Ruangan Wajib Diisi`),
        tarikh: Yup.string().required(`Ruangan Wajib Diisi`),
        masa: Yup.string().required(`Ruangan Wajib Diisi`),
        alamat_p1: Yup.string().required(`Ruangan Wajib Diisi`),
        poskod: Yup.string().required(`Ruangan Wajib Diisi`),
        bandar: Yup.string().required(`Ruangan Wajib Diisi`),
        daerah: Yup.string().required(`Ruangan Wajib Diisi`),
        dun: Yup.string().required(`Ruangan Wajib Diisi`),
        parlimen: Yup.string().required(`Ruangan Wajib Diisi`),
        tindakan: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const defaultValues = {
        kod: '',
        kaedah: '',
        haiwan: '',
        bilangan: '',
        tarikh: '',
        masa: '',
        alamat_p1: '',
        poskod: '',
        bandar: '',
        daerah: '',
        dun: '',
        parlimen: '',
        tindakan: ''
    }
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        data['jenis'] = type;
        console.log('datadata',data)
        // // logIn(data)
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Tambah Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }

    const resetFieldValue = (field, value) => {
        resetField(field)
        setValue(field, value)
    }
    useEffect(() => {
        if(parlimenValue != ''){
            if(parlimenValue == 'N1' || parlimenValue == 'N2' || parlimenValue == 'N3'){
                resetFieldValue('parlimen', "P41 - Kepala Batas")
            } else if(parlimenValue == 'N4' || parlimenValue == 'N5' || parlimenValue == 'N6'){
                resetFieldValue('parlimen', "P42 - Tasek Gelugor")
            } else if(parlimenValue == 'N7' || parlimenValue == 'N8' || parlimenValue == 'N9'){
                resetFieldValue('parlimen', "P43 - Bagan")
            } else if(parlimenValue == 'N10' || parlimenValue == 'N11' || parlimenValue == 'N12'){
                resetFieldValue('parlimen', "P44 - Permatang Pauh")
            } else if(parlimenValue == 'N13' || parlimenValue == 'N14' || parlimenValue == 'N15'){
                resetFieldValue('parlimen', "P45 - Bukit Mertajam")
            } else if(parlimenValue == 'N16' || parlimenValue == 'N17' || parlimenValue == 'N18'){
                resetFieldValue('parlimen', "P46 - Batu Kawan")
            } else if(parlimenValue == 'N19' || parlimenValue == 'N20' || parlimenValue == 'N21'){
                resetFieldValue('parlimen', "P47 - Nibong Tebal")
            } 
        }
    }, [parlimenValue])
    
    return (
        <>
            <DialogContent>
                <div className="flex flex-col my-1">
                    <div className="w-full px-2 /mb-6">
                        <div className='flex flex-col /mt-4 /mb-1'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-wrap /mb-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg /pt-2 pb-1 /px-4 /mt-4'>
                                    <div className="w-full text-gray-500 mb-3">Butiran Operasi</div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Operasi"
                                                    // placeholder="Masukkan alamat anda"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={addressValue}
                                                    // onChange={(event) => {
                                                    //     setAddressValue(event.target.value)
                                                    //     resetFieldValue('address', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.kod ? errors.kod.message : ''}
                                                    error={errors.kod ? errors.kod : false}
                                                    fullWidth
                                                    // multiline
                                                    // maxRows={3}
                                                >
                                                    {data?.data.map((op, key) => (
                                                        <MenuItem key={key} value={op.kod}>{op.kod+' - '+op.nama}</MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                            control={control}
                                            name="kod"
                                        />
                                    </div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Aduan"
                                                    // placeholder="Masukkan aduan"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={addressValue}
                                                    // onChange={(event) => {
                                                    //     setAddressValue(event.target.value)
                                                    //     resetFieldValue('address', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    // helperText={errors.aduan ? errors.aduan.message : ''}
                                                    // error={errors.aduan ? errors.aduan : false}
                                                    fullWidth
                                                    multiline
                                                    maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="aduan"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                type=='Penangkapan' ?
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Kaedah Tangkapan"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.kaedah ? errors.kaedah.message : ''}
                                                    error={errors.kaedah ? errors.kaedah : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Perangkap">Perangkap</MenuItem>
                                                    <MenuItem value="Lasso">Lasso</MenuItem>
                                                    <MenuItem value="PSG">PSG</MenuItem>
                                                    <MenuItem value="Jaring">Jaring</MenuItem>
                                                </TextField>
                                                :
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Kaedah Tangkapan"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.kaedah ? errors.kaedah.message : ''}
                                                    error={errors.kaedah ? errors.kaedah : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Tembak">Tembak</MenuItem>
                                                    <MenuItem value="Perangkap">Perangkap</MenuItem>
                                                    <MenuItem value="Racun">Racun</MenuItem>
                                                    <MenuItem value="Musnah Sarang Gagak">Musnah Sarang Gagak</MenuItem>
                                                    <MenuItem value="Lasso">Lasso</MenuItem>
                                                    <MenuItem value="PSG">PSG</MenuItem>
                                                    <MenuItem value="Jaring">Jaring</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="kaedah"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-1 /pr-1  mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Jenis Haiwan"
                                                    placeholder="Pilih jenis haiwan"
                                                    variant="outlined"
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.haiwan ? errors.haiwan.message : ''}
                                                    error={errors.haiwan ? errors.haiwan : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="Anjing Liar">Anjing Liar</MenuItem>
                                                    <MenuItem value="Kucing Liar">Kucing Liar</MenuItem>
                                                    <MenuItem value="Burung Merpati">Burung Merpati</MenuItem>
                                                    <MenuItem value="Burung Gagak">Burung Gagak</MenuItem>
                                                    <MenuItem value="Lembu">Lembu</MenuItem>
                                                    <MenuItem value="Kambing">Kambing</MenuItem>
                                                    <MenuItem value="Kerbau">Kerbau</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="haiwan"
                                        />
                                    </div>
                                    {/* <div className="w-full lg:w-4/12 pl-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Bil. Tangkapan"
                                                    placeholder="Masukkan bil. tangkapan"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.city ? errors.city.message : ''}
                                                    error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="city"
                                        />
                                    </div> */}
                                    <div className="w-full lg:w-4/12 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Bil. Tangkapan"
                                                    // placeholder="Masukkan bilangan tangkapan"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.bilangan ? errors.bilangan.message : ''}
                                                    error={errors.bilangan ? errors.bilangan : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="bilangan"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 pl-1 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="date"
                                                    label="Tarikh"
                                                    // placeholder="Pilih tarikh"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.tarikh ? errors.tarikh.message : ''}
                                                    error={errors.tarikh ? errors.tarikh : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="tarikh"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 pl-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="time"
                                                    label="Masa"
                                                    // placeholder="Pilih masa"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.masa ? errors.masa.message : ''}
                                                    error={errors.masa ? errors.masa : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="masa"
                                        />
                                    </div>
                                </div>
                                {/* <hr /> */}
                                <div className='flex flex-wrap /mb-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg pt-2 pb-1 /px-4 /mt-4'>
                                    <div className="w-full text-gray-500 mb-3">Info Lokasi</div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Alamat Lokasi"
                                                    // placeholder="Masukkan alamat lokasi"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.alamat_p1 ? errors.alamat_p1.message : ''}
                                                    error={errors.alamat_p1 ? errors.alamat_p1 : false}
                                                    fullWidth
                                                    multiline
                                                    maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="alamat_p1"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Poskod"
                                                    // placeholder="Masukkan poskod"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.poskod ? errors.poskod.message : ''}
                                                    error={errors.poskod ? errors.poskod : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="poskod"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Bandar"
                                                    // placeholder="Masukkan bandar"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.bandar ? errors.bandar.message : ''}
                                                    error={errors.bandar ? errors.bandar : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="bandar"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Daerah"
                                                    // placeholder="Pilih daerah"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.daerah ? errors.daerah.message : ''}
                                                    error={errors.daerah ? errors.daerah : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="SPU">SPU - Seberang Perai Utara</MenuItem>
                                                    <MenuItem value="SPT">SPT - Seberang Perai Tengah</MenuItem>
                                                    <MenuItem value="SPS">SPS - Seberang Perai Selatan</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="daerah"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-1 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="DUN"
                                                    // placeholder="Pilih DUN"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    onChange={(event) => {
                                                        setParlimenValue(event.target.value);
                                                        resetFieldValue('dun', event.target.value)
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.dun ? errors.dun.message : ''}
                                                    error={errors.dun ? errors.dun : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="N1">N1 - Penaga</MenuItem>
                                                    <MenuItem value="N2">N2 - Bertam</MenuItem>
                                                    <MenuItem value="N3">N3 - Pinang Tunggal</MenuItem>
                                                    <MenuItem value="N4">N4 - Permatang Berangan</MenuItem>
                                                    <MenuItem value="N5">N5 - Sungai Dua</MenuItem>
                                                    <MenuItem value="N6">N6 - Telok Ayer Tawar</MenuItem>
                                                    <MenuItem value="N7">N7 - Sungai Puyu</MenuItem>
                                                    <MenuItem value="N8">N8 - Bagan Jermal</MenuItem>
                                                    <MenuItem value="N9">N9 - Bagan Dalam</MenuItem>
                                                    <MenuItem value="N10">N10 - Seberang Jaya</MenuItem>
                                                    <MenuItem value="N11">N11 - Permatang Pasir</MenuItem>
                                                    <MenuItem value="N12">N12 - Penanti</MenuItem>
                                                    <MenuItem value="N13">N13 - Berapit</MenuItem>
                                                    <MenuItem value="N14">N14 - Machang Bubuk</MenuItem>
                                                    <MenuItem value="N15">N15 - Padang Lalang</MenuItem>
                                                    <MenuItem value="N16">N16 - Perai</MenuItem>
                                                    <MenuItem value="N17">N17 - Bukit Tengah</MenuItem>
                                                    <MenuItem value="N18">N18 - Bukit Tambun</MenuItem>
                                                    <MenuItem value="N19">N19 - Jawi</MenuItem>
                                                    <MenuItem value="N20">N20 - Sungai Bakap</MenuItem>
                                                    <MenuItem value="N21">N21 - Sungai Acheh</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="dun"
                                        />
                                    </div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Parlimen"
                                                    // placeholder="Pilih masa"
                                                    variant="standard"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.parlimen ? errors.parlimen.message : ''}
                                                    error={errors.parlimen ? errors.parlimen : false}
                                                    fullWidth
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                />
                                            )}
                                            control={control}
                                            name="parlimen"
                                        />
                                    </div>
                                </div>
                                {/* <hr /> */}
                                <div className='flex flex-wrap w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg pt-2 pb-1 /px-4 /mt-4'>
                                    <div className="w-full text-gray-500 mb-3">Maklumat Tindakan</div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Tindakan"
                                                    // placeholder="Masukkan alamat lokasi"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={errors.tindakan ? errors.tindakan.message : ''}
                                                    error={errors.tindakan ? errors.tindakan : false}
                                                    fullWidth
                                                    >
                                                        <MenuItem value="Hantar ke SPCA">Hantar ke SPCA</MenuItem>
                                                        <MenuItem value="Hantar ke Paun">Hantar ke Paun</MenuItem>
                                                        <MenuItem value="Hantar ke JPV">Hantar ke JPV</MenuItem>
                                                        <MenuItem value="Musnah">Musnah</MenuItem>
                                                        <MenuItem value="Lepas">Lepas</MenuItem>
                                                    </TextField>
                                            )}
                                            control={control}
                                            name="tindakan"
                                        />
                                    </div>
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Nota"
                                                    // placeholder="Masukkan poskod"
                                                    variant="outlined"
                                                    size="small"
                                                    multiline
                                                    maxRows={3}
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    InputLabelProps={{ shrink: true }}
                                                    // helperText={errors.city ? errors.city.message : ''}
                                                    // error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="nota"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {/* <>
                    <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                    <span className='ml-2'>Loading...</span>
                </> :  */}
                <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>
                    <Button
                        color="blue"
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                    >
                        Simpan
                    </Button>
                </>
            </DialogActions>
        </>
    )
}
