import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import Input from '@material-tailwind/react/Input';
import { toast } from 'react-toastify';

import ResponseStatus from 'components/ResponseStatus';
import Example from 'components/Spinner/Example';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormControlLabel, Alert, MenuItem, Checkbox, TextField, FormHelperText, Backdrop, CircularProgress, FormControl } from '@mui/material';
import premiseType from 'data/premiseType.json'

// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useEditApplication, useApplicationView } from 'query/actions/Crud';

import useDynamicRefs from 'use-dynamic-refs';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSearchCukai } from 'query/actions/Crud';

export default function PermohonanKemaskini() {
    const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useEditApplication();
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    const params = useParams();
    const userInfo = useRecoilValue(userInfoAtom);
    const [info, setInfo] = useState({});  
    const [pets, setPets] = useState(); 
    const [appNo, setAppNo] = useState(); 
    const [page, setPage] = useState(0);
    const [callbackPage, setCallbackPage] = useState();
    const [data, setData] = useState({
      formOne: {},
      formTwo: {},
      formThree: {},
      formFour: {}
    });

    // view
    useEffect(() => {
        if(params.id){
            let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
            let data = {'id': params.id, 'user': accessToken}
            mutateView(data, {
                onSuccess: (data) => {
                    setAppNo(data.data.data.info.application_no)
                    setInfo(data.data.data.info)
                    setPets(data.data.data.pets)
                }
            })
        }
    }, [params])

    //* Customize Icon
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, 1) 100%)',
                // 'linear-gradient( 95deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 0.6) 100%)',
            //   'linear-gradient( 95deg, #90EE90 0%, #90EE90 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, .8) 100%)',
                // 'linear-gradient( 95deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 1) 100%)',
            //   'linear-gradient( 95deg, #90EE90 0%, #90EE90 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 5,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
    }));
  
    const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundImage:
            // 'linear-gradient( 136deg, #90EE90 0%, #009000 100%)',
            'linear-gradient( 136deg, rgba(123, 31, 162, 1) 0%, rgba(123, 31, 162, 1) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
            backgroundImage:
            // 'linear-gradient( 136deg, #009000 0%, #90EE90 100%)',
            'linear-gradient( 136deg, rgba(123, 31, 162, .8) 0%, rgba(123, 31, 162, .8) 100%)',
            boxShadow: '0 4px 10px 0 rgba(123, 31, 162, .25)',
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = { 
            1: <span>1</span>,
            2: <span>2</span>,
            3: <span>3</span>,
            4: <span>4</span>,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    ColorlibStepIcon.propTypes = {
      /**
       * Whether this step is active.
       * @default false
       */
      active: PropTypes.bool,
      className: PropTypes.string,
      /**
       * Mark the step as completed. Is passed to child components.
       * @default false
       */
      completed: PropTypes.bool,
      /**
       * The label displayed in the step icon.
       */
      icon: PropTypes.node,
    };

    const stepsLabel = ['Butiran Pemohon', 'Maklumat Premis', 'Info Anjing', 'Pengesahan'];


    const NextPage = () => {
        if (isLastPage()) return;
        return setPage(p => p + 1);
    };

    const goBack = () => {
        if (isFirstpage()) {
            // setShowAlertCancel(true);
            return;
        }
        return setPage(p => p - 1);
    };

    const isLastPage = () => {
        return page === 3;
    };
    const isFirstpage = () => {
        return page === 0;
    };

    const updateData = (type, newData) => {
        setData((data) => {
          return { ...data, [type]: newData };
        });
        if (!isLastPage()) return NextPage();
    };
    console.log('datadata',data)

    useEffect(() => {
        if (isLastPage()) {
          submitData(data);
        }
    }, [data]);

    const submitData = async (data) => {
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        data['applicationId'] = params.id
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Kemaskini Permohonan Berjaya", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        if(code == '500' || code == '201'){
            toast.update(loadingToast, { 
                render: "Kemaskini Permohonan Gagal", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        setCallbackPage(
            <ResponseStatus 
                type={code == '200' ? 'success' : 'error'} 
                close={null} 
                title={code == '200' ? 'Berjaya!' : 'Gagal'}
                subtitle={code == '200' ? 
                    'Permohonan anda telah dikemaskini. Sila buat bayaran untuk dapatkan lesen anda.' : 
                    code=='201' ? 
                    'Premis telah didaftar dan mempunyai lesen yang aktif.' :
                    'Kemaskini permohonan gagal, sila cuba lagi.'}
                isButton={code == '200' ?
                    userInfo?.isAdmin ? 
                    {page: '/v2/lesen/senarai-permohonan', name: 'Pergi ke Senarai Permohonan'} :
                    {page: '/v2/permohonan/senarai', name: 'Pergi ke Senarai Permohonan'} : 
                    null
                }
            />
        )
    }

    const getPage = (page) => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        window.scrollTo(0, 0);
        if(page == 0){
            return (<FormStepOne update={updateData} data={data} back={goBack} />)
        } else if(page == 1){
            return (<FormStepTwo update={updateData} data={data} back={goBack} />)
        } else if(page == 2){
            return (<FormStepThree update={updateData} data={data} back={goBack} />)
        } else if(page == 3){
            return (<FormStepFour update={updateData} data={data} back={goBack} />)
        }
    }

    return (
        <>
            <div className={`bg-${userInfo?.isAdmin ? 'teal' : 'cyan'}-500 pt-5 pb-28 px-3 md:px-8 h-auto`}>
                <div className="container mx-auto max-w-full">
                    <Stepper alternativeLabel activeStep={page} connector={<ColorlibConnector />}>
                        {stepsLabel.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </div>
            </div>

            <div className="px-3 md:px-8 -mt-12">
                <div className="container px-4 mx-auto max-w-full">
                    <div className='flex'>
                        {/* {getPage(page)} */}
                        {/* {page == 0 ? (<FormStepOne update={updateData} data={data} back={goBack} />) :
                            page == 1 ? (<FormStepTwo update={updateData} data={data} back={goBack} />) :
                            page == 2 ? (<FormStepThree update={updateData} data={data} back={goBack} />) :
                            page == 3 ? (<FormStepFour update={updateData} data={data} back={goBack} />) :
                        ''} */}
                        <FormStepOne appNo={appNo} enable={page == 0 ? 'block' : 'hidden'} update={updateData} edit={info} data={data} back={goBack} />
                        <FormStepTwo appNo={appNo} enable={page == 1 ? 'block' : 'hidden'} update={updateData} edit={info} data={data} back={goBack} />
                        <FormStepThree appNo={appNo} enable={page == 2 ? 'block' : 'hidden'} update={updateData} edit={pets} data={data} back={goBack} />
                        <FormStepFour appNo={appNo} enable={page == 3 ? 'block' : 'hidden'} update={updateData} data={data} back={goBack} page={callbackPage} />
                    </div>
                </div>
            </div>
        </>
    )
}

const FormStepOne = ({ appNo, enable, update, edit, data, back }) => {
    const userInfo = useRecoilValue(userInfoAtom);
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const numRegExp = /^\d+$/;
    const validationSchema = userInfo?.isAdmin ? Yup.object().shape({
        mykad: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. MyKad Tidak Sah')
                .min(12, "Minimum nombor adalah 12 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        name: Yup.string().required(`Ruangan Wajib Diisi`),
        phone: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                .min(10, "Minimum nombor adalah 10 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        email: Yup.string().email('Format emel salah'),//.required(`Ruangan Wajib Diisi`),
        address: Yup.string().required(`Ruangan Wajib Diisi`),
        postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        city: Yup.string().required(`Ruangan Wajib Diisi`)
    }) : Yup.object().shape({
        address: Yup.string().required(`Ruangan Wajib Diisi`),
        postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        city: Yup.string().required(`Ruangan Wajib Diisi`)
    });

    const defaultValues = userInfo?.isAdmin ? {
        mykad: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        postcode: '',
        city: ''
      } : {
        address: '',
        postcode: '',
        city: ''
      }

    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if(edit){
            if(userInfo?.isAdmin){
                reset({
                    mykad: edit?.mykad,
                    name: edit?.name,
                    phone: edit?.phone,
                    email: edit?.email,
                    address: edit?.ma_address,
                    postcode: edit?.ma_postcode,
                    city: edit?.ma_city
                });
            } else {
                reset({
                    address: edit?.ma_address,
                    postcode: edit?.ma_postcode,
                    city: edit?.ma_city
                });
            }
        }
    }, [edit])

    function onSubmit(data) {
        update('formOne', data);
    }

    const { mutate, error, isError, isLoading, isSuccess } = useSearchCukai();
    const [cukai, setCukai] = useState('');
    const handleChange = (event) => {
      setCukai(event.target.value);
    };
    const [searchCukai, setSearchCukai] = useState(false);
    const [dataCukai, setDataCukai] = useState();
    const onSearchCukai = async (data) => {
        setSearchCukai(true)
        // setTimeout(() => setSearchCukai(false), 1000)
        await mutate(data, {
            onSuccess: (data) => {
                console.log('datacukai', data.data)
                setDataCukai(data.data)
                setSearchCukai(false)
                
                if(userInfo?.isAdmin){
                    reset({
                        name: data.data.data.nama,
                        email: data.data.data.emel,
                        phone: data.data.data.no_hp,
                        address: data.data.data.no_rumah+' '+data.data.data.jalan+' '+data.data.data.tempat,
                        postcode: data.data.data.bandar.substring(0, data.data.data.bandar.indexOf(' ')),
                        city: data.data.data.bandar.substring(data.data.data.bandar.indexOf(' ') + 1),
                    })
                } else {
                    reset({
                        // name: data.data.data.nama,
                        address: data.data.data.no_rumah+' '+data.data.data.jalan+' '+data.data.data.tempat,
                        postcode: data.data.data.bandar.substring(0, data.data.data.bandar.indexOf(' ')),
                        city: data.data.data.bandar.substring(data.data.data.bandar.indexOf(' ') + 1),
                    })
                }
            }
        })
    }

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-xl lg:text-2xl">Butiran Pemohon</h2>
                                <span className='uppercase hidden lg:block'>Langkah 1</span>
                                {/* <Button
                                    color="transparent"
                                    buttonType="link"
                                    // size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 1
                                </Button> */}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className='flex flex-row items-center'>
                                <h6 className="text-gray-500 text-sm uppercase mr-2">
                                    No. Permohonan :
                                </h6>
                                <h6 className="text-lg font-bold">
                                    {appNo}
                                </h6>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* {userInfo?.isAdmin ? 
                                <> */}
                                {/* <div className="mt-2 px-4 pt-1 bg-gray-500 bg-opacity-5 rounded-xl"> */}
                                    <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                        Semakan Cukai Taksiran
                                    </h6>
                                    <div className="flex flex-wrap mt-1 mb-5">
                                        <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                            <TextField
                                                id="cukai"
                                                label="No. Cukai Taksiran"
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                fullWidth
                                                value={cukai}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                            <Button
                                                type="button"
                                                color="blue"
                                                size="lg"
                                                onClick={() => onSearchCukai(cukai)}
                                            >
                                                {searchCukai ? 
                                                <>
                                                    <Example type='spin' height='1.5rem' width='1.5rem' />
                                                    <span className='ml-2'>Loading...</span>
                                                </> : 
                                                'Semak'
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                {/* </div> */}
                                {/* </>
                                : ''} */}

                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Maklumat Peribadi
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    {userInfo?.isAdmin ?
                                    <>
                                        <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="mykad"
                                                        label="No. MyKad"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        helperText={errors.mykad ? errors.mykad.message : ' '}
                                                        error={errors.mykad ? errors.mykad : false}
                                                        fullWidth
                                                    />
                                                )}
                                                control={control}
                                                name="mykad"
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="name"
                                                        label="Nama Penuh (Seperti di MyKad)"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        helperText={errors.name ? errors.name.message : ' '}
                                                        error={errors.name ? errors.name : false}
                                                        fullWidth
                                                    />
                                                )}
                                                control={control}
                                                name="name"
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="phone"
                                                        label="No. Telefon"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        helperText={errors.phone ? errors.phone.message : ' '}
                                                        error={errors.phone ? errors.phone : false}
                                                        fullWidth
                                                    />
                                                )}
                                                control={control}
                                                name="phone"
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                            <Controller
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        id="email"
                                                        label="Emel"
                                                        variant="outlined"
                                                        size="small"
                                                        color="secondary"
                                                        helperText={errors.email ? errors.email.message : ' '}
                                                        error={errors.email ? errors.email : false}
                                                        fullWidth
                                                    />
                                                )}
                                                control={control}
                                                name="email"
                                            />
                                        </div>
                                    </> : 
                                    <>
                                        <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                            <TextField
                                                id="mykad"
                                                label="No. MyKad"
                                                variant="standard"
                                                size="small"
                                                color="secondary"
                                                value={userInfo.userId}
                                                fullWidth
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                            <TextField
                                                id="name"
                                                label="Nama Penuh (Seperti di MyKad)"
                                                variant="standard"
                                                size="small"
                                                color="secondary"
                                                value={userInfo.name}
                                                fullWidth
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                            <TextField
                                                id="phone"
                                                label="No. Telefon"
                                                variant="standard"
                                                size="small"
                                                color="secondary"
                                                value={userInfo.phone}
                                                fullWidth
                                                readOnly
                                            />
                                        </div>
                                        <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                            <TextField
                                                id="email"
                                                label="Emel"
                                                variant="standard"
                                                size="small"
                                                color="secondary"
                                                value={userInfo.email}
                                                fullWidth
                                                readOnly
                                            />
                                        </div>
                                    </>
                                    }
                                </div>

                                <h6 className="text-purple-500 text-sm my-6 font-light uppercase">
                                    Alamat Surat Menyurat
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="address"
                                                    label="Alamat"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.address ? errors.address.message : ''}
                                                    error={errors.address ? errors.address : false}
                                                    fullWidth
                                                    multiline
                                                    maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="address"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="postcode"
                                                    label="Poskod"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.postcode ? errors.postcode.message : ''}
                                                    error={errors.postcode ? errors.postcode : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="postcode"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="city"
                                                    label="Bandar"
                                                    variant="outlined"
                                                    size="small"
                                                    color="secondary"
                                                    helperText={errors.city ? errors.city.message : ''}
                                                    error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="city"
                                        />
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <div></div>
                    <Button
                        type="button"
                        color="purple"
                        size="lg"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepTwo = ({ appNo, enable, update, edit, data, back }) => {
    const [checked, setChecked] = useState(false);
    const [addressValue, setAddressValue] = useState('');
    const [postcodeValue, setPostcodeValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [parlimenValue, setParlimenValue] = useState('');
    // const handleChange = (event) => {
    //   setName(event.target.value);
    // };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const numRegExp = /^\d+$/;
    const validationSchema = Yup.object().shape({
        address: Yup.string().required(`Ruangan Wajib Diisi`),
        postcode: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(numRegExp, 'Poskod Tidak Sah')
                .min(5, "Minimum nombor adalah 5 digit")
                .max(5, "Maksimum nombor adalah 5 digit"),
        city: Yup.string().required(`Ruangan Wajib Diisi`),
        district: Yup.string().required(`Ruangan Wajib Diisi`),
        dun: Yup.string().required(`Ruangan Wajib Diisi`),
        parlimen: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_status: Yup.string().required(`Ruangan Wajib Diisi`),
        premise_type: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const defaultValues = {
        address: '',
        postcode: '',
        city: '',
        district: '',
        dun: '',
        parlimen: '',
        premise_status: '',
        premise_type: ''
    }

    useEffect(() => {
        if(edit){
            reset({
                address: edit?.pa_address,
                postcode: edit?.pa_postcode,
                city: edit?.pa_city,
                district: edit?.district,
                dun: edit?.dun,
                parlimen: edit?.parlimen,
                premise_status: edit?.premise_status,
                premise_type: edit?.premise_type
            });
        }
    }, [edit])

    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        resetField("address")
        resetField("postcode")
        resetField("city")
        if(checked){
            setAddressValue(data.formOne.address)
            setPostcodeValue(data.formOne.postcode)
            setCityValue(data.formOne.city)
            setValue("address", data.formOne.address)
            setValue("postcode", data.formOne.postcode)
            setValue("city", data.formOne.city)
        } else {
            setAddressValue('')
            setPostcodeValue('')
            setCityValue('')
            setValue("address", "")
            setValue("postcode", "")
            setValue("city", "")
        }
    }, [checked])

    const resetFieldValue = (field, value) => {
        resetField(field)
        setValue(field, value)
    }

    function onSubmit(data) {
        update('formTwo', data);
    }

    useEffect(() => {
        if(parlimenValue != ''){
            if(parlimenValue == 'N1' || parlimenValue == 'N2' || parlimenValue == 'N3'){
                resetFieldValue('parlimen', "P41 - Kepala Batas")
            } else if(parlimenValue == 'N4' || parlimenValue == 'N5' || parlimenValue == 'N6'){
                resetFieldValue('parlimen', "P42 - Tasek Gelugor")
            } else if(parlimenValue == 'N7' || parlimenValue == 'N8' || parlimenValue == 'N9'){
                resetFieldValue('parlimen', "P43 - Bagan")
            } else if(parlimenValue == 'N10' || parlimenValue == 'N11' || parlimenValue == 'N12'){
                resetFieldValue('parlimen', "P44 - Permatang Pauh")
            } else if(parlimenValue == 'N13' || parlimenValue == 'N14' || parlimenValue == 'N15'){
                resetFieldValue('parlimen', "P45 - Bukit Mertajam")
            } else if(parlimenValue == 'N16' || parlimenValue == 'N17' || parlimenValue == 'N18'){
                resetFieldValue('parlimen', "P46 - Batu Kawan")
            } else if(parlimenValue == 'N19' || parlimenValue == 'N20' || parlimenValue == 'N21'){
                resetFieldValue('parlimen', "P47 - Nibong Tebal")
            } 
        }
    }, [parlimenValue])

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-xl lg:text-2xl">Maklumat Premis</h2>
                                <span className='uppercase hidden lg:block'>Langkah 2</span>
                                {/* <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 2
                                </Button> */}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className='flex flex-row items-center'>
                                <h6 className="text-gray-500 text-sm uppercase mr-2">
                                    No. Permohonan :
                                </h6>
                                <h6 className="text-lg font-bold">
                                    {appNo}
                                </h6>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Alamat Premis
                                </h6>
                                {/* <div className="flex flex-wrap -mt-4">
                                    <div className="w-full lg:w-12/12 mb-1 font-light">
                                        <FormControlLabel 
                                            control={<Checkbox color="secondary" checked={checked} onChange={() => setChecked(!checked)} />} 
                                            label="Sama Seperti Alamat Surat Menyurat" 
                                        />
                                    </div>
                                </div> */}
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-12/12 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Alamat"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={addressValue}
                                                    // onChange={(event) => {
                                                    //     setAddressValue(event.target.value)
                                                    //     resetFieldValue('address', event.target.value)
                                                    // }}
                                                    helperText={errors.address ? errors.address.message : ''}
                                                    error={errors.address ? errors.address : false}
                                                    fullWidth
                                                    multiline
                                                    maxRows={3}
                                                />
                                            )}
                                            control={control}
                                            name="address"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Poskod"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={postcodeValue}
                                                    // onChange={(event) => {
                                                    //     setPostcodeValue(event.target.value);
                                                    //     resetFieldValue('postcode', event.target.value)
                                                    // }}
                                                    helperText={errors.postcode ? errors.postcode.message : ''}
                                                    error={errors.postcode ? errors.postcode : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="postcode"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type="text"
                                                    label="Bandar"
                                                    variant="outlined"
                                                    size="small"
                                                    // value={cityValue}
                                                    // onChange={(event) => {
                                                    //     setCityValue(event.target.value)
                                                    //     resetFieldValue('city', event.target.value)
                                                    // }}
                                                    helperText={errors.city ? errors.city.message : ''}
                                                    error={errors.city ? errors.city : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name="city"
                                        />
                                    </div>
                                </div>

                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Kawasan
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-4/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Daerah"
                                                    variant="outlined"
                                                    size="small"
                                                    helperText={errors.district ? errors.district.message : ''}
                                                    error={errors.district ? errors.district : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="SPU">SPU - Seberang Perai Utara</MenuItem>
                                                    <MenuItem value="SPT">SPT - Seberang Perai Tengah</MenuItem>
                                                    <MenuItem value="SPS">SPS - Seberang Perai Selatan</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="district"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="DUN"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(event) => {
                                                        setParlimenValue(event.target.value);
                                                        resetFieldValue('dun', event.target.value)
                                                    }}
                                                    helperText={errors.dun ? errors.dun.message : ''}
                                                    error={errors.dun ? errors.dun : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value="N1">N1 - Penaga</MenuItem>
                                                    <MenuItem value="N2">N2 - Bertam</MenuItem>
                                                    <MenuItem value="N3">N3 - Pinang Tunggal</MenuItem>
                                                    <MenuItem value="N4">N4 - Permatang Berangan</MenuItem>
                                                    <MenuItem value="N5">N5 - Sungai Dua</MenuItem>
                                                    <MenuItem value="N6">N6 - Telok Ayer Tawar</MenuItem>
                                                    <MenuItem value="N7">N7 - Sungai Puyu</MenuItem>
                                                    <MenuItem value="N8">N8 - Bagan Jermal</MenuItem>
                                                    <MenuItem value="N9">N9 - Bagan Dalam</MenuItem>
                                                    <MenuItem value="N10">N10 - Seberang Jaya</MenuItem>
                                                    <MenuItem value="N11">N11 - Permatang Pasir</MenuItem>
                                                    <MenuItem value="N12">N12 - Penanti</MenuItem>
                                                    <MenuItem value="N13">N13 - Berapit</MenuItem>
                                                    <MenuItem value="N14">N14 - Machang Bubuk</MenuItem>
                                                    <MenuItem value="N15">N15 - Padang Lalang</MenuItem>
                                                    <MenuItem value="N16">N16 - Perai</MenuItem>
                                                    <MenuItem value="N17">N17 - Bukit Tengah</MenuItem>
                                                    <MenuItem value="N18">N18 - Bukit Tambun</MenuItem>
                                                    <MenuItem value="N19">N19 - Jawi</MenuItem>
                                                    <MenuItem value="N20">N20 - Sungai Bakap</MenuItem>
                                                    <MenuItem value="N21">N21 - Sungai Acheh</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="dun"
                                        />
                                    </div>
                                    <div className="w-full lg:w-4/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    // select
                                                    label="Parlimen"
                                                    variant="standard"
                                                    size="small"
                                                    helperText={errors.parlimen ? errors.parlimen.message : ''}
                                                    error={errors.parlimen ? errors.parlimen : false}
                                                    fullWidth
                                                    inputProps={
                                                        { readOnly: true, }
                                                    }
                                                >
                                                    {}
                                                    {/* <MenuItem value={10}>test 1</MenuItem>
                                                    <MenuItem value={20}>test 2</MenuItem>
                                                    <MenuItem value={30}>test 3</MenuItem>
                                                    <MenuItem value={40}>test 4</MenuItem> */}
                                                </TextField>
                                            )}
                                            control={control}
                                            name="parlimen"
                                        />
                                    </div>
                                </div>

                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Ciri-Ciri Premis
                                </h6>
                                <div className="flex flex-wrap mt-1 mb-5">
                                    <div className="w-full lg:w-6/12 pr-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Status Premis"
                                                    variant="outlined"
                                                    size="small"
                                                    helperText={errors.premise_status ? errors.premise_status.message : ''}
                                                    error={errors.premise_status ? errors.premise_status : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value='owner'>Pemilik Premis</MenuItem>
                                                    <MenuItem value='renter'>Penyewa Premis</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="premise_status"
                                        />
                                    </div>
                                    <div className="w-full lg:w-6/12 pl-4 mb-5 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Jenis Premis"
                                                    variant="outlined"
                                                    size="small"
                                                    helperText={errors.premise_type ? errors.premise_type.message : ''}
                                                    error={errors.premise_type ? errors.premise_type : false}
                                                    fullWidth
                                                >
                                                    <MenuItem value='pangsapuri'>Pangsapuri Tingkat Bawah</MenuItem>
                                                    <MenuItem value='teres'>Teres</MenuItem>
                                                    <MenuItem value='berkembar'>Berkembar</MenuItem>
                                                    <MenuItem value='banglo'>Banglo</MenuItem>
                                                    <MenuItem value='kampung'>Kampung</MenuItem>
                                                    <MenuItem value='lkg'>Ladang/Kilang/Gudang</MenuItem>
                                                </TextField>
                                            )}
                                            control={control}
                                            name="premise_type"
                                        />
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <Button
                        type="button"
                        color="gray"
                        size="lg"
                        onClick={back}
                    >
                        Kembali
                    </Button>
                    <Button
                        type="button"
                        color="purple"
                        size="lg"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepThree = ({ appNo, enable, update, edit, data, back }) => {
    const [petValue, setPetValue] = useState();
    const [buttonAdd, setButtonAdd] = useState(false);
    const formSchema = Yup.object().shape({
        gender: Yup.string().required(`Ruangan Wajib Diisi`),
        breed: Yup.string().required(`Ruangan Wajib Diisi`),
        color: Yup.string().required(`Ruangan Wajib Diisi`),
        microchip: Yup.boolean(),
        sterilization: Yup.boolean()
    });
    const validationSchema = Yup.object().shape({
        pet: Yup.array().of(formSchema).required("Must have fields").min(1, "Minimum of 1 field")
    });
    const defaultValues = {
        pet: [{ gender: '', breed: '', color: '', microchip: false, sterilization: false }]
    }
    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    });

    useEffect(() => {
        if(edit){
            reset({
                pet: edit
            });
        }
    }, [edit])

    useEffect(() => {
        premiseType.map((item) => {
            if(data.formTwo.premise_type == item.label){
                setPetValue(item.value)
            }
        })
    }, [data.formTwo])
    
    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "pet"
    });

    function onSubmit(data) {
        update('formThree', data);
    }
    
    useEffect(() => {
        setButtonAdd(false)
        if(petValue != undefined && fields.length > (petValue - 1)){
        // if(fields.length >= petValue){
            setButtonAdd(true)
        }
    }, [fields, petValue])
    // console.log('petValue',petValue)
    // console.log('fields.length',fields.length)

    const [getRef, setRef] =  useDynamicRefs();
    const [image, setImage] = useState([])
    const [document, setDocument] = useState([])
    const fileImage = useRef(null)
    const fileDocument = useRef(null)
    const handleClick = (ref, file) => {
        if(file=='image'){
            ref.current.click();
        } else if(file=='document'){
            ref.current.click();
        }
    };
    const handleChangeImage = (index, event) => {
        // const fileUploaded = event.target.files[0];
        // setImage(fileUploaded)
        let data = [...image];
        data[index] = event.target.files[0];
        setImage(data);
    };
    const handleChangeDocument = (index, event) => {
        // const fileUploaded = event.target.files[0];
        // setDocument(fileUploaded)
        let data = [...document];
        data[index] = event.target.files[0];
        setDocument(data);
    };
    const handleRemove = (index, file) => {
        if(file=='image'){
            let data = [...image];
            var i = data.indexOf(index)
            data.splice(i, 1)
            setImage(data)
            // setImage(null);
        } else if(file=='document'){
            let data = [...document];
            var i = data.indexOf(index)
            data.splice(i, 1)
            setDocument(data)
            // setDocument(null);
        }
    };
    // console.log('imageimage',image)
    // console.log('documentdocument',document)

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-xl lg:text-2xl">Info Anjing</h2>
                                <span className='uppercase hidden lg:block'>Langkah 3</span>
                                {/* <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 3
                                </Button> */}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className='flex flex-row items-center'>
                                <h6 className="text-gray-500 text-sm uppercase mr-2">
                                    No. Permohonan :
                                </h6>
                                <h6 className="text-lg font-bold">
                                    {appNo}
                                </h6>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='flex flex-wrap justify-between'>
                                    <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                        <span className="pr-3">
                                            Bilangan anjing yang dibenarkan: 
                                        </span>
                                        <div className='pr-1 bg-purple-500 bg-opacity-20 rounded-lg inline-block text-white'>
                                            <span className="font-bold text-purple-500 text-lg px-2 capitalize">{petValue}</span>
                                            <span className="text-purple-500"><i className="fa-solid fa-dog w-5"></i></span>
                                        </div>
                                    </h6>
                                    <div className='flex items-center'>
                                        {petValue > 1 ?
                                            <>
                                            {!buttonAdd ?
                                                <Button
                                                    type="button"
                                                    onClick={() => { append({ gender: '', breed: '', color: '', microchip: false, sterilization: false }); }}
                                                    size="sm"
                                                    color="indigo"
                                                    iconOnly
                                                    disabled={buttonAdd}
                                                    >
                                                    <i className="fa-solid fa-plus"></i>
                                                </Button>
                                            : false}
                                            </>
                                        : ''}
                                    </div>
                                </div>
                                <div className="mt-1 mb-5">
                                    {fields.map((item, index) => {
                                        return (
                                            <div key={item.id} className="mt-2 px-4 pt-4 flex flex-wrap bg-gray-500 bg-opacity-5 rounded-lg">
                                                <div className='flex flex-wrap w-full lg:w-8/12'>
                                                    {/* <div className="w-full lg:w-3/12 pr-4 mb-0 font-light">
                                                        <div className='-mb-2 font-medium'>Gambar</div>
                                                        {image[index] ? 
                                                            <>
                                                            <div className='mt-2 truncate'>
                                                                <i onClick={() => handleRemove(index, 'image')} className="fa-solid fa-circle-xmark text-red-500 cursor-pointer"></i>
                                                                <span className='pl-2'>{image[index].name}</span>
                                                            </div>
                                                            </>
                                                        :
                                                            <Button 
                                                                type='button' 
                                                                size='sm' 
                                                                className="mt-3" 
                                                                style={{padding: '.1rem .5rem'}}
                                                                onClick={() => handleClick(getRef(`fileImage${index}`),'image')}
                                                            >
                                                                Muat Naik
                                                            </Button>
                                                        }
                                                        <input onChange={event => handleChangeImage(index, event)} ref={setRef(`fileImage${index}`)} type={'file'} className='hidden' />
                                                    </div>
                                                    <div className="w-full lg:w-3/12 pr-4 mb-0 font-light">
                                                        <div className='-mb-2 font-medium'>Sijil Vaksin</div>
                                                        {document[index] ? 
                                                            <>
                                                            <div className='mt-2 truncate'>
                                                                <i onClick={() => handleRemove(index, 'document')} className="fa-solid fa-circle-xmark text-red-500 cursor-pointer"></i>
                                                                <span className='pl-2'>{document[index].name}</span>
                                                            </div>
                                                            </>
                                                        :
                                                            <Button 
                                                                type='button' 
                                                                size='sm' 
                                                                className="mt-3" 
                                                                style={{padding: '.1rem .5rem'}}
                                                                onClick={() => handleClick(getRef(`fileDocument${index}`), 'document')}
                                                            >
                                                                Muat Naik
                                                            </Button>
                                                        }
                                                        <input onChange={event => handleChangeDocument(index, event)} ref={setRef(`fileDocument${index}`)} type={'file'} className='hidden' />
                                                    </div> */}
                                                    <div className="w-full lg:w-4/12 pr-4 mb-0 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    select
                                                                    label="Jantina"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    helperText={errors?.pet?.[index]?.gender ? errors?.pet?.[index]?.gender.message : ' '}
                                                                    error={errors?.pet?.[index]?.gender ? errors?.pet?.[index]?.gender : false}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value='jantan'>Jantan</MenuItem>
                                                                    <MenuItem value='betina'>Betina</MenuItem>
                                                                </TextField>
                                                            )}
                                                            control={control}
                                                            name={`pet[${index}].gender`}
                                                        />
                                                    </div>
                                                    <div className="w-full lg:w-4/12 px-4 mb-0 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    select
                                                                    label="Baka"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    helperText={errors?.pet?.[index]?.breed ? errors?.pet?.[index]?.breed.message : ' '}
                                                                    error={errors?.pet?.[index]?.breed ? errors?.pet?.[index]?.breed : false}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value='exotic'>Exotic/Import</MenuItem>
                                                                    <MenuItem value='kacukan'>Kacukan</MenuItem>
                                                                    <MenuItem value='tempatan'>Tempatan</MenuItem>
                                                                </TextField>
                                                            )}
                                                            control={control}
                                                            name={`pet[${index}].breed`}
                                                        />
                                                    </div>
                                                    <div className="w-full lg:w-4/12 px-4 mb-0 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    select
                                                                    label="Warna"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    helperText={errors?.pet?.[index]?.color ? errors?.pet?.[index]?.color.message : ' '}
                                                                    error={errors?.pet?.[index]?.color ? errors?.pet?.[index]?.color : false}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="Coklat">Coklat</MenuItem>
                                                                    <MenuItem value="Coklat Putih">Coklat Putih</MenuItem>
                                                                    <MenuItem value="Coklat Hitam">Coklat Hitam</MenuItem>
                                                                    <MenuItem value="Emas">Emas</MenuItem>
                                                                    <MenuItem value="Hitam">Hitam</MenuItem>
                                                                    <MenuItem value="Hitam Putih">Hitam Putih</MenuItem>
                                                                    <MenuItem value="Kelabu">Kelabu</MenuItem>
                                                                    <MenuItem value="Kuning">Kuning</MenuItem>
                                                                    <MenuItem value="Perang">Perang</MenuItem>
                                                                    <MenuItem value="Putih">Putih</MenuItem>
                                                                    <MenuItem value="Lain-lain">Lain-lain</MenuItem>
                                                                </TextField>
                                                            )}
                                                            control={control}
                                                            name={`pet[${index}].color`}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='flex justify-between w-full lg:w-4/12'>
                                                    <div className="w-20 px-4 mb-0 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <>
                                                                    <div className='-mb-2 font-medium'>Mikrocip</div>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Checkbox checked={field.value} {...field} name="microchip" />
                                                                        }
                                                                        label="Ya"
                                                                    />
                                                                </>
                                                            )}
                                                            control={control}
                                                            name={`pet[${index}].microchip`}
                                                        />
                                                    </div>
                                                    <div className="w-20 px-4 mb-0 font-light">
                                                        <Controller
                                                            render={({ field }) => (
                                                                <>
                                                                    <div className='-mb-2 font-medium'>Mandul</div>
                                                                    <FormControlLabel
                                                                        control={
                                                                        <Checkbox checked={field.value} {...field} name="sterilization" />
                                                                        }
                                                                        label="Ya"
                                                                    />
                                                                </>
                                                            )}
                                                            control={control}
                                                            name={`pet[${index}].sterilization`}
                                                        />
                                                    </div>
                                                    <div className="w-10 pl-1 -mt-4 font-light flex items-center items-stretch">
                                                        {index > 0 ?
                                                        // <div 
                                                        //     className='inline-block align-middle text-red-500' 
                                                        //     style={{fontSize: '20px'}}
                                                        //     onClick={() => remove(index)}
                                                        // >
                                                        //     <i class="fas fa-times-circle"></i>
                                                        // </div>
                                                        <Button
                                                            type="button"
                                                            onClick={() => remove(index)}
                                                            size="sm"
                                                            color="red"
                                                            iconOnly
                                                            >
                                                            <i className="fa-solid fa-minus"></i>
                                                        </Button>
                                                        : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    <Button
                        color="gray"
                        size="lg"
                        onClick={back}
                    >
                        Kembali
                    </Button>
                    <Button
                        color="purple"
                        size="lg"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Seterusnya
                    </Button>
                </div>
            </div>
        </>
    )
}

const FormStepFour = ({ appNo, enable, update, data, back, page }) => {
    const [isLoading, setIsLoading] = useState(false)
    const userInfo = useRecoilValue(userInfoAtom);
    const validationSchema = Yup.object().shape({
        confirm: Yup.bool().oneOf([true], 'Ruangan Wajib Diisi')
    });
    const { handleSubmit, control, formState:{ errors } } = useForm({
        defaultValues: { confirm: false },
        resolver: yupResolver(validationSchema),
    });

    function onSubmit(data) {
        update('formFour', data);
        setIsLoading(true)
    }

    useEffect(() => {
        setIsLoading(false)
    }, [page])

    return (
        <>
            <div className={`${enable} flex flex-col w-full`}>
                <div className=''>
                    <Card>
                        {page ? '' : 
                        <CardHeader size='sm' color="purple" contentPosition="none">
                            <div className="w-full flex items-center justify-between">
                                <h2 className="text-white text-xl lg:text-2xl">Pengesahan</h2>
                                <span className='uppercase hidden lg:block'>Langkah 4</span>
                                {/* <Button
                                    color="transparent"
                                    buttonType="link"
                                    size="lg"
                                    style={{ padding: 0 }}
                                >
                                    Langkah 4
                                </Button> */}
                            </div>
                        </CardHeader>
                        }
                        <CardBody>
                            <div className='flex flex-row items-center'>
                                <h6 className="text-gray-500 text-sm uppercase mr-2">
                                    No. Permohonan :
                                </h6>
                                <h6 className="text-lg font-bold">
                                    {appNo}
                                </h6>
                            </div>
                            {page ? page :
                            <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h6 className="text-purple-500 text-sm mt-3 mb-6 font-light uppercase">
                                    Pengesahan Maklumat
                                </h6>
                                <div className="flex flex-wrap justify-center mt-1 mb-5">
                                    <div className="w-full lg:w-8/12 mb-10 font-light">
                                        <div className="flex flex-row">
                                            <div className="w-full lg:w-6/12 pr-4">
                                                <TextField
                                                    label="No. MyKad"
                                                    variant="standard"
                                                    size="small"
                                                    color="secondary"
                                                    value={userInfo?.isAdmin ? data.formOne.mykad : userInfo.userId}
                                                    fullWidth
                                                    readOnly
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pl-4">
                                                <TextField
                                                    label="Nama Pemohon"
                                                    variant="standard"
                                                    size="small"
                                                    color="secondary"
                                                    value={userInfo?.isAdmin ? data.formOne.name : userInfo.name}
                                                    fullWidth
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="w-full lg:w-8/12 mb-10 font-light">
                                        <Alert severity="info">Permohonan yang lengkap akan disemak dan diluluskan oleh MPKK dalam masa 3 hari bekerja.</Alert>
                                    </div> */}
                                    <div className="w-full lg:w-8/12 mb-0 font-light">
                                        <Controller
                                            render={({ field }) => (
                                                <FormControl
                                                    {...field}
                                                    // className="!-mt-2"
                                                    variant="outlined"
                                                    error={errors.confirm ? true : false}
                                                >
                                                    <FormControlLabel 
                                                        control={<Checkbox />} 
                                                        label="Dengan ini, saya mengakui bahawa segala maklumat adalah benar dan sahih. Pihak MBSP berhak membuat sebarang tindakan sekiranya didapati mempunyai maklumat palsu." 
                                                    />
                                                    <FormHelperText error>{errors?.confirm ? errors.confirm.message : ' '}</FormHelperText>
                                                </FormControl>
                                            )}
                                            control={control}
                                            name="confirm"
                                        />
                                    </div>
                                    {/* <div className="w-full lg:w-8/12 mb-10 font-light">
                                        <Alert severity="warning">Permohonan yang telah lulus perlu membuat bayaran dalam masa 7 hari untuk mengelakkan permohonan terbatal.</Alert>
                                    </div> */}
                                </div>
                            </form>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={isLoading}
                                // onClick={handleClose}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            </>
                            }
                        </CardBody>
                    </Card>
                </div>
                <div className='flex justify-between w-full py-5'>
                    {page ? '' : 
                        <>
                        <Button
                            color="gray"
                            size="lg"
                            onClick={back}
                        >
                            Kembali
                        </Button>
                        <Button
                            color="green"
                            size="lg"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {isLoading ?
                                <>
                                <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                <span className='ml-2'>Loading...</span>
                                </>
                            : 'Hantar'}
                            
                        </Button>
                        </>
                    }
                </div>
            </div>
        </>
    )
}