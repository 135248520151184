import React from 'react'
import DataTableCardTangkapLupus from 'components/DataTableCardTangkapLupus';
import CarianTangkapLulus from 'components/kacauganggu/CarianTangkapLulus';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

export default function TangkapLupus() {
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <>
      <div className="bg-teal-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        {userInfo?.isAdmin ?
        <div className="container mx-auto max-w-full">
          <div className="px-4 mb-16">
            <CarianTangkapLulus />
          </div>
        </div>
        : ''}
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <DataTableCardTangkapLupus />
          </div>
        </div>
      </div>
    </>
  )
}
