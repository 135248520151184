import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { toast } from 'react-toastify';

import * as api from "api/Admin";

// insert
const useTransaction = () => {

    return useMutation(newTransactionRequest, {
        onSuccess: (data) => {
            if(data.data.response.response_code == '200'){
                toast.success("Sah Bayaran Berjaya!", { 
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            } else if(data.data.response.response_code == '500'){
                toast.error("No. Resit telah wujud!", { 
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            } else {
                toast.error("Ralat!", { 
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000
                });
            }
        },
    });
};
const newTransactionRequest = async (data) => {
    return api.newTransactionRequest(data)
}

// list
const useLicenseList = () => {
    let history = useHistory();

    // return useQuery(['ApplicationList', data], () => applicationListRequest(data))

    return useMutation(licenseListRequest, {
        onSuccess: (data) => {
            // toast.success("Kemaskini Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });

            // history.reload()
        },
    });
};
const licenseListRequest = async (data) => {
    return api.licenseListRequest(data)
}

// view
const useLicenseView = () => {

    return useMutation(licenseViewRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const licenseViewRequest = async (data) => {
    return api.licenseViewRequest(data)
}

// update
const useApplicationStatusUpdate = () => {
    let history = useHistory();

    return useMutation(applicationStatusUpdateRequest, {
        onSuccess: (data) => {
            toast.success("Kemaskini Berjaya!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            // history.push('/lesen/senarai-permohonan')
            // history.go(0)
            // window.location.reload();
        },
    });
};
const applicationStatusUpdateRequest = async (data) => {
    return api.applicationStatusUpdateRequest(data)
}

const useGetTransaction = (bid) => {
    return useQuery(['GetTransaction'], () => getTransaction(bid), {
        cacheTime: 0,
        refetchOnWindowFocus: false
    });
};
const getTransaction = async (bid) => {
    return api.getTransaction(bid)
}

export { 
    useTransaction, 
    useGetTransaction,
    useLicenseList, 
    useLicenseView,
    useApplicationStatusUpdate
};