import React, { useState, useEffect } from 'react';
import { Button, Input } from '@material-tailwind/react';
import { DialogActions, DialogContent, Tooltip, MenuItem, TextField } from '@mui/material';
import CustomModal from './CustomModal';
import { useApplicationView } from 'query/actions/Crud';
import View from './ActionButton/View';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import Example from './Spinner/Example';
import { Spinner } from './Spinner';
import { useRoles } from 'query';
import { useRegisterStaff } from 'query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateStaff } from 'query';
import { useUpdateUser } from 'query';

export default function ButtonGroupActionPengguna({isAdmin, id, data, status, channel, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);

    const userInfo = useRecoilValue(userInfoAtom);
    
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const actionButton = (id, action) => {
        // let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
        // let data = {'id': id, 'user': accessToken}
        // if(action == 'Lihat'){
        //     setTitle('Butiran Permohonan')
        //     // getApplicationView(data)
        // }
        setSize('sm')
        setBackdropClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Kemaskini Pengguna"; break;
            case 2: titleModal = "Padam Pengguna"; break;
            // case 1: titleModal = "Butiran Permohonan"; setSize('lg'); setBackdropClose(true); break;
            // case 2: titleModal = "Status Permohonan"; break;
            // case 3: titleModal = "Status Permohonan"; break;
            // case 4: titleModal = "Status Permohonan"; break;
            // case 5: titleModal = "Pengesahan Bayaran"; break;
            // case 6: titleModal = "Cetak Bil"; setSize('md'); setBackdropClose(true); break;
            // case 7: titleModal = "Status Permohonan";
        }
        setTitle(titleModal)
        setPage(<UpdatePengguna closeModal={closeModal} data={data} type={action} setRefreshTable={setRefreshTable} />)
        setShowModal(true)
    }

    return (
        <>
        <div className='flex flex-wrap my-4'>
            <div className={`p-1`}>
                <Tooltip title="Kemaskini" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="orange"
                        iconOnly
                        onClick={() => actionButton(data.employee_id, 1)}
                    >
                        <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            <div className={`p-1`}>
                <Tooltip title="Padam" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="red"
                        iconOnly
                        onClick={() => actionButton(data.employee_id, 2)}
                    >
                        <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
        </div>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={size}
                title={title}
                content={page}
                backdropClose={backdropClose}
            /> : 
        ''}
        </>
    )
}

function UpdatePengguna({type, data, closeModal, setRefreshTable}){    
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useUpdateUser();
    // const roles = useRoles();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        name: Yup.string().min(4, "Nama mesti lebih dari 3 aksara").required(`Ruangan Wajib Diisi`),
        mykad: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. MyKad Tidak Sah')
                .min(12, "Minimum nombor adalah 12 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`),
        phone: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                .min(10, "Minimum nombor adalah 10 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues: {
            name: '',
            mykad: '',
            email: '',
            phone: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (dataSubmit) => {
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        dataSubmit['user_id'] = data.user_id
        await mutate(dataSubmit, {
            onSuccess: (data) => {
                Notify(data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        // setPage(
        //     <ResponseStatus 
        //         type={'success'} 
        //         close={null} 
        //         title={'Berjaya!'}
        //         subtitle={'Log masuk ke akaun anda sekarang.'}
        //         isButton={null}
        //     />
        // )
    }

    const passwordVisibility = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        reset({
            name: data.name,
            mykad: data.mykad,
            email: data.email,
            phone: data.phone
        })
    }, [data])

    function onStatusUpdate(type){
        let d = {
            status: type
        }
        onSubmit(d)
    }

    return (
        <>
        {type == 1 ? 
            isLoading ? <div className='flex justify-center items-center'><Example type='spin' color='black' height='5rem' width='5rem' /></div> :
            // page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-col w-full">
                    <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col my-1">
                            
                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Nama"
                                            error={errors.name ? errors.name.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="name"
                                />
                            </div>
                            
                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="No. MyKad"
                                            error={errors.mykad ? errors.mykad.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="mykad"
                                />
                            </div>

                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="email"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="Emel"
                                            error={errors.email ? errors.email.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="email"
                                />
                            </div>

                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="No. Telefon"
                                            error={errors.phone ? errors.phone.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="phone"
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="orange"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Kemaskini
                </Button>
            </DialogActions>
            </>
        : 
            <>
            <DialogContent>
                <div className="flex flex-col my-1">
                    <div className="w-full px-2 mb-6">
                        <h2 className="text-sm">Anda pasti untuk padam?</h2>
                        <div className="mt-4 mb-3">
                            <span className="pr-1 text-gray-500">Akaun Pengguna: </span>
                            <span className="font-bold text-sm">{data.email}</span>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {/* {isLoadingUpdate ? 
                    <>
                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                        <span className='ml-2'>Loading...</span>
                    </> :  */}
                    <>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>
                        
                        <Button
                            color="red"
                            onClick={() => onStatusUpdate(3)}
                            ripple="light"
                        >
                            Padam
                        </Button>
                    </>
                {/* } */}
            </DialogActions>
            </>
        }
        </>
        
    )
}