import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Skeleton from '@mui/material/Skeleton';
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useApplicationView, useApplicationStatusUpdate } from "query/actions/Crud";
import { useLicenseView } from "query/actions/license/Crud";
import { TextField, MenuItem, FormControlLabel, Checkbox } from "@mui/material";
import Example from "components/Spinner/Example";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
// toastify
import { toast } from 'react-toastify';
import { useOperationByType } from 'query/actions/tangkaplupus/Crud';
import { useEditOperationByType } from 'query/actions/tangkaplupus/Crud';
import { useDeleteOperationByType } from "query/actions/tangkaplupus/Crud";

export default function ViewKacauGanggu({closeModal, id, data, action, type, setRefreshTable, setTitle, setBackdropClose, setHideButtonClose}){  
    const userInfo = useRecoilValue(userInfoAtom);
    const [parlimenValue, setParlimenValue] = useState('');
    const {data: dataOperation, isLoading} = useOperationByType(data?.jenis);
    const {mutate} = useEditOperationByType();
    const {mutate: mutateDelete} = useDeleteOperationByType();

    const validationSchema = Yup.object().shape({
        kod: Yup.string().required(`Ruangan Wajib Diisi`),
        kaedah: Yup.string().required(`Ruangan Wajib Diisi`),
        haiwan: Yup.string().required(`Ruangan Wajib Diisi`),
        bilangan: Yup.string().required(`Ruangan Wajib Diisi`),
        tarikh: Yup.string().required(`Ruangan Wajib Diisi`),
        masa: Yup.string().required(`Ruangan Wajib Diisi`),
        alamat_p1: Yup.string().required(`Ruangan Wajib Diisi`),
        poskod: Yup.string().required(`Ruangan Wajib Diisi`),
        bandar: Yup.string().required(`Ruangan Wajib Diisi`),
        daerah: Yup.string().required(`Ruangan Wajib Diisi`),
        dun: Yup.string().required(`Ruangan Wajib Diisi`),
        parlimen: Yup.string().required(`Ruangan Wajib Diisi`),
        tindakan: Yup.string().required(`Ruangan Wajib Diisi`)
    });
    const defaultValues = {
        kod: '',
        kaedah: '',
        haiwan: '',
        bilangan: '',
        tarikh: '',
        masa: '',
        alamat_p1: '',
        poskod: '',
        bandar: '',
        daerah: '',
        dun: '',
        parlimen: '',
        tindakan: ''
    }
    const { handleSubmit, control, formState:{ errors }, setValue, reset, resetField } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (dataSubmit) => {
        dataSubmit['id'] = id;
        dataSubmit['jenis'] = data?.jenis;
        dataSubmit['updated_by'] = userInfo?.staffId;
        console.log('datadata',dataSubmit)
        // // logIn(data)
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(dataSubmit, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Kemaskini Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }

    const resetFieldValue = (field, value) => {
        resetField(field)
        setValue(field, value)
    }
    useEffect(() => {
        if(parlimenValue != ''){
            if(parlimenValue == 'N1' || parlimenValue == 'N2' || parlimenValue == 'N3'){
                resetFieldValue('parlimen', "P41 - Kepala Batas")
            } else if(parlimenValue == 'N4' || parlimenValue == 'N5' || parlimenValue == 'N6'){
                resetFieldValue('parlimen', "P42 - Tasek Gelugor")
            } else if(parlimenValue == 'N7' || parlimenValue == 'N8' || parlimenValue == 'N9'){
                resetFieldValue('parlimen', "P43 - Bagan")
            } else if(parlimenValue == 'N10' || parlimenValue == 'N11' || parlimenValue == 'N12'){
                resetFieldValue('parlimen', "P44 - Permatang Pauh")
            } else if(parlimenValue == 'N13' || parlimenValue == 'N14' || parlimenValue == 'N15'){
                resetFieldValue('parlimen', "P45 - Bukit Mertajam")
            } else if(parlimenValue == 'N16' || parlimenValue == 'N17' || parlimenValue == 'N18'){
                resetFieldValue('parlimen', "P46 - Batu Kawan")
            } else if(parlimenValue == 'N19' || parlimenValue == 'N20' || parlimenValue == 'N21'){
                resetFieldValue('parlimen', "P47 - Nibong Tebal")
            } 
        }
    }, [parlimenValue])

    useEffect(() => {
        if(action==2){
            reset({
                kod: data?.kod,
                aduan: data?.aduan,
                kaedah: data?.kaedah,
                haiwan: data?.haiwan,
                bilangan: data?.bilangan,
                tarikh: moment(data?.tarikh).format('YYYY-MM-DD'),
                masa: data?.masa,
                alamat_p1: data?.alamat_p1,
                poskod: data?.poskod,
                bandar: data?.bandar,
                daerah: data?.daerah,
                dun: data?.dun,
                parlimen: data?.parlimen,
                tindakan: data?.tindakan,
                nota: data?.nota
            });
        }
    }, [action])

    const [alasan, setAlasan] = useState('');
    const [alertAlasan, setAlertAlasan] = useState(false);
    const handleChange = (event) => {
        setAlasan(event.target.value);
        if(event.target.value != '' || event.target.value != null){
            setAlertAlasan(false)
        }
    };

    async function onDelete(){
        if(alasan == '' || alasan == undefined){
            setAlertAlasan(true)
        } else {
            let data = {'id': id, 'deleted_reason': alasan, 'deleted_by': userInfo?.staffId}
            await mutateDelete(data, {
                onSuccess: (data) => {
                    if(data.data.response.response_code == '200'){
                        toast.success('Batal Berjaya!', { 
                            isLoading: false,
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000
                        });
                        setRefreshTable(true)
                        closeModal()
                    } else if(data.data.response.response_code == '201'){
                        toast.error('Ralat!', { 
                            isLoading: false,
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000
                        });
                    }
                }
            })
        }
    }

    // return
    // 1-application 2-license
    if(type==1){
        if(action==1){
            return (
                <>
                {/* <div className="lg:w-4xl md:w-2xl"> */}
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 /mb-6">
                                <div className='flex flex-col /mt-4 /mb-1'>
                                        <div className='flex flex-col /mb-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg /pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500 /mb-3">Butiran Operasi</div>
                                            <div className="flex flex-col rounded-xl shadow-lg border border-gray-100 p-2 px-4">
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Operasi</div>
                                                    <div className="w-1/2 ">{data?.kod+' - '+data?.nama}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Aduan</div>
                                                    <div className="w-1/2 ">{data?.aduan}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Kaedah Tangkapan</div>
                                                    <div className="w-1/2 ">{data?.kaedah}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Jenis Haiwan</div>
                                                    <div className="w-1/2 ">{data?.haiwan}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Bil. Tangkapan</div>
                                                    <div className="w-1/2 ">{data?.bilangan}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Tarikh</div>
                                                    <div className="w-1/2 ">{data?.tarikh}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Masa</div>
                                                    <div className="w-1/2 ">{data?.masa}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col mt-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg /pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500">Info Lokasi</div>
                                            <div className="flex flex-col rounded-xl shadow-lg border border-gray-100 p-2 px-4">
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Alamat Lokasi</div>
                                                    <div className="w-1/2 ">{data?.alamat_p1+', '+data?.poskod+' '+data?.bandar}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Daerah</div>
                                                    <div className="w-1/2 ">{data?.daerah}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">DUN</div>
                                                    <div className="w-1/2 ">{data?.dun}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Parlimen</div>
                                                    <div className="w-1/2 ">{data?.parlimen}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col mt-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg /pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500">Maklumat Tindakan</div>
                                            <div className="flex flex-col rounded-xl shadow-lg border border-gray-100 p-2 px-4">
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Tindakan</div>
                                                    <div className="w-1/2 ">{data?.tindakan}</div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <div className="w-1/2 font-bold">Nota</div>
                                                    <div className="w-1/2 ">{data?.nota}</div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>

                        {/* {userInfo?.isAdmin && info?.status!='2' ?
                        <Button
                            color="green"
                            // onClick={handleSubmit(onSubmit)}
                            ripple="light"
                        >
                            Simpan
                        </Button>
                        : ''
                        } */}
                    </DialogActions>

                {/* </div> */}
                </>
            )

        } else if(action==2){
            return (
                <>
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 /mb-6">
                                <div className='flex flex-col /mt-4 /mb-1'>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='flex flex-wrap /mb-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg /pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500 mb-3">Butiran Operasi</div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Operasi"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.kod ? errors.kod.message : ''}
                                                            error={errors.kod ? errors.kod : false}
                                                            fullWidth
                                                        >
                                                            {dataOperation?.data.map((op, key) => (
                                                                <MenuItem key={key} value={op.kod}>{op.kod+' - '+op.nama}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                    control={control}
                                                    name="kod"
                                                />
                                            </div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Aduan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth
                                                            multiline
                                                            maxRows={3}
                                                        />
                                                    )}
                                                    control={control}
                                                    name="aduan"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        data?.jenis=='Penangkapan' ?
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Kaedah Tangkapan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.kaedah ? errors.kaedah.message : ''}
                                                            error={errors.kaedah ? errors.kaedah : false}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="Perangkap">Perangkap</MenuItem>
                                                            <MenuItem value="Lasso">Lasso</MenuItem>
                                                            <MenuItem value="PSG">PSG</MenuItem>
                                                            <MenuItem value="Jaring">Jaring</MenuItem>
                                                        </TextField>
                                                        :
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Kaedah Tangkapan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.kaedah ? errors.kaedah.message : ''}
                                                            error={errors.kaedah ? errors.kaedah : false}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="Tembak">Tembak</MenuItem>
                                                            <MenuItem value="Perangkap">Perangkap</MenuItem>
                                                            <MenuItem value="Racun">Racun</MenuItem>
                                                            <MenuItem value="Musnah Sarang Gagak">Musnah Sarang Gagak</MenuItem>
                                                            <MenuItem value="Lasso">Lasso</MenuItem>
                                                            <MenuItem value="PSG">PSG</MenuItem>
                                                            <MenuItem value="Jaring">Jaring</MenuItem>
                                                        </TextField>
                                                    )}
                                                    control={control}
                                                    name="kaedah"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pl-1 /pr-1  mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Jenis Haiwan"
                                                            placeholder="Pilih jenis haiwan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.haiwan ? errors.haiwan.message : ''}
                                                            error={errors.haiwan ? errors.haiwan : false}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="Anjing Liar">Anjing Liar</MenuItem>
                                                            <MenuItem value="Kucing Liar">Kucing Liar</MenuItem>
                                                            <MenuItem value="Burung Merpati">Burung Merpati</MenuItem>
                                                            <MenuItem value="Burung Gagak">Burung Gagak</MenuItem>
                                                            <MenuItem value="Lembu">Lembu</MenuItem>
                                                            <MenuItem value="Kambing">Kambing</MenuItem>
                                                            <MenuItem value="Kerbau">Kerbau</MenuItem>
                                                        </TextField>
                                                    )}
                                                    control={control}
                                                    name="haiwan"
                                                />
                                            </div>
                                            <div className="w-full lg:w-4/12 pr-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Bil. Tangkapan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.bilangan ? errors.bilangan.message : ''}
                                                            error={errors.bilangan ? errors.bilangan : false}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="bilangan"
                                                />
                                            </div>
                                            <div className="w-full lg:w-4/12 pl-1 pr-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="date"
                                                            label="Tarikh"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.tarikh ? errors.tarikh.message : ''}
                                                            error={errors.tarikh ? errors.tarikh : false}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="tarikh"
                                                />
                                            </div>
                                            <div className="w-full lg:w-4/12 pl-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="time"
                                                            label="Masa"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.masa ? errors.masa.message : ''}
                                                            error={errors.masa ? errors.masa : false}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="masa"
                                                />
                                            </div>
                                        </div>
                                        {/* <hr /> */}
                                        <div className='flex flex-wrap /mb-5 w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500 mb-3">Info Lokasi</div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Alamat Lokasi"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.alamat_p1 ? errors.alamat_p1.message : ''}
                                                            error={errors.alamat_p1 ? errors.alamat_p1 : false}
                                                            fullWidth
                                                            multiline
                                                            maxRows={3}
                                                        />
                                                    )}
                                                    control={control}
                                                    name="alamat_p1"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Poskod"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.poskod ? errors.poskod.message : ''}
                                                            error={errors.poskod ? errors.poskod : false}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="poskod"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pl-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Bandar"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.bandar ? errors.bandar.message : ''}
                                                            error={errors.bandar ? errors.bandar : false}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="bandar"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pr-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Daerah"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.daerah ? errors.daerah.message : ''}
                                                            error={errors.daerah ? errors.daerah : false}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="SPU">SPU - Seberang Perai Utara</MenuItem>
                                                            <MenuItem value="SPT">SPT - Seberang Perai Tengah</MenuItem>
                                                            <MenuItem value="SPS">SPS - Seberang Perai Selatan</MenuItem>
                                                        </TextField>
                                                    )}
                                                    control={control}
                                                    name="daerah"
                                                />
                                            </div>
                                            <div className="w-full lg:w-6/12 pl-1 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="DUN"
                                                            variant="outlined"
                                                            size="small"
                                                            onChange={(event) => {
                                                                setParlimenValue(event.target.value);
                                                                resetFieldValue('dun', event.target.value)
                                                            }}
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.dun ? errors.dun.message : ''}
                                                            error={errors.dun ? errors.dun : false}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="N1">N1 - Penaga</MenuItem>
                                                            <MenuItem value="N2">N2 - Bertam</MenuItem>
                                                            <MenuItem value="N3">N3 - Pinang Tunggal</MenuItem>
                                                            <MenuItem value="N4">N4 - Permatang Berangan</MenuItem>
                                                            <MenuItem value="N5">N5 - Sungai Dua</MenuItem>
                                                            <MenuItem value="N6">N6 - Telok Ayer Tawar</MenuItem>
                                                            <MenuItem value="N7">N7 - Sungai Puyu</MenuItem>
                                                            <MenuItem value="N8">N8 - Bagan Jermal</MenuItem>
                                                            <MenuItem value="N9">N9 - Bagan Dalam</MenuItem>
                                                            <MenuItem value="N10">N10 - Seberang Jaya</MenuItem>
                                                            <MenuItem value="N11">N11 - Permatang Pasir</MenuItem>
                                                            <MenuItem value="N12">N12 - Penanti</MenuItem>
                                                            <MenuItem value="N13">N13 - Berapit</MenuItem>
                                                            <MenuItem value="N14">N14 - Machang Bubuk</MenuItem>
                                                            <MenuItem value="N15">N15 - Padang Lalang</MenuItem>
                                                            <MenuItem value="N16">N16 - Perai</MenuItem>
                                                            <MenuItem value="N17">N17 - Bukit Tengah</MenuItem>
                                                            <MenuItem value="N18">N18 - Bukit Tambun</MenuItem>
                                                            <MenuItem value="N19">N19 - Jawi</MenuItem>
                                                            <MenuItem value="N20">N20 - Sungai Bakap</MenuItem>
                                                            <MenuItem value="N21">N21 - Sungai Acheh</MenuItem>
                                                        </TextField>
                                                    )}
                                                    control={control}
                                                    name="dun"
                                                />
                                            </div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Parlimen"
                                                            variant="standard"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.parlimen ? errors.parlimen.message : ''}
                                                            error={errors.parlimen ? errors.parlimen : false}
                                                            fullWidth
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }
                                                        />
                                                    )}
                                                    control={control}
                                                    name="parlimen"
                                                />
                                            </div>
                                        </div>
                                        {/* <hr /> */}
                                        <div className='flex flex-wrap w-full bg-white rounded-xl /border /border-gray-100 /overflow-hdden /shadow-lg pt-2 pb-1 /px-4 /mt-4'>
                                            <div className="w-full text-gray-500 mb-3">Maklumat Tindakan</div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            select
                                                            label="Tindakan"
                                                            variant="outlined"
                                                            size="small"
                                                            InputLabelProps={{ shrink: true }}
                                                            helperText={errors.tindakan ? errors.tindakan.message : ''}
                                                            error={errors.tindakan ? errors.tindakan : false}
                                                            fullWidth
                                                            >
                                                                <MenuItem value="Hantar ke SPCA">Hantar ke SPCA</MenuItem>
                                                                <MenuItem value="Hantar ke Paun">Hantar ke Paun</MenuItem>
                                                                <MenuItem value="Hantar ke JPV">Hantar ke JPV</MenuItem>
                                                                <MenuItem value="Musnah">Musnah</MenuItem>
                                                                <MenuItem value="Lepas">Lepas</MenuItem>
                                                            </TextField>
                                                    )}
                                                    control={control}
                                                    name="tindakan"
                                                />
                                            </div>
                                            <div className="w-full lg:w-12/12 mb-5 font-light">
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            type="text"
                                                            label="Nota"
                                                            variant="outlined"
                                                            size="small"
                                                            multiline
                                                            maxRows={3}
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth
                                                        />
                                                    )}
                                                    control={control}
                                                    name="nota"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <>
                            <Button 
                                color="gray"
                                buttonType="link"
                                onClick={(e) => closeModal()}
                                ripple="dark"
                            >
                                Kembali
                            </Button>
                            <Button
                                color="orange"
                                onClick={handleSubmit(onSubmit)}
                                ripple="light"
                            >
                                Kemaskini
                            </Button>
                        </>
                    </DialogActions>
                </>
            )
        } else if(action==3){
            return (
                <>
                {/* <div className="lg:w-2xl md:w-lg"> */}
                    <DialogContent>
                        <div className="flex flex-col my-1">
                            <div className="w-full px-2 mb-6">
                                <h2 className="text-sm">Anda pasti untuk batal data ini?</h2>
                                <div className="mt-4 mb-3">
                                    <span className="pr-1 text-gray-500">Kod & Nama Operasi: </span>
                                    <span className="font-bold /text-sm">{data?.kod+' '+data?.nama}</span>
                                </div>
                                <div className="mt-3 flex flex-col">
                                    <span className="pr-1 text-gray-500">Alasan/Sebab:</span>
                                    <TextField
                                        id="outlined-name"
                                        size="small"
                                        multiline
                                        rows={3}
                                        // label="Alasan/Sebab"
                                        value={alasan}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        error={alertAlasan}
                                        helperText={alertAlasan ? 'Ruangan Wajib Diisi' : ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>
                        <Button
                            color="red"
                            onClick={onDelete}
                            ripple="light"
                        >
                            Batal
                        </Button>
                    </DialogActions>
                {/* </div> */}
                </>
            )
        }
    }
}