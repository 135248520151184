import axios from 'axios';
import {decode as atob, encode as btoa} from 'base-64'

const client_id = "vrsyM6Ob2P6IT1pO1IKPbCwuh3nePXBBzSqiPgXt";
const prod = true;

const userDetail = JSON.parse(sessionStorage.getItem('userInfo'))
// console.log('userDetailuserDetail',userDetail)

const refetch = () => {
    return JSON.parse(sessionStorage.getItem('userInfo'))
}

export const header = (type) => {
    if(type === 'multipart'){
        return {
            headers: {
                // Authorization: 'Bearer ' + getToken(),
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                // Token: getToken(),
                crossDomain: true,
                'Access-Control-Allow-Origin': '*',
            },
        }
    }

    return {
        headers: {
            // Authorization: 'Bearer ' + getToken(),
            // Token: getToken(),
            crossDomain: true,
            'Access-Control-Allow-Origin': '*',
        },
        timeout: 20000,
    }
}

export const apiUrl = (role) => {
    if (!prod) {
        return `http://localhost/mpkk/api/`;
    } else {
        return `https://spmh.mbsp.gov.my/apps/user/api/`;
    }
};
export const baseUrl = () => {
    if (!prod) {
        return `http://localhost/mpkk/`;
    } else {
        return `https://spmh.mbsp.gov.my/apps/`;
    }
};

/* api */
export const ldapConnect = async (credential) => {
    return await axios.post(
        apiUrl() + `ldap_connect`,
        {
            email: credential?.email,
            password: credential?.password
        },
        header()
    );
}

export const oauthLogin = async (credential) => {
    return await axios.get(
        apiUrl() + `oauth`,
        header()
    );
}

export const verifyLogin = async (credential) => {
    return await axios.post(
        apiUrl() + `verify_login`,
        {
            email: credential
        },
        header()
    );
}

export const getDashboard = async () => {
    return await axios.get(
        apiUrl('user') + `dashboard?user_id=${userDetail.userId}`,
        header()
    );
}
export const getUtama = async () => {
    let accessToken = null;
    if(userDetail != null){
        accessToken = userDetail
    } else {
        accessToken = refetch()
    }

    if(accessToken != null || userDetail.length > 0){
        return await axios.get(
            apiUrl() + `getUtama?user=${accessToken.accessToken}`,
            header()
        );
    }
}

export const loginRequest = async (credential) => {
    return await axios.post(
        apiUrl('user') + `login`,
        {
            user_id: credential?.id,
            password: credential?.password
        },
        header()
    );
}

export const forgotPassword = async (data) => {
    return await axios.post(
        apiUrl() + `forgot_password`,
        {
            mykad: data
        },
        header()
    );
}

export const logoutRequest = async () => {
    return await axios.get(
        apiUrl('user') + `logout`,
        header()
    );
}

export const registerRequest = async (data) => {
    return await axios.post(
        apiUrl() + `register`,
        {
            mykad: data.mykad,
            name: data.name,
            email: data.email,
            phone: data.phone,
            // access_token: data.access_token,
            // refresh_token: data.refresh_token,
            password: data.password,
        },
        header()
    );
}

export const newApplicationRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newApplication`,
        {
            accessToken: userDetail.accessToken,
            channel: userDetail?.isAdmin ? 2 : 1,
            formOne: data.formOne,
            formTwo: data.formTwo,
            formThree: data.formThree,
            formFour: data.formFour,
        },
        header()
    );
}

export const editApplicationRequest = async (data) => {
    return await axios.post(
        apiUrl() + `editApplication`,
        {
            applicationId: data.applicationId,
            accessToken: userDetail.accessToken,
            channel: userDetail?.isAdmin ? 2 : 1,
            formOne: data.formOne,
            formTwo: data.formTwo,
            formThree: data.formThree,
            formFour: data.formFour,
        },
        header()
    );
}

export const applicationListRequest = async (data) => {
    let addUrl = '', searchUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    if(data.search != '' && data.search != null){
        searchUrl = `&search=${btoa(JSON.stringify(data.search))}`
    }
    return await axios.get(
        apiUrl() + `applicationList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}${searchUrl}`,
        header()
    );
}

export const applicationViewRequest = async (data) => {
    return await axios.get(
        apiUrl() + `applicationView?id=${data.id}`,
        header()
    );
}

export const applicationStatusUpdateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `applicationStatusUpdate`,
        {
            accessToken: userDetail.accessToken,
            application_id: data.id,
            status: data.status,
            reason: data.reason,
        },
        header()
    );
}

export const licenseListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `licenseList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const licenseViewRequest = async (data) => {
    return await axios.get(
        apiUrl() + `licenseView?id=${data.id}`,
        header()
    );
}

export const licenseStatusUpdateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `licenseStatusUpdate`,
        {
            accessToken: userDetail.accessToken,
            license_id: data.id,
            status: data.status,
            reason: data.reason,
        },
        header()
    );
}

export const newTransactionRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newTransaction`,
        {
            transaction_id: data.receipt,
            license_id: data.id,
            receipt_no: data.receipt,
            channel: userDetail?.isAdmin ? 2 : 1,
            status: 'paid',
            created_by: userDetail?.isAdmin ? userDetail.staff_id : userDetail.accessToken,
        },
        header()
    );
}

export const getPetList = async (data) => {
    const res = await axios.get(
        apiUrl() + `petList?id=${data}`,
        header()
    );
    return res.data.data;
}

export const newPetPlateRequest = async (data) => {
    return await axios.post(
        apiUrl() + `newPetPlate`,
        {
            accessToken: userDetail.accessToken,
            license_id: data.id,
            plates: data.plates,
        },
        header()
    );
}

export const employeeListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `employeeList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const getRoles = async () => {
    const res = await axios.get(
        apiUrl() + `roles`,
        header()
    );
    return res.data.data;
}

export const registerStaffRequest = async (data) => {
    return await axios.post(
        apiUrl() + `register_staff`,
        {
            staff_id: data.id,
            name: data.name,
            email: data.email,
            department: data.department,
            role_id: data.role
        },
        header()
    );
}

export const roleListRequest = async (data) => {
    let addUrl = '', page = 0, perPage = 5;
    if(data.user != ''){
        addUrl = `&user=${data.user}`
    }
    return await axios.get(
        apiUrl() + `roleList?page=${data.page ? data.page : page}&per_page=${data.perPage ? data.perPage : perPage}${addUrl}`,
        header()
    );
}

export const registerRoleRequest = async (data) => {
    return await axios.post(
        apiUrl() + `register_role`,
        {
            role_name: data.name,
            role_resources: data.role
        },
        header()
    );
}

export const payment = async (data) => {
    return await axios.post(
        apiUrl() + `payment`,
        {
            data
        },
        header()
    );
}

export const profile = async () => {
    const res = await axios.post(
        apiUrl() + `profile`,
        {
            access_token: userDetail?.accessToken
        },
        header()
    );
    return res.data.data;
}

export const updateProfile = async (data) => {
    const res = await axios.post(
        apiUrl() + `updateProfile`,
        {
            data
        },
        header()
    );
    return res.data;
}

export const changePassword = async (data) => {
    const res = await axios.post(
        apiUrl() + `changePassword`,
        {
            data
        },
        header()
    );
    return res.data;
}