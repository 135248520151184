import React, { useState, useEffect } from 'react';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import { useApplicationView } from 'query/actions/Crud';
import View from './ActionButton/View';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useHistory } from 'react-router-dom';

export default function ButtonGroupAction({isAdmin, id, row, status, channel, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);
    const [hideButtonClose, setHideButtonClose] = useState(false);

    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }
    
    // view
    const getApplicationView = async (data) => {
        await mutateView(data, {
            onSuccess: (data) => {
                setResults(data.data.data)
            }
        })
    }

    let history = useHistory();
    function onHandlerRedirect(){
        if(userInfo?.isAdmin){
            history.push(`/v2/lesen/permohonan-kemaskini/${id}`)
        } else {
            history.push(`/v2/permohonan/kemaskini/${id}`)
        }
    }

    const actionButton = (id, action) => {
        // let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
        // let data = {'id': id, 'user': accessToken}
        // if(action == 'Lihat'){
        //     setTitle('Butiran Permohonan')
        //     // getApplicationView(data)
        // }
        setSize('sm')
        setBackdropClose(false)
        setHideButtonClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Butiran Permohonan"; setSize('lg'); setBackdropClose(true); break;
            case 2: titleModal = "Status Permohonan"; break;
            case 3: titleModal = "Status Permohonan"; break;
            case 4: titleModal = "Status Permohonan"; break;
            case 5: titleModal = "Pengesahan Bayaran"; setHideButtonClose(true); break;
            case 6: titleModal = "Cetak Bil"; setSize('md'); setBackdropClose(true); break;
            case 7: titleModal = "Status Permohonan";
            case 8: titleModal = "Bayaran"; setBackdropClose(false); break;
            case 9: titleModal = "Kemaskini Permohonan"; onHandlerRedirect(); break;
        }
        setTitle(titleModal)
        setPage(<View 
            closeModal={closeModal} 
            id={id} 
            action={action} 
            type={1} 
            setRefreshTable={setRefreshTable} 
            setTitle={setTitle}
        />)
        setShowModal(true)
    }

    return (
        <>
        {isAdmin ? 
            <div className='flex flex-wrap my-4'>
                <div className={`p-1`}>
                    <Tooltip title="Lihat" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="indigo"
                            iconOnly
                            onClick={() => actionButton(id, 1)}
                        >
                            <i className="fa-solid fa-eye" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* {channel=='1' ?
                <>
                <div className={`p-1 ${status == '2' ? 'hidden' : ''}`}>
                    <Tooltip title="Lulus" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="green"
                            iconOnly
                            onClick={() => actionButton(id, 2)}
                        >
                            <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '2' ? 'hidden' : ''}`}>
                    <Tooltip title="Tidak Lengkap" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                            onClick={() => actionButton(id, 4)}
                        >
                            <i className="fa-solid fa-clipboard-question" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                </>
                : 
                <> */}
                <div className={`p-1 ${status == '1' ? '' : 'hidden'}`}>
                {/* <div className={`p-1 ${status == '1' || status == '2' || row.status == '4' ? 'hidden' : ''}`}> */}
                    <Tooltip title="Pengesahan Bayaran" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="teal"
                            iconOnly
                            onClick={() => actionButton(id, 5)}
                        >
                            <i className="fa-solid fa-file-invoice-dollar" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* </>
                } */}
                <div className={`p-1 ${status == '1' ? '' : 'hidden'}`}>
                {/* <div className={`p-1 ${status == '1' || status == '2' || row.status == '4' ? 'hidden' : ''}`}> */}
                    <Tooltip title="Cetak Bil" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="blue"
                            iconOnly
                            onClick={() => actionButton(id, 6)}
                        >
                            <i className="fa-solid fa-print" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${row.status == '4' ? 'hidden' : ''}`}>
                {/* <div className={`p-1`}> */}
                    <Tooltip title="Batal" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="red"
                            iconOnly
                            onClick={() => actionButton(id, 3)}
                        >
                            <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>
        : 
            <div className='flex flex-wrap my-4'>
                <div className='p-1'>
                    <Tooltip title="Lihat" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="indigo"
                            iconOnly
                            onClick={() => actionButton(id, 1)}
                        >
                            <i className="fa-solid fa-eye" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '2' || row.status == '4' ? 'hidden' : ''}`}>
                    <Tooltip title="Bayar" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="green"
                            iconOnly
                            onClick={() => actionButton(id, 8)}
                        >
                            <i className="fa-solid fa-credit-card" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '2' || row.status == '4' ? 'hidden' : ''}`}>
                    <Tooltip title="Cetak Bil" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="blue"
                            iconOnly
                            onClick={() => actionButton(id, 6)}
                        >
                            <i className="fa-solid fa-print" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '2' || row.status == '4' ? 'hidden' : ''}`}>
                    <Tooltip title="Kemaskini" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                            onClick={() => actionButton(id, 9)}
                        >
                            <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> 
                {/* <div className={`p-1 ${status == '3' ? '' : 'hidden'}`}>
                    <Tooltip title="Mohon Semula" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="blue"
                            iconOnly
                        >
                            <i className="fa-solid fa-arrow-rotate-right" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '4' ? '' : 'hidden'}`}>
                    <Tooltip title="Kemaskini" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                        >
                            <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> */}
            </div>
        }

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={size}
                title={title}
                content={page}
                backdropClose={backdropClose}
                hideButtonClose={hideButtonClose}
            /> : 
        ''}
        </>
    )
}