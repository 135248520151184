import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function CarianPermohonan({setSearch, setPage}) {
    const [toggle, setToggle] = useState(false);
    const [toggleName, setToggleName] = useState('Buka');

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    const validationSchema = Yup.object().shape({
        permohonan: Yup.string().when("permohonan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(10, "Minimum 10 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        mykad: Yup.string().when("mykad", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(12, "Minimum 12 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        nama: Yup.string().when("nama", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        status: Yup.string().when("bayaran", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        saluran: Yup.string().when("saluran", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        tarikh: Yup.string().when("tarikh", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
    }, [
        ["permohonan", "permohonan"],
        ["mykad", "mykad"],
        ["nama", "nama"],
        ["bayaran", "bayaran"],
        ["saluran", "saluran"],
        ["tarikh", "tarikh"],
    ]);
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, setValue } = useForm({
        defaultValues: {
            permohonan: '',
            mykad: '',
            nama: '',
            bayaran: '',
            saluran: '',
            tarikh: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        console.log('datadata',data)
        setSearch(data)
        setPage(0)
    }

    const onResetForm = () => {
        reset()
        setSearch(null)
        setPage(0)
    }

    return (
        <>
            {/* <CardHeader className='mx-4 z-50' size='sm' color='teal' contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Butiran Pemohon</h2>
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </CardHeader> */}

            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-wrap mt-2'>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. Permohonan" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.permohonan ? errors.permohonan.message : ''}
                                                error={errors.permohonan ? errors.permohonan : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="permohonan"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="No. MyKad" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.mykad ? errors.mykad.message : ''}
                                                error={errors.mykad ? errors.mykad : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="mykad"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Nama Pemilik" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.nama ? errors.nama.message : ''}
                                                error={errors.nama ? errors.nama : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="nama"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Bayaran" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.bayaran ? errors.bayaran.message : ''}
                                                error={errors.bayaran ? errors.bayaran : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'1'}>{'Belum Bayar'}</MenuItem>
                                                <MenuItem value={'2'}>{'Telah Bayar'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="bayaran"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Saluran" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.saluran ? errors.saluran.message : ''}
                                                error={errors.saluran ? errors.saluran : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'1'}>{'Online'}</MenuItem>
                                                <MenuItem value={'2'}>{'Kaunter'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="saluran"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='date'
                                                size='small' 
                                                label="Tarikh" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.tarikh ? errors.tarikh.message : ''}
                                                error={errors.tarikh ? errors.tarikh : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="tarikh"
                                    />
                                </div>
                                <div className='flex justify-center w-full p-2'>
                                    <Button 
                                        color="gray"
                                        buttonType="link"
                                        onClick={onResetForm}
                                        ripple="dark"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="blue"
                                        // onClick={() => onStatusUpdate(action)}
                                        ripple="light"
                                        type="submit"
                                    >
                                        Cari
                                    </Button>
                                </div>
                            </div>
                        </form>
                        {/* <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 mb-0 mt-6">
                            <div className='pr-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option>United States</option>
                                    <option>Canada</option>
                                    <option>France</option>
                                    <option>Germany</option>
                                </select>
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='pl-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 mb-4 mt-3">
                            <div className='pr-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='pl-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option>United States</option>
                                    <option>Canada</option>
                                    <option>France</option>
                                    <option>Germany</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}