import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { toast } from 'react-toastify';

import * as api from "api/Api";
import * as admin from "api/Admin";

const userDetail = JSON.parse(sessionStorage.getItem('userInfo'))

// insert
const useNewApplication = () => {

    return useMutation(newApplicationRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const newApplicationRequest = async (data) => {
    // if(userDetail?.isAdmin){
    //     return admin.newApplicationRequest(data)
    // } else {
        return api.newApplicationRequest(data)
    // }
}

// edit
const useEditApplication = () => {

    return useMutation(editApplicationRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const editApplicationRequest = async (data) => {
    // if(userDetail?.isAdmin){
    //     return admin.editApplicationRequest(data)
    // } else {
        return api.editApplicationRequest(data)
    // }
}

// list
const useApplicationList = () => {
    let history = useHistory();

    // return useQuery(['ApplicationList', data], () => applicationListRequest(data))

    return useMutation(applicationListRequest, {
        onSuccess: (data) => {
            // toast.success("Kemaskini Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });

            // history.reload()
        },
    });
};
const applicationListRequest = async (data) => {
    return api.applicationListRequest(data)
}

// view
const useApplicationView = () => {

    return useMutation(applicationViewRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const applicationViewRequest = async (data) => {
    return api.applicationViewRequest(data)
}

// update
const useApplicationStatusUpdate = () => {
    let history = useHistory();

    return useMutation(applicationStatusUpdateRequest, {
        onSuccess: (data) => {
            toast.success("Kemaskini Berjaya!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            // history.push('/lesen/senarai-permohonan')
            // history.go(0)
            // window.location.reload();
        },
    });
};
const applicationStatusUpdateRequest = async (data) => {
    return api.applicationStatusUpdateRequest(data)
}

// update
const useLicenseStatusUpdate = () => {
    let history = useHistory();

    return useMutation(licenseStatusUpdateRequest, {
        onSuccess: (data) => {
            toast.success("Kemaskini Berjaya!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            // history.push('/lesen/senarai-permohonan')
            // history.go(0)
            // window.location.reload();
        },
    });
};
const licenseStatusUpdateRequest = async (data) => {
    return api.licenseStatusUpdateRequest(data)
}

const useCourierInfo = () => {
    return useQuery(['courierInfo'], () => courierInfo(), {
        cacheTime: 0,
        refetchOnWindowFocus: false
    });
};
const courierInfo = async () => {
    return admin.courierInfo()
}

const useUpdateCourierInfo = () => {
    const queryClient = useQueryClient();
    return useMutation(updateCourierInfo, {
        onSuccess: (data) => {
            queryClient.refetchQueries(['courierInfo'], { active: true })
        },
    });
};
const updateCourierInfo = async (data) => {
    return admin.updateCourierInfo(data)
}

const usePayment = () => {
    return useMutation(payment, {
        onSuccess: (data) => {
        },
    });
};
const payment = async (data) => {
    return api.payment(data)
}

const useSearchCukai = () => {
    return useMutation(searchCukai, {
        onSuccess: (data) => {
        },
    });
};
const searchCukai = async (data) => {
    return admin.searchCukai(data)
}

export { 
    useNewApplication, 
    useEditApplication,
    useApplicationList, 
    useApplicationView,
    useApplicationStatusUpdate,
    useLicenseStatusUpdate,
    useCourierInfo,
    useUpdateCourierInfo,
    usePayment,
    useSearchCukai
};