import Card from '@material-tailwind/react/Card';
import CardRow from '@material-tailwind/react/CardRow';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardStatus from '@material-tailwind/react/CardStatus';
import CardStatusFooter from '@material-tailwind/react/CardStatusFooter';
import Icon from '@material-tailwind/react/Icon';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function StatusCard({
    color,
    icon,
    title,
    amount,
    viewMore,
    percentage,
    percentageColor,
    percentageIcon,
    date,
}) {
    let history = useHistory()
    return (
        <div className="px-4 mb-10">
            <Card>
                <CardRow>
                    <CardHeader size='sm' color={color} iconOnly className="mb-0">
                        <Icon family={'font-awesome'} name={icon} size="3xl" color="white" />
                    </CardHeader>

                    <CardStatus title={title} amount={amount} />
                </CardRow>

                {viewMore ?
                <CardStatusFooter>
                    <Button onClick={() => history.push(viewMore)} sx={{marginY: '-8px'}}>
                        <span className="mr-2">Lihat Lagi</span> 
                        <Icon family={'font-awesome'} name={'fas fa-arrow-right'} />
                    </Button>
                </CardStatusFooter>
                : ''}

                {/* <CardStatusFooter
                    // amount={percentage}
                    // color={percentageColor}
                    date={date}
                >
                    <Icon color={percentageColor} name={percentageIcon} />
                </CardStatusFooter> */}
            </Card>
        </div>
    );
}
