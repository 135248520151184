import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { useLicenseList } from 'query/actions/license/Crud';
import moment from "moment";
import ButtonGroupActionLesen from './ButtonGroupActionLesen';
import differenceBy from 'lodash/differenceBy';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import LicenseCard from './LicenseCard';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import CarianLesen from './lesen/CarianLesen';
import PermohonanPembaharuan from 'pages/user/PermohonanPembaharuan';

export default function DataTableCardLesen() {
    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate, error, isError, isLoading, isSuccess } = useLicenseList();
    // const { mutate, error, isError, isLoading, isSuccess, data: dataAtom } = useApplicationList();
    const [lists, setLists] = useState({});
    const [page, setPage] = useState(0);
    const [isAdmin, setIsAdmin] = useState(true);
    const countPerPage = 10;
    const [search, setSearch] = useState(null);
    const [refreshTable, setRefreshTable] = useState(false);
    const [pageModal, setPageModal] = useState(null);
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const getLicenseList = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                setLists(data.data.data)
            }
        })
    }

    useEffect(() => {
        let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
        let data = search==null ? 
            {'page': page, 'perPage': countPerPage, 'user': accessToken} :
            {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
        // let data = {'page': page, 'perPage': countPerPage, 'user': accessToken}
        getLicenseList(data);
        setIsAdmin((accessToken!='' ? false : true))

        if(refreshTable){
            setRefreshTable(false)
        }
    }, [page, search, refreshTable]);
    // }, [page, refreshTable]);

    // search
    // useEffect(() => {
    //     let accessToken = userInfo?.isAdmin ? '' : userInfo?.accessToken;
    //     let data = {'page': page, 'perPage': countPerPage, 'user': accessToken, 'search': search}
    //     getLicenseList(data);
    //     setIsAdmin((accessToken!='' ? false : true))
    //     console.log('search',search)
    // }, [page, search]);

	const [selectedRows, setSelectedRows] = useState([]);
	const [toggleCleared, setToggleCleared] = useState(false);

	const handleRowSelected = useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const contextActions = useMemo(() => {
		const handleApprove = () => {
            setPageModal(<View closeModal={closeModal} id={''} action={7} />)
            setShowModal(true)
			// console.log('selectedRows',selectedRows)
			// if (window.confirm(`Luluskan permohonan:\r ${selectedRows.map(r => r.application_id)}?`)) {
			// 	setToggleCleared(!toggleCleared);
			// 	setLists(differenceBy(lists, selectedRows, 'status'));
			// }
		};

		return (
            <>
            <div className='px-1 hidden md:block lg:block'>
                <Button
                    onClick={() => handleApprove()}
                    type="button"
                    color="green"
                >
                    <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    Lulus
                </Button>
            </div>
            <div className='px-1 lg:hidden'>
                <Tooltip title="Lulus" placement="left">
                    <Button
                        onClick={() => handleApprove()}
                        type="button"
                        color="green"
                        iconOnly
                    >
                        <i className="fa-solid fa-circle-check" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            </>
		);
	}, [lists, selectedRows, toggleCleared]);

    // next
    const [showNext, setShowNext] = useState(false);
    const [nextId, setNextId] = useState();
    const onNext = (id, show) => {
        setShowNext(show)
        setNextId(id)
    }

    // admin
    const columns = userInfo?.isAdmin ? [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '4.5rem'
        },
        {
            name: 'NO. LESEN',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.license_no }</div>
                    {row.payment==2 && row.plate_no!=0 ?
                    <div style={{fontSize: '10px', width: 'fit-content'}}  className={`p-1 text-white bg-green-500 rounded-lg font-medium border-2 border-green-500`}>
                        Selesai
                        <i className={`fa-solid pl-1 fa-circle-check`}></i>
                    </div> : row.payment==2 && row.plate_no==0 ?
                    <div style={{fontSize: '10px', width: 'fit-content'}}  className={`p-1 text-white bg-orange-500 rounded-lg font-medium border-2 border-orange-500`}>
                        Serahan Patil
                        <i className={`fa-solid pl-1 fa-dog`}></i>
                    </div> : ''
                    }
                </div>,
            selector: row => row.row_num,
            sortable: true,
            width: '10rem'
        },
        {
            name: 'NO. PERMOHONAN',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.application_no }</div>
                </div>,
            selector: row => row.row_num,
            sortable: true,
            width: '10rem'
        },
        {
            name: 'PEMILIK LESEN',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ row.name }</div>
                    <div className='mb-1'>{ row.mykad }</div>
                    <div className='mb-1'>{ row.phone }</div>
                    <div>{ row.email }</div>
                </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '19.5rem'
        },
        {
            name: 'STATUS',
            cell: row => <div style={{fontSize: '10px'}} 
                        className={`px-2 py-1 rounded-lg text-white font-medium text-xs 
                        ${row.status==1 ? 'bg-gray-500' : 
                        row.status==2 ? 'bg-green-500' : 
                        row.status==3 ? 'bg-amber-500' : 'bg-red-500'}`
                    }>
                    { row.status==1 ? 'Tidak Aktif' : 
                        row.status==2 ? 'Aktif' : 
                        row.status==3 ? 'Tamat Tempoh' : 'Batal' }
                </div>,
            selector: row => row.status,
            sortable: true,
            width: '8rem'
        },
        {
            name: 'SALURAN',
            cell: row => <div style={{fontSize: '10px'}}  className={`p-1 rounded-lg font-medium border-2
                ${row.channel==1 ? 'bg-green-500 border-green-500' : 'bg-amber-500 border-amber-500'}`}>
                <i className={`fa-solid fa-circle pr-1 text-white`}></i>
                <span className='text-white'>
                    { row.channel==1 ? 'Online' : 'Kaunter' }
                </span>
                </div>,
            selector: row => row.channel,
            sortable: true,
            width: '6.5rem'
        },
        // {
        //     name: 'BAYARAN',
        //     cell: row => <div style={{fontSize: '10px'}}  className={`p-1 rounded-lg font-medium border-2
        //         ${row.payment==2 ? 'border-green-500' : 'border-amber-500'}`}>
        //         <i className={`fa-solid pr-1 ${row.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
        //         { row.payment==2 ? 'Telah Bayar' : 'Belum Bayar' }
        //         </div>,
        //     selector: row => row.payment,
        //     sortable: true,
        //     width: '8rem'
        // },
        {
            name: 'SERAHAN',
            cell: row => <div style={{fontSize: '10px'}}>
                    {row.submission_type!=null ?
                    <div 
                        style={{fontSize: '10px', width: 'fit-content'}}
                        className={`p-1 text-white 
                            ${row.submission_type=='Perkhidmatan Kurier' ? 'bg-indigo-500 border-indigo-500' : 
                            row.submission_type=='Ambil Sendiri Di MBSP' ? 'bg-purple-500 border-purple-500' : 'bg-teal-500 border-teal-500'} 
                            rounded-lg font-medium border-2`}>
                        {row.submission_type}
                        {/* <i className={`fa-solid pl-1 fa-dog`}></i> */}
                    </div> : ''}
                </div>,
            selector: row => row.submission_type,
            sortable: true,
            width: '7rem'
        },
        {
            name: 'TARIKH',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
                    <div>{ moment(row.created_at).format('h:mm A') }</div>
                </div>,
            selector: row => row.created_at,
            sortable: true,
            width: '7rem'
        },
        {
            name: ' ',
            cell: row => <><ButtonGroupActionLesen 
                        isAdmin={isAdmin} 
                        id={row.license_id} 
                        status={row.status} 
                        plate_no={row.plate_no}
                        row={row}
                        setRefreshTable={setRefreshTable}
                    />
                    {/* <div className={`p-1 ${(row.status == '2' && row.plate_no == 0 && row.submission_type == 'Perkhidmatan Kurier') ? '' : 'hidden'}`}>
                        <Tooltip title="Penghantaran Patil" placement="top">
                            <Button
                                type="button"
                                size="sm"
                                color="orange"
                                onClick={() => onNext(row.application_id, true)}
                                iconOnly
                            >
                                <i className="fa-solid fa-shipping-fast text-black" style={{fontSize: '1.25rem'}}></i>
                            </Button>
                        </Tooltip>
                    </div> */}
                    </>,
            selector: row => row.license_id,
            sortable: true,
            //   width: '12%'
        }
    ] : [
        {
            name: <span>#</span>,
            cell: row => row.row_num,
            selector: row => row.row_num,
            sortable: true,
            width: '4.5rem'
        },
        {
            name: 'INFO LESEN',
            cell: row => <div className="flex flex-wrap mt-8">
                <LicenseCard
                    icon={row.status=='1' ? "fa-solid fa-clock" : 
                            row.status=='2' ? "fa-solid fa-circle-check" :
                            "fa-solid fa-ban"}
                    title="No. Lesen"
                    account={row.license_no}
                    status={row.status_info}
                    valid={row.start_date!=null ? `Sah sehingga ${ moment(row.end_date).format('D MMM YYYY') }` : '-'}
                />
            </div>,
            selector: row => row.row_num,
            sortable: true,
            width: '24rem'
        },
        {
            name: ' ',
            cell: row => <div className="flex flex-col">
                <div className="mb-3">
                    <span className="pr-1 text-xs text-gray-500">No. Lesen: </span>
                    <span className="font-bold text-md">{row.license_no}</span>
                </div>
                <div className="mb-3">
                    <span className="text-xs text-gray-500">Status: </span>
                    <span 
                        style={{fontSize: '10px'}}
                        className={`px-2 py-1 text-white rounded-lg font-medium border-2
                            ${row.status=='1' ? 'bg-gray-500 border-gray-500' : 
                                row.status=='2' ? 'bg-green-500 border-green-500' :
                                row.status=='3' ? 'bg-amber-500 border-amber-500' :
                                'bg-red-500 border-red-500'}`}
                    >
                        {row.status_info}
                        {row.status=='1' ? <i className="fa-solid fa-clock pl-1"></i> : 
                            row.status=='2' ? <i className="fa-solid fa-circle-check pl-1"></i> :
                            row.status=='3' ? <i className="fa-regular fa-calendar-xmark pl-1"></i> :
                            <i className="fa-solid fa-ban pl-1"></i>}
                    </span>
                </div>
                <div className="mb-3">
                    <span className="text-xs text-gray-500">Bayaran: </span>
                    <span
                        style={{fontSize: '10px'}}
                        className={`px-2 py-1 rounded-lg font-medium border-2
                            ${row.payment==2 ? 'border-green-500' : 'border-amber-500'}`}
                    >
                        <i className={`fa-solid pr-1 ${row.payment==2 ? 'fa-circle-check text-green-500' : 'fa-circle text-amber-500'}`}></i>
                        { row.payment==2 ? 'Telah Bayar' : 'Belum Bayar' }
                    </span>
                </div>
                <div className="mb-5">
                    <span className="pr-1 text-xs text-gray-500">Tempoh: </span>
                    <span className="font-bold text-md">
                        {row.start_date!=null ? 
                        `${moment(row.start_date).format('D MMM YYYY')} - ${moment(row.end_date).format('D MMM YYYY')}` :
                        '-'}
                    </span>
                </div>
            </div>,
            //   selector: row => `${ row.status } ${ row.channel }`,
            sortable: true,
            width: '19rem'
        },
        {
            name: 'TARIKH',
            cell: row => <div className='flex flex-col my-4'>
                    <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
                    <div>{ moment(row.created_at).format('h:mm A') }</div>
                </div>,
            selector: row => row.created_at,
            sortable: true,
            width: '7rem'
        },
        {
            name: ' ',
            cell: row => <><ButtonGroupActionLesen 
                        isAdmin={isAdmin} 
                        id={row.license_id} 
                        status={row.status} 
                        // channel={row.channel}
                        setRefreshTable={setRefreshTable}
                    />
                    {/* <div className={`p-1 ${row.status == '3' ? '' : ''}`}> */}
                    <div className={`p-1 ${row.status == '3' || (moment().format('M') == 12 && row.status=='2') ? '' : 'hidden'}`}>
                        <Tooltip title="Pembaharuan" placement="top">
                            <Button
                                type="button"
                                size="sm"
                                color="blue"
                                onClick={() => onNext(row.application_id, true)}
                                // iconOnly
                            >
                                <span className='text-sm'>Pembaharuan</span>
                                {/* <i className="fa-solid fa-arrow-rotate-right" style={{fontSize: '1.25rem'}}></i> */}
                            </Button>
                        </Tooltip>
                    </div></>,
            selector: row => row.license_id,
            sortable: true,
            //   width: '12%'
        }
    ];

    const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);

    return (
        <>
        {showNext ?
        <PermohonanPembaharuan id={nextId} />
        :
        <>
        {userInfo?.isAdmin ?
        <div className='mb-14'>
            <CarianLesen setSearch={setSearch} setPage={setPage} />
        </div> : ''}
        <Card>
            <CardHeader size='sm' color="purple" contentPosition="left">
                <h2 className="text-white text-xl">Senarai Lesen</h2>
            </CardHeader>
            <CardBody className='-mt-3'>
                <div className="overflow-x-auto mt-4">
                    <DataTable 
                        // title=" "
                        className='w-full'
                        columns={columns} 
                        data={lists.result}
                        progressPending={isLoading} 
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={lists.total}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                          noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page - 1)}
                        // selectableRows={isAdmin}
                        selectableRowDisabled={rowDisabledCriteria}
                        contextActions={contextActions}
                        onSelectedRowsChange={handleRowSelected}
                        clearSelectedRows={toggleCleared}
                    />
                </div>
            </CardBody>
        </Card>
        </>
        }

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={''}
                title={`${selectedRows.length} Permohonan Dipilih`}
                content={pageModal}
                backdropClose={true}
            /> : 
        ''}
        </>
    );
}


// // admin
// const columns = [
//     {
//       name: '#',
//       cell: row => row.application_id,
//       selector: row => row.application_id,
//       sortable: true,
//       width: '5rem'
//     },
//     {
//       name: 'SALURAN',
//       cell: row => <div style={{fontSize: '10px'}} className={`px-2 py-1 rounded-lg text-white text-xs ${row.channel==1 ? 'bg-purple-500' : 'bg-teal-500'}`}>
//           { row.channel==1 ? 'Online' : 'Kaunter' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'INFO PEMOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ row.name }</div>
//             <div className='mb-1'>{ row.mykad }</div>
//             <div className='mb-1'>{ row.phone }</div>
//             <div>{ row.email }</div>
//       </div>,
//     //   selector: row => `${ row.status } ${ row.channel }`,
//       sortable: true,
//       width: '22rem'
//     },
//     {
//       name: 'STATUS',
//       cell: row => <div style={{fontSize: '10px'}} 
//                 className={`px-2 py-1 rounded-lg text-white text-xs 
//                 ${row.status==1 ? 'bg-gray-500' : 
//                 row.status==2 ? 'bg-teal-500' : 
//                 row.status==3 ? 'bg-red-500' : 'bg-amber-500'}`
//             }>
//             { row.status==1 ? 'Baru' : row.status==2 ? 'Lulus' : row.status==3 ? 'Batal' : 'Tidak Lengkap' }
//         </div>,
//       selector: row => row.channel,
//       sortable: true,
//       width: '8rem'
//     },
//     {
//       name: 'TARIKH MOHON',
//       cell: row => <div className='flex flex-col my-4'>
//             <div className='mb-1'>{ moment(row.created_at).format('D MMM YYYY') }</div>
//             <div>{ moment(row.created_at).format('h:mm A') }</div>
//         </div>,
//       selector: row => row.created_at,
//       sortable: true,
//     //   width: '12%'
//     },
//     {
//       name: ' ',
//       cell: row => <ButtonGroupAction 
//                 isAdmin={isAdmin} 
//                 id={row.application_id} 
//                 status={row.status} 
//             />,
//       selector: row => row.application_id,
//       sortable: true,
//     //   width: '12%'
//     }
//   ];

// const rowDisabledCriteria = row => (row.disabled==='true' ? true : false);
