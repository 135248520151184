import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem } from '@mui/material';

export default function CarianTangkapLulus() {
    const [toggle, setToggle] = useState(false);
    const [toggleName, setToggleName] = useState('Buka');

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    return (
        <>
            {/* <CardHeader className='mx-4 z-50' size='sm' color='teal' contentPosition="none">
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-2xl">Butiran Pemohon</h2>
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </CardHeader> */}

            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <div className='flex flex-wrap mt-2'>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="No. Lesen" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth />
                            </div>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="No. MyKad" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth />
                            </div>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="Nama Pemilik" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth />
                            </div>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="Status" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth select>
                                    <MenuItem value={'1'}>{'Tidak Aktif'}</MenuItem>
                                    <MenuItem value={'2'}>{'Aktif'}</MenuItem>
                                    <MenuItem value={'3'}>{'Tamat Tempoh'}</MenuItem>
                                </TextField>
                            </div>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="Bayaran" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth select>
                                    <MenuItem value={'1'}>{'Belum Bayar'}</MenuItem>
                                    <MenuItem value={'2'}>{'Telah Bayar'}</MenuItem>
                                </TextField>
                            </div>
                            <div className='w-full lg:w-4/12 p-2'>
                                <TextField id="outlined-basic" size='small' label="Tarikh" variant="outlined" type={'date'} InputLabelProps={{ shrink: true }} fullWidth />
                            </div>
                        </div>
                        {/* <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 mb-0 mt-6">
                            <div className='pr-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option>United States</option>
                                    <option>Canada</option>
                                    <option>France</option>
                                    <option>Germany</option>
                                </select>
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='pl-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 mb-4 mt-3">
                            <div className='pr-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='px-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <input className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' type='text' name='no_permohonan' />
                            </div>
                            <div className='pl-3'>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Select your country</label>
                                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option>United States</option>
                                    <option>Canada</option>
                                    <option>France</option>
                                    <option>Germany</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}