import React, { useState, useEffect } from "react";
import DialogContent from '@mui/material/DialogContent';
import { Button } from "@material-tailwind/react";

export default function PopupBanner({closeModal, srcBanner}){

    return (
        <DialogContent>
            <div className="flex flex-wrap w-full">
                <div className="flex w-full flex-col my-1">
                    <img src={srcBanner} />
                </div>
                <div className='flex w-full justify-center /mt-5'>
                    <Button
                        color="gray"
                        // buttonType="outline"
                        size="lg"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        type="button"
                        onClick={closeModal}
                    >
                        Tutup
                    </Button>
                </div>
            </div>
        </DialogContent>
    )
}