import React, { useState, useEffect } from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import Button from '@material-tailwind/react/Button';
import { TextField, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export default function CarianLaporanOperasiHaiwanMerayau({setSearch, setPage}) {
    const [toggle, setToggle] = useState(false);
    const [toggleName, setToggleName] = useState('Buka');

    const toggleCard = () => {
        setToggle(!toggle)
        if(toggle){
            setToggleName('Buka')
        } else {
            setToggleName('Tutup')
        }
    }

    useEffect(() => {
        toggleCard()
    }, [])

    const validationSchema = Yup.object().shape({
        kod: Yup.string().when("kod", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        permohonan: Yup.string().when("permohonan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(12, "Minimum 12 digit").max(12, "Maximum 12 digit").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        haiwan: Yup.string().when("haiwan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        nama: Yup.string().when("nama", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        jenis: Yup.string().when("jenis", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        tindakan: Yup.string().when("tindakan", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        bandar: Yup.string().when("bandar", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        tarikh: Yup.string().when("tarikh", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        alamat: Yup.string().when("alamat", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().min(3, "Minimum 3 aksara").required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
        kaedah: Yup.string().when("kaedah", (val, schema) => {
            if(val?.length > 0) {  //if address exist then apply min max else not
               return Yup.string().required("Required");
            } else { 
               return Yup.string().notRequired();
            }
        }),
    }, [
        ["kod", "kod"], 
        ["permohonan", "permohonan"],
        ["haiwan", "haiwan"],
        ["nama", "nama"],
        ["jenis", "jenis"],
        ["tindakan", "tindakan"],
        ["bandar", "bandar"],
        ["tarikh", "tarikh"],
        ["alamat", "alamat"],
        ["kaedah", "kaedah"],
    ]);
    const { register, handleSubmit, control, formState:{ errors }, reset, resetField, setValue } = useForm({
        defaultValues: {
            kod: '',
            permohonan: '',
            haiwan: '',
            nama: '',
            jenis: '',
            tindakan: '',
            bandar: '',
            tarikh: '',
            kaedah: '',
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (data) => {
        console.log('datadata',data)
        setSearch(data)
        setPage(0)
    }

    const onResetForm = () => {
        reset()
        setSearch(null)
        setPage(0)
    }

    return (
        <>
            <div className='rounded-xl overflow-hidden1 relative shadow-lg bg-blue-500 bg-opacity-200 border-2 px-5 py-3 mx-4 z-10'>
                <div className="w-full flex items-center justify-between">
                    <h2 className="text-white text-xl">Carian</h2>
                    <Button
                        className="text-white"
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        style={{ padding: 0 }}
                        onClick={toggleCard}
                    >
                        {toggleName}
                    </Button>
                </div>
            </div>

            <Card className={toggle ? '-mt-8 pb-0' : 'hidden -mt-8 pb-0'}>
                <CardBody>
                    <div className="container mx-auto max-w-full">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-wrap justify-between mt-2'>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Nama Operasi" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.nama ? errors.nama.message : ''}
                                                error={errors.nama ? errors.nama : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="nama"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Kod" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.kod ? errors.kod.message : ''}
                                                error={errors.kod ? errors.kod : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="kod"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Jenis Operasi" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.jenis ? errors.jenis.message : ''}
                                                error={errors.jenis ? errors.jenis : false}
                                                fullWidth 
                                            >
                                                <MenuItem value={'Penangkapan'}>{'Penangkapan'}</MenuItem>
                                                <MenuItem value={'Pelupusan'}>{'Pelupusan'}</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="jenis"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Jenis Haiwan" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.haiwan ? errors.haiwan.message : ''}
                                                error={errors.haiwan ? errors.haiwan : false}
                                                fullWidth 
                                                >
                                                    <MenuItem value="Anjing Liar">Anjing Liar</MenuItem>
                                                    <MenuItem value="Kucing Liar">Kucing Liar</MenuItem>
                                                    <MenuItem value="Burung Merpati">Burung Merpati</MenuItem>
                                                    <MenuItem value="Burung Gagak">Burung Gagak</MenuItem>
                                                    <MenuItem value="Lembu">Lembu</MenuItem>
                                                    <MenuItem value="Kambing">Kambing</MenuItem>
                                                    <MenuItem value="Kerbau">Kerbau</MenuItem>
                                                </TextField>
                                        )}
                                        control={control}
                                        name="haiwan"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Kaedah Tangkapan" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.kaedah ? errors.kaedah.message : ''}
                                                error={errors.kaedah ? errors.kaedah : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="Tembak">Tembak</MenuItem>
                                                <MenuItem value="Perangkap">Perangkap</MenuItem>
                                                <MenuItem value="Racun">Racun</MenuItem>
                                                <MenuItem value="Musnah Sarang Gagak">Musnah Sarang Gagak</MenuItem>
                                                <MenuItem value="Lasso">Lasso</MenuItem>
                                                <MenuItem value="PSG">PSG</MenuItem>
                                                <MenuItem value="Jaring">Jaring</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="kaedah"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Tindakan" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.tindakan ? errors.tindakan.message : ''}
                                                error={errors.tindakan ? errors.tindakan : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="Hantar ke SPCA">Hantar ke SPCA</MenuItem>
                                                <MenuItem value="Hantar ke Paun">Hantar ke Paun</MenuItem>
                                                <MenuItem value="Hantar ke JPV">Hantar ke JPV</MenuItem>
                                                <MenuItem value="Musnah">Musnah</MenuItem>
                                                <MenuItem value="Lepas">Lepas</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="tindakan"
                                    />
                                </div>
                                <div className='w-full lg:w-4/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Alamat Tempat" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.alamat ? errors.alamat.message : ''}
                                                error={errors.alamat ? errors.alamat : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="alamat"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                size='small' 
                                                label="Bandar" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.bandar ? errors.bandar.message : ''}
                                                error={errors.bandar ? errors.bandar : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="bandar"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="DUN" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="N1">N1 - Penaga</MenuItem>
                                                <MenuItem value="N2">N2 - Bertam</MenuItem>
                                                <MenuItem value="N3">N3 - Pinang Tunggal</MenuItem>
                                                <MenuItem value="N4">N4 - Permatang Berangan</MenuItem>
                                                <MenuItem value="N5">N5 - Sungai Dua</MenuItem>
                                                <MenuItem value="N6">N6 - Telok Ayer Tawar</MenuItem>
                                                <MenuItem value="N7">N7 - Sungai Puyu</MenuItem>
                                                <MenuItem value="N8">N8 - Bagan Jermal</MenuItem>
                                                <MenuItem value="N9">N9 - Bagan Dalam</MenuItem>
                                                <MenuItem value="N10">N10 - Seberang Jaya</MenuItem>
                                                <MenuItem value="N11">N11 - Permatang Pasir</MenuItem>
                                                <MenuItem value="N12">N12 - Penanti</MenuItem>
                                                <MenuItem value="N13">N13 - Berapit</MenuItem>
                                                <MenuItem value="N14">N14 - Machang Bubuk</MenuItem>
                                                <MenuItem value="N15">N15 - Padang Lalang</MenuItem>
                                                <MenuItem value="N16">N16 - Perai</MenuItem>
                                                <MenuItem value="N17">N17 - Bukit Tengah</MenuItem>
                                                <MenuItem value="N18">N18 - Bukit Tambun</MenuItem>
                                                <MenuItem value="N19">N19 - Jawi</MenuItem>
                                                <MenuItem value="N20">N20 - Sungai Bakap</MenuItem>
                                                <MenuItem value="N21">N21 - Sungai Acheh</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                select
                                                type='text'
                                                size='small' 
                                                label="Parlimen" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.status ? errors.status.message : ''}
                                                error={errors.status ? errors.status : false}
                                                fullWidth 
                                            >
                                                <MenuItem value="P41 - Kepala Batas">P41 - Kepala Batas</MenuItem>
                                                <MenuItem value="P42 - Tasek Gelugor">P42 - Tasek Gelugor</MenuItem>
                                                <MenuItem value="P43 - Bagan">P43 - Bagan</MenuItem>
                                                <MenuItem value="P44 - Permatang Pauh">P44 - Permatang Pauh</MenuItem>
                                                <MenuItem value="P45 - Bukit Mertajam">P45 - Bukit Mertajam</MenuItem>
                                                <MenuItem value="P46 - Batu Kawan">P46 - Batu Kawan</MenuItem>
                                                <MenuItem value="P47 - Nibong Tebal">P47 - Nibong Tebal</MenuItem>
                                            </TextField>
                                        )}
                                        control={control}
                                        name="status"
                                    />
                                </div>
                                <div className='w-full lg:w-2/12 p-2'>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type='date'
                                                size='small' 
                                                label="Tarikh" 
                                                variant="outlined" 
                                                InputLabelProps={{ shrink: true }} 
                                                helperText={errors.tarikh ? errors.tarikh.message : ''}
                                                error={errors.tarikh ? errors.tarikh : false}
                                                fullWidth 
                                            />
                                        )}
                                        control={control}
                                        name="tarikh"
                                    />
                                </div>
                                <div className='flex justify-center w-full p-2'>
                                    <Button 
                                        color="gray"
                                        buttonType="link"
                                        onClick={onResetForm}
                                        ripple="dark"
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        color="blue"
                                        // onClick={() => onStatusUpdate(action)}
                                        ripple="light"
                                        type="submit"
                                    >
                                        Cari
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}