import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "api/Api"
import * as admin from "api/Admin"
// recoil atom
import { useRecoilState } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';

export const useGetDashboard = () => {
    return useQuery(["GetDashboard"], () => api.getDashboard());
};

export const useGetUtama = () => {
    return useQuery(["GetUtama"], () => api.getUtama());
};

// list
export const useEmployeeList = () => {
    return useMutation(employeeListRequest, {
        onSuccess: (data) => {
        },
    });
};
const employeeListRequest = async (data) => {
    return api.employeeListRequest(data)
}

export const useRoles = () => {
    return useQuery(["Roles"], () => api.getRoles());
};

export const useRegisterStaff = () => {
    return useMutation(registerStaffRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const registerStaffRequest = async (credential) => {
    return api.registerStaffRequest(credential)
}

// list
export const useRoleList = () => {
    return useMutation(roleListRequest, {
        onSuccess: (data) => {
        },
    });
};
const roleListRequest = async (data) => {
    return api.roleListRequest(data)
}

export const useRegisterRole = () => {
    return useMutation(registerRoleRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const registerRoleRequest = async (credential) => {
    return api.registerRoleRequest(credential)
}



export const useProfile = () => {
    return useQuery(["Profile"], () => api.profile());
};

export const useUpdateProfile = () => {
    const queryClient = useQueryClient();
    const [auth, setAuth] = useRecoilState(userInfoAtom);
    return useMutation(updateProfile, {
        onSuccess: (data) => {
            if(data.response.response_code == '200'){
                sessionStorage.clear();
                setAuth(data.data);
                sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(data.data)
                );
                queryClient.refetchQueries(['Profile'], { active: true })
            }
        },
    });
};
const updateProfile = async (data) => {
    return api.updateProfile(data)
}

// list
export const useUserList = () => {
    return useMutation(userListRequest, {
        onSuccess: (data) => {
        },
    });
};
const userListRequest = async (data) => {
    return admin.userListRequest(data)
}

export const useUpdateUser = () => {
    return useMutation(updateUser, {
        onSuccess: (data) => {
        },
    });
};
const updateUser = async (data) => {
    return admin.updateUser(data)
}

export const useChangePassword = () => {
    return useMutation(changePassword, {
        onSuccess: (data) => {
        },
    });
};
const changePassword = async (data) => {
    return api.changePassword(data)
}