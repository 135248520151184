import { useQuery, useMutation } from "react-query";
import * as api from "api/Admin"

export const useGetDashboard = () => {
    return useQuery(["GetDashboard"], () => api.getDashboard(), { refetchOnWindowFocus: false });
};

// list
export const useEmployeeList = () => {
    return useMutation(employeeListRequest, {
        onSuccess: (data) => {
        },
    });
};
const employeeListRequest = async (data) => {
    return api.employeeListRequest(data)
}

export const useRoles = () => {
    return useQuery(["Roles"], () => api.getRoles());
};

export const useRegisterStaff = () => {
    return useMutation(registerStaffRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const registerStaffRequest = async (credential) => {
    return api.registerStaffRequest(credential)
}

export const useUpdateStaff = () => {
    return useMutation(updateStaff, {
        onSuccess: (data) => {
        },
    });
};
const updateStaff = async (data) => {
    return api.updateStaff(data)
}

// list
export const useRoleList = () => {
    return useMutation(roleListRequest, {
        onSuccess: (data) => {
        },
    });
};
const roleListRequest = async (data) => {
    return api.roleListRequest(data)
}

export const useRegisterRole = () => {
    return useMutation(registerRoleRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const registerRoleRequest = async (credential) => {
    return api.registerRoleRequest(credential)
}

export const useUpdateRole = () => {
    return useMutation(updateRole, {
        onSuccess: (data) => {
        },
    });
};
const updateRole = async (data) => {
    return api.updateRole(data)
}

export const useRoleMenu = (data) => {
    return useQuery(["roleMenu", data], () => api.roleMenu(data), { refetchOnWindowFocus: false });
};

// load data staff
export const useSearchStaffInfo = () => {
    return useMutation(searchStaffInfo, {
        onSuccess: (data) => {
        },
    });
};
const searchStaffInfo = async (data) => {
    return api.searchStaffInfo(data)
}