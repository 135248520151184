import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useRegister } from "query/actions/Registration";
import { Spinner } from 'components/Spinner';
import ResponseStatus from "./ResponseStatus";
import { toast } from 'react-toastify';
import Example from 'components/Spinner/Example';
import { useSetRecoilState } from 'recoil';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';
import { useLogin } from 'query/actions/Auth';
import { useForgotPasssword } from "query/actions/Auth";

export default function Login({closeModal, setTitle}){    
    const [page, setPage] = useState(null); 
    const [mykad, setMykad] = useState(''); 
    const [alertMykad, setAlertMykad] = useState(false);
    const [alertMykadName, setAlertMykadName] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess, data } = useLogin();
    const { mutate: mutateForgotPassword, isLoading: isLoadingForgotPassword } = useForgotPasssword();
    const setUserInfoAtom = useSetRecoilState(userInfoAtom);

    const validationSchema = Yup.object().shape({
        id: Yup.string().required(`Ruangan Wajib Diisi`),
        password: Yup.string().required(`Ruangan Wajib Diisi`),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues: {
            id: '',
            password: ''
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        await mutate(data, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code)
            }
        })
    }
    // console.log('datadata',data?.data.response.response_code)

    const Notify = (code) => {
        if(code == '500'){
            toast.error("ID Pengguna atau Katalaluan tidak sah!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        } 
    };

    const onForgotPassword = (type, show) => {
        setTitle(type=='login' ? 'Log Masuk Pengguna' : 'Lupa Katalaluan')
        setShowForgotPassword(show)
        setAlertMykad(false)
    }

    const handleChange = (event) => {
        setMykad(event.target.value);
        if(event.target.value != '' || event.target.value != null){
            setAlertMykad(false)
            setAlertMykadName('Ruangan Wajib Diisi')
        }
    };
    
    const onSubmitForgotPassword = async () => {
        if(mykad == '' || mykad == undefined){
            setAlertMykad(true)
            setAlertMykadName('Ruangan Wajib Diisi')
        } else if(mykad.length < 12){
            setAlertMykad(true)
            setAlertMykadName('No. MyKad adalah 12 digit')
        } else {
            // console.log('mykad',mykad)
            await mutateForgotPassword(mykad, {
                onSuccess: (data) => {
                    if(data.data.response.response_code == '200'){
                        toast.success('Berjaya!', { 
                            isLoading: false,
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000
                        });
                        setPage(
                            <ResponseStatus 
                                type={'success'} 
                                close={closeModal} 
                                title={'Berjaya!'}
                                subtitle={'Semak emel untuk katalaluan sementara dan log masuk ke akaun anda.'}
                                isButton={{page: null, name: 'Selesai'}}
                            />
                        )
                    } else if(data.data.response.response_code == '500'){
                        toast.error('Akaun tidak wujud!', { 
                            isLoading: false,
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000
                        });
                    }
                }
            })
        }
    }

    return (
        <>
        {/* <div className="lg:w-2xl md:w-lg"> */}
            {isLoading || isLoadingForgotPassword ? <div className='flex justify-center items-center h-40'><Spinner /></div> :
            page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-wrap w-full">
                    {/* <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span> */}
                    {!showForgotPassword ?
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-wrap my-1">
                            <div className="w-full mt-1 mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <InputIcon
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="ID Pengguna"
                                            iconFamily="font-awesome"
                                            iconName="far fa-id-card"
                                            error={errors.id ? errors.id.message : ''}
                                            disabled={isLoading ? true : false}
                                        />
                                    )}
                                    control={control}
                                    name="id"
                                />
                            </div>
                            
                            <div className="w-full mb-5 font-light">
                                <Controller
                                    render={({ field }) => (
                                    <InputIcon
                                        {...field}
                                        type="password"
                                        color="lightBlue"
                                        size="regular"
                                        outline={true}
                                        placeholder="Katalaluan"
                                        iconFamily="font-awesome"
                                        iconName="fas fa-lock"
                                        error={errors.password ? errors.password.message : ''}
                                        disabled={isLoading ? true : false}
                                    />
                                    )}
                                    control={control}
                                    name="password"
                                />
                            </div>

                            <div className="flex justify-center w-full font-light">
                                <Button 
                                    color=""
                                    buttonType="link"
                                    onClick={() => onForgotPassword('forgot', true)}
                                    ripple="dark"
                                    className="capitalize"
                                    size="lg"
                                    type="button"
                                >
                                    Lupa Katalaluan?
                                </Button>
                            </div>
                        </div>

                        <div className='flex justify-center mt-5'>
                            {isLoading ? 
                            <>
                                <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                                <span className='ml-2'>Loading...</span>
                            </> : 
                            <Button
                                color="cyan"
                                // buttonType="outline"
                                size="lg"
                                rounded={true}
                                block={false}
                                iconOnly={false}
                                ripple="dark"
                                type="submit"
                            >
                                Log Masuk
                            </Button>
                            }
                        </div>
                    </form>
                    :
                    <>
                        <div className="flex w-full flex-col my-1">
                            <div className="w-full mt-1 mb-6 text-gray-500">
                            Masukkan No. Mykad anda untuk menerima katalaluan sementara melalui e-mel yang telah didaftarkan.
                            </div>
                            <div className="w-full mt-1 mb-6 font-light">
                                <InputIcon
                                    type="number"
                                    color="lightBlue"
                                    size="regular"
                                    outline={true}
                                    placeholder="No. MyKad (ID Pengguna)"
                                    iconFamily="font-awesome"
                                    iconName="far fa-id-card"
                                    onChange={handleChange}
                                    error={alertMykad ? alertMykadName : ''}
                                    disabled={isLoading ? true : false}
                                />
                            </div>
                        </div>
                        <div className='flex w-full justify-center /mt-5'>
                            <Button
                                color="cyan"
                                // buttonType="outline"
                                size="lg"
                                rounded={true}
                                block={false}
                                iconOnly={false}
                                ripple="dark"
                                type="button"
                                onClick={onSubmitForgotPassword}
                            >
                                Hantar
                            </Button>
                        </div>
                        <div className='flex w-full justify-center mt-5'>
                            <Button
                                color=""
                                buttonType="link"
                                size="lg"
                                rounded={true}
                                block={false}
                                iconOnly={false}
                                ripple="dark"
                                className="capitalize"
                                onClick={() => onForgotPassword('login', false)}
                                type="button"
                            >
                                <i className="fa-solid fa-circle-arrow-left mr-2"></i>Kembali ke Log Masuk
                            </Button>
                        </div>
                    </>
                    }
                </div>
            </DialogContent>
            {/* <DialogActions>
                <div className='flex justify-center'>
                    {isLoading ? 
                    <>
                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                        <span className='ml-2'>Loading...</span>
                    </> : 
                    <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>
                    <Button
                        color="teal"
                        buttonType="outline"
                        size="lg"
                        // rounded={true}
                        block={false}
                        iconOnly={false}
                        ripple="dark"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Log Masuk
                    </Button>
                    </>
                    }
                </div>
            </DialogActions> */}
            </>
            }
        {/* </div> */}
        </>
        
    )
}