import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import reportWebVitals from './reportWebVitals';

/* style */
import '../src/assets/styles/index.css';

/* React Query */
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

/* Toastify */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer autoClose={5000} closeOnClick={true} pauseOnHover={true} />
            {/* <QueryClientProvider client={queryClient}> */}
            {/* <BrowserRouter basename="/v2"> */}
                <App />
            </BrowserRouter>
            {/* </QueryClientProvider> */}
        {/* </BrowserRouter> */}
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
