import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { toast } from 'react-toastify';

import * as api from "api/Admin";

// list
const useTangkapLupusList = () => {
    let history = useHistory();

    // return useQuery(['ApplicationList', data], () => applicationListRequest(data))

    return useMutation(tangkapLupusListRequest, {
        onSuccess: (data) => {
            // toast.success("Kemaskini Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });

            // history.reload()
        },
    });
};
const tangkapLupusListRequest = async (data) => {
    return api.tangkapLupusListRequest(data)
}

// list
const useOperationList = () => {
    let history = useHistory();

    // return useQuery(['ApplicationList', data], () => applicationListRequest(data))

    return useMutation(operationListRequest, {
        onSuccess: (data) => {
            // toast.success("Kemaskini Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });

            // history.reload()
        },
    });
};
const operationListRequest = async (data) => {
    return api.operationListRequest(data)
}

// insert
const useNewOperation = () => {

    return useMutation(newOperation, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const newOperation = async (data) => {
    return api.newOperation(data)
}

// list
const useOperationByType = (data) => {
    return useQuery(['OperationList', data], () => operationByType(data))
};
const operationByType = async (data) => {
    return api.operationByType(data)
}

// insert
const useNewOperationByType = () => {

    return useMutation(newOperationByType, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const newOperationByType = async (data) => {
    return api.newOperationByType(data)
}

// edit
const useEditOperationByType = () => {

    return useMutation(editOperationByType, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const editOperationByType = async (data) => {
    return api.editOperationByType(data)
}

// delete
const useDeleteOperationByType = () => {

    return useMutation(deleteOperationByType, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const deleteOperationByType = async (data) => {
    return api.deleteOperationByType(data)
}

// report
const useTangkapLupusListReport = () => {
    return useMutation(tangkapLupusListReport, {
        onSuccess: (data) => {
        },
    });
};
const tangkapLupusListReport = async (data) => {
    return api.tangkapLupusListReport(data)
}

export { 
    useTangkapLupusList, 
    useOperationList,
    useNewOperation,
    useOperationByType,
    useNewOperationByType,
    useEditOperationByType,
    useDeleteOperationByType,
    useTangkapLupusListReport
};