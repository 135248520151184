import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import UserSidebar from 'components/user/UserSidebar';
import Footer from 'components/Footer';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';

// handle the main routes
function PublicRoute({ component: Component, ...rest }) {
    const userInfo = useRecoilValue(userInfoAtom);
    console.log('userInfouserInfo',userInfo)
  
    return (
      <Route
        // basename={'/v2'}
        {...rest}
        render={(props) =>
          userInfo?.accessToken ? (
            <Redirect
              to={{ pathname: "/v2/utama" }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
  
  export default PublicRoute;