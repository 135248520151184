import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
/* React Query */
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Sidebar from 'components/Sidebar';
import Dashboard from 'pages/Dashboard';
import Settings from 'pages/Settings';
import Tables from 'pages/Tables';
import Maps from 'pages/Maps';
import Footer from 'components/Footer';

import "@material-tailwind/react/tailwind.css";
// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// route
import AdminRoute from 'utils/AdminRoute';
import UserRoute from 'utils/UserRoute';
import PublicRoute from 'utils/PublicRoute';
import SingleRoute from 'utils/SingleRoute';

// pages
import Permohonan from 'pages/lesen/Permohonan';
import SenaraiPermohonan from 'pages/lesen/SenaraiPermohonan';
import Login from 'pages/Login';
import Tadbir from 'pages/tadbir/Login';
import Utama from 'pages/user/Utama';
import PermohonanBaru from 'pages/user/PermohonanBaru';
import PermohonanKemaskini from 'pages/user/PermohonanKemaskini';
import SenaraiLesen from 'pages/lesen/SenaraiLesen';
import Test from 'pages/Test';
import SenaraiPentadbir from 'pages/tadbir/SenaraiPentadbir';
import SenaraiPeranan from 'pages/tadbir/SenaraiPeranan';
import LaporanLesen from 'pages/laporan/LaporanLesen';
import TangkapLupus from 'pages/kacauganggu/TangkapLupus';
import SenaraiOperasi from 'pages/kacauganggu/SenaraiOperasi';
import CarianMaklumat from 'pages/penguatkuasaan/CarianMaklumat';
import CajPerkhidmatanKurier from 'pages/tadbir/CajPerkhidmatanKurier';
import PembaharuanLesen from 'pages/lesen/PembaharuanLesen';
import LaporanHaiwanMerayau from 'pages/laporan/LaporanHaiwanMerayau';
import LaporanKacauGanggu from 'pages/laporan/LaporanKacauGanggu';
import PenghantaranPatil from 'pages/lesen/PenghantaranPatil';
import Receipt from 'pages/Receipt';
import RedirectRoute from 'utils/RedirectRoute';
import Profil from 'pages/user/Profil';
import SenaraiPengguna from 'pages/tadbir/SenaraiPengguna';
import TukarKatalaluan from 'pages/user/TukarKatalaluan';

const queryClient = new QueryClient();

function App() {
    return (
        <>
        {/* <ToastContainer autoClose={5000} closeOnClick={true} pauseOnHover={true} /> */}
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                {/* <Sidebar /> */}
                {/* <BrowserRouter basename='/v2'> */}
                <Switch>
                    {/* <BrowserRouter basename='/v2'> */}
                        <PublicRoute exact path="/v2/logmasuk" component={Login} />
                        <PublicRoute exact path="/v2/access" component={Test} />
                        <PublicRoute exact path="/v2/tadbir" component={Tadbir} />
                        
                        <UserRoute exact path="/v2/utama" component={Utama} />
                        <UserRoute exact path="/v2/permohonan/baru" component={PermohonanBaru} />
                        <UserRoute exact path="/v2/permohonan/kemaskini/:id" component={PermohonanKemaskini} />
                        <UserRoute exact path="/v2/permohonan/senarai" component={SenaraiPermohonan} />
                        <UserRoute exact path="/v2/maklumat-lesen" component={SenaraiLesen} />
                        <UserRoute exact path="/v2/tetapan/profil-akaun" component={Profil} />
                        <UserRoute exact path="/v2/tetapan/tukar-katalaluan" component={TukarKatalaluan} />

                        <SingleRoute exact path="/v2/payment/receipt/:bid" component={Receipt} />
                    
                    {/* <div className="md:ml-64"> */}
                        {/* <AdminRoute exact path="/" component={RedirectRoute} /> */}
                        <AdminRoute exact path="/" component={Dashboard} />
                        <AdminRoute exact path="/v2" component={Dashboard} />
                        <AdminRoute exact path="/v2/lesen" component={Settings} />
                        <AdminRoute exact path="/v2/lesen/permohonan-baru" component={PermohonanBaru} />
                        <AdminRoute exact path="/v2/lesen/permohonan-kemaskini" component={PermohonanKemaskini} />
                        <AdminRoute exact path="/v2/lesen/senarai-permohonan" component={SenaraiPermohonan} />
                        <AdminRoute exact path="/v2/lesen/senarai-lesen" component={SenaraiLesen} />
                        <AdminRoute exact path="/v2/lesen/penghantaran-patil" component={PenghantaranPatil} />
                        <AdminRoute exact path="/v2/lesen/pembaharuan-lesen" component={PembaharuanLesen} />
                        <AdminRoute exact path="/v2/lesen/carian-patil" component={CarianMaklumat} />
                        <AdminRoute exact path="/v2/kacauganggu/tangkap-lupus" component={TangkapLupus} />
                        <AdminRoute exact path="/v2/kacauganggu/senarai-operasi" component={SenaraiOperasi} />
                        <AdminRoute exact path="/v2/penguatkuasaan/carian-maklumat" component={CarianMaklumat} />
                        <AdminRoute exact path="/v2/laporan/lesen" component={LaporanLesen} />
                        <AdminRoute exact path="/v2/laporan/operasi-haiwan-merayau" component={LaporanHaiwanMerayau} />
                        <AdminRoute exact path="/v2/laporan/operasi-kacau-ganggu" component={LaporanKacauGanggu} />
                        <AdminRoute exact path="/v2/tetapan/senarai-pengguna" component={SenaraiPengguna} />
                        <AdminRoute exact path="/v2/tetapan/senarai-pentadbir" component={SenaraiPentadbir} />
                        <AdminRoute exact path="/v2/tetapan/senarai-peranan" component={SenaraiPeranan} />
                        <AdminRoute exact path="/v2/tetapan/caj-perkhidmatan-kurier" component={CajPerkhidmatanKurier} />

                        <AdminRoute exact path="/maps" component={Maps} />
                    {/* </BrowserRouter>  */}
                    {/* </div>
                    
                    <Redirect from="*" to="/" /> */}
                </Switch>
                {/* </BrowserRouter> */}
                {/* <Footer /> */}
                <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
        </RecoilRoot>
        </>
    );
}

export default App;


// "@material-tailwind/react": "^0.3.4",