import { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

export default function UserSidebarItem({item}){
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
    const location = useLocation().pathname;

    let history = useHistory();

    useEffect(() => {
        let result = location.split('/').slice(0,3).join('/');
        setActive(result)
    }, [location])
    
    useEffect(() => {
        if(active == item.path){
            setOpen(!open)
        }
    }, [active])

    const handleClick = () => {
        setOpen(!open);
    };
    
    
    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
        {item.childrens ? 
            <>
            <ListItemButton 
                id={`${item.title}`}
                className="selected"
                selected={active == item.path}
                onClick={handleClick}
                sx={{ padding: 1.5, 
                    "&.Mui-selected": {
                        // backgroundColor: "rgb(3, 169, 244)",
                        backgroundColor: "rgb(0, 188, 212)",
                        borderRadius: 3,
                        color: "white"
                    },
                    "&.Mui-selected:hover": {backgroundColor: "rgba(0, 188, 212, 0.7)"}, 
                    // "&.Mui-selected:hover": {backgroundColor: "rgba(3, 169, 244, 0.7)"}, 
                    "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                }}
            >
                {/* <ListItemIcon>
                    <SendIcon />
                </ListItemIcon> */}
                <div className="w-8 text-lg flex items-center">
                    <i className={item.icon}></i>
                </div>
                <ListItemText primary={item.title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            { item.childrens.map((child, index) => 
            <>
            <Collapse key={index} in={open} timeout="auto" sx={{mt: 1}} unmountOnExit>
                <List component="div" disablePadding>
                    <a href={child.path || "#"}>
                        <ListItemButton 
                            className="submenu"
                            selected={location == child.path}
                            id={`${child.title}`} 
                            sx={{ 
                                ml: 2, 
                                paddingY: 1.5, 
                                fontSize: 14, 
                                "&.Mui-selected": {
                                    // backgroundColor: "rgb(239, 246, 255)",
                                    backgroundColor: "rgb(232, 255, 255)",
                                    borderRadius: 3,
                                    color: "rgb(0, 109, 131)",
                                    fontWeight: "bold"
                                    // color: "rgba(59, 130, 246)"
                                },
                                "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                            }}
                        >
                            {child.title}
                            {/* <ListItemIcon>
                                <StarBorder />
                            </ListItemIcon> */}
                            {/* <ListItemText primary={child.title} /> */}
                        </ListItemButton>
                    </a>
                </List>
            </Collapse>
            </>
            ) }
            </> 
            :
            <>
            <a href={item.path || "#"}>
                <ListItemButton 
                    id={`${item.title}`} 
                    selected={location == item.path}
                    sx={{ padding: 1.5, 
                        "&.Mui-selected": {
                            // backgroundColor: "rgb(3, 169, 244)",
                            backgroundColor: "rgb(0, 188, 212)",
                            borderRadius: 3,
                            color: "white"
                        }, 
                        "&.Mui-selected:hover": {backgroundColor: "rgba(0, 188, 212, 0.7)"}, 
                        // "&.Mui-selected:hover": {backgroundColor: "rgba(3, 169, 244, 0.7)"},  
                        "&.MuiListItemButton-root:hover": {borderRadius: 3} 
                    }}
                >
                    {/* <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon> */}
                    <div className="w-8 text-lg flex items-center">
                        <i className={item.icon}></i>
                    </div>
                    <ListItemText primary={item.title} />
                </ListItemButton>
            </a>
            </>
        }
        </List>
    )
}