import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import LicenseCard from 'components/LicenseCard';
import { useTransaction } from "query/actions/transaction/Crud"
import { Swiper, SwiperSlide } from "swiper/react";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@material-tailwind/react/Button";
import { TextField, MenuItem } from "@mui/material";
import Example from "components/Spinner/Example";
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import required modules
import { Pagination } from "swiper";
import { useGetPetList } from "query/actions/plate/Crud";
import ResponseStatus from "./ResponseStatus";
import { useNewPetPlate } from "query/actions/plate/Crud";

export default function DirectTransaction({id, action, closeModal, setRefreshTable, setTitle}) {
    const swiperRef = useRef(null);
    const [openPlate, setOpenPlate] = useState(false)
    const [licenseId, setLicenseId] = useState('')

    useEffect(() => {
        if(action==7){
            setLicenseId(id)
            setTimeout(() => {
                setOpenPlate(true)
                setTitle('Serahan Patil')
            //     swiperRef.current.slideNext();
            }, 500)
        }
    }, [])

    return (
        <>
        <Swiper
            slidesPerView={"auto"}
            // spaceBetween={20}
            // slidesOffsetAfter={20}
            className="mySwiper"
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            allowTouchMove={false}
            // observer={true}
            // rebuildOnUpdate={true}
        >
            {action==7 ? '' :
            <SwiperSlide>
                <FormTransaction 
                    id={id} 
                    swiperRef={swiperRef} 
                    closeModal={closeModal} 
                    setTitle={setTitle} 
                    setOpenPlate={setOpenPlate} 
                    setLicenseId={setLicenseId}
                />
            </SwiperSlide>
            }
            <SwiperSlide>
                {/* {licenseId=='' ? 'Loading...' : */}
                    <FormPlate 
                        id={licenseId} 
                        action={action} 
                        closeModal={closeModal} 
                        setTitle={setTitle} 
                        openPlate={openPlate} 
                        setRefreshTable={setRefreshTable}
                    /> 
                {/* } */}
            </SwiperSlide>
        </Swiper>
        </>
    )
}

const FormTransaction = ({id, swiperRef, closeModal, setTitle, setOpenPlate, setLicenseId}) => {
    const { mutate, isLoading, data } = useTransaction();
    const userInfo = useRecoilValue(userInfoAtom);

    const validationSchema = Yup.object().shape({
        receipt: Yup.string().required(`Ruangan Wajib Diisi`),
    });

    const { handleSubmit, control, formState:{ errors } } = useForm({
        defaultValues: { receipt: '' },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        let dat = {id: id, receipt: data.receipt }
        await mutate(dat, {
            onSuccess: (data) => {
                setLicenseId(data.data.data.license_id);
                setOpenPlate(true)
                setTitle('Serahan Patil')
                setTimeout(() => {
                    swiperRef.current.slideNext();
                }, 250)
            }
        })
        // setOpenPlate(true)
        // setTitle('Serahan Patil')
        // swiperRef.current.slideNext();
    }

    return (
        <>
        <DialogContent>
            <div className="flex flex-col my-1">
                <div className="w-full px-2 mb-6">
                    <h2 className="text-sm">Sila masukkan resit bayaran untuk pengesahan.</h2>
                    <div className="mt-4 mb-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="receipt"
                                        size='small' 
                                        label="Resit Bayaran" 
                                        variant="outlined" 
                                        InputLabelProps={{ shrink: true }} 
                                        helperText={errors.receipt ? errors.receipt.message : ' '}
                                        error={errors.receipt ? errors.receipt : false}
                                        fullWidth
                                    />
                                )}
                                control={control}
                                name="receipt"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            {isLoading ? 
                <>
                    <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                    <span className='ml-2'>Loading...</span>
                </> : 
                <>
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>

                    {userInfo?.isAdmin ?
                    <Button
                        color="green"
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                    >
                        Sah Bayaran
                    </Button>
                    : ''
                    }
                </>
            }
        </DialogActions>
        </>
    )
}

const FormPlate = ({id, action, closeModal, setTitle, openPlate, setRefreshTable}) => {
    const { data } = useGetPetList(id);
    const { mutate, isLoading, isSuccess, isFetching } = useNewPetPlate();
    const userInfo = useRecoilValue(userInfoAtom);
    const [responsePage, setResponsePage] = useState(false);

    const formSchema = Yup.object().shape({
        plate: Yup.string().required(`Ruangan Wajib Diisi`)
    });

    const validationSchema = Yup.object().shape({
        pet: Yup.array().of(formSchema).required("Ruangan Wajib Diisi")
    });

    const { handleSubmit, control, formState:{ errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { pet: [{ plate: '' }] },
    });

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "pet"
    });

    useEffect(() => {
        // console.log('trigger 1')
        // if(!isFetching){
        //     console.log('trigger 2')
        //     for(let i=1; i < data?.total; i++){
        //         console.log('trigger 3')
        //         if(id != ''){
        //             console.log('trigger 4')
        //             append({plate: ''})
        //         }
        //     }
        // }
        console.log('trigger 1')
        if(data != undefined){
            console.log('trigger 2')
            for(let i=1; i < data?.total; i++){
                console.log('trigger 3')
                if(id != ''){
                    console.log('trigger 4')
                    append({plate: ''})
                }
            }
        }
    }, [data])
    console.log('datadata', data)

    const onSubmit = async (data) => {
        // setResponsePage(false)
        let dat = {id: id, plates: data.pet }
        console.log('plate',dat)
        await mutate(dat, {
            onSuccess: (data) => {
                setTitle('')
                setResponsePage(true)
            }
        })
    }

    return (
        <>
        <DialogContent>
            <div className="flex flex-col my-1">
                <div className="w-full px-2 mb-6">
                    {responsePage ? 
                    <ResponseStatus 
                        type={'success'} 
                        close={null} 
                        title={'Berjaya!'}
                        subtitle={action==7 ? 'Serahan patil telah selesai.' : 'Pengesahan bayaran dan serahan patil telah selesai.'}
                        isButton={null}
                    /> :
                    <>
                    <h2 className="text-sm">Sila masukkan no. patil untuk serahan.</h2>
                    <div className="mt-4 mb-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            return (
                            <div key={item.id} className="mt-2 flex flex-wrap">
                                {/* <div className='flex flex-wrap w-full lg:w-6/12'> */}
                                    {/* <div className="w-full mb-0 font-light"> */}
                                        <Controller
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    id="plate"
                                                    size='small' 
                                                    label={`No. Patil ${index + 1}`}
                                                    variant="outlined" 
                                                    InputLabelProps={{ shrink: true }} 
                                                    helperText={errors?.pet?.[index]?.plate ? errors?.pet?.[index]?.plate.message : ' '}
                                                    error={errors?.pet?.[index]?.plate ? errors?.pet?.[index]?.plate : false}
                                                    fullWidth
                                                />
                                            )}
                                            control={control}
                                            name={`pet[${index}].plate`}
                                        />
                                    {/* </div> */}
                                {/* </div>
                                <div className='flex flex-col w-full lg:w-6/12'> */}
                                    {/* <div className="capitalize">{openPlate ? data[index].gender : ''}</div>
                                    <div className="capitalize">{openPlate ? data[index].breed : ''}</div> */}
                                {/* </div> */}
                            </div>
                            )
                            
                        })}
                        </form>
                    </div>
                    </>
                    }
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            {isLoading ? 
                <>
                    <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                    <span className='ml-2'>Loading...</span>
                </> : 
                <>
                {responsePage ?
                    <Button 
                        color="green"
                        // buttonType="link"
                        onClick={(e) => { closeModal(); setRefreshTable(true) }}
                        ripple="light"
                    >
                        Selesai
                    </Button>
                : 
                    <>
                    {action==7 ?
                    <Button 
                        color="gray"
                        buttonType="link"
                        onClick={(e) => closeModal()}
                        ripple="dark"
                    >
                        Kembali
                    </Button>
                    : ''}

                    {userInfo?.isAdmin ?
                    <Button
                        color="green"
                        // onClick={() => onSubmitTransaction(id)}
                        onClick={handleSubmit(onSubmit)}
                        ripple="light"
                    >
                        Simpan
                    </Button>
                    : ''
                    }
                    </>
                }
                </>
            }
        </DialogActions>
        </>
    )
}