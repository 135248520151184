import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';

import { toast } from 'react-toastify';

import * as api from "api/Admin";

const useGetPetList = (license_id) => {
    return useQuery(["GetPetList", license_id], () => api.getPetList(license_id));
};

const useNewPetPlate = () => {
    return useMutation(newPetPlateRequest, {
        onSuccess: (data) => {
            // toast.success("Sah Bayaran Berjaya!", { 
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: 5000
            // });
        },
    });
};
const newPetPlateRequest = async (data) => {
    return api.newPetPlateRequest(data)
}

const useDeliveryView = () => {
    return useMutation(deliveryViewRequest, {
        onSuccess: (data) => {
        },
    });
};
const deliveryViewRequest = async (data) => {
    return api.deliveryViewRequest(data)
}

const useDeliveryUpdate = () => {
    return useMutation(deliveryUpdateRequest, {
        onSuccess: (data) => {
        },
    });
};
const deliveryUpdateRequest = async (data) => {
    return api.deliveryUpdateRequest(data)
}

export { 
    useGetPetList,
    useNewPetPlate,
    useDeliveryView,
    useDeliveryUpdate
};