import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from "@material-tailwind/react/Button";
import Input from "@material-tailwind/react/Input";
import InputIcon from "@material-tailwind/react/InputIcon";
import { useRegister } from "query/actions/Registration";
import { Spinner } from 'components/Spinner';
import ResponseStatus from "./ResponseStatus";
import { toast } from 'react-toastify';
import { TextField } from "@mui/material";

export default function UserInfo({closeModal, userData, accessData}){    
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useRegister();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        mykad: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. MyKad Tidak Sah')
                .min(12, "Minimum nombor adalah 12 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        // name: Yup.string().required(`Ruangan Wajib Diisi`),
        phone: Yup.string().required(`Ruangan Wajib Diisi`)
                .matches(phoneRegExp, 'No. Telefon Tidak Sah')
                .min(10, "Minimum nombor adalah 10 digit")
                .max(12, "Maksimum nombor adalah 12 digit"),
        // email: Yup.string().email('Format emel salah').required(`Ruangan Wajib Diisi`),
        // password: Yup.string().required(`Ruangan Wajib Diisi`),
        // confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Katalaluan Tidak Sama')
    });
    const { register, handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues: {
            // mykad: '',
            name: '',
            phone: '',
            // email: '',
            // password: '',
            // confirm_password: ''
        },
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        // console.log('errorserrors',errors)
        // console.log('datadata',data)
        // // logIn(data)
        let requestData = {
            ...data, 
            email: userData?.email,
            name: userData?.name,
            access_token: accessData?.access_token,
            refresh_token: accessData?.refresh_token
        }
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        await mutate(requestData, {
            onSuccess: (data) => {
                Notify(data.data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "All is good", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        if(code == '500'){
            toast.update(loadingToast, { 
                render: "Wrong", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
        
        setPage(
            <ResponseStatus 
                type={'success'} 
                close={null} 
                title={'Berjaya!'}
                subtitle={'Log masuk ke akaun anda sekarang.'}
                isButton={{name: 'Teruskan', page: '/utama'}}
            />
        )
    }

    const passwordVisibility = () => {
        setShowPassword(!showPassword)
    }

    return (
        <>
        {/* <div className="lg:w-2xl md:w-lg"> */}
            {isLoading ? <div className='flex justify-center items-center'><Spinner /></div> :
            page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-col w-full">
                    <div className="flex justify-center mt-1 mb-5">
                        <div className="w-full rounded-lg border border-gray-400 p-5 mb-2 font-light">
                            <div className="flex flex-row">
                                <div className="w-full lg:w-6/12 pr-4">
                                    <TextField
                                        label="Nama"
                                        variant="standard"
                                        size="small"
                                        color="secondary"
                                        value={userData?.name}
                                        fullWidth
                                        readOnly
                                    />
                                </div>
                                <div className="w-full lg:w-6/12 pl-4">
                                    <TextField
                                        label="Emel"
                                        variant="standard"
                                        size="small"
                                        color="secondary"
                                        value={userData?.email}
                                        fullWidth
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col my-1">
                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="No. MyKad (ID Pengguna)"
                                            error={errors.mykad ? errors.mykad.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="mykad"
                                />
                            </div>

                            <div className="w-full mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="text"
                                            color="lightBlue"
                                            size="regular"
                                            outline={true}
                                            placeholder="No. Telefon"
                                            error={errors.phone ? errors.phone.message : ''}
                                        />
                                    )}
                                    control={control}
                                    name="phone"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="red"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Log Keluar
                </Button>

                <Button
                    color="green"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Simpan
                </Button>
            </DialogActions>
            </>
            }
        {/* </div> */}
        </>
        
    )
}