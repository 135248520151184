import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

// recoil atom
import { userInfoAtom } from 'recoil/UserInfo';
import * as api from "api/Api";

const useRegister = () => {
    let history = useHistory();
    const [auth, setAuth] = useRecoilState(userInfoAtom);

    return useMutation(registerRequest, {
        onSuccess: (data) => {
            // setAuth(data.data);
            // history.replace('/')
            <><div>Test return</div></>
        },
    });
};
const registerRequest = async (credential) => {
    return api.registerRequest(credential)
}

export { useRegister };