import React, { useState, useEffect } from 'react';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import View from './ActionButton/View';
import { useHistory } from 'react-router-dom';

export default function ButtonGroupActionLesen({isAdmin, id, status, plate_no, row, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);
    const [hideButtonClose, setHideButtonClose] = useState(false);
    
    let history = useHistory()
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }

    const actionButton = (id, action) => {
        setSize('sm')
        setBackdropClose(false)
        setHideButtonClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Butiran Lesen"; setSize('lg'); setBackdropClose(true); break;
            case 2: titleModal = "Status Permohonan"; break;
            case 3: titleModal = "Status Permohonan"; break;
            case 4: titleModal = "Batal Lesen"; break;
            case 5: titleModal = "Pengesahan Bayaran"; setHideButtonClose(true); break;
            case 6: titleModal = "Cetak Bil"; setSize('md'); setBackdropClose(true); break;
            case 7: titleModal = "Serahan Patil"; setHideButtonClose(true); break;
        }
        setTitle(titleModal)
        setPage(<View 
            closeModal={closeModal} 
            id={id} 
            action={action} 
            type={2} 
            setRefreshTable={setRefreshTable} 
            setTitle={setTitle}
            // setBackdropClose={setBackdropClose}
            // setHideButtonClose={setHideButtonClose}
        />)
        setShowModal(true)
    }

    return (
        <>
        {isAdmin ? 
            <div className='flex flex-wrap my-4'>
                <div className={`p-1`}>
                    <Tooltip title="Lihat" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="indigo"
                            iconOnly
                            onClick={() => actionButton(id, 1)}
                        >
                            <i className="fa-solid fa-eye" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* <div className={`p-1 ${status == '2' || status == '4' ? 'hidden' : ''}`}> */}
                <div className={`p-1 ${(status == '1') ? '' : 'hidden'}`}>
                    <Tooltip title="Pengesahan Bayaran" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="teal"
                            iconOnly
                            onClick={() => actionButton(id, 5)}
                        >
                            <i className="fa-solid fa-file-invoice-dollar" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* <div className={`p-1 ${status == '2' ? 'hidden' : ''}`}>
                    <Tooltip title="Cetak Bil" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="blue"
                            iconOnly
                            onClick={() => actionButton(id, 6)}
                        >
                            <i className="fa-solid fa-print" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> */}
                <div className={`p-1 ${status == '4' ? 'hidden' : ''}`}>
                    <Tooltip title="Batal" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="red"
                            iconOnly
                            onClick={() => actionButton(id, 4)}
                        >
                            <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${(status == '2' && plate_no == 0 && row.submission_type != 'Perkhidmatan Kurier') ? '' : 'hidden'}`}>
                    <Tooltip title="Serah Patil" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                            onClick={() => actionButton(id, 7)}
                        >
                            <i className="fa-solid fa-dog" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${(status == '2' && plate_no == 0 && row.submission_type == 'Perkhidmatan Kurier') ? '' : 'hidden'}`}>
                    <Tooltip title="Penghantaran Patil" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                            onClick={() => history.push('/v2/lesen/penghantaran-patil')}
                        >
                            <i className="fa-solid fa-shipping-fast text-black" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>
        : 
            <div className='flex flex-wrap my-4'>
                <div className='p-1'>
                    <Tooltip title="Lihat" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="indigo"
                            iconOnly
                            onClick={() => actionButton(id, 1)}
                        >
                            <i className="fa-solid fa-eye" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* <div className={`p-1 ${status == '2' || status == '4' ? 'hidden' : ''}`}> */}
                <div className={`p-1 ${(status == '1') ? '' : 'hidden'}`}>
                    <Tooltip title="Bayar" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="green"
                            iconOnly
                        >
                            <i className="fa-solid fa-credit-card" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                {/* <div className={`p-1 ${status == '3' ? '' : 'hidden'}`}>
                    <Tooltip title="Mohon Semula" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="blue"
                            iconOnly
                        >
                            <i className="fa-solid fa-arrow-rotate-right" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> */}
                {/* <div className={`p-1 ${status == '4' ? '' : 'hidden'}`}>
                    <Tooltip title="Kemaskini" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                        >
                            <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> */}
            </div>
        }

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={size}
                title={title}
                content={page}
                backdropClose={backdropClose}
                hideButtonClose={hideButtonClose}
            /> : 
        ''}
        </>
    )
}