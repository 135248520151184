import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useVerifyLogin } from "query/actions/Auth";
import UserInfo from "components/UserInfo";
import CustomModal from "components/CustomModal";
import { Spinner } from "components/Spinner";
import { FullPageSpinner } from "components/Spinner/fullPageSpinner";

export default function Test() {
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    // console.log('search',search)
    // console.log('code',code)
    const { mutate, isLoading } = useVerifyLogin()
    const [showModal, setShowModal] = useState(false);
    const [accessToken, setAccessToken] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [accessData, setAccessData] = useState()
    const [userData, setUserData] = useState()
    const history = useHistory();

    async function closeModal() {
        await setShowModal(false);
        history.replace('/logmasuk')
    }

    const accessTokenRequest = async (data) => {
        let res = await axios.post(
            'https://oauth.mpkk.gov.my/oauth/token',
            {
                grant_type: 'authorization_code',
		 		client_id: 7,
		 		client_secret: 'vrsyM6Ob2P6IT1pO1IKPbCwuh3nePXBBzSqiPgXt',
		 		redirect_uri: 'https://oro-staging.mpkk.gov.my/apps/auth/callback',
		 		code: data,
            },
            {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
				"Accept": 'application/json, text/plain, */*'
            }
        );
        setAccessToken(res.data.access_token)
        setAccessData(res.data)
        // console.log('res', res)
    }
    const userInfo = async (data) => {
        const headers = {
            headers: { Authorization: `Bearer ${data}` }
        };
        let res = await axios.get(
            `https://oauth.mpkk.gov.my/api/user`,
            headers
        );

        if(res.data.email != ''){
            setUserData(res.data)
            setName(res.data.name)
            setEmail(res.data.email)
            verifyLogin(res.data.email)
        }
        // console.log('resUserInfo', res)
    }

    const verifyLogin = (email) => {
        mutate(email, {
            onSuccess: (data) => {
                if (data.data.response.response_code === "201") {
                    setShowModal(true)
                }
            },
        });
    }

    useEffect(() => {
        accessTokenRequest(code)
    }, [code])

    useEffect(() => {
        if(accessToken != ''){
            userInfo(accessToken)
        }
    }, [accessToken])


    return <>
        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                hideButtonClose={true}
                size='sm'
                title={'Maklumat Pengguna Baru'}
                content={<UserInfo userData={userData} accessData={accessData} closeModal={closeModal} />}
            /> : 
            // <div className='flex justify-center items-center'><Spinner /></div>
            <FullPageSpinner />
        }
    </>
}