import React, { useState, useEffect } from 'react';
import { Button } from '@material-tailwind/react';
import { DialogActions, DialogContent, Tooltip, MenuItem, TextField, FormControl, InputLabel, Select, OutlinedInput, Box, Chip, FormControlLabel, Checkbox } from '@mui/material';
import CustomModal from './CustomModal';
import { useApplicationView } from 'query/actions/Crud';
import View from './ActionButton/View';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import Example from './Spinner/Example';
import { Spinner } from './Spinner';
import { useRegisterStaff } from 'query';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useUpdateStaff } from 'query';
import items from 'data/sidebar.json'
import { useRoles } from 'query/admin';
import { useUpdateRole } from 'query/admin';

export default function ButtonGroupActionPeranan({isAdmin, id, data, status, channel, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);

    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }
    
    // view
    const getApplicationView = async (data) => {
        await mutateView(data, {
            onSuccess: (data) => {
                setResults(data.data.data)
            }
        })
    }

    const actionButton = (id, action) => {
        // let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
        // let data = {'id': id, 'user': accessToken}
        // if(action == 'Lihat'){
        //     setTitle('Butiran Permohonan')
        //     // getApplicationView(data)
        // }
        setSize('sm')
        setBackdropClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Kemaskini Peranan"; break;
            case 2: titleModal = "Padam Peranan"; break;
            // case 1: titleModal = "Butiran Permohonan"; setSize('lg'); setBackdropClose(true); break;
            // case 2: titleModal = "Status Permohonan"; break;
            // case 3: titleModal = "Status Permohonan"; break;
            // case 4: titleModal = "Status Permohonan"; break;
            // case 5: titleModal = "Pengesahan Bayaran"; break;
            // case 6: titleModal = "Cetak Bil"; setSize('md'); setBackdropClose(true); break;
            // case 7: titleModal = "Status Permohonan";
        }
        setTitle(titleModal)
        setPage(<UpdatePeranan closeModal={closeModal} data={data} type={action} setRefreshTable={setRefreshTable} />)
        setShowModal(true)
    }

    return (
        <>
        <div className='flex flex-wrap my-4'>
            <div className={`p-1`}>
                <Tooltip title="Kemaskini" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="orange"
                        iconOnly
                        onClick={() => actionButton(data.role_id, 1)}
                    >
                        <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
            <div className={`p-1`}>
                <Tooltip title="Padam" placement="top">
                    <Button
                        type="button"
                        size="sm"
                        color="red"
                        iconOnly
                        onClick={() => actionButton(data.role_id, 2)}
                    >
                        <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                    </Button>
                </Tooltip>
            </div>
        </div>

        {showModal ? 
            <CustomModal 
                closeModal={closeModal} 
                size={size}
                title={title}
                content={page}
                backdropClose={backdropClose}
            /> : 
        ''}
        </>
    )
}

function UpdatePeranan({type, data, closeModal, setRefreshTable}){    
    const [page, setPage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const { mutate, error, isError, isLoading, isSuccess } = useUpdateRole();
    const roles = useRoles();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const validationSchema = Yup.object().shape({
        title: Yup.string().required(`Ruangan Wajib Diisi`),
    });
    const { register, handleSubmit, control, formState:{ errors }, reset, setValue } = useForm({
        defaultValues: {
            title: '',
            lesen: '',
            laporan: '',
            tetapan: '',
            delete: false,
            edit: false
        },
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = async (dataSubmit) => {
        const loadingToast = toast.loading("Please wait...", {
            position: toast.POSITION.TOP_CENTER
        });
        dataSubmit['role_id'] = data.id
        // console.log('dataSubmit',dataSubmit)
        await mutate(dataSubmit, {
            onSuccess: (data) => {
                Notify(data.response.response_code, loadingToast)
            }
        })
    }

    const Notify = (code, loadingToast) => {
        if(code == '200'){
            toast.update(loadingToast, { 
                render: "Berjaya!", 
                type: "success", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
            setRefreshTable(true)
            closeModal()
        }
        
        if(code == '201'){
            toast.update(loadingToast, { 
                render: "Ralat!", 
                type: "error", 
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000
            });
        }
    }

    useEffect(() => {
        reset({
            title: data.title,
            lesen: JSON.parse(data.role)['lesen'],
            kacauganggu: JSON.parse(data.role)['kacauganggu'],
            penguatkuasaan: JSON.parse(data.role)['penguatkuasaan'],
            laporan: JSON.parse(data.role)['laporan'],
            tetapan: JSON.parse(data.role)['tetapan'],
            delete: JSON.parse(data.role)['delete'],
            edit: JSON.parse(data.role)['edit']
        })
        setValue('lesen', JSON.parse(data.role)['lesen'])
        setValue('kacauganggu', JSON.parse(data.role)['kacauganggu'])
        setValue('penguatkuasaan', JSON.parse(data.role)['penguatkuasaan'])
        setValue('laporan', JSON.parse(data.role)['laporan'])
        setValue('tetapan', JSON.parse(data.role)['tetapan'])
        setValue('delete', JSON.parse(data.role)['delete'])
        setValue('edit', JSON.parse(data.role)['edit'])
        menu['lesen'] = JSON.parse(data.role)['lesen']
        menu['kacauganggu'] = JSON.parse(data.role)['kacauganggu']
        menu['penguatkuasaan'] = JSON.parse(data.role)['penguatkuasaan']
        menu['laporan'] = JSON.parse(data.role)['laporan']
        menu['tetapan'] = JSON.parse(data.role)['tetapan']
        // console.log('role_resources', JSON.parse(data.role_resources)['delete'])
    }, [data])

    function onStatusUpdate(type){
        let d = {
            remove: type
        }
        onSubmit(d)
    }
    // console.log('data',data)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    };

    const [menu, setMenu] = useState([]);
    const handleChange = (event, params) => {
        const {
          target: { value }
        } = event;
        // console.log('paramsparams',params)
        setValue(params.state, typeof value === "string" ? value.split(",") : value)
        menu[params.state] = typeof value === "string" ? value.split(",") : value
    };
    // console.log('menu',menu)

    return (
        <>
        {type == 1 ? 
            isLoading ? <div className='flex justify-center items-center'><Example type='spin' color='black' height='5rem' width='5rem' /></div> :
            // page != null ? page :
            <> 
            <DialogContent>
                <div className="flex flex-col w-full">
                    {/* <span className="block text-sm mb-5">Sila lengkapkan semua ruangan.</span> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col w-full my-1">
                            
                            <div className="w-full pt-1 /mb-6 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            className="capitalize"
                                            label="Nama Peranan"
                                            variant="outlined"
                                            size="small"
                                            // color="secondary"
                                            helperText={errors.title ? errors.title.message : ' '}
                                            error={errors.title ? errors.title : false}
                                            fullWidth
                                            inputProps={{ style: { textTransform: "capitalize" } }}
                                        />
                                    )}
                                    control={control}
                                    name="title"
                                />
                            </div>
                            
                            <span className="block text-sm mb-1">Senarai Menu</span>
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[1].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.lesen ? menu?.lesen : []}
                                                onChange={(e) => handleChange(e, {state:'lesen',setstate:setMenu})}
                                                input={<OutlinedInput label={items[1].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[1].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="lesen"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[2].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.kacauganggu ? menu?.kacauganggu : []}
                                                onChange={(e) => handleChange(e, {state:'kacauganggu',setstate:setMenu})}
                                                input={<OutlinedInput label={items[2].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[2].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="kacauganggu"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[3].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.penguatkuasaan ? menu?.penguatkuasaan : []}
                                                onChange={(e) => handleChange(e, {state:'penguatkuasaan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[3].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[3].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="penguatkuasaan"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[4].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.laporan ? menu?.laporan : []}
                                                onChange={(e) => handleChange(e, {state:'laporan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[4].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[4].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="laporan"
                                />
                            </div>
                            
                            <div className="w-full mb-3 font-light">
                                <Controller
                                    render={({ field }) => (
                                        <FormControl
                                            {...field} 
                                            fullWidth
                                            size="small"
                                            error={errors.lesen ? errors.lesen.message : ''}
                                        >
                                            <InputLabel id="demo-multiple-name-label">{items[5].title}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                // {...field}
                                                multiple
                                                value={menu?.tetapan ? menu?.tetapan : []}
                                                onChange={(e) => handleChange(e, {state:'tetapan',setstate:setMenu})}
                                                input={<OutlinedInput label={items[5].title} />}
                                                renderValue={(selected) => {
                                                    return (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                        {selected.map((person, index) => (
                                                            <Chip key={index} label={person} />
                                                        ))}
                                                    </Box>
                                                    );
                                                }}
                                                MenuProps={MenuProps}
                                                fullWidth
                                                // error={errors.name ? errors.name.message : ''}
                                            >
                                                {items[5].childrens.map((child, index) => 
                                                    <MenuItem key={index} value={child.title}>{child.title}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    control={control}
                                    name="tetapan"
                                />
                            </div>

                            <span className="block text-sm mt-6 mb-2">Senarai Tindakan</span>
                            <div className='flex /justify-between w-full -mt-4'>
                                <div className="w-1/2 px-4 mb-0 font-light">
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                            <FormControl
                                                {...field}
                                                variant="outlined"
                                            >
                                                {/* <div className='-mb-2 font-medium'>Kemaskini</div> */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={field.value} name="edit" />
                                                        // <Checkbox {...field} name="edit" />
                                                    }
                                                    label="Kemaskini"
                                                />
                                            </FormControl>
                                            </>
                                        )}
                                        control={control}
                                        name={`edit`}
                                    />
                                </div>
                                <div className="w-1/2 px-4 mb-0 font-light">
                                    <Controller
                                        render={({ field }) => (
                                            <>
                                            <FormControl
                                                {...field}
                                                variant="outlined"
                                            >
                                                {/* <div className='-mb-2 font-medium'>Batal</div> */}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={field.value} name="delete" />
                                                        // <Checkbox {...field} name="delete" />
                                                    }
                                                    label="Batal"
                                                />
                                                </FormControl>
                                            </>
                                        )}
                                        control={control}
                                        name={`delete`}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="gray"
                    buttonType="link"
                    onClick={(e) => closeModal()}
                    ripple="dark"
                >
                    Kembali
                </Button>

                <Button
                    color="green"
                    onClick={handleSubmit(onSubmit)}
                    ripple="light"
                >
                    Tambah
                </Button>
            </DialogActions>
            </>
        : 
            <>
            <DialogContent>
                <div className="flex flex-col my-1">
                    <div className="w-full px-2 mb-6">
                        <h2 className="text-sm">Anda pasti untuk padam?</h2>
                        <div className="mt-4 mb-3">
                            <span className="pr-1 text-gray-500">Nama Peranan: </span>
                            <span className="font-bold text-sm capitalize">{data.title}</span>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {/* {isLoadingUpdate ? 
                    <>
                        <Example type='spin' color='black' height='1.25rem' width='1.25rem' />
                        <span className='ml-2'>Loading...</span>
                    </> :  */}
                    <>
                        <Button 
                            color="gray"
                            buttonType="link"
                            onClick={(e) => closeModal()}
                            ripple="dark"
                        >
                            Kembali
                        </Button>
                        
                        <Button
                            color="red"
                            onClick={() => onStatusUpdate(3)}
                            ripple="light"
                        >
                            Padam
                        </Button>
                    </>
                {/* } */}
            </DialogActions>
            </>
        }
        </>
        
    )
}