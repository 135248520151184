import React, { useState, useEffect } from 'react';
import { Button } from '@material-tailwind/react';
import { Tooltip } from '@mui/material';
import CustomModal from './CustomModal';
import { useApplicationView } from 'query/actions/Crud';
import View from './ActionButton/View';
// recoil
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'recoil/UserInfo';
import { useHistory } from 'react-router-dom';
import ViewKacauGanggu from './ActionButton/ViewKacauGanggu';

export default function ButtonGroupActionTangkapLupus({isAdmin, id, data, status, channel, setRefreshTable}) {
    const [results, setResults] = useState();
    const [title, setTitle] = useState();
    const [page, setPage] = useState(null);
    const [size, setSize] = useState('');
    const [backdropClose, setBackdropClose] = useState(false);
    const [hideButtonClose, setHideButtonClose] = useState(false);

    const userInfo = useRecoilValue(userInfoAtom);
    const { mutate: mutateView, isLoading: isLoadingView, data: dataView } = useApplicationView();
    
    const [showModal, setShowModal] = useState(false);
    async function closeModal() {
        await setShowModal(false);
    }
    
    // view
    const getApplicationView = async (data) => {
        await mutateView(data, {
            onSuccess: (data) => {
                setResults(data.data.data)
            }
        })
    }

    let history = useHistory();
    function onHandlerRedirect(){
        if(userInfo?.isAdmin){
            history.push(`/lesen/permohonan-kemaskini/${id}`)
        } else {
            history.push(`/permohonan/kemaskini/${id}`)
        }
    }

    const actionButton = (id, data, action) => {
        // let accessToken = userInfo?.accessToken ? userInfo?.accessToken : '';
        // let data = {'id': id, 'user': accessToken}
        // if(action == 'Lihat'){
        //     setTitle('Butiran Permohonan')
        //     // getApplicationView(data)
        // }
        setSize('sm')
        setBackdropClose(false)
        setHideButtonClose(false)
        let titleModal;
        switch (action) {
            case 1: titleModal = "Butiran Operasi"; setSize('sm'); setBackdropClose(true); break;
            case 2: titleModal = "Kemaskini Operasi"; setSize('sm'); setBackdropClose(true); break;
            case 3: titleModal = "Batal Operasi"; break;
        }
        setTitle(titleModal)
        setPage(<ViewKacauGanggu 
            closeModal={closeModal} 
            id={id} 
            data={data}
            action={action} 
            type={1} 
            setRefreshTable={setRefreshTable} 
            setTitle={setTitle}
        />)
        setShowModal(true)
    }

    return (
        <>
            <div className='flex flex-wrap my-4'>
                <div className={`p-1`}>
                    <Tooltip title="Lihat" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="indigo"
                            iconOnly
                            onClick={() => actionButton(id, data, 1)}
                        >
                            <i className="fa-solid fa-eye" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
                <div className={`p-1 ${status == '2' ? 'hidden' : ''}`}>
                    <Tooltip title="Kemaskini" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="orange"
                            iconOnly
                            onClick={() => actionButton(id, data, 2)}
                        >
                            <i className="fa-solid fa-edit" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div> 
                <div className={`p-1`}>
                    <Tooltip title="Batal" placement="top">
                        <Button
                            type="button"
                            size="sm"
                            color="red"
                            iconOnly
                            onClick={() => actionButton(id, data, 3)}
                        >
                            <i className="fa-solid fa-ban" style={{fontSize: '1.25rem'}}></i>
                        </Button>
                    </Tooltip>
                </div>
            </div>

            {showModal ? 
                <CustomModal 
                    closeModal={closeModal} 
                    size={size}
                    title={title}
                    content={page}
                    backdropClose={backdropClose}
                    hideButtonClose={hideButtonClose}
                /> : 
            ''}
        </>
    )
}